import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeStocktakingRow } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createStocktakingRow(stocktakingId, params) {
  return dispatch => {
    return API.createStocktakingRow(stocktakingId, params)
      .then(res => {
        const { entities } = normalizeStocktakingRow(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { stocktakingId, params, data: res.data };
        dispatch({ type: types.STOCKTAKING_ROW_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateStocktakingRow(stocktakingRowId, params) {
  return dispatch => {
    return API.updateStocktakingRow(stocktakingRowId, params)
      .then(res => {
        const { entities } = normalizeStocktakingRow(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { stocktakingRowId, params, data: res.data };
        dispatch({ type: types.STOCKTAKING_ROW_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteStocktakingRow(stocktakingRowId) {
  return dispatch => {
    return API.deleteStocktakingRow(stocktakingRowId)
      .then(res => {
        const payload = { stocktakingRowId };
        dispatch({ type: types.STOCKTAKING_ROW_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
