import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

class BreadcrumbItem extends Component {
  isClickable = () => this.props.linkTo != null;

  renderLoadingItem = () => <div className={styles['loading-item']} />;

  render() {
    if (this.props.loading) return this.renderLoadingItem();

    let item = (
      <div
        className={`
        ${styles['item']}
        ${this.isClickable() ? styles['clickable'] : ''}
        ${this.props.active ? styles['active'] : ''}
      `}
      >
        {this.props.children}
      </div>
    );

    if (this.props.to) {
      return <Link to={this.props.to}>{item}</Link>;
    } else {
      return item;
    }
  }
}

BreadcrumbItem.propTypes = {
  linkTo: PropTypes.string,
  active: PropTypes.bool,
  loading: PropTypes.bool,
};

BreadcrumbItem.defaultProps = {
  loading: false,
};

export default BreadcrumbItem;
