import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeSparePartReservation } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function updateSparePartReservation(sparePartReservationId, params) {
  return dispatch => {
    return API.updateSparePartReservation(sparePartReservationId, params)
      .then(res => {
        const { entities } = normalizeSparePartReservation(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { sparePartReservationId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_RESERVATION_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteSparePartReservation(sparePartReservationId) {
  return (dispatch, getState) => {
    const sparePartReservation = getState().entities.sparePartReservationById[sparePartReservationId];
    return API.deleteSparePartReservation(sparePartReservationId)
      .then(res => {
        const payload = { sparePartReservationId, data: sparePartReservation };
        dispatch({ type: types.SPARE_PART_RESERVATION_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createSparePartReservationForWorkOrder(workOrderId, params) {
  return dispatch => {
    return API.createSparePartReservationForWorkOrder(workOrderId, params)
      .then(res => {
        const { entities } = normalizeSparePartReservation(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_RESERVATION_CREATED_FOR_WORK_ORDER, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createSparePartReservationForRecurringMaintenance(recurringMaintenanceId, params) {
  return dispatch => {
    return API.createSparePartReservationForRecurringMaintenance(recurringMaintenanceId, params)
      .then(res => {
        const { entities } = normalizeSparePartReservation(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { recurringMaintenanceId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_RESERVATION_CREATED_FOR_RECURRING_MAINTENANCE, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
