import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const Value = ({ skipBackground, children }) => {
  return <div className={skipBackground === false ? styles['item'] : null}>{children}</div>;
};

export default Value;

Value.propTypes = {
  skipBackground: PropTypes.bool,
};

Value.defaultProps = {
  skipBackground: false,
};
