
const getBillingInvoices = (state) => {
  const { billing, entities } = state;
  return billing.ids.map(id => entities.billingInvoiceById[id]);
}
const getTotalEntries = state => state.billing.totalEntries;
const getIsFullyLoaded = state => state.billing.isFullyLoaded;
const getPaginateFrom = state => state.billing.paginateFrom;

export default {
  getBillingInvoices,
  getTotalEntries,
  getIsFullyLoaded,
  getPaginateFrom
}
