import React from 'react';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default React.forwardRef((props, ref) => {
  const renderLabel = () => {
    if (props.caret) {
      return (
        <>
          <div>{props.label}</div>
          <div className={styles['caret']}>
            <Icon type="caret-down" size={12} />
          </div>
        </>
      );
    }
    return <div>{props.label}</div>;
  };

  return (
    <div className={styles['button']} onClick={props.onClick} ref={ref}>
      {renderLabel()}
    </div>
  );
});
