import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listRequestSelectableAssignee = (systemId, params) =>
  request.get(
    `systems/${systemId}/request_selectable_assignees?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
export const createRequestSelectableAssignee = (systemId, params) =>
  request.post(`systems/${systemId}/request_selectable_assignees`, params);
export const updateRequestSelectableAssignee = (requestSelectableAssigneeId, params) =>
  request.patch(`request_selectable_assignees/${requestSelectableAssigneeId}`, params);
export const deleteRequestSelectableAssignee = requestSelectableAssigneeId =>
  request.delete(`request_selectable_assignees/${requestSelectableAssigneeId}`);
