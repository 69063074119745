import { request, setUserBaseUrl } from 'sdk/utilities/Axios';
import store from 'state';
import HelperFunctions from 'utilities/HelperFunctions';
import { AuthTypes } from 'state/ducks/auth';

const init = () => {
  setUserBaseUrl(process.env.REACT_APP_API_URL);
  request.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response) {
        const endpoint = error.request.responseURL.substr(error.request.responseURL.lastIndexOf('/') + 1);
        if (endpoint !== 'login' && error.response.status === 401) {
          HelperFunctions.clearUserAuthToken();
          store.dispatch({ type: AuthTypes.UNAUTHENTICATE_USER });
          return Promise.reject(error);
        }
      } else if (error.request) {
        // The request was made but no response was received
      } else {
        // Something happened in setting up the request that triggered an Error
      }
      return Promise.reject(error);
    }
  );
};

export default {
  init,
};
