import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';

class Boolean extends Component {
  renderSubtitle = () => {
    switch (this.props.comparator) {
      case HelperFunctions.FILTER_COMPARABLES.Exists: {
        if (this.props.hasValue) {
          return <FormattedMessage id="filters.types.boolean.exists.title" />;
        }
        return <FormattedMessage id="filters.types.boolean.not-exists.title" />;
      }

      case HelperFunctions.FILTER_COMPARABLES.Exact: {
        return <FormattedMessage id="filters.types.boolean.exact.title" />;
      }

      default:
        return null;
    }
  };

  renderValue = () => {
    if (this.props.comparator === HelperFunctions.FILTER_COMPARABLES.Exact) {
      if (this.props.value === true) {
        return <FormattedMessage id="general.true" />;
      }
      if (this.props.value === false) {
        return <FormattedMessage id="general.false" />;
      }
    }
    return null;
  };

  render() {
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Container
        title={this.props.title}
        subtitle={this.renderSubtitle()}
        onClear={() => this.props.onClear()}
      >
        {this.renderValue()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Container>
    );
  }
}

Boolean.propTypes = {
  required: PropTypes.bool,
  title: PropTypes.node,
  comparator: PropTypes.string,
  onClear: PropTypes.func,
};

Boolean.defaultProps = {
  title: null,
  comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
  required: false,
  onClear: () => {},
};

export default Boolean;
