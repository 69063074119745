import types from './types';

const INITIAL_STATE = {
  title: null,
  tabs: null,
  selectedTab: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_OPTIONS:
      return {
        tabs: action.payload.tabs,
        title: action.payload.title,
        selectedTab: action.payload.tabs == null ? null : action.payload.tabs.find(tab => tab.selected === true) === null ? null : action.payload.tabs.find(tab => tab.selected === true).id,
      };
    case types.CLEAR_OPTIONS:
      return {
        title: null,
        tabs: null,
        selectedTab: null,
      };
    case types.SELECT_OPTION:
      return {
        ...state,
        selectedTab: action.payload,
      }
    default:
      return state;
  }
};
