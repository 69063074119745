import { HelperFunctions, SDKReduxTypes } from 'sdk';
import { uniq } from 'lodash-es';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.TEMPLATE_FIELD_CREATED:
    case SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_CREATED:
    case SDKReduxTypes.TEMPLATE_FIELD_UPDATED:
    case SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_UPDATED: {
      let templateFieldId;
      let sort;
      let column;
      let templateType;
      if (action.type === SDKReduxTypes.TEMPLATE_FIELD_CREATED) {
        templateFieldId = action.payload.data.id;
        sort = action.payload.data.sort;
        column = action.payload.data.column;
        templateType = action.payload.data.template_type;
      } else if (action.type === SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_CREATED) {
        templateFieldId = action.payload.id;
        sort = action.payload.sort;
        column = action.payload.column;
        templateType = action.payload.template_type;
      } else if (action.type === SDKReduxTypes.TEMPLATE_FIELD_UPDATED) {
        templateFieldId = action.payload.data.id;
        sort = action.payload.data.sort;
        column = action.payload.data.column;
        templateType = action.payload.data.template_type;
      } else if (action.type === SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_UPDATED) {
        templateFieldId = action.payload.id;
        sort = action.payload.sort;
        column = action.payload.column;
        templateType = action.payload.template_type;
      }

      const fieldsToSort = Object.entries(state)
        .map(([id, templateField]) => templateField)
        .filter(
          field =>
            field.template_type === templateType && field.column === column && field.id !== templateFieldId
        )
        .sort((a, b) => a.sort - b.sort);

      let i = 0;
      const newTemplateFields = fieldsToSort.reduce((acc, field) => {
        i++;
        if (i === sort) i++;
        return {
          ...acc,
          [field.id]: {
            ...field,
            sort: i,
          },
        };
      }, {});

      return {
        ...state,
        ...newTemplateFields,
      };
    }
    case SDKReduxTypes.TEMPLATE_FIELD_ITEM_CREATED:
    case SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_ITEM_CREATED: {
      let templateFieldId;
      let templateFieldItemId;
      if (action.type === SDKReduxTypes.TEMPLATE_FIELD_ITEM_CREATED) {
        templateFieldId = action.payload.templateFieldId;
        templateFieldItemId = action.payload.data.id;
      } else if (action.type === SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_ITEM_CREATED) {
        templateFieldId = action.payload.template_field_id;
        templateFieldItemId = action.payload.id;
      }
      if (state[templateFieldId]) {
        return {
          ...state,
          [templateFieldId]: {
            ...state[templateFieldId],
            template_field_items: uniq([...state[templateFieldId].template_field_items, templateFieldItemId]),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.TEMPLATE_FIELD_ITEM_DELETED:
    case SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_ITEM_DELETED: {
      let templatefieldItemId;
      if (action.type === SDKReduxTypes.TEMPLATE_FIELD_ITEM_DELETED) {
        templatefieldItemId = action.payload.templateFieldItemId;
      } else if (action.type === SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_ITEM_DELETED) {
        templatefieldItemId = action.payload;
      }
      return Object.entries(state).reduce((acc, [id, templateField]) => {
        return {
          ...acc,
          [id]: {
            ...templateField,
            template_field_items: templateField.template_field_items.filter(id => id !== templatefieldItemId),
          },
        };
      }, {});
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.templateFieldById) {
        return HelperFunctions.mergeEntities(state, action.payload.templateFieldById);
      }
      return state;
    default:
      return state;
  }
};
