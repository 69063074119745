import actions from "./actions";

const setFieldErrors = actions.setFieldErrors;
const removeFieldsFromErrors = actions.removeFieldsFromErrors;
const removeAllFieldErrors = actions.removeAllFieldErrors;

export default {
  setFieldErrors,
  removeFieldsFromErrors,
  removeAllFieldErrors,
};
