import React from 'react';
import Currencies from 'currency-format';
import { Decimal } from 'decimal.js';

export default ({
  value,
  currency,
  fractionSize = Currencies[currency].fractionSize,
  currencyFontSize = null,
}) => {
  if (value == null) return '-';

  const renderValue = () => {
    const decimalValue = Decimal.isDecimal(value) ? value : new Decimal(value);
    return new Decimal(decimalValue)
      .toFixed(fractionSize)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  return (
    <>
      <span>{renderValue()}</span>
      <span>&nbsp;</span>
      <span style={{ fontSize: currencyFontSize }}>{currency}</span>
    </>
  );
};
