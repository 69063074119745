import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { omit } from 'lodash-es';
import { InlineModal, DatePicker } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class Date extends Component {
  inputRef = null;

  constructor(props) {
    super(props);
    this.state = {
      value: this.isDateStringValid(props.value) ? this.formatDate(props.value).format('LL') : '',
      showDatePicker: false,
      date: this.isDateStringValid(props.value) ? this.formatDate(props.value).format('YYYY-MM-DD') : null,
      lastValidDate: this.isDateStringValid(props.value)
        ? this.formatDate(props.value).format('YYYY-MM-DD')
        : null,
    };
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.inputRef.select();
    }
  }

  formatDate = date => {
    if (moment(date, 'YYYY-MM-DD').isValid()) return moment(date, 'YYYY-MM-DD');
    return moment(date, 'LL');
  };

  isDateStringValid = dateString =>
    moment(dateString, 'YYYY-MM-DD').isValid() || moment(dateString, 'LL').isValid();

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.isDateStringValid(this.props.value) ? this.formatDate(this.props.value).format('LL') : '',
        date: this.isDateStringValid(this.props.value)
          ? this.formatDate(this.props.value).format('YYYY-MM-DD')
          : null,
        lastValidDate: this.isDateStringValid(this.props.value)
          ? this.formatDate(this.props.value).format('YYYY-MM-DD')
          : null,
      });
    }
  }

  renderIcon = () => {
    if (!this.props.icon) return null;
    return <div className={styles['icon-container']}>{this.props.icon}</div>;
  };

  renderRightLabel = () => {
    if (!this.props.rightLabel) return null;
    return (
      <div
        className={[styles['label-container'], styles['right']].join(' ')}
        onClick={() => this.inputRef.focus()}
      >
        {this.props.rightLabel}
      </div>
    );
  };

  onLeaveField = () => {
    if (!this.state.value) {
      this.setState(
        {
          value: '',
          date: null,
          lastValidDate: null,
        },
        () => {
          if (this.props.value !== this.state.date) {
            this.props.onChangeDate(this.state.date);
          }
          this.setState({ showDatePicker: false });
        }
      );
      return;
    }
    if (this.isDateStringValid(this.state.value)) {
      this.setState(
        {
          value: this.formatDate(this.state.value).format('LL'),
          date: this.formatDate(this.state.value).format('YYYY-MM-DD'),
          lastValidDate: this.formatDate(this.state.value).format('YYYY-MM-DD'),
        },
        () => {
          if (this.props.value !== this.state.date) {
            this.props.onChangeDate(this.state.date);
          }
          this.setState({ showDatePicker: false });
        }
      );
    } else {
      this.setState(
        {
          value: this.isDateStringValid(this.state.lastValidDate)
            ? this.formatDate(this.state.lastValidDate).format('LL')
            : '',
          date: this.state.lastValidDate,
        },
        () => {
          if (this.props.value !== this.state.lastValidDate) {
            this.props.onChangeDate(this.state.lastValidDate);
          }
          this.setState({ showDatePicker: false });
        }
      );
    }
  };

  render() {
    let additionalStyles = {};
    let classNames = [styles['input-container']];
    if (this.props.error) {
      classNames = [...classNames, styles['error']];
    }
    if (this.props.disabled) {
      classNames = [...classNames, styles['disabled']];
    }
    if (this.props.rightLabel) {
      classNames = [...classNames, styles['has-right-label']];
    }

    return (
      <>
        <div className={classNames.join(' ')} ref={ref => (this.inlineModalPositioningRef = ref)}>
          {this.renderIcon()}
          <input
            {...omit(this.props, ['rightLabel', 'icon', 'autoFocus', 'footerComponent', 'showOnOccasion'])}
            ref={ref => {
              this.inputRef = ref;
            }}
            value={this.state.value}
            onChange={e => {
              this.setState({
                value: e.target.value,
              });
            }}
            onKeyDown={e => {
              if (e.key === 'Tab') {
                this.onLeaveField();
              }
            }}
            onFocus={e => {
              this.inputRef.select();
              this.setState({ showDatePicker: true });
            }}
            className={styles['input']}
            style={additionalStyles}
          />
          {this.renderRightLabel()}
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showDatePicker}
          onClose={this.onLeaveField}
        >
          <InlineModal.Body width={380} paddingTop>
            <DatePicker.Day
              date={this.state.date}
              onSelectDate={value => {
                this.setState(
                  {
                    value: moment(value).format('LL'),
                    date: moment(value).format('YYYY-MM-DD'),
                    lastValidDate: moment(value).format('YYYY-MM-DD'),
                  },
                  () => {
                    if (this.props.value !== this.state.date) {
                      this.props.onChangeDate(value);
                      this.setState({ showDatePicker: false });
                    } else {
                      this.setState({ showDatePicker: false });
                    }
                  }
                );
              }}
              inline
              disabledDays={this.props.disabledDays}
              footerComponent={this.props.footerComponent}
            />
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  }
}

Date.propTypes = {
  onChangeDate: PropTypes.func,
  onFocus: PropTypes.func,
  icon: PropTypes.string,
  rightLabel: PropTypes.node,
  disabled: PropTypes.bool,
  showOnOccasion: PropTypes.bool,
};
Date.defaultProps = {
  onChangeDate: () => {},
  onFocus: () => {},
  disabled: false,
  showOnOccasion: false,
};
