import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { List } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class UserListItem extends Component {
  render() {
    if (this.props.loading) {
      return (
        <List.Item small>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    }
    return (
      <List.Item small>
        <List.Item.TitleColumn title={this.props.user.name} subtitle={this.props.user.email} />
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default injectIntl(connect(mapStateToProps)(UserListItem));
