import React, { Component } from 'react';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import CountryDropdown from './CountryDropdown';

export default class EditAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      address1: '',
      address2: '',
      city: '',
      zip: '',
      country: '',
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { name, address1, address2, city, zip, country } = this.props.adress;
      this.setState({
        name,
        address1,
        address2,
        city,
        zip,
        country,
      });
    }
  }

  save = () => {
    const { name, address1, address2, city, zip, country } = this.state;
    this.props.onSave({
      name,
      address1,
      address2,
      city,
      zip,
      country,
    });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={560}>
        <Modal.Header ignoreLine title={this.props.title} onClose={this.props.onClose} />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field view={false} label="Name">
                  <Field.Text
                    value={this.state.name}
                    onChange={name => {
                      this.setState({ name });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field view={false} label="Address 1">
                  <Field.Text
                    value={this.state.address1}
                    onChange={address1 => {
                      this.setState({ address1 });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field view={false} label="Address 2">
                  <Field.Text
                    value={this.state.address2}
                    onChange={address2 => {
                      this.setState({ address2 });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column md={4}>
                <Field view={false} label="ZIP">
                  <Field.Text
                    value={this.state.zip}
                    onChange={zip => {
                      this.setState({ zip });
                    }}
                  />
                </Field>
              </Grid.Column>
              <Grid.Column md={8}>
                <Field view={false} label="City">
                  <Field.Text
                    value={this.state.city}
                    onChange={city => {
                      this.setState({ city });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <CountryDropdown
                  selected={this.state.country}
                  onSelectedCountry={country => this.setState({ country: country })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="Save" loading={this.state.isSaving} onClick={this.save} />
            <Button label="Cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}
