import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listStocktakingRows = (stocktakingId, params) =>
  request.get(
    `stocktakings/${stocktakingId}/stocktaking_rows?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
export const createStocktakingRow = (stocktakingId, data) =>
  request.post(`stocktakings/${stocktakingId}/stocktaking_rows`, data);
export const fetchStocktakingRow = stocktakingRowId => request.get(`stocktaking_rows/${stocktakingRowId}`);
export const updateStocktakingRow = (stocktakingRowId, data) =>
  request.patch(`stocktaking_rows/${stocktakingRowId}`, data);
export const deleteStocktakingRow = stocktakingRowId => request.delete(`stocktaking_rows/${stocktakingRowId}`);
