import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import moment from 'moment';
import { SystemTier } from 'sdk/System';
import { Modal } from 'views/components/Shared/Layout';
import { List, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class ChangeTierModal extends Component {
  renderTierLabel = () => {
    if (this.props.organisation.tier === SystemTier.Enterprise) {
      return SystemTier.Enterprise;
    }
    if (this.props.organisation.tier === SystemTier.Pro) {
      return SystemTier.Pro;
    }
    return SystemTier.Basic;
  };

  renderButtonsForBasic = () => {
    return (
      <>
        <List.Item clickable onClick={() => this.props.onTierActionSelected('upgrade')}>
          <Icon type="file-alt" blue withBackground backgroundSize={34} size={16} />
          <List.Item.TitleColumn
            title="Upgrade to PRO"
            subtitle="Customer has decided to upgrade their system to PRO"
          />
        </List.Item>
        {this.props.organisation.demo === true ? null : (
          <List.Item clickable onClick={() => this.props.onTierActionSelected('upgrade-trial')}>
            <Icon type="cogs" blue withBackground backgroundSize={34} size={16} />
            <List.Item.TitleColumn
              title="Upgrade to PRO with a trial period"
              subtitle="Customer wants to try PRO without charge"
            />
          </List.Item>
        )}
      </>
    );
  };

  renderButtonsForPro = () => {
    return (
      <List.Item clickable onClick={() => this.props.onTierActionSelected('downgrade')}>
        <Icon type="file-alt" blue withBackground backgroundSize={34} size={16} />
        <List.Item.TitleColumn
          title="Downgrade to BASIC"
          subtitle="PRO data will be lost for this customer"
        />
      </List.Item>
    );
  };

  renderButtonsForProTrial = () => {
    return (
      <>
        <List.Item clickable onClick={() => this.props.onTierActionSelected('upgrade')}>
          <Icon type="file-alt" blue withBackground backgroundSize={34} size={16} />
          <List.Item.TitleColumn
            title="Upgrade to PRO with billing"
            subtitle="Customer has decided to upgrade their system to PRO"
          />
        </List.Item>

        <List.Item clickable onClick={() => this.props.onTierActionSelected('downgrade')}>
          <Icon type="cogs" blue withBackground backgroundSize={34} size={16} />
          <List.Item.TitleColumn
            title="Downgrade to BASIC"
            subtitle="PRO data will be lost for this customer"
          />
        </List.Item>
      </>
    );
  };

  renderContent = () => {
    if (this.props.organisation.tier === 'basic') return this.renderButtonsForBasic();
    else if (this.props.organisation.tier_trial_expiration_date !== null)
      return this.renderButtonsForProTrial();
    else return this.renderButtonsForPro();
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={560}>
          <Modal.Header
            ignoreLine
            title="Change tier"
            subtitle={
              <>
                <p>
                  The current tier for{' '}
                  <span className={styles['subtitle-bold']}>{this.props.organisation.internal_name}</span> is{' '}
                  <span className={styles['subtitle-bold']}>{this.renderTierLabel()}</span>{' '}
                  {this.props.organisation.tier_trial_expiration_date === null
                    ? null
                    : 'during a trial period until ' +
                      moment(this.props.organisation.tier_trial_expiration_date).format('LL')}
                </p>
              </>
            }
            onClose={() => {
              this.props.onClose();
            }}
          />
          <Modal.Content>
            <List light>{this.renderContent()}</List>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrganisationAsAdmin: SDKReduxOperations.updateOrganisationAsAdmin,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChangeTierModal)));
