import React, { Component } from 'react';
import { Row as GridSystemRow } from 'react-grid-system';

class Row extends Component {
  render() {
    return (
      <div className="layout-grid-row">
        <GridSystemRow gutterWidth={18} {...this.props}>
          {this.props.children}
        </GridSystemRow>
      </div>
    );
  }
}

export default Row;
