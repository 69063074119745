import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class TabBarItem extends Component {
  render() {
    let classNames = [styles['item']];
    if (this.props.active) classNames = [...classNames, styles['active']];
    if (this.props.locked) classNames = [...classNames, styles['locked']];

    let item = (
      <div className={classNames.join(' ')} onClick={this.props.onClick}>
        <span>
          {this.props.children}
          {this.props.locked ? <Icon type="lock" /> : null}
        </span>
        <div className={styles['line']} />
      </div>
    );

    if (this.props.to) {
      return <Link to={this.props.to}>{item}</Link>;
    } else {
      return item;
    }
  }
}

TabBarItem.propTypes = {
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};
TabBarItem.defaultProps = {
  active: false,
  onClick: () => {},
};

export default TabBarItem;
