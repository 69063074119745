import types from './types';

const setNavigatedTo = payload => ({ type: types.SET_NAVIGATED_TO, payload });

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});

const fetchOrganisationsSuccess = payload => ({
  type: types.FETCH_ORGANISATIONS_SUCCESS,
  payload,
});

export default {
  setNavigatedTo,
  addQueryParameter,
  fetchOrganisationsSuccess,
};
