import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import { Status } from 'sdk/ChecklistTemplateVersion';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.WORK_ORDER_CREATED: {
      const { data } = action.payload;
      const { checklist_instances } = data;
      if (checklist_instances && checklist_instances.length > 0) {
        let templateId = checklist_instances[0].checklist_template_id;
        if (state && state[templateId]) {
          return update(
            { ...state },
            {
              [templateId]: {
                $unset: ['draft_version'],
              },
            }
          );
        }
      }
      return state;
    }
    case SDKReduxTypes.RECURRING_MAINTENANCE_CREATED: {
      const { data } = action.payload;
      const { checklist_template_links } = data;
      if (checklist_template_links && checklist_template_links.length > 0) {
        let templateId = checklist_template_links[0].checklist_template_id;
        if (state && state[templateId]) {
          return update(
            { ...state },
            {
              [templateId]: {
                $unset: ['draft_version'],
              },
            }
          );
        }
      }
      return state;
    }
    case SDKReduxTypes.CHECKLIST_TEMPLATE_VERSION_DELETED: {
      const { checklistTemplateVersion } = action.payload;
      const { checklist_template_id } = checklistTemplateVersion;
      if (state && state[checklist_template_id])
        return update(
          { ...state },
          {
            [checklist_template_id]: {
              $unset: ['draft_version'],
            },
          }
        );

      return state;
    }
    case SDKReduxTypes.CHECKLIST_TEMPLATE_UPDATED: {
      const { params, templateId } = action.payload;
      const { reusable, recurring_maintenance_id, work_order_id } = params;
      if (state && state[templateId]) {
        if (reusable === true || recurring_maintenance_id || work_order_id) {
          return update(
            { ...state },
            {
              [templateId]: {
                $unset: ['draft_version'],
              },
            }
          );
        }
      }
      return state;
    }
    case SDKReduxTypes.CHECKLIST_TEMPLATE_VERSION_UPDATED: {
      const { params, data } = action.payload;
      if (params && params.status === Status.Active) {
        return update(
          { ...state },
          {
            [data.checklist_template_id]: {
              $unset: ['draft_version'],
              active_version: {
                $set: data.id,
              },
            },
          }
        );
      }
      return state;
    }
    case SDKReduxTypes.CHECKLIST_TEMPLATE_VERSION_CREATED_FOR_CHECKLIST_TEMPLATE: {
      const { checklistTemplateId, data } = action.payload;
      return update(
        { ...state },
        {
          [checklistTemplateId]: {
            draft_version: {
              $set: data.id,
            },
          },
        }
      );
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.checklistTemplateById) {
        return HelperFunctions.mergeEntities(state, action.payload.checklistTemplateById);
      }
      return state;

    default:
      return state;
  }
};
