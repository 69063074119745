import types from './types';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  isAuthenticated: false,
  isPreparingApp: true,
  groups: [],
  currentUser: null,
  currentSystem: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.AUTHENTICATE_USER:
      return { ...state, isPreparingApp: false };
    case types.AUTHENTICATE_USER_ERROR:
      return { ...state,  isPreparingApp: false, isAuthenticated: false };
    case types.PREPARE_AUTHENTICATED_USER:
      return { ...state, isPreparingApp: true, isAuthenticated: true };
    case types.PREPARE_AUTHENTICATED_USER_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        isPreparingApp: false,
      };
    case types.APP_IS_READY: {
      const { currentUser } = action.payload;

      return {
        ...state,
        currentUser,
        isPreparingApp: false,
        isAuthenticated: true,
      };
    }
    case types.UNAUTHENTICATE_USER:
      return { ...state, isAuthenticated: false, isPreparingApp: false };
    /*
      ----------
        SettingsDuck
      ----------
    */
    case SDKReduxTypes.CHANGED_PROFILE_PICTURE: {
      const { id } = action.payload.data;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          profile_picture_image_id: id
        }
      };
    }
    case SDKReduxTypes.DELETED_PROFILE_PICTURE: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          profile_picture_image_id: null
        }
      }
    }
    case SDKReduxTypes.ME_UPDATED: {
      const { data } = action.payload;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...data,
        }
      };
    }
    default:
      return state;
  }
};
