import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './style.module.scss';

export default class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentHeight: 0,
    };
    this.debouncedWindowResizedFunction = debounce(this.setMaxHeight, 300);
  }

  componentDidMount() {
    window.addEventListener('resize', this.debouncedWindowResizedFunction);
    this.setMaxHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedWindowResizedFunction);
  }

  setMaxHeight = () => {
    const headerHeight =
      this.contentRef == null || this.contentRef.previousSibling == null
        ? 0
        : this.contentRef.previousSibling.getBoundingClientRect().height;
    const marginFromBottomOfScreen = 20;
    this.setState({
      maxHeight:
        this.props.maxHeight != null && window.innerHeight > this.props.maxHeight
          ? this.props.maxHeight
          : window.innerHeight - headerHeight - marginFromBottomOfScreen,
      contentHeight: this.contentRef == null ? 0 : this.contentRef.scrollHeight,
    });
  };

  render() {
    return (
      <PerfectScrollbar
        className={`
        ${styles['content']}
        ${this.props.dropdown ? styles['dropdown'] : ''}
        ${this.props.noPadding ? styles['no-padding'] : ''}
        ${this.props.paddingTop ? styles['top-padding'] : ''}`}
        containerRef={ref => (this.contentRef = ref)}
        style={{ maxHeight: this.state.maxHeight, width: this.props.width, padding: this.props.padding }}
      >
        {this.props.children}
      </PerfectScrollbar>
    );
  }
}

Body.propTypes = {
  noPadding: PropTypes.bool,
  paddingTop: PropTypes.bool,
  maxHeight: PropTypes.number,
  children: PropTypes.element,
};
