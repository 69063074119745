import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeAttachmentFolder } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createAttachmentFolder(systemId, params) {
  return dispatch => {
    return API.createAttachmentFolder(systemId, params)
      .then(res => {
        const { entities } = normalizeAttachmentFolder(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.ATTACHMENT_FOLDER_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateAttachmentFolder(attachmentFolderId, params) {
  return dispatch => {
    return API.updateAttachmentFolder(attachmentFolderId, params)
      .then(res => {
        const { entities } = normalizeAttachmentFolder(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { attachmentFolderId, params, data: res.data };
        dispatch({ type: types.ATTACHMENT_FOLDER_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteAttachmentFolder(folderAttachmentId) {
  return dispatch => {
    return API.deleteAttachmentFolder(folderAttachmentId)
      .then(res => {
        const payload = { folderAttachmentId };
        dispatch({ type: types.ATTACHMENT_FOLDER_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
