import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { ListLayout } from 'views/components/Shared/Layout';
import { Menu } from 'views/components/Shared/General';
import HelperFunctions from 'utilities/HelperFunctions';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { BillingSelectors } from 'state/ducks/billing';
import { Overview, BillingActivities } from './Tabs';

class Billing extends Component {
  componentDidMount() {
    HelperFunctions.setDocumentTitle('Billing');
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Billing);
  }

  renderHeader = () => {
    return <ListLayout.Header title={<span>Billing</span>} />;
  };

  renderLeftMenu = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <ListLayout.Content.Menu>
        <ListLayout.Content.Menu.Content>
          <Menu.Item
            linkTo={{
              pathname: '/billing',
              search: 'list=overview',
            }}
            title={<span>Overview</span>}
            selected={list === 'overview'}
          />
          <Menu.Item
            linkTo={{
              pathname: '/billing',
              search: 'list=activities',
            }}
            title={<span>Billing activities</span>}
            selected={list === 'activities'}
          />
        </ListLayout.Content.Menu.Content>
      </ListLayout.Content.Menu>
    );
  };

  renderMainContent = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === 'overview') {
      return <Overview />;
    }
    if (list === 'activities') {
      return <BillingActivities />;
    }
    return null;
  };

  render() {
    return (
      <ListLayout>
        {this.renderHeader()}
        <ListLayout.Content>
          {this.renderLeftMenu()}
          {this.renderMainContent()}
        </ListLayout.Content>
      </ListLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    billingInvoices: BillingSelectors.getBillingInvoices(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Billing));
