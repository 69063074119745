import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeChecklistTemplateVersion } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function updateChecklistTemplateVersion(checklistTemplateVersionId, params) {
  return dispatch => {
    return API.updateChecklistTemplateVersion(checklistTemplateVersionId, params)
      .then(res => {
        const { entities } = normalizeChecklistTemplateVersion(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { checklistTemplateVersionId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_TEMPLATE_VERSION_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteChecklistTemplateVersion(checklistTemplateVersionId) {
  return (dispatch, getState) => {
    const checklistTemplateVersion = getState().entities.checklistTemplateVersionById[
      checklistTemplateVersionId
    ];
    return API.deleteChecklistTemplateVersion(checklistTemplateVersionId)
      .then(res => {
        const payload = { checklistTemplateVersionId, checklistTemplateVersion };
        dispatch({ type: types.CHECKLIST_TEMPLATE_VERSION_DELETED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createChecklistTemplateVersionForChecklistTemplate(checklistTemplateId, params) {
  return dispatch => {
    return API.createChecklistTemplateVersionForChecklistTemplate(checklistTemplateId, params)
      .then(res => {
        const { entities } = normalizeChecklistTemplateVersion(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { checklistTemplateId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_TEMPLATE_VERSION_CREATED_FOR_CHECKLIST_TEMPLATE, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
