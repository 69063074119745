import * as axios from 'axios';
import { isArray, mergeWith } from 'lodash-es';

const buildQueryParamsForList = (object, allowedParams = []) => {
  let allowedIncludingDefaultParams = ['page', 'sort', 'sort-order', ...allowedParams];
  let newObj = {};
  for (var key in object) {
    if (allowedIncludingDefaultParams.includes(key)) {
      newObj[key] = object[key];
    }
  }
  return newObj;
};

const getDataChanges = (afterEdit, beforeEdit) => {
  return Object.keys(afterEdit)
    .filter(key => {
      const valueAfterEdit = afterEdit[key] || '';
      const valueBeforeEdit = beforeEdit[key] || '';
      return valueAfterEdit !== valueBeforeEdit;
    })
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: afterEdit[key],
      }),
      {}
    );
};

const getPairs = (obj = {}, keys = []) => {
  return Object.entries(obj)
    .filter(item => {
      return item[1] != null;
    })
    .reduce((pairs, [key, value]) => {
      if (typeof value === 'object') {
        pairs.push(...getPairs(value, [...keys, key]));
      } else {
        pairs.push([[...keys, key], value]);
      }
      return pairs;
    }, []);
};

const convertObjToQueryParameters = obj => {
  return getPairs(obj)
    .map(([[key0, ...keysRest], value]) => `${key0}${keysRest.map(a => `[${a}]`).join('')}=${value}`)
    .join('&');
};

const base64EncodeFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        file: reader.result.split(',')[1],
        mime_type: file.type,
        name: file.name,
      });
    };
    reader.onerror = error => {
      reject(error);
    };
  });
};

const uploadFileToS3 = ({ url, file, mime_type }, uploadProgressCallback = null, config = null) => {
  const instance = axios.create();
  let axiosConfig = {
    ...config,
    headers: {
      'Content-Type': mime_type,
    },
  };

  if (uploadProgressCallback) {
    axiosConfig = {
      ...axiosConfig,
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        uploadProgressCallback({ loaded: Math.round(loaded), total: Math.round(total) });
      },
    };
  }
  return instance.put(url, file, axiosConfig);
};

const getExtension = name => {
  let extension = name.split('.');
  if (extension.length === 1 || (extension[0] === '' && extension.length === 2)) {
    return null;
  } else {
    return extension.pop();
  }
};

const getPaginationFromHeader = (headers = null) => {
  return headers == null
    ? null
    : {
        currentPage: Number(headers['page-number']),
        totalPages: Number(headers['total-pages']),
        totalEntries: Number(headers['total-entries']),
        paginateFrom: headers['paginate-from-token'],
      };
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/*
  Get language specific ordinal suffix

  Currently only swedish is supported
*/
const getOrdinalSuffix = (intl, n) => {
  switch (intl.locale) {
    case 'sv':
      return n + ([1, 2].includes(n % 10) && ![11, 12].includes(n) ? ':a' : ':e');
    case 'en':
      var j = n % 10,
        k = n % 100;
      if (j === 1 && k !== 11) {
        return n + 'st';
      }
      if (j === 2 && k !== 12) {
        return n + 'nd';
      }
      if (j === 3 && k !== 13) {
        return n + 'rd';
      }
      return n + 'th';
    default:
      return null;
  }
};

const hasError = (e, opts) => {

  if (e.response) {
    const { code } = opts;
    let { errors } = e.response.data;
    if (opts.key == null) {
      return errors.find(error => error.code === code) != null;
    } 
    else {
      const keys = isArray(opts.key) ? opts.key : [opts.key];

      if (errors != null) {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (errors[key] != null) {
            const lastKey = i + 1 === keys.length;
            if (lastKey) {
              return errors[key].some(err => err.code === code);
            } else {
              errors = errors[key][0];
            }
          } else {
            return false;
          }
        }

        return false;
      }
    }
  }

  return false;
};

const validateEmail = email => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const getCancelTokenForRequest = () => {
  let cancel;
  return {
    cancel: () => {
      if (cancel !== undefined) {
        cancel();
      }
    },
    getCancelTokenConfig: () => ({
      cancelToken: new axios.CancelToken(c => (cancel = c)),
    }),
  };
};

const flattenIntlMessages = (nestedMessages, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenIntlMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

const getAvailableCurrencies = () => [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BOV',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHE',
  'CHF',
  'CHW',
  'CLF',
  'CLP',
  'CNY',
  'COP',
  'COU',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MXV',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STN',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'USN',
  'UYI',
  'UYU',
  'UYW',
  'UZS',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XBA',
  'XBB',
  'XBC',
  'XBD',
  'XCD',
  'XDR',
  'XOF',
  'XPD',
  'XPF',
  'XSU',
  'XUA',
  'YER',
  'ZAR',
  'ZMW',
  'ZWL',
];

const FILTER_COMPARABLES = {
  Any: 'any',
  NoneOf: 'none_of',
  Exact: 'exact',
  NotExact: 'not_exact',
  Exists: 'exists',
  LessThan: 'less_than',
  LessThanOrEqual: 'less_than_or_equal',
  MoreThan: 'more_than',
  MoreThanOrEqual: 'more_than_or_equal',
  Between: 'between',
  Search: 'search',
  Preset: 'preset',
  Before: 'before',
  After: 'after',
  ExactOrNil: 'exact_or_nil',
};

const mergeEntities = (src, target) => {
  return mergeWith({}, src, target, (target, src) => {
    if (isArray(target)) {
      return src;
    }
  });
};

export default {
  uploadFileToS3,
  getExtension,
  buildQueryParamsForList,
  convertObjToQueryParameters,
  base64EncodeFile,
  getDataChanges,
  getPaginationFromHeader,
  capitalizeFirstLetter,
  getOrdinalSuffix,
  validateEmail,
  hasError,
  getAvailableCurrencies,
  getCancelTokenForRequest,
  mergeEntities,
  flattenIntlMessages,
  FILTER_COMPARABLES,
};
