import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { HeaderSelectors } from 'state/ducks/header';
import { Header as LayoutHeader } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

const Header = ({ tabs, title, currentRoute, selectedTab }) => {
  const renderTabButtons = () => {
    return tabs.map((tab, i) => {
      return (
        <Link to={tab.route == null ? currentRoute : tab.route} key={tab.id}>
          <div
            className={`${styles['tab']} ${
              selectedTab !== null && selectedTab === tab.id ? styles['selected'] : ''
            }`}
          >
            {tab.title}
          </div>
        </Link>
      );
    });
  };

  const renderTabs = () => {
    return tabs != null ? (
      <React.Fragment>
        <div className={styles['separator']}></div>
        <div className={styles['tabs']}>{renderTabButtons()}</div>
      </React.Fragment>
    ) : null;
  };

  return (
    <LayoutHeader maxWidth={960} smallTitle title={title !== null ? { title } : null}>
      <div className={styles['left-content']}>{renderTabs()}</div>
    </LayoutHeader>
  );
};

function mapStateToProps(state) {
  return {
    tabs: HeaderSelectors.getTabs(state),
    title: HeaderSelectors.getTitle(state),
    selectedTab: HeaderSelectors.getSelectedTab(state),
  };
}

export default connect(mapStateToProps)(Header);
