import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NewInlineModal, Field } from 'views/components/Shared/General';
import Item from 'views/components/Shared/General/NewInlineModal/Dropdown/Item';
import Separator from 'views/components/Shared/General/NewInlineModal/Dropdown/Separator';

export default class Dropdown extends Component {
  state = {
    focus: false,
  };

  renderClearItem = () => {
    if (!this.props.value || !this.props.clearable) return null;

    return (
      <React.Fragment>
        <NewInlineModal.Dropdown.Item onClick={this.props.onClear}>
          <FormattedMessage id="general.clean" />
        </NewInlineModal.Dropdown.Item>
        <NewInlineModal.Dropdown.Separator />
      </React.Fragment>
    );
  };

  renderSearch = () => {
    if (this.props.searchable) {
      return (
        <NewInlineModal.Header.Search
          autoFocus
          placeholder={this.props.searchPlaceholder}
          value={this.props.searchTerm}
          onClick={e => e.stopPropagation()}
          onChange={value => this.props.onSearch(value)}
          onClear={() => this.props.onSearch('')}
        />
      );
    }
    return null;
  };

  renderDropdownMenu = () => {
    return (
      <div onClick={() => this.setState({ focus: false })}>
        {this.renderClearItem()}
        {this.props.children}
      </div>
    );
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            if (this.props.disabled === false) {
              this.setState(prevState => ({
                focus: !prevState.focus,
              }));
            }
          }}
        >
          <Field.Resource
            {...this.props}
            angleDown={this.props.angleDown}
            clearable={false}
            focus={this.state.focus}
            value={this.props.value}
            onClear={this.props.onClear}
          />
        </div>

        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.focus}
          onClose={() => this.setState({ focus: false })}
        >
          <NewInlineModal.Dropdown>
            {this.renderSearch()}
            <NewInlineModal.Dropdown.Items>{this.renderDropdownMenu()}</NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  }
}

Dropdown.Item = Item;
Dropdown.Divider = Separator;

Dropdown.propTypes = {
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  angleDown: PropTypes.bool,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
  searchTerm: PropTypes.string,
  searchPlaceholder: PropTypes.string,
};
Dropdown.defaultProps = {
  clearable: true,
  angleDown: true,
  disabled: false,
  onClear: () => {},
  onOpen: () => {},
  onSearch: () => {},
  searchable: false,
  searchPlaceholder: null,
  searchTerm: '',
};
