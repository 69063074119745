import React, { Component } from 'react';
import { Field } from 'views/components/Shared/General';

class ImportTypeDropdown extends Component {
  renderDropdownItems = () => (
    <>
      <Field.Dropdown.Item
        selected={this.props.value === 'amu'}
        onClick={() => {
          this.props.onSelectedValue('amu');
        }}
      >
        {this.renderSelectedValue('amu')}
      </Field.Dropdown.Item>
      <Field.Dropdown.Item
        selected={this.props.value === 'idus'}
        onClick={() => {
          this.props.onSelectedValue('idus');
        }}
      >
        {this.renderSelectedValue('idus')}
      </Field.Dropdown.Item>
      <Field.Dropdown.Item
        selected={this.props.value === 'maintmaster'}
        onClick={() => {
          this.props.onSelectedValue('maintmaster');
        }}
      >
        {this.renderSelectedValue('maintmaster')}
      </Field.Dropdown.Item>
    </>
  );

  renderSelectedValue = (value) => {
    switch (value) {
      case 'amu':
        return 'AM Underhåll';

      case 'idus':
        return 'IDUS';

      case 'maintmaster':
        return 'MaintMaster';

      default:
        return null;
    }
  };

  render() {
    return (
      <Field label="Import Type">
        <Field.Dropdown clearable={false} value={this.renderSelectedValue(this.props.value)}>
          {this.renderDropdownItems()}
        </Field.Dropdown>
      </Field>
    );
  }
}

export default ImportTypeDropdown;
