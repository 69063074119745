import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import toast from 'react-hot-toast';
import { API, SDKReduxOperations, HelperFunctions } from 'sdk';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import {
  Button,
  Field,
  ConfirmDeleteInlineModal,
  FieldErrorWrapper,
  Icon,
} from 'views/components/Shared/General';

class AdditionalCostDetail extends Component {
  getInitialState = () => ({
    showErrorForCost: false,
    showErrorForTitle: false,
    isSavingBillingAdditionalCost: false,
    isDeletingBillingAdditionalCost: false,
    showDatePicker: false,
    title: null,
    cost: null,
    billing_system_id: null,
    systems: [],
    isFetching: false,
  });
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState(), isFetching: true });
      API.listSystemsForOrganisationAsAdmin(this.props.match.params.id, { no_pagination: true }).then(
        ({ data: systems }) => {
          if (this.props.billingAdditionalCost) {
            this.setState({
              systems,
              isFetching: false,
              title: this.props.billingAdditionalCost.title,
              cost: this.props.billingAdditionalCost.cost,
              billing_system_id: this.props.billingAdditionalCost.billing_system_id,
            });
          } else {
            this.setState({
              systems,
              isFetching: false,
              title: '',
              cost: null,
            });
          }
        }
      );
    }
  }

  deleteBillingAdditionalCost = () => {
    this.setState({ isDeletingBillingAdditionalCost: true });
    this.props.deleteBillingAdditionalCostAsAdmin(this.props.billingAdditionalCost.id).then(res => {
      this.setState({ isDeletingBillingAdditionalCost: false });
      toast(() => <ToastMessage success text="Additional cost deleted" />);
      this.props.onClose();
    });
  };

  hasError = () => {
    let error = false;
    if (this.state.title === null || this.state.title.length === 0) {
      this.setState({ showErrorForTitle: true });
      error = true;
    }
    if (this.state.cost === null || this.state.cost.length === 0) {
      this.setState({ showErrorForCost: true });
      error = true;
    }
    return error;
  };

  saveBillingAdditionalCost = () => {
    if (this.hasError()) return;
    this.setState({ isSavingBillingAdditionalCost: true });
    const formattedCost = this.state.cost.replace(/ /g, '');
    const data = {
      title: this.state.title,
      cost: formattedCost,
      billing_system_id: this.state.billing_system_id,
    };
    if (this.props.billingAdditionalCost === null) {
      this.props
        .createBillingAdditionalCostAsAdmin(this.props.organisation.id, data)
        .then(res => {
          this.setState({ isSavingBillingAdditionalCost: false });
          toast(() => <ToastMessage success text="Additional cost created" />);
          this.props.onClose();
        })
        .catch(error => {
          if (HelperFunctions.hasError(error, '10003', 'cost')) {
            this.setState({ showErrorForInvalidCost: true });
          }
          this.setState({ isSavingBillingAdditionalCost: false });
        });
    } else {
      this.props
        .updateBillingAdditionalCostAsAdmin(this.props.billingAdditionalCost.id, data)
        .then(res => {
          this.setState({ isSavingBillingAdditionalCost: false });
          toast(() => <ToastMessage success text="Additional cost updated" />);
          this.props.onClose();
        })
        .catch(error => {
          if (HelperFunctions.hasError(error, '10003', 'cost')) {
            this.setState({ showErrorForInvalidCost: true });
          }
          this.setState({ isSavingBillingAdditionalCost: false });
        });
    }
  };

  renderAdditionalCostTitle = () => {
    if (this.props.billingAdditionalCost === null) return 'Create additional cost';
    else return 'Edit additional cost';
  };

  renderSystemDropdown = () => {
    const system = this.state.systems.find(({ id }) => id === this.state.billing_system_id) || {};
    return (
      <Field label="System">
        <Field.Dropdown clearable={false} value={system.name}>
          {this.state.systems.map(system => (
            <Field.Dropdown.Item
              selected={this.state.billing_system_id === system.id}
              onClick={() => {
                this.setState({ billing_system_id: system.id });
              }}
            >
              {system.name}
            </Field.Dropdown.Item>
          ))}
        </Field.Dropdown>
      </Field>
    );
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return <Modal.Loader />;
    }
    return (
      <>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>{this.renderSystemDropdown()}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label="Title">
                  <Field.Text
                    error={this.state.showErrorForTitle}
                    value={this.state.title}
                    onChange={title => this.setState({ title, showErrorForTitle: false })}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label="Cost">
                  <FieldErrorWrapper
                    local
                    position="top"
                    show={this.state.showErrorForInvalidCost}
                    errorElement="Invalid cost (Ex: write as 15000)"
                  >
                    <Field.Text
                      error={this.state.showErrorForCost}
                      value={this.state.cost}
                      onChange={cost =>
                        this.setState({ cost, showErrorForCost: false, showErrorForInvalidCost: false })
                      }
                      rightLabel="SEK"
                    />
                  </FieldErrorWrapper>
                </Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="Save"
              loading={this.state.isSavingBillingAdditionalCost}
              onClick={() => this.saveBillingAdditionalCost()}
            />
            <Button label="Cancel" onClick={() => this.props.onClose()} />
          </Button.Group>
        </Modal.Footer>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={560}>
        <Modal.Header
          ignoreLine
          title={this.renderAdditionalCostTitle()}
          onClose={() => {
            this.props.onClose();
          }}
          iconButtons={
            this.props.billingAdditionalCost === null ? null : (
              <ConfirmDeleteInlineModal
                position="right"
                trigger={<Button type="icon" icon={<Icon type="trash-alt" regular red />} label="Delete" />}
                title="Are you sure?"
                onDelete={() => this.deleteBillingAdditionalCost()}
                loading={this.state.isDeletingBillingAdditionalCost}
                buttonLabel="Delete"
                cancelButtonLabel="Cancel"
                subtitle={`Deleting cost created ${this.props.billingAdditionalCost.created_at}`}
              />
            )
          }
        />
        {this.renderContent()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createBillingAdditionalCostAsAdmin: SDKReduxOperations.createBillingAdditionalCostAsAdmin,
      updateBillingAdditionalCostAsAdmin: SDKReduxOperations.updateBillingAdditionalCostAsAdmin,
      deleteBillingAdditionalCostAsAdmin: SDKReduxOperations.deleteBillingAdditionalCostAsAdmin,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
    billingAdditionalCost: EntitySelectors.getBillingAdditionalCost(state, ownProps.billingAdditionalCostId),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdditionalCostDetail)));
