import * as API from './api';
import { API as SDKApi } from 'sdk';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeAttachment } from 'sdk/Schemas';
import * as types from './reduxTypes';

/*
  GENERAL ATTACHMENT
*/

export function createDraftAttachment(systemId, params, meta = {}) {
  return dispatch => {
    return SDKApi.createAttachment(systemId, params)
      .then(res => {
        const { entities } = normalizeAttachment(res.data);
        const payload = { systemId, params, data: res.data, meta };
        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.ATTACHMENT_DRAFT_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteAttachment(attachmentId) {
  return (dispatch, getState) => {
    const attachment = getState().entities.attachmentById[attachmentId];
    return API.deleteAttachment(attachmentId)
      .then(res => {
        const payload = { attachmentId, data: attachment };
        dispatch({ type: types.ATTACHMENT_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateAttachment(attachmentId, params) {
  return dispatch => {
    return API.updateAttachment(attachmentId, params)
      .then(res => {
        const { entities } = normalizeAttachment(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { attachmentId, params, data: res.data };
        dispatch({ type: types.ATTACHMENT_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createAttachmentForChecklistTemplateRow(checklistTemplateRowId, params) {
  return dispatch => {
    return API.createAttachmentForChecklistTemplateRow(checklistTemplateRowId, params)
      .then(res => {
        const { entities } = normalizeAttachment(res.data);
        const payload = { checklistTemplateRowId, params, data: res.data };

        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.ATTACHMENT_CREATED_FOR_CHECKLIST_TEMPLATE_ROW, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function removeAttachmentFromChecklistTemplaterow(checklistTemplateRowId, attachmentId) {
  return dispatch => {
    return API.removeAttachmentFromChecklistTemplaterow(checklistTemplateRowId, attachmentId)
      .then(() => {
        const payload = { attachmentId, checklistTemplateRowId };
        dispatch({ type: types.ATTACHMENT_REMOVED_FROM_CHECKLIST_TEMPlATE_ROW, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

/*
  ASSET ATTACHMENT
*/

export function createAttachmentForAsset(assetId, params) {
  const { attachment_folder_id, description, title, extension, type, mime_type, link_url } = params;
  return dispatch => {
    return API.createAttachmentForAsset(assetId, {
      attachment_folder_id,
      description,
      title,
      extension,
      type,
      mime_type,
      link_url,
    })
      .then(res => {
        const { data: attachment } = res;

        const { entities } = normalizeAttachment(attachment);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { assetId, params, data: attachment };
        dispatch({ type: types.ATTACHMENT_CREATED_FOR_ASSET, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function removeAttachmentFromAsset(assetId, attachmentId) {
  return dispatch => {
    return API.removeAttachmentFromAsset(assetId, attachmentId)
      .then(() => {
        const payload = { attachmentId, assetId };
        dispatch({ type: types.ATTACHMENT_REMOVED_FROM_ASSET, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function removeAttachmentFromSparePart(sparePartId, attachmentId) {
  return dispatch => {
    return API.removeAttachmentFromSparePart(sparePartId, attachmentId)
      .then(() => {
        const payload = { attachmentId, sparePartId };
        dispatch({ type: types.ATTACHMENT_REMOVED_FROM_SPARE_PART, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createAttachmentForMultipleAssets(systemId, params) {
  const { asset_ids, attachment_folder_id, description, title, type, mime_type, link_url } = params;
  return dispatch => {
    return API.createAttachmentForMultipleAssets(systemId, {
      asset_ids,
      attachment_folder_id,
      description,
      title,
      mime_type,
      type,
      link_url,
    })
      .then(res => {
        const { data: attachment } = res;

        const { entities } = normalizeAttachment(attachment);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: attachment };

        dispatch({
          type: types.ATTACHMENT_CREATED_FOR_MULTIPLE_ASSETS,
          payload,
        });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

/*
  WORKORDER ATTACHMENT
*/

export function createRegistrationAttachmentForWorkOrder(workOrderId, params) {
  const { attachment_folder_id, description, title, extension, file, type, mime_type, link_url } = params;
  return dispatch => {
    return API.createRegistrationAttachmentForWorkOrder(workOrderId, {
      attachment_folder_id,
      description,
      title,
      extension,
      file,
      type,
      mime_type,
      link_url,
    })
      .then(res => {
        const { data: attachment } = res;

        const { entities } = normalizeAttachment(attachment);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderId, params, data: attachment };
        dispatch({
          type: types.REGISTRATION_ATTACHMENT_CREATED_FOR_WORK_ORDER,
          payload,
        });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createCompletionAttachmentForWorkOrder(workOrderId, params) {
  const { description, title, extension, file, type, mime_type, link_url } = params;
  return dispatch => {
    return API.createCompletionAttachmentForWorkOrder(workOrderId, {
      description,
      title,
      extension,
      file,
      type,
      mime_type,
      link_url,
    })
      .then(res => {
        const { data: attachment } = res;

        const { entities } = normalizeAttachment(attachment);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderId, params, data: attachment };
        dispatch({
          type: types.COMPLETION_ATTACHMENT_CREATED_FOR_WORK_ORDER,
          payload,
        });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function addRegistrationAttachmentToWorkOrder(workOrderId, attachmentId) {
  return dispatch => {
    return API.addRegistrationAttachmentToWorkOrder(workOrderId, attachmentId)
      .then(res => {
        const { data: attachment } = res;
        const { entities } = normalizeAttachment(attachment);
        dispatch(EntityOperations.updateEntities(entities));
        const payload = { workOrderId, attachmentId, data: attachment };

        dispatch({
          type: types.REGISTRATION_ATTACHMENT_ADDED_TO_WORK_ORDER,
          payload,
        });
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createRegistrationAttachmentForRecurringMaintenance(recurringMaintenanceId, params) {
  return dispatch => {
    return API.createRegistrationAttachmentForRecurringMaintenance(recurringMaintenanceId, params)
      .then(res => {
        const { entities } = normalizeAttachment(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { recurringMaintenanceId, params, data: res.data };
        dispatch({
          type: types.REGISTRATION_ATTACHMENT_CREATED_FOR_RECURRING_MAINTENANCE,
          payload,
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function addRegistrationAttachmentToRecurringMaintenance(recurringMaintenanceId, attachmentId) {
  return dispatch => {
    return API.addRegistrationAttachmentToRecurringMaintenance(recurringMaintenanceId, attachmentId)
      .then(res => {
        const { data: attachment } = res;
        const { entities } = normalizeAttachment(attachment);
        dispatch(EntityOperations.updateEntities(entities));
        const payload = {
          recurringMaintenanceId,
          attachmentId,
          data: attachment,
        };

        dispatch({
          type: types.REGISTRATION_ATTACHMENT_ADDED_TO_RECURRING_MAINTENANCE,
          payload,
        });
      })
      .catch(e => {
        throw e;
      });
  };
}

export function removeRegistrationAttachmentFromWorkOrder(workOrderId, attachmentId) {
  return dispatch => {
    return API.removeRegistrationAttachmentFromWorkOrder(workOrderId, attachmentId)
      .then(() => {
        const payload = { attachmentId, workOrderId };
        dispatch({
          type: types.REGISTRATION_ATTACHMENT_REMOVED_FROM_WORK_ORDER,
          payload,
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function removeRegistrationAttachmentFromRecurringMaintenance(recurringMaintenanceId, attachmentId) {
  return dispatch => {
    return API.removeRegistrationAttachmentFromRecurringMaintenance(recurringMaintenanceId, attachmentId)
      .then(() => {
        const payload = { attachmentId, recurringMaintenanceId };
        dispatch({ type: types.REGISTRATION_ATTACHMENT_REMOVED_FROM_RECURRING_MAINTENANCE, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

/*
  VENDOR ATTACHMENT
*/

export function createAttachmentForVendor(vendorId, params) {
  return dispatch => {
    return API.createAttachmentForVendor(vendorId, params)
      .then(res => {
        const { entities } = normalizeAttachment(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { vendorId, params, data: res.data };
        dispatch({ type: types.ATTACHMENT_CREATED_FOR_VENDOR, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

/*
  SPARE PART ATTACHMENT
*/

export function createAttachmentForSparePart(sparePartId, params) {
  return dispatch => {
    return API.createAttachmentForSparePart(sparePartId, params)
      .then(res => {
        const { entities } = normalizeAttachment(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { sparePartId, params, data: res.data };
        dispatch({ type: types.ATTACHMENT_CREATED_FOR_SPARE_PART, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createAttachmentForPurchaseOrder(purchaseOrderId, params) {
  return dispatch => {
    return API.createAttachmentForPurchaseOrder(purchaseOrderId, params)
      .then(res => {
        const { entities } = normalizeAttachment(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { purchaseOrderId, params, data: res.data };
        dispatch({ type: types.ATTACHMENT_CREATED_FOR_PURCHASE_ORDER, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createAttachmentForMultipleSpareParts(systemId, params) {
  const { spare_part_ids, attachment_folder_id, description, title, type, mime_type, link_url } = params;
  return dispatch => {
    return API.createAttachmentForMultipleSpareParts(systemId, {
      spare_part_ids,
      attachment_folder_id,
      description,
      title,
      mime_type,
      type,
      link_url,
    })
      .then(res => {
        const { data: attachment } = res;

        const { entities } = normalizeAttachment(attachment);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: attachment };

        dispatch({
          type: types.ATTACHMENT_CREATED_FOR_MULTIPLE_SPARE_PARTS,
          payload,
        });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
