import React from 'react';
import moment from 'moment';
import { SystemTier } from 'sdk/System';
import styles from './style.module.scss';

const TierField = ({ tier, trial, trialPeriod }) => {
  const renderTitle = () => {
    if (tier === SystemTier.Enterprise) {
      return SystemTier.Enterprise;
    } else if (tier === SystemTier.Pro) {
      return SystemTier.Pro;
    }
    return SystemTier.Basic;
  };

  const renderSubtitle = () => {
    if (!trial) return null;
    return (
      <p className={styles['subtitle']}>
        {trialPeriod === undefined || trialPeriod === null
          ? 'Trial period without date'
          : `Trial period until ${moment(trialPeriod).format('LL')}`}
      </p>
    );
  };

  const getBoxTierStyle = () => {
    if (tier === 'basic') return styles['basic'];
    else return styles['pro'];
  };

  var classNames = [styles['tier-field'], getBoxTierStyle()];

  return (
    <div className={classNames.join(' ')}>
      <p className={styles['text']}>{renderTitle()}</p>
      {renderSubtitle()}
    </div>
  );
};

export default TierField;
