import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';

class HandleSubscriptionModal extends Component {
  state = {
    confirmInput: '',
    isSaving: false,
  };

  changeSubscription = () => {
    this.setState({ isSaving: true });
    this.props
      .updateOrganisationAsAdmin(this.props.organisation.id, {
        billing_automatic_renewal: !this.props.organisation.billing_automatic_renewal,
      })
      .then(() => {
        this.setState({ isSaving: false });
        this.props.onClose();
        toast(() => <ToastMessage success text="System activated" />);
      });
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={540}>
          <Modal.Header
            ignoreLine
            title="Are you sure?"
            subtitle={
              this.props.organisation.billing_automatic_renewal === true
                ? 'The customer can still use AM Service until the end of the month'
                : 'The customer wants to start using AM Service again'
            }
            onClose={() => {
              this.props.onClose();
            }}
          />
          <Modal.Footer>
            <Button.Group>
              <Button
                destructive={this.props.organisation.billing_automatic_renewal === true}
                primary
                label={
                  this.props.organisation.billing_automatic_renewal === true
                    ? 'Cancel subscription'
                    : 'Activate subscription'
                }
                loading={this.state.isSaving}
                onClick={() => this.changeSubscription()}
              />
              <Button label="Cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrganisationAsAdmin: SDKReduxOperations.updateOrganisationAsAdmin,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(HandleSubscriptionModal)));
