import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeChecklistTemplateRow } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function updateChecklistTemplateRow(checklistTemplateRowId, params) {
  return dispatch => {
    return API.updateChecklistTemplateRow(checklistTemplateRowId, params)
      .then(res => {
        const { entities } = normalizeChecklistTemplateRow(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { checklistTemplateRowId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_TEMPLATE_ROW_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteChecklistTemplateRow(checklistTemplateRowId) {
  return (dispatch, getState) => {
    const checklistTemplateRow = getState().entities.checklistTemplateRowById[
      checklistTemplateRowId
    ];
    return API.deleteChecklistTemplateRow(checklistTemplateRowId)
      .then(res => {
        const payload = { checklistTemplateRowId, data: checklistTemplateRow };
        dispatch({ type: types.CHECKLIST_TEMPLATE_ROW_DELETED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createChecklistTemplateRowForChecklistTemplateVersion(
  checklistTemplateVersionId,
  params
) {
  return dispatch => {
    return API.createChecklistTemplateRowForChecklistTemplateVersion(
      checklistTemplateVersionId,
      params
    )
      .then(res => {
        const { entities } = normalizeChecklistTemplateRow(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { checklistTemplateVersionId, params, data: res.data };
        dispatch({
          type: types.CHECKLIST_TEMPLATE_ROW_CREATED_FOR_CHECKLIST_TEMPLATE_VERISON,
          payload,
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
