import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizePermissionProfile } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createPermissionProfile(systemId, params) {
  return dispatch => {
    return API.createPermissionProfile(systemId, params)
      .then(res => {
        const { entities } = normalizePermissionProfile(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.PERMISSION_PROFILE_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updatePermissionProfile(permissionProfileId, params) {
  return dispatch => {
    return API.updatePermissionProfile(permissionProfileId, params)
      .then(res => {
        const { entities } = normalizePermissionProfile(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { permissionProfileId, params, data: res.data };
        dispatch({ type: types.PERMISSION_PROFILE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deletePermissionProfile(permissionProfileId) {
  return dispatch => {
    return API.deletePermissionProfile(permissionProfileId)
      .then(res => {
        const payload = { permissionProfileId };
        dispatch({ type: types.PERMISSION_PROFILE_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
