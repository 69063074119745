import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SDKReduxOperations } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { WhiteCard, Button, Tooltip, Field, List, Icon } from 'views/components/Shared/General';
import { Grid, ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';
import EditAddressModal from './EditAddressModal';
import styles from './style.module.scss';

class BillingDetails extends Component {
  state = {
    editing: false,
    isSaving: false,
    editingBillingInformation: {},
    editingBillingInformationBeforeEdit: {},
    showEditAddressModal: false,
  };
  formatBillingInformation = value => {
    if (value === null) return '-';
    return value;
  };

  hasNoAddress = () => {
    const { billing_address1, billing_address2, billing_zip, billing_city, billing_country, billing_name } =
      this.props.organisation;
    return (
      !billing_address1 &&
      !billing_address2 &&
      !billing_zip &&
      !billing_city &&
      !billing_country &&
      !billing_name
    );
  };

  editBillingInformation = () => {
    const editingBillingInformation = {
      billing_name: this.props.organisation.billing_name,
      billing_address1: this.props.organisation.billing_address1,
      billing_address2: this.props.organisation.billing_address2,
      billing_zip: this.props.organisation.billing_zip,
      billing_city: this.props.organisation.billing_city,
      billing_country: this.props.organisation.billing_country,
      billing_orgnr: this.props.organisation.billing_orgnr,
      billing_vat: this.props.organisation.billing_vat,
      billing_email: this.props.organisation.billing_email,
      billing_your_reference: this.props.organisation.billing_your_reference,
      fortnox_customer_number: this.props.organisation.fortnox_customer_number,
    };
    this.setState({
      editing: true,
      editingBillingInformation: editingBillingInformation,
      editingBillingInformationBeforeEdit: editingBillingInformation,
    });
  };

  cancelEdit = () => {
    if (this.state.isSaving) return null;

    this.setState({ editing: false });
  };

  getModifiedBillingInformationData = () => {
    const organisation = this.state.editingBillingInformation;
    const billingInformationBeforeEdit = this.state.editingBillingInformationBeforeEdit;

    return Object.keys(organisation)
      .filter(key => organisation[key] !== billingInformationBeforeEdit[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: organisation[key],
        }),
        {}
      );
  };

  setBillingInformationValue = obj => {
    const newEditingBillingInformation = {
      ...this.state.editingBillingInformation,
      ...obj,
    };

    this.setState({
      editingBillingInformation: newEditingBillingInformation,
    });
  };

  save = () => {
    this.setState({ isSaving: true });

    const params = this.getModifiedBillingInformationData();

    this.props
      .updateOrganisationAsAdmin(this.props.organisation.id, params)
      .then(() => {
        this.setState({ isSaving: false, editing: false });
        toast(() => <ToastMessage success text="Changes have been saved" />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderEditAddressModal = () => {
    return (
      <EditAddressModal
        adress={this.state.editingAddress}
        title="Edit address"
        open={this.state.showEditAddressModal}
        onSave={adress => {
          this.setBillingInformationValue({
            billing_name: adress.name,
            billing_address1: adress.address1,
            billing_address2: adress.address2,
            billing_zip: adress.zip,
            billing_city: adress.city,
            billing_country: adress.country,
          });
          this.setState({
            showEditAddressModal: false,
            editingAddress: {},
          });
        }}
        onClose={() => {
          this.setState({ showEditAddressModal: false, editingAddress: {} });
        }}
      />
    );
  };

  renderAddressData = obj => {
    return (
      <React.Fragment>
        {obj.name ? <p>{obj.name}</p> : null}
        {obj.address1 ? <p>{obj.address1}</p> : null}
        {obj.address2 ? <p>{obj.address2}</p> : null}
        {obj.zip || obj.city ? (
          <p>
            {obj.zip ? <span>{obj.zip} </span> : null}
            {obj.city ? <span>{obj.city}</span> : null}
          </p>
        ) : null}
        {obj.country ? <p>{obj.country}</p> : null}
      </React.Fragment>
    );
  };

  renderEditingAddress = () => (
    <>
      <div
        className={`${styles['adress']} ${this.state.editing ? styles['edit'] : ''}`}
        onClick={() => {
          this.setState(prevState => ({
            showEditAddressModal: true,
            editingAddress: {
              name: this.state.editingBillingInformation.billing_name,
              address1: this.state.editingBillingInformation.billing_address1,
              address2: this.state.editingBillingInformation.billing_address2,
              zip: this.state.editingBillingInformation.billing_zip,
              city: this.state.editingBillingInformation.billing_city,
              country: this.state.editingBillingInformation.billing_country,
            },
          }));
        }}
      >
        {this.renderAddressData({
          name: this.state.editingBillingInformation.billing_name,
          address1: this.state.editingBillingInformation.billing_address1,
          address2: this.state.editingBillingInformation.billing_address2,
          zip: this.state.editingBillingInformation.billing_zip,
          city: this.state.editingBillingInformation.billing_city,
          country: this.state.editingBillingInformation.billing_country,
        })}
      </div>
    </>
  );

  renderAddress = () => {
    const { organisation } = this.props;
    if (this.state.editing) {
      return (
        <>
          <Field.Resource clearable={false} value={this.renderEditingAddress()} />
        </>
      );
    }
    if (this.hasNoAddress()) return '-';
    return (
      <>
        {this.renderAddressData({
          name: organisation.billing_name,
          address1: organisation.billing_address1,
          address2: organisation.billing_address2,
          zip: organisation.billing_zip,
          city: organisation.billing_city,
          country: organisation.billing_country,
        })}
      </>
    );
  };

  renderHeaderButtons = () => {
    if (this.state.editing) return null;

    return (
      <Tooltip
        trigger={
          <div>
            <Button
              type="icon"
              icon={<Icon type="pen" regular />}
              onClick={() => this.editBillingInformation()}
            />
          </div>
        }
        label="Edit"
      />
    );
  };

  renderFooter = () => {
    if (!this.state.editing) return null;

    return (
      <Button.Group>
        <Button primary small label="Save" loading={this.state.isSaving} onClick={this.save} />
        <Button small label="Cancel" onClick={this.cancelEdit} />
      </Button.Group>
    );
  };

  renderOrganisationNumber = () => {
    if (this.state.editing)
      return (
        <Field.Text
          value={this.state.editingBillingInformation.billing_orgnr}
          onChange={billing_orgnr => {
            this.setBillingInformationValue({ billing_orgnr });
          }}
        />
      );
    return (
      <p className={styles['values']}>
        {this.formatBillingInformation(this.props.organisation.billing_orgnr)}
      </p>
    );
  };
  renderVatNumber = () => {
    if (this.state.editing)
      return (
        <Field.Text
          value={this.state.editingBillingInformation.billing_vat}
          onChange={billing_vat => {
            this.setBillingInformationValue({ billing_vat });
          }}
        />
      );
    return (
      <p className={styles['values']}>{this.formatBillingInformation(this.props.organisation.billing_vat)}</p>
    );
  };

  renderEmail = () => {
    if (this.state.editing)
      return (
        <>
          <Field.Text
            value={this.state.editingBillingInformation.billing_email}
            onChange={billing_email => {
              this.setBillingInformationValue({ billing_email });
            }}
          />
        </>
      );
    return (
      <p className={styles['values']}>
        {this.formatBillingInformation(this.props.organisation.billing_email)}
      </p>
    );
  };

  renderReference = () => {
    if (this.state.editing)
      return (
        <Field.Text
          value={this.state.editingBillingInformation.billing_your_reference}
          onChange={billing_your_reference => {
            this.setBillingInformationValue({ billing_your_reference });
          }}
        />
      );
    return (
      <p className={styles['values']}>
        {this.formatBillingInformation(this.props.organisation.billing_your_reference)}
      </p>
    );
  };

  renderFortnoxNumber = () => {
    if (this.state.editing)
      return (
        <Field.Text
          value={this.state.editingBillingInformation.fortnox_customer_number}
          onChange={fortnox_customer_number => {
            this.setBillingInformationValue({ fortnox_customer_number });
          }}
        />
      );
    return (
      <p className={styles['values']}>
        {this.formatBillingInformation(this.props.organisation.fortnox_customer_number)}
      </p>
    );
  };

  render() {
    return (
      <>
        <WhiteCard
          title="Billing information"
          headerButtons={this.renderHeaderButtons()}
          footer={this.renderFooter()}
        >
          <Grid>
            {this.props.loading ? (
              <List.Item>
                <List.Item.TitleColumn loading />
              </List.Item>
            ) : (
              <Grid.Row>
                <Grid.Column md={6}>
                  <p className={styles['title']}>Organisation number</p>
                  {this.renderOrganisationNumber()}
                </Grid.Column>

                <Grid.Column md={6}>
                  <p className={styles['title']}>VAT Number</p>
                  {this.renderVatNumber()}
                </Grid.Column>
              </Grid.Row>
            )}
            <div className={styles['separator']} />
            {this.props.loading ? (
              <>
                <List.Item>
                  <List.Item.TitleColumn loading />
                </List.Item>
                <List.Item>
                  <List.Item.TitleColumn loading />
                </List.Item>
              </>
            ) : (
              <Grid.Row>
                <Grid.Column md={6}>
                  <p className={styles['title']}>Billing address</p>
                  {this.renderAddress()}
                </Grid.Column>
                <Grid.Column md={6}>
                  <p className={styles['title']}>Billing email</p>
                  {this.renderEmail()}
                </Grid.Column>
              </Grid.Row>
            )}
            {this.props.loading ? null : (
              <Grid.Row>
                <Grid.Column md={6}>
                  <p className={styles['title']}>Their reference</p>
                  {this.renderReference()}
                </Grid.Column>
                <Grid.Column md={6}>
                  <p className={styles['title']}>Fortnox customer number</p>
                  {this.renderFortnoxNumber()}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </WhiteCard>
        {this.renderEditAddressModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrganisationAsAdmin: SDKReduxOperations.updateOrganisationAsAdmin,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(BillingDetails)));
