import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import TierModal from '../../../components/TierModal';
import ActivateSystemModal from '../../../components/ActivateSystemModal';
import HandleSubscriptionModal from '../../../components/HandleSubscriptionModal';
import ActionButton from './ActionButton';
import styles from './style.module.scss';

class ActionButtons extends Component {
  state = {
    showActivateModal: false,
    showTierModal: false,
    showSubscriptionModal: false,
  };
  renderActivateSystemModal = () => {
    return (
      <ActivateSystemModal
        open={this.state.showActivateModal}
        onClose={() => this.setState({ showActivateModal: false })}
      />
    );
  };
  renderChangeTierModal = () => {
    return (
      <TierModal open={this.state.showTierModal} onClose={() => this.setState({ showTierModal: false })} />
    );
  };
  renderHandleSubscriptionModal = () => {
    return (
      <HandleSubscriptionModal
        open={this.state.showSubscriptionModal}
        onClose={() => this.setState({ showSubscriptionModal: false })}
      />
    );
  };
  render() {
    return (
      <>
        <div className={styles['grid-header']}>System actions</div>
        <div className={styles['button-grid']}>
          {this.props.organisation.demo ? (
            <div className={styles['grid-item']} style={{ msGridRow: 1, msGridColumn: 1 }}>
              <ActionButton
                title="Activate"
                subtitle="Activate this system"
                icon="check"
                onClick={() => this.setState({ showActivateModal: true })}
                iconThickness="regular"
              />
            </div>
          ) : null}
          <div className={styles['grid-item']} style={{ msGridRow: 2, msGridColumn: 1 }}>
            <ActionButton
              title="Change tier"
              subtitle="Change the tier of this system"
              onClick={() => this.setState({ showTierModal: true })}
              icon="cogs"
            />
          </div>
          {this.props.organisation.demo ? null : (
            <div className={styles['grid-item']} style={{ msGridRow: 2, msGridColumn: 1 }}>
              <ActionButton
                title={
                  this.props.organisation.billing_automatic_renewal === true
                    ? 'Cancel automatic renewal'
                    : 'Re-activate system'
                }
                subtitle={
                  this.props.organisation.billing_automatic_renewal === true
                    ? 'The contract has been terminated'
                    : 'Reinstate system and activate billing again'
                }
                onClick={() => this.setState({ showSubscriptionModal: true })}
                icon={
                  this.props.organisation.billing_automatic_renewal === true
                    ? 'exclamation-triangle'
                    : 'check'
                }
                iconThickness="regular"
              />
            </div>
          )}
        </div>
        {this.renderChangeTierModal()}
        {this.renderActivateSystemModal()}
        {this.renderHandleSubscriptionModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps)(ActionButtons)));
