import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import styles from './style.module.scss';

const Section = props => {
  return <div className={styles['section']}>{props.children}</div>;
};

export default Section;
