import types from './types';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  id: null,
  users: {
    ids: [],
    pagination: {},
  },
  systems: {
    ids: [],
  },
  billingDiscounts: {
    ids: [],
  },
  billingAdditionalCosts: {
    ids: [],
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_ORGANISATION_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        id,
      };
    }
    case types.FETCH_SYSTEMS_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        systems: {
          ...state.systems,
          ids,
        },
      };
    }
    case types.FETCH_USERS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        users: {
          ...state.users,
          ids,
          pagination,
        },
      };
    }

    case types.FETCH_BILLING_ADDITIONAL_COSTS_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        billingAdditionalCosts: {
          ...state.billingAdditionalCosts,
          ids,
        },
      };
    }
    case SDKReduxTypes.SYSTEM_CREATED_AS_ADMIN: {
      const { data: system } = action.payload;
      return {
        ...state,
        systems: {
          ...state.systems,
          ids: [system.id, ...state.systems.ids],
        },
      };
    }
    case SDKReduxTypes.BILLING_ADDITIONAL_COST_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        billingAdditionalCosts: {
          ...state.billingAdditionalCosts,
          ids: [data.id, ...state.billingAdditionalCosts.ids],
        },
      };
    }

    case SDKReduxTypes.BILLING_ADDITIONAL_COST_DELETED: {
      const { billingAdditionalCostId } = action.payload;
      return {
        ...state,
        billingAdditionalCosts: {
          ...state.billingAdditionalCosts,
          ids: state.billingAdditionalCosts.ids.filter(id => id !== billingAdditionalCostId),
        },
      };
    }

    case types.FETCH_BILLING_DISCOUNTS_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        billingDiscounts: {
          ...state.billingDiscounts,
          ids,
        },
      };
    }
    case SDKReduxTypes.BILLING_DISCOUNT_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        billingDiscounts: {
          ...state.billingDiscounts,
          ids: [data.id, ...state.billingDiscounts.ids],
        },
      };
    }

    case SDKReduxTypes.BILLING_DISCOUNT_DELETED: {
      const { billingDiscountId } = action.payload;
      return {
        ...state,
        billingDiscounts: {
          ...state.billingDiscounts,
          ids: state.billingDiscounts.ids.filter(id => id !== billingDiscountId),
        },
      };
    }

    default:
      return state;
  }
};
