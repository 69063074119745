import 'react-app-polyfill/ie11';
import 'core-js/features/object';
import 'core-js/features/array';
import 'core-js/features/string';
import 'core-js/features/map';

/*
  ------------
    DOM Polyfills
  ------------
*/
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector;
}