import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeLoginCredential } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createLoginCredential(userId, params) {
  return dispatch => {
    return API.createLoginCredential(userId, params)
      .then(res => {
        const { entities } = normalizeLoginCredential(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { userId, data: res.data };
        dispatch({ type: types.LOGIN_CREDENTIAL_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createLoginCredentialForGroup(systemId, params) {
  return dispatch => {
    return API.createLoginCredentialForGroup(systemId, params)
      .then(res => {
        const { entities } = normalizeLoginCredential(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, data: res.data };
        dispatch({ type: types.LOGIN_CREDENTIAL_CREATED_FOR_GROUP, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateLoginCredential(loginCredentialId, params) {
  return dispatch => {
    return API.updateLoginCredential(loginCredentialId, params)
      .then(res => {
        const { entities } = normalizeLoginCredential(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { loginCredentialId, data: res.data };
        dispatch({ type: types.LOGIN_CREDENTIAL_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteLoginCredential(userId, loginCredentialId) {
  return (dispatch, getState) => {
    const loginCredential = getState().entities.loginCredentialById[loginCredentialId];
    return API.deleteLoginCredential(loginCredentialId)
      .then(res => {
        const payload = { loginCredentialId, loginCredential };
        dispatch({ type: types.LOGIN_CREDENTIAL_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
