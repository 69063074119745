import React from 'react';
import { Loader } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default props => {
  let classNames = [styles['content'], 'modal-content'];
  if (props.noPadding) {
    classNames = [...classNames, styles['no-padding']];
  }
  if (props.grayBackground) {
    classNames = [...classNames, styles['gray']];
  }
  if (props.noHeader) {
    classNames = [...classNames, styles['no-header']];
  }
  if (props.hasTabs) {
    classNames = [...classNames, styles['has-tabs']];
  }

  return (
    <div className={classNames.join(' ')}>
      {props.loading === true ? (
        <div className={styles['loading-container']}>
          <Loader />
        </div>
      ) : (
        props.children
      )}
    </div>
  );
};
