import React, { Component } from 'react';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';

export default class EditTierTrialPeriodModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDatePicker: false,
      tier_trial_expiration_date: this.props.currentTierTrialExpirationDate,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        tier_trial_expiration_date: this.props.currentTierTrialExpirationDate,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  save = () => {
    const tier_trial_expiration_date = this.state.tier_trial_expiration_date;
    this.props.onSave({ tier_trial_expiration_date });
    this.props.onClose();
  };

  renderContent = () => (
    <Field label="Date">
      <Field.Date
        open={this.state.showDatePicker}
        onClose={() => this.setState({ showDatePicker: false })}
        onFocus={() => this.setState({ showDatePicker: true })}
        disabledDays={{
          before: new Date(),
        }}
        value={this.state.tier_trial_expiration_date}
        onChangeDate={tier_trial_expiration_date => {
          this.setState({
            tier_trial_expiration_date,
            showDatePicker: false,
          });
        }}
        onClear={() => {
          this.setState({
            tier_trial_expiration_date: null,
            showDatePicker: false,
          });
        }}
      />
    </Field>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={501}>
        <Modal.Header ignoreLine title="Edit tier trial expiration date" onClose={this.props.onClose} />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="Save" loading={this.props.saving} onClick={this.save} />
            <Button label="Cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}
