import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeUserSettings } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function updateUserSettings(systemId, userId, params) {
  return dispatch => {
    return API.updateUserSettings(systemId, userId, params)
      .then(res => {
        const { entities } = normalizeUserSettings(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, userId, params, data: res.data };
        dispatch({ type: types.USER_SETTINGS_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
