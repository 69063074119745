import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listTemplateFieldItems = (templateFieldId, params) =>
  request.get(
    `template_fields/${templateFieldId}/template_field_items?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`
  );
export const fetchTemplateFieldItem = templateFieldItemId =>
  request.get(`template_field_items/${templateFieldItemId}`);
export const createTemplateFieldItem = (templateFieldId, data) =>
  request.post(`template_fields/${templateFieldId}/template_field_items`, data);
export const updateTemplateFieldItem = (templateFieldItemId, data) =>
  request.patch(`template_field_items/${templateFieldItemId}`, data);
export const deleteTemplateFieldItem = templateFieldItemId =>
  request.delete(`template_field_items/${templateFieldItemId}`);
