import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import Breadcrumb from './Breadcrumb';
import BreadcrumbItem from './BreadcrumbItem';
import TabBar from './TabBar';
import TabBarItem from './TabBarItem';

class Header extends Component {
  renderBreadcrumb = () => {
    if (!this.props.breadcrumbComponent) return null;
    return <div className={styles['breadcrumb-container']}>{this.props.breadcrumbComponent}</div>;
  };

  renderTitleContainer = () => {
    return (
      <div className={styles['title-container']}>
        {this.renderSubtitle()}
        {this.renderTitle()}
        {this.renderExtraComponent()}
      </div>
    );
  };

  renderSubtitle = () => {
    if (!this.props.subtitle) return null;

    return <div className={styles['subtitle']}>{this.props.subtitle}</div>;
  };
  renderExtraComponent = () => {
    if (!this.props.extraComponent) return null;

    return <div className={styles['extra']}>{this.props.extraComponent}</div>;
  };

  renderTitle = () => {
    if (!this.props.title) return null;

    return (
      <div className={styles['title-wrapper']}>
        <div className={styles['title']} title={this.props.titleHoverText}>
          {this.props.title}
        </div>
        {this.renderEditTitleButton()}
      </div>
    );
  };

  renderEditTitleButton = () => {
    if (!this.props.editTitleButton) return null;

    return <div className={styles['edit-title-button']}>{this.props.editTitleButton}</div>;
  };

  renderTabBar = () => {
    return <div className={styles['tab-bab-container']}>{this.props.tabBarComponent}</div>;
  };

  renderButtons = () => {
    if (!this.props.buttonsComponent) return null;

    return <div className={styles['buttons-container']}>{this.props.buttonsComponent}</div>;
  };

  renderBottomContainer = () => {
    if (!this.props.tabBarComponent && !this.props.buttonsComponent) return null;
    return (
      <div className={styles['bottom-container']}>
        {this.renderTabBar()}
        {this.renderButtons()}
      </div>
    );
  };

  render() {
    const containerStyle = {
      maxWidth: this.props.noMaxWidth ? 'none' : this.props.maxWidth,
    };

    let classNames = [styles['background']];
    if (this.props.tabBarComponent || this.props.buttonsComponent) {
      classNames = [...classNames, styles['no-bottom-padding']];
    }
    if (this.props.title && this.props.smallTitle) {
      classNames = [...classNames, styles['small-title']];
    }
    if (!this.props.title) {
      classNames = [...classNames, styles['no-title']];
    }

    return (
      <div className={classNames.join(' ')} style={{ minWidth: this.props.minWidth }}>
        <div className={styles['container']} style={containerStyle}>
          {this.renderBreadcrumb()}
          {this.renderTitleContainer()}
          {this.renderBottomContainer()}
        </div>
      </div>
    );
  }
}

Header.Breadcrumb = Breadcrumb;
Header.BreadcrumbItem = BreadcrumbItem;
Header.TabBar = TabBar;
Header.TabBarItem = TabBarItem;

Header.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
  smallTitle: PropTypes.bool,
  extraComponent: PropTypes.node,
  breadcrumbComponent: PropTypes.node,
  tabBarComponent: PropTypes.node,
  editTitleButton: PropTypes.node,
  buttonsComponent: PropTypes.node,
  minWidth: PropTypes.number.isRequired,
  maxWidth: PropTypes.number.isRequired,
  noMaxWidth: PropTypes.bool.isRequired,
  titleHoverText: PropTypes.string,
};

Header.defaultProps = {
  minWidth: 800,
  maxWidth: 960,
  smallTitle: false,
  titleHoverText: null,
  noMaxWidth: false,
};

export default Header;
