import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import styles from './style.module.scss';

const Item = props => {
  const renderContent = () => <div className={styles['content']}>{props.children}</div>;

  const renderRightContainer = () => {
    if (!props.rightComponent) return null;
    return <div className={styles['right-container']}>{props.rightComponent}</div>;
  };

  const renderLeftContainer = () => {
    if (!props.leftComponent) return null;
    return <div className={styles['left-container']}>{props.leftComponent}</div>;
  };

  const classNames = () => {
    let classNames = [styles['list-item']];
    if (props.selected) {
      classNames = [...classNames, styles['selected']];
    }
    if (props.loading) {
      classNames = [...classNames, styles['loading']];
    }
    if (props.destructive) {
      classNames = [...classNames, styles['destructive']];
    }
    return classNames;
  };

  if (props.loading) {
    return (
      <div className={classNames().join(' ')} onClick={props.onClick}>
        {renderLeftContainer()}
        <div style={{ width: 100, height: 14 }}>
          <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
            <rect x="0" y="0" rx="3" ry="3" width="100" height="12" />
          </ContentLoader>
        </div>
        {renderRightContainer()}
      </div>
    );
  }

  return (
    <div className={classNames().join(' ')} onClick={props.onClick} onDoubleClick={props.onDoubleClick}>
      {renderLeftContainer()}
      {renderContent()}
      {renderRightContainer()}
    </div>
  );
};

export default Item;

Item.propTypes = {
  selected: PropTypes.bool,
  destructive: PropTypes.bool,
  onClick: PropTypes.func,
  leftComponent: PropTypes.element,
  rightComponent: PropTypes.element,
};

Item.defaultProps = {
  selected: false,
  destructive: false,
  leftComponent: null,
  rightComponent: null,
  onClick: () => {},
  onDoubleClick: () => {},
};
