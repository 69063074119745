const FETCH_ORGANISATION_SUCCESS = `system/FETCH_ORGANISATION_SUCCESS`;
const FETCH_USERS_SUCCESS = 'system/fetch_users_success';
const FETCH_SYSTEMS_SUCCESS = 'system/FETCH_SYSTEMS_SUCCESS';
const FETCH_BILLING_DISCOUNTS_SUCCESS = 'system/fetch_billing_discounts_success';
const FETCH_BILLING_ADDITIONAL_COSTS_SUCCESS = 'system/fetch_billing_additional_costs_success';

export default {
  FETCH_ORGANISATION_SUCCESS,
  FETCH_USERS_SUCCESS,
  FETCH_SYSTEMS_SUCCESS,
  FETCH_BILLING_DISCOUNTS_SUCCESS,
  FETCH_BILLING_ADDITIONAL_COSTS_SUCCESS,
};
