import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ContentLoader from 'react-content-loader';
import { SystemTier } from 'sdk/System';
import { EntitySelectors } from 'sdk/State/entities';
import { OrganisationSelectors } from 'state/ducks/organisation';
import { WhiteCard, MoneyWithCurrency } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

class RevenueCard extends Component {
  calculateRevenue = () => {
    const organisation = this.props.organisation;
    var discountedRevenue = false;
    var price = 0;
    var originalPrice = 0;
    if (organisation.billing_tier === SystemTier.Basic) {
      originalPrice = 299;
      price = 299;
    } else {
      originalPrice = 699;
      price = 699;
    }
    if (this.props.activeDiscount != null) {
      price = this.props.activeDiscount.price;
      discountedRevenue = true;
    }

    var userCount = organisation.billable_user_count;
    if (organisation.billable_users_cap && organisation.billable_users_cap < userCount) {
      userCount = organisation.billable_users_cap;
    }

    return (
      <>
        <div className={styles['revenue']}>
          <MoneyWithCurrency
            currency="SEK"
            value={userCount * originalPrice}
            fractionSize={0}
            currencyFontSize={16}
          />
        </div>
        {discountedRevenue ? (
          <div className={styles['subtitle']}>
            <MoneyWithCurrency currency="SEK" value={userCount * price} fractionSize={0} /> with discount
          </div>
        ) : null}
      </>
    );
  };

  renderContentLoader = () => (
    <div className={styles['loader-container']}>
      <ContentLoader
        primaryColor="#F5F5F5"
        secondaryColor="#EFEFEF"
        height={50}
        viewBox="0 0 135 65"
        preserveAspectRatio="xMinYMin"
      >
        <rect x="2" y="13" rx="0" ry="0" width="130" height="35" />
      </ContentLoader>
    </div>
  );

  renderContent = () => {
    if (this.props.loading) return this.renderContentLoader();
    return this.calculateRevenue();
  };

  render() {
    return (
      <CardGrid.Row>
        <WhiteCard noPadding>
          <div className={styles['container']}>
            <div className={styles['light-title']}>Monthly revenue</div>
            {this.renderContent()}
          </div>
        </WhiteCard>
      </CardGrid.Row>
    );
  }
}
function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return;

  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
    activeDiscount: OrganisationSelectors.getActiveDiscount(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps)(RevenueCard)));
