import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HelperFunctions } from 'sdk';
import { Button } from 'views/components/Shared/General';
import { NoneOf, Any, Exists, NotExists } from './comparators';

class Resource extends Component {
  renderAnyComparator = () => {
    return (
      <Any
        trigger={this.props.trigger}
        selected={this.props.comparator === HelperFunctions.FILTER_COMPARABLES.Any}
        onClick={() => {
          if (this.props.comparator !== HelperFunctions.FILTER_COMPARABLES.Any) {
            this.props.onChange({ value: [], comparator: HelperFunctions.FILTER_COMPARABLES.Any });
          }
        }}
      />
    );
  };

  renderNoneOfComparator = () => {
    return (
      <NoneOf
        trigger={this.props.trigger}
        selected={this.props.comparator === HelperFunctions.FILTER_COMPARABLES.NoneOf}
        onClick={() => {
          if (this.props.comparator !== HelperFunctions.FILTER_COMPARABLES.NoneOf) {
            this.props.onChange({ value: [], comparator: HelperFunctions.FILTER_COMPARABLES.NoneOf });
          }
        }}
      />
    );
  };

  renderExistsComparator = () => {
    if (this.props.required === false) {
      return (
        <Exists
          selected={
            this.props.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.props.value === true
          }
          onClick={() => {
            this.props.onChange({ value: true, comparator: HelperFunctions.FILTER_COMPARABLES.Exists });
          }}
        />
      );
    }
    return null;
  };

  renderNotExistsComparator = () => {
    if (this.props.required === false) {
      return (
        <NotExists
          selected={
            this.props.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.props.value === false
          }
          onClick={() => {
            this.props.onChange({
              value: false,
              comparator: HelperFunctions.FILTER_COMPARABLES.Exists,
            });
          }}
        />
      );
    }
    return null;
  };

  renderApplyFiltersButton = () => {
    return (
      <Button
        primary
        disabled={this.props.hasFiltersApplied === false}
        label="filters.add-button"
        onClick={this.props.onApplyfilter}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderAnyComparator()}
        {this.renderNoneOfComparator()}
        {this.renderExistsComparator()}
        {this.renderNotExistsComparator()}
        {this.renderApplyFiltersButton()}
      </>
    );
  }
}

Resource.propTypes = {
  hasFiltersApplied: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

Resource.defaultProps = {
  required: false,
  hasFiltersApplied: false,
  onChange: () => {},
};

export default Resource;
