import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listRequests = (systemId, params, config) =>
  request.get(`systems/${systemId}/requests?${HelperFunctions.convertObjToQueryParameters(params)}`, config);
export const fetchRequest = requestId => request.get(`requests/${requestId}`);
export const createRequest = (systemId, params) => request.post(`systems/${systemId}/requests`, params);
export const updateRequest = (requestId, params, config) =>
  request.patch(`requests/${requestId}`, params, config);
export const archiveRequest = (requestId, params) => request.put(`requests/${requestId}/archive`, params);
export const resetRequestFromArchive = (requestId, params) =>
  request.put(`requests/${requestId}/reset_from_archive`, params);
export const deleteRequest = requestId => request.delete(`requests/${requestId}`);
export const addRequestToWorkOrder = (requestId, params) =>
  request.put(`requests/${requestId}/add_to_work_order`, params);
export const getRequestsReport = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/request_report?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const batchUpdateRequests = (systemId, data) =>
  request.put(`systems/${systemId}/requests/batch`, data);
