import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listMeterReadings = (meterId, params, config = null) => {
  return request.get(
    `meters/${meterId}/meter_readings?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
};

export const updateMeterReading = (meterReadingId, data) =>
  request.patch(`meter_readings/${meterReadingId}`, data);
export const createMeterReading = (meterId, data) => request.post(`meters/${meterId}/meter_readings`, data);
export const deleteMeterReading = meterReadingId => request.delete(`meter_readings/${meterReadingId}`);
export const createMeterReadingForChecklistInstanceRow = (checklistInstanceRowId, data) =>
  request.post(`checklist_instance_rows/${checklistInstanceRowId}/meter_readings`, data);
