import React from 'react';
import { Pagination as GeneralPagination } from 'views/components/Shared/General';
import styles from './style.module.scss';

const Pagination = props => {
  return (
    <div className={styles['pagination']}>
      <GeneralPagination {...props} hideOptions />
    </div>
  );
};

export default Pagination;
