import React from 'react';
import styles from './style.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = props => {
  const getIconType = () => {
    if (props.light) {
      return 'fal';
    }
    if (props.regular) {
      return 'far';
    }
    return 'fas';
  };

  const getIconColor = () => {
    if (props.color) {
      return props.color;
    }
    if (props.white) {
      return '#fff';
    }
    if (props.lightGray) {
      return '#898989';
    }
    if (props.blue) {
      return '#4c9ac8';
    }
    if (props.orange) {
      return '#f07f39';
    }
    if (props.green) {
      return '#2baf2d';
    }
    if (props.red) {
      return '#c55050';
    }
  };

  if (props.withBackground) {
    let backgroundClassName = [styles['background']];
    if (props.blue) {
      backgroundClassName = [...backgroundClassName, styles['blue']];
    } else if (props.orange) {
      backgroundClassName = [...backgroundClassName, styles['orange']];
    } else if (props.green) {
      backgroundClassName = [...backgroundClassName, styles['green']];
    } else if (props.red) {
      backgroundClassName = [...backgroundClassName, styles['red']];
    } else {
      backgroundClassName = [...backgroundClassName, styles['gray']];
    }

    const type = getIconType();
    let classNames = [type, props.type];
    const color = getIconColor();
    return (
      <div
        className={backgroundClassName.join(' ')}
        style={{
          width: props.backgroundSize,
          height: props.backgroundSize,
          borderRadius: props.backgroundSize / 2,
          backgroundColor: props.backgroundColor,
        }}
      >
        <FontAwesomeIcon
          icon={classNames}
          style={{ fontSize: props.size, color: color }}
          fixedWidth={props.fixedWidth}
        />
      </div>
    );
  }

  const color = getIconColor();
  const type = getIconType();

  return (
    <FontAwesomeIcon
      icon={[type, props.type]}
      style={{ fontSize: props.size, color: color, opacity: props.disabled ? 0.5 : 1 }}
      fixedWidth={props.fixedWidth}
    />
  );
};

export default Icon;

Icon.defaultProps = {
  size: 14,
  disabled: false,
  fixedWidth: false,
  backgroundColor: null,
};
