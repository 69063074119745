import { API } from 'sdk';
import {
  normalizeOrganisation,
  normalizeBillingDiscount,
  normalizeBillingAdditionalCost,
  normalizeUser,
  normalizeSystem,
} from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import HelperFunctions from 'utilities/HelperFunctions';
import actions from './actions';

const fetchOrganisation = id => dispatch => {
  return API.fetchOrganisationAsAdmin(id).then(res => {
    const { entities, result } = normalizeOrganisation(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(actions.fetchOrganisationSuccess({ id: result }));
    return res.data;
  });
};

const fetchUsers =
  (orgId, params = {}) =>
  dispatch => {
    return API.listUsersAsAdmin({
      ...params,
      organisation_id: orgId,
      page_size: 8,
    }).then(res => {
      const { entities, result } = normalizeUser(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchUsersSuccess({
          ids: result,
          pagination,
        })
      );

      return res.data;
    });
  };

const fetchSystems = orgId => dispatch => {
  return API.listSystemsForOrganisationAsAdmin(orgId, { no_pagination: true }).then(res => {
    const { entities, result } = normalizeSystem(res.data);
    dispatch(EntityOperations.updateEntities(entities));

    dispatch(
      actions.fetchSystemsSuccess({
        ids: result,
      })
    );

    return res.data;
  });
};

const fetchBillingAdditionalCosts = orgId => dispatch => {
  return API.listAdditionalCostsAsAdmin(orgId).then(res => {
    const { entities, result } = normalizeBillingAdditionalCost(res.data);
    dispatch(EntityOperations.updateEntities(entities));

    dispatch(
      actions.fetchBillingAdditionalCostsSuccess({
        ids: result,
      })
    );

    return res.data;
  });
};

const fetchBillingDiscounts = orgId => dispatch => {
  return API.listDiscountsAsAdmin(orgId).then(res => {
    const { entities, result } = normalizeBillingDiscount(res.data);
    dispatch(EntityOperations.updateEntities(entities));

    dispatch(
      actions.fetchBillingDiscountsSuccess({
        ids: result,
      })
    );

    return res.data;
  });
};

export default {
  fetchSystems,
  fetchOrganisation,
  fetchUsers,
  fetchBillingAdditionalCosts,
  fetchBillingDiscounts,
};
