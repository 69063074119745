import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listWorkOrderTypes = (systemId, params) =>
  request.get(`systems/${systemId}/work_order_types?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const updateWorkOrderType = (workOrderTypeId, data) =>
  request.patch(`work_order_types/${workOrderTypeId}`, data);
export const createWorkOrderType = (systemId, data) =>
  request.post(`systems/${systemId}/work_order_types`, data);
export const deleteWorkOrderType = workOrderTypeId => request.delete(`work_order_types/${workOrderTypeId}`);
