import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeChecklistInstance } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function updateChecklistInstance(checklistInstanceId, params) {
  return dispatch => {
    return API.updateChecklistInstance(checklistInstanceId, params)
      .then(res => {
        const { entities } = normalizeChecklistInstance(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { checklistInstanceId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_INSTANCE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createChecklistInstanceForTemplate(templateId, params, meta = {}) {
  return dispatch => {
    return API.createChecklistInstanceForTemplate(templateId, params)
      .then(res => {
        const { entities } = normalizeChecklistInstance(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { templateId, data: res.data, meta };
        dispatch({ type: types.CHECKLIST_INSTANCE_CREATED_FROM_TEMPLATE, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteChecklistInstance(checklistInstanceId) {
  return (dispatch, getState) => {
    const checklistInstance = getState().entities.checklistInstanceById[checklistInstanceId];
    return API.deleteChecklistInstance(checklistInstanceId)
      .then(res => {
        const payload = { checklistInstanceId, data: checklistInstance };
        dispatch({ type: types.CHECKLIST_INSTANCE_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
