import { omit } from 'lodash-es';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.PURCHASE_ORDER_ROW_CREATED_FOR_PURCHASE_ORDER: {
      const { purchaseOrderId, data: purchaseOrderRow } = action.payload;
      if (state[purchaseOrderId]) {
        return {
          ...state,
          [purchaseOrderId]: {
            ...state[purchaseOrderId],
            purchase_order_rows: [...state[purchaseOrderId].purchase_order_rows, purchaseOrderRow.id],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.PURCHASE_ORDER_ROW_DELETED: {
      const { purchaseOrderRowId, data } = action.payload;
      const purchaseOrderId = data.purchase_order_id;
      if (state[purchaseOrderId]) {
        return {
          ...state,
          [purchaseOrderId]: {
            ...state[purchaseOrderId],
            purchase_order_rows: state[purchaseOrderId].purchase_order_rows.filter(
              id => id !== purchaseOrderRowId
            ),
          },
        };
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.purchaseOrderById) {
        return HelperFunctions.mergeEntities(state, action.payload.purchaseOrderById);
      }
      return state;
    default:
      return state;
  }
};
