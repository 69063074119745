import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { ListLayout } from 'views/components/Shared/Layout';
import { Menu } from 'views/components/Shared/General';
import HelperFunctions from 'utilities/HelperFunctions';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import Imports from './Imports';

class Developer extends Component {
  componentDidMount() {
    HelperFunctions.setDocumentTitle('Developer');
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Developer);
  }

  renderHeader = () => {
    return <ListLayout.Header title={<span>Developer</span>} />;
  };

  renderLeftMenu = () => {
    return (
      <ListLayout.Content.Menu>
        <ListLayout.Content.Menu.Content>
          <Menu.Item
            linkTo={{
              pathname: '/developer',
            }}
            title={<span>Migration forms</span>}
            selected={true}
          />
        </ListLayout.Content.Menu.Content>
      </ListLayout.Content.Menu>
    );
  };

  renderMainContent = () => {
    return (
      <ListLayout.Content.MainContent>
        <ListLayout.Content.MainContent.Content>
          <Imports />
        </ListLayout.Content.MainContent.Content>
      </ListLayout.Content.MainContent>
    );
  };

  render() {
    return (
      <ListLayout>
        {this.renderHeader()}
        <ListLayout.Content>
          {this.renderLeftMenu()}
          {this.renderMainContent()}
        </ListLayout.Content>
      </ListLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

export default withRouter(connect(null, mapDispatchToProps)(Developer));
