import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { OrganisationSelectors, OrganisationOperations } from 'state/ducks/organisation';
import { Modal } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { Button, List, Icon } from 'views/components/Shared/General';
import DiscountModalListItem from './DiscountModalListItem';
import styles from './style.module.scss';

class DiscountsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDoneFetching: false,
    };
  }

  isLoaded = () => this.props.billingDiscountIds != null && this.props.billingDiscountIds.length !== 0;

  componentDidMount() {
    this.fetchBillingDiscounts();
    this.setState({ isLoading: true, isDoneFetching: false });
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  fetchBillingDiscounts = params => {
    this.props
      .fetchBillingDiscounts(this.props.match.params.id)
      .then(discounts => {
        this.setState({ isTableLoading: false, isDoneFetching: true });
      })
      .catch(() => {
        this.setState({ isTableLoading: false, isDoneFetching: true });
      });
  };

  renderContentLoader = () => (
    <List.Item>
      <List.Item.TitleColumn loading />
    </List.Item>
  );

  renderDiscounts = () => {
    if (!this.isLoaded() && !this.state.isDoneFetching) return this.renderContentLoader();
    if (this.state.isDoneFetching && this.props.billingDiscountIds.length === 0) {
      return this.renderEmptyDataSet();
    }
    return this.props.billingDiscountIds.map(discountId => {
      return (
        <DiscountModalListItem
          onClose={() => this.props.onClose()}
          onClick={() => {
            this.props.onDetailSelected(discountId);
          }}
          id={discountId}
          key={discountId}
        />
      );
    });
  };

  renderEmptyDataSet = () => {
    return (
      <>
        <List.Item clickable onClick={() => this.props.onDetailSelected(null)}>
          <Icon type="cogs" blue withBackground backgroundSize={34} size={16} />
          <List.Item.TitleColumn
            title="Create custom discount"
            subtitle="Define your own discount for this customer"
          />
        </List.Item>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={560}>
          <Modal.Header
            ignoreLine
            title="Discounts"
            subtitle={
              this.props.billingDiscountIds.length === 0 ? (
                <p>
                  There are no discounts for the system{' '}
                  <span className={styles['subtitle-bold']}>{this.props.organisation.internal_name}</span>
                </p>
              ) : (
                <>
                  <p>
                    Current discount plan for{' '}
                    <span className={styles['subtitle-bold']}>{this.props.organisation.internal_name}</span>
                  </p>
                </>
              )
            }
            onClose={() => {
              this.props.onClose();
            }}
          />
          <Modal.Content>
            {this.props.billingDiscountIds.length === 0 ? null : (
              <>
                <Button primary label="Create new" onClick={() => this.props.onDetailSelected(null)} />

                <div className={styles['separator']} />
              </>
            )}
            <List light>{this.renderDiscounts()}</List>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createBillingDiscountAsAdmin: SDKReduxOperations.createBillingDiscountAsAdmin,
      fetchBillingDiscounts: OrganisationOperations.fetchBillingDiscounts,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const billingDiscountIds = OrganisationSelectors.getBillingDiscountIds(state);
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
    billingDiscountIds,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(DiscountsList)));
