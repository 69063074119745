import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeWorkOrderSpentTimeCategory } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createWorkOrderSpentTimeCategory(systemId, params) {
  return dispatch => {
    return API.createWorkOrderSpentTimeCategory(systemId, params)
      .then(res => {
        const { entities } = normalizeWorkOrderSpentTimeCategory(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.WORK_ORDER_SPENT_TIME_CATEGORY_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateWorkOrderSpentTimeCategory(workOrderSpentTimeCategoryId, params) {
  return dispatch => {
    return API.updateWorkOrderSpentTimeCategory(workOrderSpentTimeCategoryId, params)
      .then(res => {
        const { entities } = normalizeWorkOrderSpentTimeCategory(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderSpentTimeCategoryId, params, data: res.data };
        dispatch({ type: types.WORK_ORDER_SPENT_TIME_CATEGORY_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteWorkOrderSpentTimeCategory(workOrderSpentTimeCategoryId) {
  return dispatch => {
    return API.deleteWorkOrderSpentTimeCategory(workOrderSpentTimeCategoryId)
      .then(res => {
        const payload = { workOrderSpentTimeCategoryId };
        dispatch({ type: types.WORK_ORDER_SPENT_TIME_CATEGORY_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
