import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import * as types from './reduxTypes';
import { normalizeOrganisation } from 'sdk/Schemas';

export function createOrganisationAsAdmin(params) {
  return dispatch => {
    return API.createOrganisationAsAdmin(params)
      .then(res => {
        const { entities } = normalizeOrganisation(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { params, data: res.data };
        dispatch({
          type: types.ORGANISATION_CREATED_AS_ADMIN,
          payload,
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateOrganisationAsAdmin(systemId, params) {
  return dispatch => {
    return API.updateOrganisationAsAdmin(systemId, params)
      .then(res => {
        const { entities } = normalizeOrganisation(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.ORGANISATION_UPDATED_AS_ADMIN, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
