import React, { Component } from 'react';
import { Field } from 'views/components/Shared/General';

class CurrencyDropdown extends Component {
  state = {
    currency: 'sek',
  };
  renderCurrencyDropdownItems = () => (
    <>
      <Field.Dropdown.Item
        selected={this.state.currency === 'sek'}
        onClick={() => {
          this.props.onSelectedValue('sek');
          this.setState({
            currency: 'sek',
          });
        }}
      >
        Swedish crown (SEK)
      </Field.Dropdown.Item>
      <Field.Dropdown.Item
        selected={this.state.currency === 'eur'}
        onClick={() => {
          this.props.onSelectedValue('eur');
          this.setState({
            currency: 'eur',
          });
        }}
      >
        Euro (EUR)
      </Field.Dropdown.Item>
      <Field.Dropdown.Item
        selected={this.state.currency === 'nok'}
        onClick={() => {
          this.props.onSelectedValue('nok');
          this.setState({
            currency: 'nok',
          });
        }}
      >
        Norwegian crown (NOK)
      </Field.Dropdown.Item>
      <Field.Dropdown.Item
        selected={this.state.currency === 'dkk'}
        onClick={() => {
          this.props.onSelectedValue('dkk');
          this.setState({
            currency: 'dkk',
          });
        }}
      >
        Danish crowns (DKK)
      </Field.Dropdown.Item>
      <Field.Dropdown.Item
        selected={this.state.currency === 'usd'}
        onClick={() => {
          this.props.onSelectedValue('usd');
          this.setState({
            currency: 'usd',
          });
        }}
      >
        US Dollar (USD)
      </Field.Dropdown.Item>
    </>
  );

  renderSelectedCurrency = () => {
    switch (this.state.currency) {
      case 'sek':
        return 'Swedish crown (SEK)';
      case 'eur':
        return 'Euro (EUR)';
      case 'nok':
        return 'Norwegian crown (NOK)';
      case 'dkk':
        return 'Danish crown (DKK)';
      case 'usd':
        return 'US Dollar (USD)';
      default:
        return null;
    }
  };

  render() {
    return (
      <Field label="Currency">
        <Field.Dropdown clearable={false} value={this.renderSelectedCurrency()}>
          {this.renderCurrencyDropdownItems()}
        </Field.Dropdown>
      </Field>
    );
  }
}

export default CurrencyDropdown;
