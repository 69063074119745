import { HelperFunctions, SDKReduxTypes } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.SPARE_PART_VENDOR_UPDATED: {
      const { primary, id } = action.payload.data;
      if (primary === true) {
        let newSparePartVendorById = {};
        for (let sparePartVendorId in state) {
          newSparePartVendorById[sparePartVendorId] = {
            ...state[sparePartVendorId],
            primary: state[sparePartVendorId].id === id,
          };
        }
        return newSparePartVendorById;
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_VENDOR_CREATED: {
      const { primary, id } = action.payload.data;
      if (primary === true) {
        let newSparePartVendorById = {};
        for (let sparePartVendorId in state) {
          newSparePartVendorById[sparePartVendorId] = {
            ...state[sparePartVendorId],
            primary: state[sparePartVendorId].id === id,
          };
        }
        return newSparePartVendorById;
      }
      return state;
    }
    case SDKReduxTypes.VENDOR_CREATED: {
      const { spare_part_vendors } = action.payload.data;
      if (spare_part_vendors && spare_part_vendors.length === 1) {
        const sparePartVendor = spare_part_vendors[0];
        const { primary, id } = sparePartVendor;
        if (primary === true) {
          let newSparePartVendorById = {};
          for (let sparePartVendorId in state) {
            newSparePartVendorById[sparePartVendorId] = {
              ...state[sparePartVendorId],
              primary: state[sparePartVendorId].id === id,
            };
          }
          return newSparePartVendorById;
        }
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.sparePartVendorById) {
        return HelperFunctions.mergeEntities(state, action.payload.sparePartVendorById);
      }
      return state;
    default:
      return state;
  }
};
