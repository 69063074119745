import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const Content = props => {
  return <div className={styles['content']}>{props.children}</div>;
};

export default Content;

Content.propTypes = {
  children: PropTypes.node,
};

Content.defaultProps = {
  children: null,
};
