import React, { Component } from 'react';
import { withRouter, matchPath } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NewInlineModal } from 'views/components/Shared/General';
import Logo from 'assets/images/Logo.png';
import { AuthSelectors, AuthOperations } from 'state/ducks/auth';
import { MenuOperations } from 'state/ducks/menu';
import MenuButton from './MenuButton';
import styles from './style.module.scss';

class Menu extends Component {
  state = {
    showUserDropdown: false,
  };

  dropdownIsOpen = () => {
    return this.state.showUserDropdown;
  };

  openDropdown = dropdown => {
    this.setState({
      showUserDropdown: false,
      [dropdown]: true,
    });
  };

  renderUserDropdown = () => {
    if (this.state.showUserDropdown) {
      return (
        <MenuButton.Dropdown
          position="right"
          subButtons={[
            {
              link: '/files',
              title: <span>Logga ut</span>,
            },
          ]}
          onClose={() => this.setState({ showMoreOptionsDropdown: false })}
        />
      );
    }
    return null;
  };

  renderUser = () => {
    return (
      <>
        <div
          ref={ref => (this.userDropdownRef = ref)}
          className={styles['menu-button-inline-modal-container']}
        >
          <MenuButton
            title={this.props.currentUser.name}
            onClick={() =>
              this.setState(prevState => ({
                showUserDropdown: !prevState.showUserDropdown,
              }))
            }
            onCloseDropdown={() => this.setState({ showUserDropdown: false })}
            onMouseEnter={() => {
              if (this.dropdownIsOpen()) {
                this.openDropdown('showUserDropdown');
              }
            }}
          />
        </div>
        <NewInlineModal
          positionToRef={this.userDropdownRef}
          open={this.state.showUserDropdown}
          minWidth={260}
          position="right"
          onClose={() => {
            this.setState({ showUserDropdown: false });
          }}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item onClick={this.props.unauthenticateUser}>
                <span>Sign out</span>
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderMenu = () => {
    return (
      <>
        <MenuButton
          onClick={this.props.resetListState}
          selected={
            matchPath(this.props.location.pathname, {
              path: '/organisations',
            }) != null
          }
          title={<span>System</span>}
          linkTo={{
            pathname: `/organisations`,
            search: 'list=customer',
          }}
        />
        <MenuButton
          onClick={this.props.resetListState}
          selected={
            matchPath(this.props.location.pathname, {
              path: '/billing',
            }) != null
          }
          title={<span>Billing</span>}
          linkTo={{
            pathname: `/billing`,
            search: 'list=overview',
          }}
        />
        <MenuButton
          onClick={this.props.resetListState}
          selected={
            matchPath(this.props.location.pathname, {
              path: '/developer',
            }) != null
          }
          title={<span>Developer</span>}
          linkTo={{
            pathname: '/developer',
          }}
        />
      </>
    );
  };

  renderLeftMenu = () => {
    return <div className={styles['left-menu']}>{this.renderMenu()}</div>;
  };

  renderRightMenu = () => {
    return <div className={styles['right-menu']}>{this.renderUser()}</div>;
  };

  render() {
    return (
      <div className={styles['menu']}>
        <div className={styles['left-container']}>
          <div
            className={styles['logo-container']}
            onClick={() => this.props.history.push('/organisations?list=customer')}
          >
            <div className={styles['logo']}>
              <img src={Logo} alt="logo" />
            </div>
            <div className={styles['title']}>Admin</div>
          </div>
          <div className={styles['separator']} />
        </div>
        {this.renderLeftMenu()}
        {this.renderRightMenu()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      unauthenticateUser: AuthOperations.unauthenticateUser,
      resetListState: MenuOperations.resetListState,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
