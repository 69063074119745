import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

const Tabs = ({ tabs, selectedTab, onSelectTab }) => (
  <div className={styles['tab-container']}>
    {tabs.map(tab => (
      <div
        key={tab.id}
        className={`${styles['tab']} ${tab.id === selectedTab ? styles['selected'] : ''}`}
        onClick={e => onSelectTab(tab.id)}
      >
        <FormattedMessage id={tab.title} />
      </div>
    ))}
  </div>
);

export default Tabs;

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
};

Tabs.defaultProps = {
  tabs: [],
};
