import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'views/components/Shared/General';
import styles from './style.module.scss';

const Delete = ({ label, children, loading, onDelete, disabled }) => {
  return (
    <div className={styles['delete-container']}>
      {children ? <div className={styles['delete']}>{children}</div> : null}
      <Button disabled={disabled} primary destructive label={label} onClick={onDelete} loading={loading} />
    </div>
  );
};

export default Delete;

Delete.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
};

Delete.defaultProps = {
  label: 'general.delete',
  loading: false,
  disabled: false,
  onDelete: () => {},
};
