import React, { Component } from 'react';
import { Button } from 'views/components/Shared/General';
import Filter from './Filter';
import Values from './Values';
import Value from './Value';
import styles from './style.module.scss';

class AppliedFilters extends Component {
  render() {
    return (
      <>
        <div className={styles['container']}>{this.props.children}</div>
        <div className={styles['add-filter-button']}>
          <Button gray label="filters.add-button" onClick={() => this.props.onAddFilter()} />
        </div>
      </>
    );
  }
}

AppliedFilters.Values = Values;
AppliedFilters.Value = Value;
AppliedFilters.Filter = Filter;
export default AppliedFilters;
