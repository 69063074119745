import * as API from './api';
import { API as SDKAdpi } from 'sdk';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeGroup, normalizeUser } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function addUsers(groupId, userIds) {
  return dispatch => {
    return API.addUsersToGroup(groupId, userIds)
      .then(res => {
        const { entities } = normalizeGroup(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { groupId, userIds, data: res.data };
        dispatch({ type: types.USERS_ADDED_TO_GROUP, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createUser(systemId, params) {
  return dispatch => {
    return API.createUser(systemId, params)
      .then(res => {
        const { entities } = normalizeUser(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.USER_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createUserForOrganisation(orgId, params) {
  return dispatch => {
    return API.createUserForOrganisation(orgId, params)
      .then(res => {
        const { entities } = normalizeUser(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { orgId, data: res.data };
        dispatch({ type: types.USER_CREATED_FOR_ORGANISATION, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateUser(userId, params) {
  return (dispatch, getState) => {
    const beforeEdit = getState().entities.userById[userId];
    return API.updateUser(userId, params)
      .then(res => {
        const { entities } = normalizeUser(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { userId, data: res.data, beforeEdit };
        dispatch({ type: types.USER_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteUser(userId) {
  return (dispatch, getState) => {
    const user = getState().entities.userById[userId];
    return API.deleteUser(userId)
      .then(res => {
        const payload = { userId, user };
        dispatch({ type: types.USER_DELETED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function removeUser(groupId, userId) {
  return dispatch => {
    return API.removeUserFromGroup(groupId, userId)
      .then(res => {
        const payload = { groupId, userId, data: res.data };
        dispatch({ type: types.USER_REMOVED_FROM_GROUP, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateProfilePicture(userId, { image, mime_type }) {
  return dispatch => {
    return API.updateProfilePicture({ image, mime_type })
      .then(res => {
        const payload = { userId, data: res.data };
        dispatch({ type: types.CHANGED_PROFILE_PICTURE, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteProfilePicture(userId, imageId) {
  return dispatch => {
    return SDKAdpi.deleteImage(imageId)
      .then(() => {
        const payload = { userId };
        dispatch({ type: types.DELETED_PROFILE_PICTURE, payload });
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateMe(params) {
  return dispatch => {
    return API.updateMe(params)
      .then(res => {
        const { entities } = normalizeUser(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { data: res.data };
        dispatch({ type: types.ME_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function resendInvite(userId, email) {
  return dispatch => {
    return API.resendInvite(userId, email)
      .then(res => {
        const { entities } = normalizeUser(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { userId, data: res.data };
        dispatch({ type: types.USER_INVITE_RESENT, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function resetNotificationBadge(systemId) {
  return dispatch => {
    return API.resetNotificationBadge(systemId)
      .then(res => {
        const payload = { systemId, data: res.data };
        dispatch({ type: types.NOTIFICATION_BADGE_RESETTED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
