import React, { Component } from 'react';
import { Icon, Button } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class Search extends Component {
  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus();
    }
  }

  focus = () => {
    this.inputRef.focus();
  };

  classNames = () => {
    let classNames = [styles['search-container']];
    if (this.props.value.length > 0) {
      classNames = [...classNames, styles['has-value']];
    }
    return classNames;
  };

  renderRightLabel = () => {
    if (this.props.rightLabel) {
      return <div className={styles['right-label']}>{this.props.rightLabel}</div>;
    }
    return null;
  };

  render() {
    let iconClasses = [styles['icon']];
    if (this.props.value !== '') {
      iconClasses = [...iconClasses, styles['has-value']];
    }
    return (
      <div className={styles['container']}>
        <div className={this.classNames().join(' ')}>
          <div className={iconClasses.join(' ')}>
            <Icon regular type="search" />
          </div>
          <input
            ref={ref => (this.inputRef = ref)}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={e => this.props.onChange(e.target.value)}
            disabled={this.props.disabled}
          />
          <div
            className={`${styles['clear-container']} ${this.props.value.length > 0 ? styles['show'] : ''}`}
          >
            <Button type="icon" icon={<Icon light type="times" />} onClick={this.props.onClear} />
          </div>
        </div>
        {this.renderRightLabel()}
      </div>
    );
  }
}
