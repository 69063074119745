import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeTemplateFieldItem } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createTemplateFieldItem(templateFieldId, params) {
  return dispatch => {
    return API.createTemplateFieldItem(templateFieldId, params)
      .then(res => {
        const { entities } = normalizeTemplateFieldItem(res.data);
        dispatch(EntityOperations.updateEntities(entities));
        const payload = { templateFieldId, params, data: res.data };
        dispatch({ type: types.TEMPLATE_FIELD_ITEM_CREATED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateTemplateFieldItem(templateFieldItemId, params) {
  return dispatch => {
    return API.updateTemplateFieldItem(templateFieldItemId, params)
      .then(res => {
        const { entities } = normalizeTemplateFieldItem(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { templateFieldItemId, params, data: res.data };
        dispatch({ type: types.TEMPLATE_FIELD_ITEM_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteTemplateFieldItem(templateFieldItemId, column) {
  return (dispatch, getState) => {
    return API.deleteTemplateFieldItem(templateFieldItemId)
      .then(res => {
        const templateFieldId = getState().entities.templateFieldItemById[templateFieldItemId]
          .template_field_id;
        const payload = { templateFieldId, templateFieldItemId, column };
        dispatch({ type: types.TEMPLATE_FIELD_ITEM_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
