import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Content from './Content';
import Filter from './Filter';

const Container = ({ children }) => <PerfectScrollbar>{children}</PerfectScrollbar>;

Container.Filter = Filter;
Container.Content = Content;
export default Container;
