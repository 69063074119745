import React, { Component } from 'react';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { API } from 'sdk';
import { Icon, Loader } from 'views/components/Shared/General';
import ActivityCard from './ActivityCard';
import styles from './style.module.scss';

export default class Activity extends Component {
  getInitialState = () => ({
    selectedButton: 'recent',
    isFetching: true,
    assetsAllTime: null,
    assetsRecent: null,
    requestsAllTime: null,
    requestsRecent: null,
    vendorsAllTime: null,
    vendorsRecent: null,
    workOrdersAllTime: null,
    workOrdersRecent: null,
  });

  state = this.getInitialState();

  componentDidUpdate(prevProps) {
    if (prevProps.open === false && this.props.open) {
      this.setState({ ...this.getInitialState() });
      API.fetchSystemActivity(this.props.systemId).then(({ data }) => {
        this.setState({
          isFetching: false,
          assetsAllTime: data.assets_all_time,
          assetsRecent: data.assets_thirty_days,
          requestsAllTime: data.requests_all_time,
          requestsRecent: data.requests_thirty_days,
          vendorsAllTime: data.vendors_all_time,
          vendorsRecent: data.vendors_thirty_days,
          workOrdersAllTime: data.work_orders_all_time,
          workOrdersRecent: data.work_orders_thirty_days,
        });
      });
    }
  }

  renderToggleButtons = () => (
    <div className={styles['segmented-container']}>
      <button
        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        selected={this.state.selectedButton === 'recent'}
        className={
          this.state.selectedButton === 'recent'
            ? styles['selected-segmented-button']
            : styles['segmented-button']
        }
        onClick={() => this.setState({ selectedButton: 'recent' })}
      >
        Last 30 days
      </button>
      <button
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        selected={this.state.selectedButton === 'all_time'}
        className={
          this.state.selectedButton === 'all_time'
            ? styles['selected-segmented-button']
            : styles['segmented-button']
        }
        onClick={() => this.setState({ selectedButton: 'all_time' })}
      >
        All time
      </button>
    </div>
  );

  renderCards = () => {
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <ActivityCard
              title="Work orders"
              icon={<Icon type="wrench" withBackground green size={120} backgroundSize={190} />}
              value={
                this.state.selectedButton === 'recent'
                  ? this.state.workOrdersRecent
                  : this.state.workOrdersAllTime
              }
            />
          </Grid.Column>
          <Grid.Column>
            <ActivityCard
              title="Requests"
              icon={<Icon type="exclamation-circle" withBackground orange size={120} backgroundSize={190} />}
              value={
                this.state.selectedButton === 'recent'
                  ? this.state.requestsRecent
                  : this.state.requestsAllTime
              }
            />
          </Grid.Column>
          <Grid.Column>
            <ActivityCard
              title="Objects"
              icon={<Icon type="box-alt" withBackground blue size={120} backgroundSize={190} />}
              value={
                this.state.selectedButton === 'recent' ? this.state.assetsRecent : this.state.assetsAllTime
              }
            />
          </Grid.Column>
          <Grid.Column>
            <ActivityCard
              title="Vendors"
              icon={<Icon type="briefcase" withBackground size={120} backgroundSize={190} />}
              value={
                this.state.selectedButton === 'recent' ? this.state.vendorsRecent : this.state.vendorsAllTime
              }
            />
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };
  render() {
    return (
      <Modal isOpen={this.props.open} width={900}>
        <Modal.Header ignoreLine title="Activity" onClose={this.props.onClose} />
        <Modal.Content grayBackground>
          {this.state.isFetching ? (
            <Loader />
          ) : (
            <>
              {this.renderToggleButtons()}
              <Grid>{this.renderCards()}</Grid>
            </>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}
