import { Socket, LongPoll } from 'phoenix';

export let socket = null;

export function connect(url, params) {
  if (socket == null) {
    return new Promise(function (resolve, reject) {
      socket = new Socket(url, {
        params: params,
      });
      socket.connect();
      socket.onOpen(function () {
        resolve();
      });
      socket.onError(function (error, transport, establishedConnections) {
        if (transport === WebSocket && establishedConnections === 0) {
          socket.replaceTransport(LongPoll);
          socket.connect();
        }
      });
      return socket;
    });
  } else {
    return Promise.resolve();
  }
}

export function disconnect() {
  socket.disconnect();
  socket = null;
}
