import React, { Component } from 'react';
import Content from './Content';
import Pagination from './Pagination';
import styles from './style.module.scss';

class Container extends Component {
  render() {
    return <div className={styles['container']}>{this.props.children}</div>;
  }
}

Container.Content = Content;
Container.Pagination = Pagination;

export default Container;
