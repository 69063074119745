import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizePurchaseOrderDeliveryRow } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function updatePurchaseOrderDeliveryRow(purchaseOrderDeliveryRowId, params) {
  return dispatch => {
    return API.updatePurchaseOrderDeliveryRow(purchaseOrderDeliveryRowId, params)
      .then(res => {
        const { entities } = normalizePurchaseOrderDeliveryRow(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { purchaseOrderDeliveryRowId, params, data: res.data };
        dispatch({ type: types.PURCHASE_ORDER_DELIVERY_ROW_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deletePurchaseOrderDeliveryRow(purchaseOrderDeliveryRowId) {
  return (dispatch, getState) => {
    const purchaseOrderDeliveryRow = getState().entities.purchaseOrderDeliveryRowById[
      purchaseOrderDeliveryRowId
    ];
    return API.deletePurchaseOrderDeliveryRow(purchaseOrderDeliveryRowId)
      .then(res => {
        const payload = { purchaseOrderDeliveryRowId, purchaseOrderDeliveryRow };
        dispatch({ type: types.PURCHASE_ORDER_DELIVERY_ROW_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
