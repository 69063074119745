import React, { Component } from 'react';

class Column extends Component {
  render() {
    const { width } = this.props;
    let style;
    if (width) {
      style = {
        flexBasis: width,
        maxWidth: width,
      };
    } else {
      style = {
        flex: 1,
      };
    }

    return (
      <div style={style} className="layout-card-grid-column">
        {this.props.children}
      </div>
    );
  }
}

export default Column;
