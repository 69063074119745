import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';

class Resource extends Component {
  renderSubtitle = () => {
    switch (this.props.comparator) {
      case HelperFunctions.FILTER_COMPARABLES.Exists: {
        if (this.props.hasValue) {
          return <FormattedMessage id="filters.types.resource.exists.title" />;
        }
        return <FormattedMessage id="filters.types.resource.not-exists.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.Any: {
        return <FormattedMessage id="filters.types.resource.any.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.NoneOf: {
        return <FormattedMessage id="filters.types.resource.none-of.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.Exists: {
        return <FormattedMessage id="filters.types.resource.exists.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.NotExists: {
        return <FormattedMessage id="filters.types.resource.not-exists.title" />;
      }

      default:
        return null;
    }
  };

  render() {
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Container
        title={this.props.title}
        subtitle={this.renderSubtitle()}
        onClear={() => this.props.onClear()}
      >
        {this.props.children}
      </SideModal.Container.Filter.AppliedFilters.Filter.Container>
    );
  }
}

Resource.propTypes = {
  required: PropTypes.bool,
  title: PropTypes.node,
  comparator: PropTypes.string,
  onClear: PropTypes.func,
};

Resource.defaultProps = {
  title: null,
  comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
  required: false,
  onClear: () => {},
};

export default Resource;
