import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default ({ to, onClick }) => (
  <div className={styles['go-back']} onClick={onClick}>
    <Icon type="angle-left" regular />
    <FormattedMessage id="general.back" />
  </div>
);
