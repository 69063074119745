import React from 'react';
import Item from './Item';
import Items from './Items';
import ItemIcon from './ItemIcon';
import EmptyDataSet from './EmptyDataSet';
import Separator from './Separator';
import Section from './Section';
import styles from './style.module.scss';

const Dropdown = props => <div className={styles['body']}>{props.children}</div>;

Dropdown.Item = Item;
Dropdown.Items = Items;
Dropdown.ItemIcon = ItemIcon;
Dropdown.EmptyDataSet = EmptyDataSet;
Dropdown.Separator = Separator;
Dropdown.Section = Section;

export default Dropdown;
