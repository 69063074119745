import { request } from '../utilities/Axios';

export const listWorkOrderPurchasesForWorkOrder = workOrderId =>
  request.get(`work_orders/${workOrderId}/work_order_purchases`);
export const listWorkOrderPurchasesForPurchaseOrder = purchaseOrderId =>
  request.get(`purchase_orders/${purchaseOrderId}/work_order_purchases`);
export const createWorkOrderPurchase = (workOrderId, params) =>
  request.post(`work_orders/${workOrderId}/work_order_purchases`, params);
export const updateWorkOrderPurchase = (id, params) => request.patch(`work_order_purchases/${id}`, params);
export const deleteWorkOrderPurchase = (id, params) => request.delete(`work_order_purchases/${id}`, params);
