import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeWorkOrderPurchase } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createWorkOrderPurchase(workOrderId, params) {
  return dispatch => {
    return API.createWorkOrderPurchase(workOrderId, params)
      .then(res => {
        const { entities } = normalizeWorkOrderPurchase(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderId, params, data: res.data };
        dispatch({ type: types.WORK_ORDER_PURCHASE_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateWorkOrderPurchase(workOrderPurchaseId, params) {
  return dispatch => {
    return API.updateWorkOrderPurchase(workOrderPurchaseId, params)
      .then(res => {
        const { entities } = normalizeWorkOrderPurchase(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderPurchaseId, params, data: res.data };
        dispatch({ type: types.WORK_ORDER_PURCHASE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteWorkOrderPurchase(workOrderPurchaseId) {
  return dispatch => {
    return API.deleteWorkOrderPurchase(workOrderPurchaseId)
      .then(res => {
        const payload = { workOrderPurchaseId };
        dispatch({ type: types.WORK_ORDER_PURCHASE_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
