import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listSparePartTypes = (systemId, params) =>
  request.get(`systems/${systemId}/spare_part_types?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const updateSparePartType = (sparePartTypeId, data) =>
  request.patch(`spare_part_types/${sparePartTypeId}`, data);
export const createSparePartType = (systemId, data) =>
  request.post(`systems/${systemId}/spare_part_types`, data);
export const deleteSparePartType = sparePartTypeId => request.delete(`spare_part_types/${sparePartTypeId}`);
