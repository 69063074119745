import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const Pagination = props => {
  return <div className={styles['pagination']}>{props.children}</div>;
};

export default Pagination;

Pagination.propTypes = {
  children: PropTypes.node,
};

Pagination.defaultProps = {
  children: null,
};
