import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { debounce } from 'lodash-es';
import HeaderButton from './HeaderButton';
import Field from './Field';
import Separator from './Separator';
import SearchField from './SearchField';
import ListItem from './ListItem';
import Loader from './Loader';
import Pagination from './Pagination';
import Delete from './Delete';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import styles from './style.module.scss';

class InlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentHeight: 0,
      contentWidth: 0,
      pxFromTopOfWindow: 0,
      pxFromLeftSideOfWindow: 0,
    };
    this.initiatedClickOutside = false;
    this.debouncedWindowResizedFunction = debounce(this.setInlineModalHorizontalAndVerticalPositions, 300);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!nextProps.open && !this.props.open) return false;
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setInlineModalHorizontalAndVerticalPositions();
      window.addEventListener('resize', this.debouncedWindowResizedFunction);
      document.addEventListener('mousedown', this.handleMouseDown, true);
      document.addEventListener('click', this.handleClickOutside, true);
      document.addEventListener('keydown', this.handleKeyDown, true);
    } else if (prevProps.open && !this.props.open) {
      document.removeEventListener('click', this.handleClickOutside, true);
      document.removeEventListener('keydown', this.handleKeyDown, true);
      document.removeEventListener('mousedown', this.handleMouseDown, true);
      window.removeEventListener('resize', this.debouncedWindowResizedFunction);
    } else {
      const contentHeightIncreased =
        this.inlineModalPositioningRef != null &&
        this.inlineModalPositioningRef.scrollHeight !== prevState.contentHeight &&
        prevState.contentHeight < this.inlineModalPositioningRef.scrollHeight;
      const contentWidthWasChanged =
        prevState.contentWidth !== this.inlineModalPositioningRef.getBoundingClientRect().width;
      if (contentWidthWasChanged) {
        this.setInlineModalHorizontalPositions();
      } else if (contentHeightIncreased) {
        this.setInlineModalHorizontalAndVerticalPositions();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('keydown', this.handleKeyDown, true);
    document.removeEventListener('mousedown', this.handleMouseDown, true);
    window.removeEventListener('resize', this.debouncedWindowResizedFunction);
  }

  handleKeyDown = e => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.stopPropagation();
      this.props.onClose(e);
    }
  };

  handleMouseDown = e => {
    const clickedOutside =
      this.inlineModalPositioningRef && !this.inlineModalPositioningRef.contains(e.target);
    if (clickedOutside) {
      this.initiatedClickOutside = true;
    }
  };

  handleClickOutside = e => {
    const clickedOutside =
      this.inlineModalPositioningRef &&
      !this.inlineModalPositioningRef.contains(e.target) &&
      !this.props.positionToRef.contains(e.target);
    if (clickedOutside && this.initiatedClickOutside) {
      this.props.onClose(e);
      this.initiatedClickOutside = false;
    }
  };

  setInlineModalHorizontalAndVerticalPositions = () => {
    const amountOfPixelsToPushFromScreen = 10;
    const windowHeight = window.innerHeight;
    const triggerElementBottomPositionFromTop = this.props.positionToRef.getBoundingClientRect().bottom;
    const inlineModalContentHeight =
      this.props.maxHeight != null && this.inlineModalPositioningRef.scrollHeight > this.props.maxHeight
        ? this.props.maxHeight
        : this.inlineModalPositioningRef.scrollHeight;

    let pxFromTopOfWindow = triggerElementBottomPositionFromTop;
    if (triggerElementBottomPositionFromTop + inlineModalContentHeight > window.innerHeight) {
      pxFromTopOfWindow =
        windowHeight - inlineModalContentHeight < 0
          ? amountOfPixelsToPushFromScreen
          : windowHeight - inlineModalContentHeight - amountOfPixelsToPushFromScreen;
    }

    let pxFromLeftSideOfWindow = this.props.positionToRef.getBoundingClientRect().left;
    if (this.props.position === 'right') {
      pxFromLeftSideOfWindow =
        this.props.positionToRef.getBoundingClientRect().right -
        this.inlineModalPositioningRef.getBoundingClientRect().width;
      if (
        pxFromLeftSideOfWindow + this.inlineModalPositioningRef.getBoundingClientRect().width >
        window.innerWidth
      ) {
        let amountOverflowing =
          pxFromLeftSideOfWindow +
          this.inlineModalPositioningRef.getBoundingClientRect().width -
          window.innerWidth;
        pxFromLeftSideOfWindow = pxFromLeftSideOfWindow - amountOverflowing - amountOfPixelsToPushFromScreen;
      }
    } else {
      if (
        pxFromLeftSideOfWindow + this.inlineModalPositioningRef.getBoundingClientRect().width >
        window.innerWidth
      ) {
        let amountOverflowing =
          pxFromLeftSideOfWindow +
          this.inlineModalPositioningRef.getBoundingClientRect().width -
          window.innerWidth;
        pxFromLeftSideOfWindow = pxFromLeftSideOfWindow - amountOverflowing - amountOfPixelsToPushFromScreen;
      }
    }

    this.setState({
      pxFromTopOfWindow,
      pxFromLeftSideOfWindow,
      contentHeight: this.inlineModalPositioningRef.scrollHeight,
    });
  };

  setInlineModalHorizontalPositions = () => {
    const amountOfPixelsToPushFromScreen = 10;

    let pxFromLeftSideOfWindow = this.props.positionToRef.getBoundingClientRect().left;
    if (this.props.position === 'right') {
      pxFromLeftSideOfWindow =
        this.props.positionToRef.getBoundingClientRect().right -
        this.inlineModalPositioningRef.getBoundingClientRect().width;
      if (
        pxFromLeftSideOfWindow + this.inlineModalPositioningRef.getBoundingClientRect().width >
        window.innerWidth
      ) {
        let amountOverflowing =
          pxFromLeftSideOfWindow +
          this.inlineModalPositioningRef.getBoundingClientRect().width -
          window.innerWidth;
        pxFromLeftSideOfWindow = pxFromLeftSideOfWindow - amountOverflowing - amountOfPixelsToPushFromScreen;
      }
    } else {
      if (
        pxFromLeftSideOfWindow + this.inlineModalPositioningRef.getBoundingClientRect().width >
        window.innerWidth
      ) {
        let amountOverflowing =
          pxFromLeftSideOfWindow +
          this.inlineModalPositioningRef.getBoundingClientRect().width -
          window.innerWidth;
        pxFromLeftSideOfWindow = pxFromLeftSideOfWindow - amountOverflowing - amountOfPixelsToPushFromScreen;
      }
    }

    this.setState({
      contentWidth: this.inlineModalPositioningRef.getBoundingClientRect().width,
      pxFromLeftSideOfWindow,
    });
  };

  getInlineModalPositioningStyles = () => {
    return {
      top: `${this.state.pxFromTopOfWindow}px`,
      left: `${this.state.pxFromLeftSideOfWindow}px`,
      width: `${this.props.width}px`,
    };
  };

  renderInlineModalContent = () => {
    return (
      <div
        ref={ref => (this.inlineModalPositioningRef = ref)}
        onClick={e => e.stopPropagation()}
        className={`inline-modal ${styles['inline-modal']}`}
        style={this.getInlineModalPositioningStyles()}
      >
        {this.props.children}
      </div>
    );
  };

  render() {
    if (this.props.open) {
      return ReactDOM.createPortal(this.renderInlineModalContent(), document.body);
    }
    return null;
  }
}

InlineModal.Header = Header;
InlineModal.Body = Body;
InlineModal.Footer = Footer;
InlineModal.HeaderButton = HeaderButton;
InlineModal.Field = Field;
InlineModal.Separator = Separator;
InlineModal.ListItem = ListItem;
InlineModal.SearchField = SearchField;
InlineModal.Loader = Loader;
InlineModal.Pagination = Pagination;
InlineModal.Delete = Delete;

InlineModal.propTypes = {
  open: PropTypes.bool,
  inline: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  nestedInsideAnotherInlineModal: PropTypes.bool,
  position: PropTypes.string.isRequired,
  width: PropTypes.number,
};

InlineModal.defaultProps = {
  inline: false,
  open: false,
  nestedInsideAnotherInlineModal: false,
  position: 'left',
  width: null,
};

export default InlineModal;
