import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';

class Date extends Component {
  renderSubtitle = () => {
    switch (this.props.comparator) {
      case HelperFunctions.FILTER_COMPARABLES.After: {
        return <FormattedMessage id="filters.types.date.after.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.Before: {
        return <FormattedMessage id="filters.types.date.before.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.Between: {
        return <FormattedMessage id="filters.types.date.between.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.Exact: {
        return <FormattedMessage id="filters.types.date.exact.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.NotExact: {
        return <FormattedMessage id="filters.types.date.not-exact.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.Exists: {
        return <FormattedMessage id="filters.types.date.exists.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.NotExists: {
        return <FormattedMessage id="filters.types.date.not-exists.title" />;
      }

      default:
        return null;
    }
  };

  render() {
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Container
        title={this.props.title}
        subtitle={this.renderSubtitle()}
        onClear={() => this.props.onClear()}
      >
        {this.props.children}
      </SideModal.Container.Filter.AppliedFilters.Filter.Container>
    );
  }
}

Date.propTypes = {
  required: PropTypes.bool,
  title: PropTypes.node,
  comparator: PropTypes.string,
  onClear: PropTypes.func,
};

Date.defaultProps = {
  title: null,
  comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
  required: false,
  onClear: () => {},
};

export default Date;
