import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeSparePart } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createSparePart(systemId, params) {
  return dispatch => {
    return API.createSparePart(systemId, params)
      .then(res => {
        const { entities } = normalizeSparePart(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateSparePart(sparePartId, params) {
  return dispatch => {
    return API.updateSparePart(sparePartId, params)
      .then(res => {
        const { entities } = normalizeSparePart(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { sparePartId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function duplicateSparePart(sparePartId, params) {
  return dispatch => {
    return API.duplicateSparePart(sparePartId, params)
      .then(res => {
        const { entities } = normalizeSparePart(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { sparePartId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_DUPLICATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteSparePart(sparePartId) {
  return dispatch => {
    return API.deleteSparePart(sparePartId)
      .then(res => {
        const payload = { sparePartId };
        dispatch({ type: types.SPARE_PART_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function addMultipleSparePartsToAttachment(attachmentId, params) {
  return dispatch => {
    return API.addMultipleSparePartsToAttachment(attachmentId, params)
      .then(res => {
        const { entities } = normalizeSparePart(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { attachmentId, params, data: res.data };
        dispatch({ type: types.SPARE_PARTS_MULTIPLE_ADDED_TO_ATTACHMENT, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
