import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import * as types from './reduxTypes';
import { normalizeSystem } from 'sdk/Schemas';

export function updateSystem(systemId, params) {
  return dispatch => {
    return API.updateSystem(systemId, params)
      .then(res => {
        const { entities } = normalizeSystem(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.SYSTEM_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createSystemAsAdmin(orgId, params) {
  return dispatch => {
    return API.createSystemAsAdmin(orgId, params)
      .then(res => {
        const { entities } = normalizeSystem(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { params, data: res.data };
        dispatch({
          type: types.SYSTEM_CREATED_AS_ADMIN,
          payload,
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateSystemAsAdmin(systemId, params) {
  return dispatch => {
    return API.updateSystemAsAdmin(systemId, params)
      .then(res => {
        const { entities } = normalizeSystem(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.SYSTEM_UPDATED_AS_ADMIN, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
