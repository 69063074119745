import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listSparePartLocations = (systemId, params) =>
  request.get(
    `systems/${systemId}/spare_part_locations?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
export const createSparePartLocation = (systemId, data) =>
  request.post(`systems/${systemId}/spare_part_locations`, data);
export const updateSparePartLocation = (sparePartLocationId, data) =>
  request.patch(`spare_part_locations/${sparePartLocationId}`, data);
export const deleteSparePartLocation = sparePartLocationId =>
  request.delete(`spare_part_locations/${sparePartLocationId}`);
