import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { Button, Field } from 'views/components/Shared/General';
import { Between, After, Before, NotExact, Exact, Exists, NotExists } from './comparators';
import styles from './style.module.scss';

class Date extends Component {
  getInitialState = () => ({
    value: null,
    comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
    showRangeDatePicker: false,
    fromDate: null,
    toDate: null,
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  componentDidMount() {
    const { value, comparator } = this.props;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Between) {
      const fromDate = value.split('.', 1)[0];
      const toDate = value.substring(value.indexOf('.') + 3);
      this.setState({ value, comparator, fromDate, toDate });
    } else {
      this.setState({
        value,
        comparator,
      });
    }
  }

  renderExactComparator = () => {
    return (
      <Exact
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exact}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.Exact) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.Exact });
          }
        }}
      >
        <Field.Date
          onChangeDate={value =>
            this.setState({ value }, () => {
              if (value) {
                this.props.onChange({
                  value: this.state.value,
                  comparator: this.state.comparator,
                });
              }
            })
          }
          value={this.state.value}
        />
      </Exact>
    );
  };

  renderNotExactComparator = () => {
    return (
      <NotExact
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.NotExact}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.NotExact) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.NotExact });
          }
        }}
      >
        <Field.Date
          onChangeDate={value =>
            this.setState({ value }, () => {
              if (value) {
                this.props.onChange({
                  value: this.state.value,
                  comparator: this.state.comparator,
                });
              }
            })
          }
          value={this.state.value}
        />
      </NotExact>
    );
  };

  renderBeforeComparator = () => {
    return (
      <Before
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Before}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.Before) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.Before });
          }
        }}
      >
        <Field.Date
          onChangeDate={value =>
            this.setState({ value }, () => {
              if (value) {
                this.props.onChange({
                  value: this.state.value,
                  comparator: this.state.comparator,
                });
              }
            })
          }
          value={this.state.value}
        />
      </Before>
    );
  };

  renderAfterComparator = () => {
    return (
      <After
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.After}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.After) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.After });
          }
        }}
      >
        <Field.Date
          onChangeDate={value =>
            this.setState({ value }, () => {
              if (value) {
                this.props.onChange({
                  value: this.state.value,
                  comparator: this.state.comparator,
                });
              }
            })
          }
          value={this.state.value}
        />
      </After>
    );
  };

  renderBetweenComparator = () => {
    return (
      <Between
        value={this.state.fromDate}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Between}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.Between) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.Between });
          }
        }}
      >
        <div>
          <Field.Date
            onChangeDate={fromDate => {
              this.setState({ fromDate }, () => {
                if (this.state.fromDate && this.state.toDate) {
                  this.setState({ value: `${this.state.fromDate}...${this.state.toDate}` }, () => {
                    if (this.state.value) {
                      this.props.onChange({
                        value: this.state.value,
                        comparator: this.state.comparator,
                      });
                    }
                  });
                } else {
                  this.setState({ value: null });
                }
              });
            }}
            value={this.state.fromDate}
          />
        </div>
        <div className={styles['and']}>
          <FormattedMessage id="general.and" />
        </div>
        <div>
          <Field.Date
            onChangeDate={toDate => {
              this.setState({ toDate }, () => {
                if (this.state.fromDate && this.state.toDate) {
                  this.setState({ value: `${this.state.fromDate}...${this.state.toDate}` }, () => {
                    if (this.state.value) {
                      this.props.onChange({
                        value: this.state.value,
                        comparator: this.state.comparator,
                      });
                    }
                  });
                } else {
                  this.setState({ value: null });
                }
              });
            }}
            value={this.state.toDate}
          />
        </div>
      </Between>
    );
  };

  renderExistsComparator = () => {
    if (this.props.required === false) {
      return (
        <Exists
          selected={
            this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.value === true
          }
          onClick={() => {
            this.setState({ value: true, comparator: HelperFunctions.FILTER_COMPARABLES.Exists }, () => {
              this.props.onChange({
                value: true,
                comparator: HelperFunctions.FILTER_COMPARABLES.Exists,
              });
            });
          }}
        />
      );
    }
    return null;
  };

  renderNotExistsComparator = () => {
    if (this.props.required === false) {
      return (
        <NotExists
          selected={
            this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.value === false
          }
          onClick={() => {
            this.setState({ value: false, comparator: HelperFunctions.FILTER_COMPARABLES.Exists }, () => {
              this.props.onChange({
                value: false,
                comparator: HelperFunctions.FILTER_COMPARABLES.Exists,
              });
            });
          }}
        />
      );
    }
    return null;
  };

  hasFiltersApplied = () => {
    const { comparator, value } = this.state;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    if (value) {
      return true;
    }
    return false;
  };

  renderApplyFiltersButton = () => {
    return (
      <Button
        primary
        disabled={this.hasFiltersApplied() === false}
        label="filters.add-button"
        onClick={() => {
          this.props.onApplyfilter({
            comparator: this.state.comparator,
            value: this.state.value,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderExactComparator()}
        {this.renderNotExactComparator()}
        {this.renderBeforeComparator()}
        {this.renderAfterComparator()}
        {this.renderBetweenComparator()}
        {this.renderExistsComparator()}
        {this.renderNotExistsComparator()}
        {this.renderApplyFiltersButton()}
      </>
    );
  }
}

Date.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  comparator: PropTypes.string,
  onApplyfilter: PropTypes.func,
};

Date.defaultProps = {
  value: null,
  comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
  required: false,
  onApplyfilter: () => {},
};

export default Date;
