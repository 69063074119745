import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.IMAGE_CREATED_FOR_CHECKLIST_INSTANCE_ROW: {
      const { checklistInstanceRowId, data } = action.payload;
      const { id } = data;
      if (state[checklistInstanceRowId]) {
        return update(
          { ...state },
          {
            [checklistInstanceRowId]: {
              images: {
                $push: [id],
              },
            },
          }
        );
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId, data } = action.payload;
      if (!data) return state;

      const { checklist_instance_row_id } = data;
      if (state[checklist_instance_row_id] == null) return state;
      return {
        ...state,
        [checklist_instance_row_id]: {
          ...state[checklist_instance_row_id],
          images: state[checklist_instance_row_id].images.filter(id => id !== imageId),
        },
      };
    }
    case SDKReduxTypes.METER_READING_CREATED_FOR_CHECKLIST_INSTANCE_ROW: {
      const { checklistInstanceRowId, data: meter_reading } = action.payload;

      if (state[checklistInstanceRowId] == null) return state;
      return {
        ...state,
        [checklistInstanceRowId]: {
          ...state[checklistInstanceRowId],
          meter_reading: meter_reading.id,
        },
      };
    }
    case SDKReduxTypes.METER_READING_DELETED: {
      const { data: meterReading } = action.payload;
      const { checklist_instance_row_id } = meterReading;
      if (checklist_instance_row_id == null) return state;
      if (state[checklist_instance_row_id] == null) return state;
      return update(state, {
        [checklist_instance_row_id]: {
          $unset: ['meter_reading'],
        },
      });
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.checklistInstanceRowById) {
        return HelperFunctions.mergeEntities(state, action.payload.checklistInstanceRowById);
      }
      return state;

    default:
      return state;
  }
};
