import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';
import { Button, Icon } from 'views/components/Shared/General';
import { SideModal } from 'views/components/Shared/Layout';

class AppliedFilter extends Component {
  renderSelectedValue = () => {
    if (this.props.children) {
      return (
        <div className={styles['value-container']}>
          <div className={styles['subtitle']}>
            {this.props.subtitle}
            <span>: </span>
          </div>
          <div className={styles['values']}>{this.props.children}</div>
        </div>
      );
    }
    return <div className={styles['subtitle']}>{this.props.subtitle}</div>;
  };

  renderAnd = () => {
    return (
      <div className={styles['and-button']}>
        <SideModal.Container.Filter.AppliedFilters.Value>
          <FormattedMessage id="filters.and" />
        </SideModal.Container.Filter.AppliedFilters.Value>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className={styles['container']}>
          <div className={styles['content']}>
            <div className={styles['title']}>{this.props.title}</div>
            {this.renderSelectedValue()}
          </div>
          <div>
            <Button type="icon" icon={<Icon regular red type="trash-alt" />} onClick={this.props.onClear} />
          </div>
        </div>
        {this.renderAnd()}
      </>
    );
  }
}

export default AppliedFilter;

AppliedFilter.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  hasValue: PropTypes.bool,
  onClear: PropTypes.func,
};

AppliedFilter.defaultProps = {
  title: null,
  children: null,
  hasValue: false,
  onClear: () => {},
};
