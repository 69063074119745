import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeChecklistInstanceRow } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function updateChecklistInstanceRow(checklistInstanceRowId, params, config) {
  return dispatch => {
    return API.updateChecklistInstanceRow(checklistInstanceRowId, params, config)
      .then(res => {
        const { entities } = normalizeChecklistInstanceRow(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { checklistInstanceRowId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_INSTANCE_ROW_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function batchIgnoreDeviations(checklistInstanceId, params) {
  return dispatch => {
    return API.batchIgnoreDeviations(checklistInstanceId, params)
      .then(res => {
        const payload = { checklistInstanceId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_INSTANCE_BATCH_IGNORE_DEVIATIONS, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
