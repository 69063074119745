import React, { Component } from 'react';
import { API } from 'sdk';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';

export default class CreateImportModal extends Component {
  state = {
    confirmInput: '',
    isSaving: false,
    s3_path: process.env.REACT_APP_LOCAL_IMPORT_PATH || '',
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  save = () => {
    this.setState({ isSaving: true });

    const { s3_path } = this.state;
    API.createImportAsAdmin({ s3_path }).then(({ data }) => {
      this.props.onSave(data);
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.s3_path) {
      this.save();
    }
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={500}>
          <Modal.Header
            ignoreLine
            title="Create import form"
            subtitle="The form should then be sent to the customer for further import information"
            onClose={() => {
              this.props.onClose();
            }}
          />
          <Modal.Content>
            <Field view={false} label="S3 Path">
              <Field.Text
                autoFocus
                value={this.state.s3_path}
                onKeyPress={this.handleKeyPress}
                onChange={s3_path => {
                  this.setState({ s3_path });
                }}
              />
            </Field>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                disabled={this.state.s3_path.length === 0}
                label="Save"
                loading={this.state.isSaving}
                onClick={this.save}
              />
              <Button label="Cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
