import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const fetchSystem = systemId => request.get(`systems/${systemId}`);
export const updateSystem = (systemId, data) => request.patch(`systems/${systemId}`, data);
export const createImage = (systemId, data) => request.post(`systems/${systemId}/images`, data);
export const createAttachment = (systemId, data) => request.post(`systems/${systemId}/attachments`, data);

export const createSystemAsAdmin = (orgId, data) =>
  request.post(`admin/organisations/${orgId}/systems`, data);
export const fetchSystemAsAdmin = id => request.get(`admin/systems/${id}`);
export const listSystemsAsAdmin = params =>
  request.get(`admin/systems?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const listSystemsForOrganisation = (orgId, params) =>
  request.get(`organisations/${orgId}/systems?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const listSystemsForOrganisationAsAdmin = (orgId, params) =>
  request.get(`/admin/organisations/${orgId}/systems?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const updateSystemAsAdmin = (systemId, data) => request.patch(`admin/systems/${systemId}`, data);
export const deleteSystemAsAdmin = systemId => request.delete(`admin/systems/${systemId}`);
export const fetchSystemActivity = systemId => request.get(`admin/systems/${systemId}/activity_overview`);
