import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listChecklistTemplates = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/checklist_templates?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const fetchChecklistTemplate = checklistTemplateId =>
  request.get(`checklist_templates/${checklistTemplateId}`);
export const createChecklistTemplate = (systemId, data) =>
  request.post(`systems/${systemId}/checklist_templates`, data);
export const updateChecklistTemplate = (checklistTemplateId, data) =>
  request.patch(`checklist_templates/${checklistTemplateId}`, data);
export const createChecklistTemplateForWorkOrder = (workOrderId, data) =>
  request.post(`work_orders/${workOrderId}/checklist_templates`, data);
export const createChecklistTemplateForRecurringMaintenance = (recurringMaintenanceId, data) =>
  request.post(`recurring_maintenances/${recurringMaintenanceId}/checklist_templates`, data);
export const deleteChecklistTemplate = checklistTemplateId =>
  request.delete(`checklist_templates/${checklistTemplateId}`);
