import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listBillingDiscounts = (orgId, params) =>
  request.get(
    `organisations/${orgId}/billing_discounts?${HelperFunctions.convertObjToQueryParameters(params)}`
  );

export const listDiscountsAsAdmin = (orgId, params) =>
  request.get(
    `admin/organisations/${orgId}/billing_discounts?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
export const createBillingDiscountAsAdmin = (orgId, data) =>
  request.post(`admin/organisations/${orgId}/billing_discounts`, data);
export const fetchBillingDiscountAsAdmin = (id, params) => request.get(`admin/billing_discounts/${id}`);
export const updateBillingDiscountAsAdmin = (id, data) =>
  request.patch(`admin/billing_discounts/${id}`, data);
export const deleteBillingDiscountAsAdmin = id => request.delete(`admin/billing_discounts/${id}`);
