import axios from 'axios';
import update from 'immutability-helper';

let userAuthInterceptor = null;
let groupAuthInterceptor = null;

export function setUserAuthToken(token) {
  if (userAuthInterceptor != null) {
    request.interceptors.request.eject(userAuthInterceptor);
    userAuthInterceptor = null;
  }

  userAuthInterceptor = request.interceptors.request.use(config => {
    return update(config, {
      headers: { authorization: { $set: `Bearer ${token}` } },
    });
  });
}

export function setGroupAuthToken(token) {
  if (groupAuthInterceptor != null) {
    groupRequest.interceptors.request.eject(groupAuthInterceptor);
    groupAuthInterceptor = null;
  }
  groupAuthInterceptor = groupRequest.interceptors.request.use(config => {
    return update(config, {
      headers: { authorization: { $set: `Bearer ${token}` } },
    });
  });
}

export function setUserBaseUrl(baseUrl) {
  request.defaults.baseURL = baseUrl;
}

export function setGroupBaseUrl(baseUrl) {
  groupRequest.defaults.baseURL = baseUrl;
}

export const request = axios.create();
export const groupRequest = axios.create();
