import { omit } from 'lodash-es';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.PURCHASE_ORDER_DELIVERY_ROW_DELETED: {
      const { purchaseOrderDeliveryRowId } = action.payload;
      return omit(state, purchaseOrderDeliveryRowId);
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.purchaseOrderDeliveryRowById) {
        return HelperFunctions.mergeEntities(state, action.payload.purchaseOrderDeliveryRowById);
      }
      return state;
    default:
      return state;
  }
};
