import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const EmptyDataSet = props => {
  return (
    <div
      className={`
      ${styles['empty-data-set']}
      ${!props.small && !props.tiny && !props.micro ? styles['big'] : ''}
      ${props.tiny ? styles['tiny'] : ''}
      ${props.micro ? styles['micro'] : ''}
      ${props.centered ? styles['centered'] : ''}
      ${props.horizontal ? styles['horizontal'] : styles['vertical']}
      ${props.mainContentContainer ? styles['main-content-container'] : ''}
      ${props.listContainer ? styles['list-container'] : ''}
      ${props.modalContainer ? styles['modal-container'] : ''}
      ${props.modalContainerWithoutHeader ? styles['modal-container-no-header'] : ''}
      ${props.inlineModalContainer ? styles['inline-modal-container'] : ''}
      ${props.image == null ? styles['no-image'] : ''}
      ${props.modal ? styles['modal'] : ''}
      ${props.noBackground ? styles['no-background'] : ''}
    `}
    >
      {props.image == null ? null : (
        <div className={styles['image']}>
          <img src={props.image} alt="" />
        </div>
      )}
      <div className={styles['text-container']}>
        <p className={styles['title']}>{props.title}</p>
        {props.subtitle ? <p className={styles['subtitle']}>{props.subtitle}</p> : null}
        {props.button ? <div className={styles['button']}>{props.button}</div> : null}
      </div>
    </div>
  );
};

export default EmptyDataSet;

EmptyDataSet.propTypes = {
  micro: PropTypes.bool.isRequired,
  small: PropTypes.bool.isRequired,
  image: PropTypes.string,
  title: PropTypes.element.isRequired,
  subtitle: PropTypes.element,
  horizontal: PropTypes.bool,
  listContainer: PropTypes.bool,
  mainContentContainer: PropTypes.bool,
  modalContainer: PropTypes.bool,
  modalContainerWithoutHeader: PropTypes.bool,
  inlineModalContainer: PropTypes.bool,
  centered: PropTypes.bool,
};
EmptyDataSet.defaultProps = {
  micro: false,
  small: true,
  listContainer: false,
  horizontal: false,
  centered: true,
  mainContentContainer: false,
  modalContainer: false,
  modalContainerWithoutHeader: false,
  inlineModalContainer: false,
};
