export const SystemTier = {
  Enterprise: 'enterprise',
  Pro: 'pro',
  Basic: 'basic',
};

export const WorkOrderCategoryType = {
  None: 'none',
  Simple: 'simple',
  Detailed: 'detailed',
};
