import React, { Component } from 'react';
import { List } from 'views/components/Shared/General';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';

class AdditionalCostModalListItem extends Component {
  renderIsBilled = () => {
    if (this.props.billingAdditionalCost.is_billed) {
      return <p style={{ color: '#2baf2d' }}>Billed</p>;
    }
    return <p style={{ color: '#838383' }}>Not billed</p>;
  };

  renderTitle = () => {
    return `${this.props.billingAdditionalCost.title} (${this.props.system.name})`;
  };

  render() {
    return (
      <>
        <List.Item
          clickable={!this.props.billingAdditionalCost.is_billed}
          onClick={() => {
            if (!this.props.billingAdditionalCost.is_billed) {
              this.props.onClick();
            } else return;
          }}
        >
          <List.Item.TitleColumn
            title={this.renderTitle()}
            subtitle={moment(this.props.billingAdditionalCost.created_at).format('LL')}
          />
          <List.Item.Column>
            <p style={{ fontSize: 13 }}>{this.renderIsBilled()}</p>
          </List.Item.Column>
          <List.Item.Column alignRight>
            <p style={{ fontSize: 13 }}>
              {this.props.billingAdditionalCost.cost} {this.props.organisation.currency}
            </p>
          </List.Item.Column>
        </List.Item>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const billingAdditionalCost = EntitySelectors.getBillingAdditionalCost(state, ownProps.id);
  return {
    billingAdditionalCost,
    system: EntitySelectors.getSystem(state, billingAdditionalCost.billing_system_id),
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps)(AdditionalCostModalListItem)));
