import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeNotification } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function updateNotification(notificationId, params) {
  return dispatch => {
    return API.updateNotification(notificationId, params)
      .then(res => {
        const { entities } = normalizeNotification(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { notificationId, params, data: res.data };
        dispatch({ type: types.NOTIFICATION_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
