import React, { Component } from 'react';
import { Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class NavigateButton extends Component {
  render() {
    return (
      <div className={styles['navigate-button']}>
        <Button
          type="icon"
          iconButtonSize={28}
          icon={<Icon regular type="angle-right" />}
          onClick={e => {
            e.stopPropagation();
            this.props.onClick();
          }}
        />
      </div>
    );
  }
}

export default NavigateButton;
