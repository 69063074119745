import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class WhiteCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.collapsable ? !props.defaultOpen : false,
    };
  }

  toogleCollapse = () => {
    if (!this.props.collapsable) return null;
    if (this.state.collapsed) {
      this.props.onOpen();
    }
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  };

  renderCollapseIcon = () => {
    if (!this.props.collapsable) return null;

    return (
      <div className={styles['collapse-icon-container']}>
        <Icon light type="angle-right" size={22} />
      </div>
    );
  };

  renderTitle = () => {
    if (!this.props.title) return null;

    let classNames = [styles['title-container']];
    if (this.props.collapsable) {
      classNames = [...classNames, styles['collapsable']];
    }

    return (
      <div onClick={this.toogleCollapse} className={classNames.join(' ')}>
        {this.renderCollapseIcon()}
        <div>
          <div className={styles['title']}>{this.props.title}</div>
          {this.renderSubtitle()}
        </div>
      </div>
    );
  };

  renderSubtitle = () => {
    if (!this.props.subtitle) return null;

    return <div className={styles['subtitle']}>{this.props.subtitle}</div>;
  };

  renderHeaderButtons = () => {
    if (!this.props.headerButtons || this.state.collapsed) return null;
    return <div className={styles['header-buttons']}>{this.props.headerButtons}</div>;
  };

  renderContent = () => {
    let classNames = [styles['content']];
    if (!this.props.title) {
      classNames = [...classNames, styles['no-title']];
    }
    if (this.props.centerContent) {
      classNames = [...classNames, styles['center-content']];
    }

    if (this.props.collapsable) {
      return (
        <AnimateHeight duration={250} height={this.state.collapsed ? 0 : 'auto'} style={{ flex: 'auto' }}>
          <div className={classNames.join(' ')}>{this.props.children}</div>
        </AnimateHeight>
      );
    } else {
      return <div className={classNames.join(' ')}>{this.props.children}</div>;
    }
  };

  renderFooter = () => {
    if (!this.props.footer) return null;
    return <div className={styles['footer']}>{this.props.footer}</div>;
  };

  render() {
    let classNames = [styles['card']];
    let style = {};
    if (this.state.collapsed) {
      classNames = [...classNames, styles['collapsed']];
    }
    if (this.props.noPadding || this.props.padding) {
      classNames = [...classNames, styles['no-padding']];
    }
    if (this.props.clickable) {
      classNames = [...classNames, styles['clickable']];
    }
    if (this.props.padding) {
      style = { padding: this.props.padding };
    }
    if (this.props.fullHeight) {
      style = { ...style, height: '100%' };
    }

    return (
      <div style={style} className={classNames.join(' ')}>
        {this.renderHeaderButtons()}
        {this.renderTitle()}
        {this.renderContent()}
        {this.renderFooter()}
      </div>
    );
  }
}

WhiteCard.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  collapsable: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  headerButtons: PropTypes.element,
  footer: PropTypes.element,
  centerContent: PropTypes.bool,
  noPadding: PropTypes.bool,
  fullHeight: PropTypes.bool,
  padding: PropTypes.string,
};

WhiteCard.defaultProps = {
  onOpen: () => {},
  collapsable: false,
  defaultOpen: false,
  centerContent: false,
  fullHeight: false,
  noPadding: false,
  padding: null,
};
