const DOMAIN_PREFIX = 'auth/';

const AUTHENTICATE_USER = `${DOMAIN_PREFIX}authenticate_user`;
const AUTHENTICATE_USER_ERROR = `${DOMAIN_PREFIX}authenticate_user_error`;

const PREPARE_AUTHENTICATED_USER = `${DOMAIN_PREFIX}prepare_authenticated_user`;
const PREPARE_AUTHENTICATED_USER_ERROR = `${DOMAIN_PREFIX}prepare_authenticated_user_error`;

const UNAUTHENTICATE_USER = `${DOMAIN_PREFIX}unauthenticate_user`;

const APP_IS_READY = `${DOMAIN_PREFIX}app_is_ready`;

export default {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_ERROR,
  UNAUTHENTICATE_USER,
  PREPARE_AUTHENTICATED_USER,
  PREPARE_AUTHENTICATED_USER_ERROR,
  APP_IS_READY,
};
