import React from 'react';
import PropTypes from 'prop-types';
import Content from './Content';
import FilterBar from './FilterBar';
import Pagination from './Pagination';
import styles from './style.module.scss';

const MainContent = props => {
  return <div className={styles['main-content']}>{props.children}</div>;
};

MainContent.Content = Content;
MainContent.FilterBar = FilterBar;
MainContent.Pagination = Pagination;
export default MainContent;

MainContent.propTypes = {
  children: PropTypes.node,
};

MainContent.defaultProps = {
  children: null,
};
