import React from 'react';
import styles from './style.module.scss';
import Item from './Item';

const Items = ({ children }) => {
  return <div className={styles['items']}>{children}</div>;
};

Items.Item = Item;
export default Items;
