import React from 'react';
import Section from './Section';
import Header from './Header';
import Detail from './Detail';
import AppliedFilters from './AppliedFilters';

const Filter = ({ children }) => <div>{children}</div>;

Filter.Detail = Detail;
Filter.Header = Header;
Filter.Section = Section;
Filter.AppliedFilters = AppliedFilters;
export default Filter;
