import { SDKReduxTypes, HelperFunctions } from 'sdk';
import { UserType } from 'sdk/SystemAccess';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.DELETED_PROFILE_PICTURE: {
      const { userId } = action.payload;
      return {
        ...state,
        [userId]: {
          ...state[userId],
          profile_picture_image_id: null,
        },
      };
    }
    case SDKReduxTypes.CHANGED_PROFILE_PICTURE: {
      const { userId, data } = action.payload;
      return {
        ...state,
        [userId]: {
          ...state[userId],
          profile_picture_image_id: data.id,
        },
      };
    }
    case SDKReduxTypes.LOGIN_CREDENTIAL_DELETED: {
      const { loginCredential } = action.payload;
      const { user_id } = loginCredential;
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          login_credential: null,
        },
      };
    }
    case SDKReduxTypes.LOGIN_CREDENTIAL_CREATED: {
      const { data } = action.payload;
      const { user_id, id } = data;
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          login_credential: id,
        },
      };
    }
    case SDKReduxTypes.USER_SETTINGS_UPDATED: {
      const { userId, data } = action.payload;
      if (state[userId] && data.hasOwnProperty('suggest_production_board_for_asset_id')) {
        return {
          ...state,
          [userId]: {
            ...state[userId],
            suggest_production_board_for_asset: data.suggest_production_board_for_asset_id,
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.USER_CREATED: {
      const { data, params } = action.payload;
      if (data.system_access.user_type === UserType.Operator) {
        if (params.user_setting && params.user_setting.suggest_production_board_for_asset_id) {
          return {
            ...state,
            [data.id]: {
              ...state[data.id],
              suggest_production_board_for_asset: params.user_setting.suggest_production_board_for_asset_id,
            },
          };
        }
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.userById) {
        return HelperFunctions.mergeEntities(state, action.payload.userById);
      }
      return state;
    default:
      return state;
  }
};
