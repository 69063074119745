import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';

export default class Radio extends Component {
  render() {
    return <Checkbox {...this.props} radio />;
  }
}

Radio.Group = Checkbox.Group;

Radio.propTypes = {
  label: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
Radio.defaultProps = {
  selected: false,
  onChange: () => {},
};
