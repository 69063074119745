import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeAsset } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createAsset(systemId, params) {
  return dispatch => {
    return API.createAsset(systemId, params)
      .then(res => {
        const { entities } = normalizeAsset(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.ASSET_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateAsset(assetId, params) {
  return dispatch => {
    return API.updateAsset(assetId, params)
      .then(res => {
        const { entities } = normalizeAsset(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { assetId, params, data: res.data };
        dispatch({ type: types.ASSET_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteAsset(assetId, params) {
  return dispatch => {
    return API.deleteAsset(assetId, params)
      .then(res => {
        const payload = { assetId, params };
        dispatch({ type: types.ASSET_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function duplicateAsset(assetId) {
  return dispatch => {
    return API.duplicateAsset(assetId)
      .then(res => {
        const { entities } = normalizeAsset(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { assetId, data: res.data };
        dispatch({ type: types.ASSET_DUPLICATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function addMultipleAssetsToAttachment(attachmentId, params) {
  return dispatch => {
    return API.addMultipleAssetsToAttachment(attachmentId, params)
      .then(res => {
        const { entities } = normalizeAsset(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { attachmentId, params, data: res.data };
        dispatch({ type: types.ASSET_MULTIPLE_ADDED_TO_ATTACHMENT, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
