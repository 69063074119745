import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import { WhiteCard } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

class OverviewCard extends Component {
  renderContentLoader = () => (
    <div className={styles['loader-container']}>
      <ContentLoader
        primaryColor="#F5F5F5"
        secondaryColor="#EFEFEF"
        height={50}
        viewBox="0 0 135 65"
        preserveAspectRatio="xMinYMin"
      >
        <rect x="2" y="13" rx="0" ry="0" width="130" height="35" />
      </ContentLoader>
    </div>
  );

  renderContent = () => {
    if (this.props.loading) return this.renderContentLoader();
    return (
      <div className={styles['value-container']}>
        <p>{this.props.value}</p>
        {this.props.subtitle ? <p className={styles['value-subtitle']}>{this.props.subtitle}</p> : null}
      </div>
    );
  };

  render() {
    return (
      <CardGrid.Row>
        <WhiteCard noPadding>
          <div className={styles['card-container']}>
            <div className={styles['static-icon']}>{this.props.icon}</div>
            <div className={styles['title-container']}>
              <p className={styles['light-title']}>{this.props.title}</p>
            </div>
            {this.renderContent()}
          </div>
        </WhiteCard>
      </CardGrid.Row>
    );
  }
}

export default OverviewCard;
