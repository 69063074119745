import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeChecklistTemplateLink } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createChecklistTemplateLinkForWorkOrder(workOrderId, params) {
  return dispatch => {
    return API.createChecklistTemplateLinkForWorkOrder(workOrderId, params)
      .then(res => {
        const { entities } = normalizeChecklistTemplateLink(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderId, params, data: res.data };
        dispatch({
          type: types.CHECKLIST_TEMPLATE_LINK_CREATED_FOR_WORK_ORDER,
          payload,
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createChecklistTemplateLinkForRecurringMaintenance(recurringMaintenanceId, params) {
  return dispatch => {
    return API.createChecklistTemplateLinkForRecurringMaintenance(recurringMaintenanceId, params)
      .then(res => {
        const { entities } = normalizeChecklistTemplateLink(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { recurringMaintenanceId, params, data: res.data };
        dispatch({
          type: types.CHECKLIST_TEMPLATE_LINK_CREATED_FOR_RECURRING_MAINTENANCE,
          payload,
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteChecklistTemplateLink(checklistTemplateLinkId) {
  return (dispatch, getState) => {
    const checklistTemplateLink = getState().entities.checklistTemplateLinkById[checklistTemplateLinkId];
    return API.deleteChecklistTemplateLink(checklistTemplateLinkId)
      .then(res => {
        const payload = { checklistTemplateLinkId, data: checklistTemplateLink };
        dispatch({ type: types.CHECKLIST_TEMPLATE_LINK_DELETED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
