import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { HelperFunctions } from 'sdk';
import sv from 'sdk/Translations/sv.json';
import en from 'sdk/Translations/en.json';

const locales = {
  sv,
  en,
};
/*
  Hook up this component to redux,
  an action is dispatched when language is changed
  which causes this component and its children to re-render.
*/

function mapStateToProps(state, ownProps) {
  return {
    key: ownProps.language,
    locale: ownProps.language,
    messages: HelperFunctions.flattenIntlMessages(locales[ownProps.language] || 'en'),
  };
}

export default connect(mapStateToProps)(IntlProvider);
