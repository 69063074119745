import React from 'react';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default ({ icon, blue, iconThickness, iconColor }) => {
  let classNames = [styles['icon']];
  if (blue) {
    classNames = [...classNames, styles['blue']];
  }
  return (
    <div className={classNames.join(' ')}>
      <Icon
        type={icon}
        regular={iconThickness === 'regular'}
        light={iconThickness === 'light'}
        green={iconColor === 'green'}
        red={iconColor === 'red'}
        orange={iconColor === 'orange'}
        blue={iconColor === 'blue'}
        white={iconColor === 'white'}
      />
    </div>
  );
};
