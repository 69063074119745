import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.USERS_ADDED_TO_GROUP: {
      const { groupId, data } = action.payload;
      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          user_count: state[groupId].user_count + data.length,
        },
      };
    }
    case SDKReduxTypes.USER_REMOVED_FROM_GROUP: {
      const { groupId } = action.payload;
      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          user_count: state[groupId].user_count - 1,
        },
      };
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.groupById) {
        return HelperFunctions.mergeEntities(state, action.payload.groupById);
      }
      return state;
    default:
      return state;
  }
};
