import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.PURCHASE_ORDER_DELIVERY_ROW_DELETED: {
      const { purchaseOrderDeliveryRow, purchaseOrderDeliveryRowId } = action.payload;
      const purchaseOrderDeliveryId = purchaseOrderDeliveryRow.purchase_order_delivery_id;
      if (state[purchaseOrderDeliveryId]) {
        return {
          ...state,
          [purchaseOrderDeliveryId]: {
            ...state[purchaseOrderDeliveryId],
            purchase_order_delivery_rows: state[purchaseOrderDeliveryId].purchase_order_delivery_rows.filter(
              id => id !== purchaseOrderDeliveryRowId
            ),
          },
        };
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.purchaseOrderDeliveryById) {
        return HelperFunctions.mergeEntities(state, action.payload.purchaseOrderDeliveryById);
      }
      return state;
    default:
      return state;
  }
};
