import React, { Component } from 'react';
import { Field } from 'views/components/Shared/General';

class CountryDropdown extends Component {
  state = {
    country: this.props.selected || '',
  };
  renderCountryDropdownItems = () => {
    return (
      <>
        <Field.Dropdown.Item
          selected={this.state.country === 'SE'}
          onClick={() => {
            this.props.onSelectedCountry('SE');
            this.setState({
              country: 'SE',
            });
          }}
        >
          SE
        </Field.Dropdown.Item>
        <Field.Dropdown.Item
          selected={this.state.country === 'EN'}
          onClick={() => {
            this.props.onSelectedCountry('EN');
            this.setState({
              country: 'EN',
            });
          }}
        >
          EN
        </Field.Dropdown.Item>
        <Field.Dropdown.Item
          selected={this.state.country === 'DK'}
          onClick={() => {
            this.props.onSelectedCountry('DK');
            this.setState({
              country: 'DK',
            });
          }}
        >
          DK
        </Field.Dropdown.Item>
        <Field.Dropdown.Item
          selected={this.state.country === 'NO'}
          onClick={() => {
            this.props.onSelectedCountry('NO');
            this.setState({
              country: 'NO',
            });
          }}
        >
          NO
        </Field.Dropdown.Item>
        <Field.Dropdown.Item
          selected={this.state.country === 'FI'}
          onClick={() => {
            this.props.onSelectedCountry('FI');
            this.setState({
              country: 'FI',
            });
          }}
        >
          FI
        </Field.Dropdown.Item>
        <Field.Dropdown.Item
          selected={this.state.country === 'US'}
          onClick={() => {
            this.props.onSelectedCountry('US');
            this.setState({
              country: 'US',
            });
          }}
        >
          US
        </Field.Dropdown.Item>
      </>
    );
  };

  render() {
    return (
      <Field label="Country code">
        <Field.Dropdown clearable={false} value={this.state.country}>
          {this.renderCountryDropdownItems()}
        </Field.Dropdown>
      </Field>
    );
  }
}

export default CountryDropdown;
