import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HelperFunctions } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dropdown from '../Dropdown';

class Currency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      currencies: HelperFunctions.getAvailableCurrencies().sort((key_a, key_b) => {
        const a = this.props.intl.formatMessage({ id: `currencies.${key_a}` });
        const b = this.props.intl.formatMessage({ id: `currencies.${key_b}` });
        return ('' + a).localeCompare(b);
      }),
    };
  }

  onChange = value => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value);
    }
  };

  renderCurrency = currency => {
    return (
      <span>
        <FormattedMessage id={`currencies.${currency}`} /> <span> - {currency}</span>
      </span>
    );
  };

  render() {
    return (
      <Dropdown
        angleDown
        clearable={this.props.clearable}
        searchable
        searchPlaceholder={this.props.intl.formatMessage({
          id: 'general.search-placeholder',
        })}
        searchTerm={this.state.searchTerm}
        onOpen={() => this.setState({ searchTerm: '' })}
        onSearch={value => this.setState({ searchTerm: value })}
        value={this.renderCurrency(this.props.value)}
      >
        {this.state.currencies
          .filter(
            currency =>
              this.state.searchTerm === '' ||
              currency.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
              this.props.intl
                .formatMessage({ id: `currencies.${currency}` })
                .toLowerCase()
                .includes(this.state.searchTerm.toLowerCase())
          )
          .map(currency => (
            <Dropdown.Item
              key={currency}
              selected={this.props.value === currency}
              onClick={() => this.props.onChange(currency)}
            >
              {this.renderCurrency(currency)}
            </Dropdown.Item>
          ))}
      </Dropdown>
    );
  }
}

export default injectIntl(Currency);

Currency.propTypes = {
  clearable: PropTypes.bool,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
};
Currency.defaultProps = {
  clearable: false,
  onClear: () => {},
  onChange: () => {},
};
