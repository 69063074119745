import React from 'react';
import styles from './style.module.scss';
import { FormattedMessage } from 'react-intl';

export default ({ label, children }) => {
  return (
    <div className={styles['row']}>
      <p>
        <FormattedMessage id={label} />
      </p>
      <div>{children}</div>
    </div>
  );
};
