import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { MenuOperations } from 'state/ducks/menu';
import { ContentContainer, CardGrid } from 'views/components/Shared/Layout';
import MetaInfo from './MetaInfo';
import AdditionalCostsButton from './AdditionalCostsButton';
import BillingDetails from './BillingDetails';
import DemoField from './DemoField';

class BillingInformation extends Component {
  render() {
    return (
      <ContentContainer>
        <CardGrid>
          <CardGrid.Column>
            <CardGrid.Row>
              <BillingDetails />
            </CardGrid.Row>
          </CardGrid.Column>
          <CardGrid.Column width={268}>
            {this.props.organisation.demo ? (
              <CardGrid.Row>
                <DemoField />
              </CardGrid.Row>
            ) : null}
            <CardGrid.Row>
              <MetaInfo />
            </CardGrid.Row>
            <CardGrid.Separator />
            <CardGrid.Row>
              <AdditionalCostsButton />
            </CardGrid.Row>
          </CardGrid.Column>
        </CardGrid>
      </ContentContainer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingInformation);
