import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Field, Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class ActivateSystemModal extends Component {
  state = {
    confirmInput: '',
    isSaving: false,
  };

  activateSystem = () => {
    this.setState({ isSaving: true });
    this.props
      .updateOrganisationAsAdmin(this.props.organisation.id, {
        demo: false,
      })
      .then(() => {
        this.setState({ isSaving: false });
        this.props.onClose();
        toast(() => <ToastMessage success text="System activated" />);
      });
  };

  hasError = () => {
    const { organisation } = this.props;
    var error = false;
    if (
      organisation.billing_orgnr == null ||
      organisation.billing_name == null ||
      organisation.billing_address1 == null ||
      organisation.billing_zip == null ||
      organisation.billing_city == null ||
      organisation.billing_country == null ||
      organisation.billing_orgnr.length === 0 ||
      organisation.billing_name.length === 0 ||
      organisation.billing_address1.length === 0 ||
      organisation.billing_zip.length === 0 ||
      organisation.billing_city.length === 0 ||
      organisation.billing_country.length === 0
    ) {
      error = true;
    }
    return error;
  };

  renderHasOrgError = () => {
    if (this.props.organisation.billing_orgnr == null || this.props.organisation.billing_orgnr.length === 0) {
      return 'Organisation number';
    }
    return null;
  };
  renderHasNameError = () => {
    if (this.props.organisation.billing_name == null || this.props.organisation.billing_name.length === 0) {
      return 'Billing name';
    }
    return null;
  };
  renderHasAddressError = () => {
    if (
      this.props.organisation.billing_address1 == null ||
      this.props.organisation.billing_address1.length === 0
    ) {
      return 'Billing address';
    }
    return null;
  };
  renderHasZipError = () => {
    if (this.props.organisation.billing_zip == null || this.props.organisation.billing_zip.length === 0) {
      return 'Billing ZIP';
    }
    return null;
  };
  renderHasCityError = () => {
    if (this.props.organisation.billing_city == null || this.props.organisation.billing_city.length === 0) {
      return 'Billing city';
    }
    return null;
  };
  renderHasCountryError = () => {
    if (
      this.props.organisation.billing_country == null ||
      this.props.organisation.billing_country.length === 0
    ) {
      return 'Billing country';
    }
    return null;
  };

  renderContent = () => (
    <React.Fragment>
      <Modal.Content>
        {this.hasError() ? (
          <div className={styles['error-container']}>
            <div className={styles['title-container']}>
              <p className={styles['error-title']}>This system cannot be activated since it is missing:</p>
              <Icon type="exclamation-triangle" regular red size={18} />
            </div>
            <ul>
              <p className={styles['error-text']}>
                <li>{this.renderHasOrgError()}</li>
                <li>{this.renderHasNameError()}</li>
                <li>{this.renderHasAddressError()}</li>
                <li>{this.renderHasZipError()}</li>
                <li>{this.renderHasCityError()}</li>
                <li>{this.renderHasCountryError()}</li>
              </p>
            </ul>
          </div>
        ) : null}
        {this.props.organisation.billing_discounts == null ||
        this.props.organisation.billing_discounts.length === 0 ? (
          <div className={styles['discount-container']}>
            {this.props.organisation.name} does not contain any discounts, are you sure you want to activate?
          </div>
        ) : null}
        {this.hasError() ? null : (
          <Field
            label={
              <p className={styles['label']}>
                Activating system <span className={styles['label-bold']}>{this.props.organisation.name}</span>
                . Type <span className={styles['label-bold']}>'activate'</span> to confirm (This can not be
                undone)
              </p>
            }
          >
            <Field.Text
              autoFocus
              value={this.state.confirmInput}
              onChange={value => {
                this.setState({ confirmInput: value });
              }}
            />
          </Field>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button.Group>
          <Button
            primary
            disabled={this.state.confirmInput !== 'activate' || this.hasError()}
            label="Activate"
            loading={this.state.isSaving}
            onClick={() => this.activateSystem()}
          />
          <Button label="Cancel" onClick={this.props.onClose} />
        </Button.Group>
      </Modal.Footer>
    </React.Fragment>
  );
  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={560}>
          <Modal.Header
            ignoreLine
            title="Activate billing"
            subtitle="Remove the demo tag and begin billing the customer"
            onClose={() => {
              this.props.onClose();
            }}
          />
          {this.renderContent()}
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrganisationAsAdmin: SDKReduxOperations.updateOrganisationAsAdmin,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ActivateSystemModal)));
