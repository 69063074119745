import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeChecklistTemplate } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function updateChecklistTemplate(templateId, params) {
  return dispatch => {
    return API.updateChecklistTemplate(templateId, params)
      .then(res => {
        const { entities } = normalizeChecklistTemplate(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { templateId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_TEMPLATE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createChecklistTemplate(systemId, params) {
  return dispatch => {
    return API.createChecklistTemplate(systemId, params)
      .then(res => {
        const { entities } = normalizeChecklistTemplate(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_TEMPLATE_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createChecklistTemplateForWorkOrder(workOrderId, params) {
  return dispatch => {
    return API.createChecklistTemplateForWorkOrder(workOrderId, params)
      .then(res => {
        const { entities } = normalizeChecklistTemplate(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_TEMPLATE_CREATED_FOR_WORK_ORDER, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createChecklistTemplateForRecurringMaintenance(recurringMaintenanceId, params) {
  return dispatch => {
    return API.createChecklistTemplateForRecurringMaintenance(recurringMaintenanceId, params)
      .then(res => {
        const { entities } = normalizeChecklistTemplate(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { recurringMaintenanceId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_TEMPLATE_CREATED_FOR_RECURRING_MAINTENANCE, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteChecklistTemplate(checklistTemplateId) {
  return dispatch => {
    return API.deleteChecklistTemplate(checklistTemplateId)
      .then(res => {
        const payload = { checklistTemplateId };
        dispatch({ type: types.CHECKLIST_TEMPLATE_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
