import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { OrganisationSelectors } from 'state/ducks/organisation';
import { bindActionCreators } from 'redux';
import { WhiteCard, Field, Icon, List, MoneyWithCurrency } from 'views/components/Shared/General';
import toast from 'react-hot-toast';
import { CardGrid, ToastMessage } from 'views/components/Shared/Layout';
import TierField from './TierField';
import EditDemoPeriodModal from './EditDemoPeriodModal';
import EditTierTrialPeriodModal from './EditTierTrialPeriodModal';
import styles from './style.module.scss';

class OverviewCard extends Component {
  state = {
    demoDateModalOpen: false,
    tierTrialModalOpen: false,
  };
  formatActiveDiscount = () => {
    if (this.props.activeDiscount != null) {
      return (
        <>
          <span className={styles['discount']}>
            <MoneyWithCurrency
              currency="SEK"
              value={this.props.activeDiscount.price}
              fractionSize={0}
              currencyFontSize={13}
            />
            <p> (Until {moment(this.props.activeDiscount.expiration_date).format('LL')})</p>
          </span>
        </>
      );
    }
    return ' - ';
  };

  isLoaded = () =>
    this.props.organisation != null &&
    this.props.organisation.demo != null &&
    this.props.organisation.tier != null;
  renderTierField = () => {
    return (
      <TierField
        tier={this.props.organisation.tier}
        trial={
          !this.props.organisation.demo &&
          this.props.organisation.tier !== this.props.organisation.billing_tier
        }
        trialPeriod={this.props.organisation.tier_trial_expiration_date}
      />
    );
  };

  renderContentLoader = () => {
    return (
      <>
        <List.Item>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
        <List.Item>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      </>
    );
  };

  save = params => {
    this.setState({ isSaving: true });

    this.props
      .updateOrganisationAsAdmin(this.props.organisation.id, params)
      .then(() => {
        this.setState({ isSaving: false });
        toast(() => <ToastMessage success text="Changes have been saved" />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderDemoDateModal = () => {
    return (
      <EditDemoPeriodModal
        open={this.state.demoDateModalOpen}
        currentExpirationDate={this.props.organisation.demo_expiration_date}
        saving={this.state.isSaving}
        onSave={data => {
          this.save(data);
        }}
        onClose={() => {
          this.setState({ demoDateModalOpen: false });
        }}
      />
    );
  };

  renderTrialDateModal = () => {
    return (
      <EditTierTrialPeriodModal
        open={this.state.tierTrialModalOpen}
        currentTierTrialExpirationDate={this.props.organisation.tier_trial_expiration_date}
        saving={this.state.isSaving}
        onSave={data => {
          this.save(data);
        }}
        onClose={() => {
          this.setState({ tierTrialModalOpen: false });
        }}
      />
    );
  };

  renderContent = () => {
    if (this.props.loading) return this.renderContentLoader();
    return (
      <>
        {this.props.organisation.billing_automatic_renewal === false ? (
          <div className={styles['cancelled-field']}>CANCELLED SUBSCRIPTION</div>
        ) : null}
        {this.props.organisation.demo ? <div className={styles['demo-field']}>DEMO</div> : null}
        {this.renderTierField()}
        <div className={styles['separator']} />
        <div>
          {this.props.organisation.demo ? (
            <div
              className={styles['clickable-row']}
              onClick={() => this.setState({ demoDateModalOpen: true })}
            >
              <Field
                icon={<Icon type="calendar-alt" red regular withBackground backgroundSize={34} size={16} />}
                value="Demo period"
              >
                <p className={styles['title']}>Demo expiration date</p>
                {this.props.organisation.demo_expiration_date
                  ? moment(this.props.organisation.demo_expiration_date).format('LL')
                  : '-'}
              </Field>
            </div>
          ) : null}

          {!this.props.organisation.demo &&
          this.props.organisation.tier !== this.props.organisation.billing_tier ? (
            <div
              className={styles['clickable-row']}
              onClick={() => this.setState({ tierTrialModalOpen: true })}
            >
              <Field
                icon={<Icon type="calendar-alt" red regular withBackground backgroundSize={34} size={16} />}
                subtitle="Tier trial period"
              >
                {this.props.organisation.tier_trial_expiration_date
                  ? moment(this.props.organisation.tier_trial_expiration_date).format('LL')
                  : '-'}
              </Field>
            </div>
          ) : null}

          <div className={styles['row']}>
            <Field icon={<Icon type="user" gray withBackground backgroundSize={34} size={16} />}>
              <p className={styles['title']}>Billable users</p>
              {this.props.organisation.billable_user_count} user(s)
            </Field>
          </div>
        </div>
      </>
    );
  };
  render() {
    return (
      <>
        <CardGrid.Row>
          <WhiteCard noPadding>
            <div className={styles['meta']}>{this.renderContent()}</div>
          </WhiteCard>
        </CardGrid.Row>
        {this.renderDemoDateModal()}
        {this.renderTrialDateModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrganisationAsAdmin: SDKReduxOperations.updateOrganisationAsAdmin,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading)
    return {
      organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
    };
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
    activeDiscount: OrganisationSelectors.getActiveDiscount(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(OverviewCard)));
