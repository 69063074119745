import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeSparePartWithdrawal } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createSparePartWithdrawal(systemId, params) {
  return dispatch => {
    return API.createSparePartWithdrawal(systemId, params)
      .then(res => {
        const { entities } = normalizeSparePartWithdrawal(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_WITHDRAWAL_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createSparePartWithdrawalForWorkOrder(workOrderId, params) {
  return (dispatch, getState) => {
    return API.createSparePartWithdrawalForWorkOrder(workOrderId, params)
      .then(({ data: sparePartWithdrawal }) => {
        const { entities } = normalizeSparePartWithdrawal(sparePartWithdrawal);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderId, params, data: sparePartWithdrawal };
        dispatch({ type: types.SPARE_PART_WITHDRAWAL_CREATED_FOR_WORK_ORDER, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateSparePartWithdrawal(sparePartWithdrawalId, params) {
  return dispatch => {
    return API.updateSparePartWithdrawal(sparePartWithdrawalId, params)
      .then(res => {
        const { entities } = normalizeSparePartWithdrawal(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { sparePartWithdrawalId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_WITHDRAWAL_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteSparePartWithdrawal(sparePartWithdrawalId) {
  return (dispatch, getState) => {
    const sparePartWithdrawal = getState().entities.sparePartWithdrawalById[sparePartWithdrawalId];
    return API.deleteSparePartWithdrawal(sparePartWithdrawalId)
      .then(res => {
        const payload = { sparePartWithdrawalId, data: sparePartWithdrawal };
        dispatch({ type: types.SPARE_PART_WITHDRAWAL_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function batchDeleteSparePartWithdrawal(sparePartWithdrawalIds) {
  return (dispatch, getState) => {
    const workOrderId = getState().entities.sparePartWithdrawalById[sparePartWithdrawalIds[0]].work_order_id;
    let requestsToWaitFor = sparePartWithdrawalIds.map(sparePartWithdrawalId => {
      return API.deleteSparePartWithdrawal(sparePartWithdrawalId);
    });
    return Promise.all(requestsToWaitFor).then(data => {
      const payload = { sparePartWithdrawalIds, workOrderId };
      dispatch({ type: types.SPARE_PART_WITHDRAWAL_BATCH_DELETED, payload });

      return sparePartWithdrawalIds;
    });
  };
}
