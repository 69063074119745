import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';

class Text extends Component {
  renderSubtitle = () => {
    switch (this.props.comparator) {
      case HelperFunctions.FILTER_COMPARABLES.Exists: {
        if (this.props.hasValue) {
          return <FormattedMessage id="filters.types.text.exists.title" />;
        }
        return <FormattedMessage id="filters.types.text.not-exists.title" />;
      }

      case HelperFunctions.FILTER_COMPARABLES.Search: {
        return <FormattedMessage id="filters.types.text.search.title" />;
      }

      default:
        return null;
    }
  };

  render() {
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Container
        title={this.props.title}
        subtitle={this.renderSubtitle()}
        onClear={() => this.props.onClear()}
      >
        {this.props.children}
      </SideModal.Container.Filter.AppliedFilters.Filter.Container>
    );
  }
}

Text.propTypes = {
  required: PropTypes.bool,
  title: PropTypes.node,
  comparator: PropTypes.string,
  onClear: PropTypes.func,
};

Text.defaultProps = {
  title: null,
  comparator: HelperFunctions.FILTER_COMPARABLES.Search,
  required: false,
  onClear: () => {},
};

export default Text;
