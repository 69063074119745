import { Socket } from '../../';
import EntityOperations from 'sdk/State/entities/operations';
import * as types from './reduxTypes';
import {
  normalizeAttachmentFolder,
  normalizeSparePartUnit,
  normalizePurchaseOrderDeliveryMethod,
  normalizePurchaseOrderPaymentTerm,
  normalizeTemplateField,
  normalizeSystem,
  normalizeMeterUnit,
  normalizeTemplateFieldItem,
  normalizeCostOtherCategory,
  normalizeWorkOrderSpentTimeCategory,
  normalizeLaborTariff,
  normalizeOrganisation,
} from 'sdk/Schemas';

let channel = null;

async function join(systemId) {
  channel = Socket.socket.channel(`system:${systemId}`);
  return new Promise(function (resolve, reject) {
    channel.join().receive('ok', data => {
      resolve(data);
    });
  });
}

function subscribe(data) {
  return dispatch => {
    let totalEntities = {};
    let purchaseOrderDeliveryMethodIds = [];
    let purchaseOrderPaymentTermIds = [];
    let sparePartUnitIds = [];
    let meterUnitIds = [];
    let attachmentFolderIds = [];
    let templateFieldIds = [];
    let costOtherCategoryIds = [];
    let workOrderSpentTimeCategoryIds = [];
    let laborTariffIds = [];

    if (data.asset_template_fields && data.spare_part_template_fields) {
      const { entities, result } = normalizeTemplateField([
        ...data.asset_template_fields,
        ...data.spare_part_template_fields,
      ]);

      templateFieldIds = result;
      totalEntities = {
        ...totalEntities,
        ...entities,
      };
    }

    if (data.purchase_order_delivery_methods) {
      const { entities, result } = normalizePurchaseOrderDeliveryMethod(data.purchase_order_delivery_methods);

      purchaseOrderDeliveryMethodIds = result;
      totalEntities = {
        ...totalEntities,
        ...entities,
      };
    }

    if (data.purchase_order_payment_terms) {
      const { entities, result } = normalizePurchaseOrderPaymentTerm(data.purchase_order_payment_terms);

      purchaseOrderPaymentTermIds = result;
      totalEntities = {
        ...totalEntities,
        ...entities,
      };
    }

    if (data.spare_part_units) {
      const { entities, result } = normalizeSparePartUnit(data.spare_part_units);

      sparePartUnitIds = result;
      totalEntities = {
        ...totalEntities,
        ...entities,
      };
    }

    if (data.meter_units) {
      const { entities, result } = normalizeMeterUnit(data.meter_units);

      meterUnitIds = result;
      totalEntities = {
        ...totalEntities,
        ...entities,
      };
    }

    if (data.attachment_folders) {
      const { entities, result } = normalizeAttachmentFolder(data.attachment_folders);

      attachmentFolderIds = result;
      totalEntities = {
        ...totalEntities,
        ...entities,
      };
    }

    if (data.cost_other_categories) {
      const { entities, result } = normalizeCostOtherCategory(data.cost_other_categories);

      costOtherCategoryIds = result;
      totalEntities = {
        ...totalEntities,
        ...entities,
      };
    }

    if (data.work_order_spent_time_categories) {
      const { entities, result } = normalizeWorkOrderSpentTimeCategory(data.work_order_spent_time_categories);

      workOrderSpentTimeCategoryIds = result;
      totalEntities = {
        ...totalEntities,
        ...entities,
      };
    }

    if (data.labor_tariffs) {
      const { entities, result } = normalizeLaborTariff(data.labor_tariffs);

      laborTariffIds = result;
      totalEntities = {
        ...totalEntities,
        ...entities,
      };
    }

    dispatch(EntityOperations.updateEntities(totalEntities));

    const payload = {
      purchaseOrderDeliveryMethodIds,
      purchaseOrderPaymentTermIds,
      sparePartUnitIds,
      meterUnitIds,
      templateFieldIds,
      attachmentFolderIds,
      costOtherCategoryIds,
      workOrderSpentTimeCategoryIds,
      laborTariffIds,
    };

    dispatch({
      type: types.WS_SYSTEM_JOINED,
      payload,
    });

    // -------------------------------------------------------------------------

    channel.on('spare_part_unit_created', sparePartUnit => {
      const { entities } = normalizeSparePartUnit(sparePartUnit);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_SYSTEM_SPARE_PART_UNIT_CREATED, payload: sparePartUnit });
    });

    channel.on('spare_part_unit_updated', sparePartUnit => {
      const { entities } = normalizeSparePartUnit(sparePartUnit);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_SYSTEM_SPARE_PART_UNIT_UPDATED, payload: sparePartUnit });
    });

    channel.on('spare_part_unit_deleted', ({ id }) => {
      dispatch({ type: types.WS_SYSTEM_SPARE_PART_UNIT_DELETED, payload: id });
    });

    // -------------------------------------------------------------------------

    channel.on('meter_unit_created', meterUnit => {
      const { entities } = normalizeMeterUnit(meterUnit);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_SYSTEM_METER_UNIT_CREATED, payload: meterUnit });
    });

    channel.on('meter_unit_updated', meterUnit => {
      const { entities } = normalizeMeterUnit(meterUnit);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_SYSTEM_METER_UNIT_UPDATED, payload: meterUnit });
    });

    channel.on('meter_unit_deleted', ({ id }) => {
      dispatch({ type: types.WS_SYSTEM_METER_UNIT_DELETED, payload: id });
    });

    // -------------------------------------------------------------------------

    channel.on('purchase_order_delivery_method_created', purchaseOrderDeliveryMethod => {
      const { entities } = normalizePurchaseOrderDeliveryMethod(purchaseOrderDeliveryMethod);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({
        type: types.WS_SYSTEM_PURCHASE_ORDER_DELIVERY_METHOD_CREATED,
        payload: purchaseOrderDeliveryMethod,
      });
    });

    channel.on('purchase_order_delivery_method_updated', purchaseOrderDeliveryMethod => {
      const { entities } = normalizePurchaseOrderDeliveryMethod(purchaseOrderDeliveryMethod);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({
        type: types.WS_SYSTEM_PURCHASE_ORDER_DELIVERY_METHOD_UPDATED,
        payload: purchaseOrderDeliveryMethod,
      });
    });

    channel.on('purchase_order_delivery_method_deleted', ({ id }) => {
      dispatch({ type: types.WS_SYSTEM_PURCHASE_ORDER_DELIVERY_METHOD_DELETED, payload: id });
    });

    // -------------------------------------------------------------------------

    channel.on('purchase_order_payment_term_created', purchaseOrderPaymentTerm => {
      const { entities } = normalizePurchaseOrderPaymentTerm(purchaseOrderPaymentTerm);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({
        type: types.WS_SYSTEM_PURCHASE_ORDER_PAYMENT_TERM_CREATED,
        payload: purchaseOrderPaymentTerm,
      });
    });

    channel.on('purchase_order_payment_term_updated', purchaseOrderPaymentTerm => {
      const { entities } = normalizePurchaseOrderPaymentTerm(purchaseOrderPaymentTerm);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({
        type: types.WS_SYSTEM_PURCHASE_ORDER_PAYMENT_TERM_UPDATED,
        payload: purchaseOrderPaymentTerm,
      });
    });

    channel.on('purchase_order_payment_term_deleted', ({ id }) => {
      dispatch({ type: types.WS_SYSTEM_PURCHASE_ORDER_PAYMENT_TERM_DELETED, payload: id });
    });

    // -------------------------------------------------------------------------

    channel.on('cost_other_category_created', costOtherCategory => {
      const { entities } = normalizeCostOtherCategory(costOtherCategory);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_COST_OTHER_CATEGORY_CREATED, payload: costOtherCategory });
    });

    channel.on('cost_other_category_updated', costOtherCategory => {
      const { entities } = normalizeCostOtherCategory(costOtherCategory);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_COST_OTHER_CATEGORY_UPDATED, payload: costOtherCategory });
    });

    channel.on('cost_other_category_deleted', ({ id }) => {
      dispatch({ type: types.WS_COST_OTHER_CATEGORY_DELETED, payload: id });
    });

    // -------------------------------------------------------------------------

    channel.on('attachment_folder_created', attachmentFolder => {
      const { entities } = normalizeAttachmentFolder(attachmentFolder);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({
        type: types.WS_SYSTEM_ATTACHMENT_FOLDER_CREATED,
        payload: attachmentFolder,
      });
    });

    channel.on('attachment_folder_updated', attachmentFolder => {
      const { entities } = normalizeAttachmentFolder(attachmentFolder);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({
        type: types.WS_SYSTEM_ATTACHMENT_FOLDER_UPDATED,
        payload: attachmentFolder,
      });
    });

    channel.on('attachment_folder_deleted', ({ id }) => {
      dispatch({ type: types.WS_SYSTEM_ATTACHMENT_FOLDER_DELETED, payload: id });
    });

    // -------------------------------------------------------------------------

    channel.on('template_field_created', templateField => {
      const { entities } = normalizeTemplateField(templateField);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_SYSTEM_TEMPLATE_FIELD_CREATED, payload: templateField });
    });

    channel.on('template_field_updated', templateField => {
      const { entities } = normalizeTemplateField(templateField);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_SYSTEM_TEMPLATE_FIELD_UPDATED, payload: templateField });
    });

    channel.on('template_field_deleted', ({ id }) => {
      dispatch({ type: types.WS_SYSTEM_TEMPLATE_FIELD_DELETED, payload: id });
    });

    // -------------------------------------------------------------------------

    channel.on('template_field_item_created', templateFieldItem => {
      const { entities } = normalizeTemplateFieldItem(templateFieldItem);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_SYSTEM_TEMPLATE_FIELD_ITEM_CREATED, payload: templateFieldItem });
    });

    channel.on('template_field_item_updated', templateFieldItem => {
      const { entities } = normalizeTemplateFieldItem(templateFieldItem);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_SYSTEM_TEMPLATE_FIELD_ITEM_UPDATED, payload: templateFieldItem });
    });

    channel.on('template_field_item_deleted', ({ id }) => {
      dispatch({ type: types.WS_SYSTEM_TEMPLATE_FIELD_ITEM_DELETED, payload: id });
    });

    // -------------------------------------------------------------------------

    channel.on('work_order_spent_time_category_created', workOrderSpentTimeCategory => {
      const { entities } = normalizeWorkOrderSpentTimeCategory(workOrderSpentTimeCategory);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({
        type: types.WS_WORK_ORDER_SPENT_TIME_CATEGORY_CREATED,
        payload: workOrderSpentTimeCategory,
      });
    });

    channel.on('work_order_spent_time_category_updated', workOrderSpentTimeCategory => {
      const { entities } = normalizeWorkOrderSpentTimeCategory(workOrderSpentTimeCategory);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({
        type: types.WS_WORK_ORDER_SPENT_TIME_CATEGORY_UPDATED,
        payload: workOrderSpentTimeCategory,
      });
    });

    channel.on('work_order_spent_time_category_deleted', ({ id }) => {
      dispatch({ type: types.WS_WORK_ORDER_SPENT_TIME_CATEGORY_DELETED, payload: id });
    });

    // -------------------------------------------------------------------------

    channel.on('labor_tariff_created', laborTariff => {
      const { entities } = normalizeLaborTariff(laborTariff);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_LABOR_TARIFF_CREATED, payload: laborTariff });
    });

    channel.on('labor_tariff_updated', laborTariff => {
      const { entities } = normalizeLaborTariff(laborTariff);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_LABOR_TARIFF_UPDATED, payload: laborTariff });
    });

    channel.on('labor_tariff_deleted', ({ id }) => {
      dispatch({ type: types.WS_LABOR_TARIFF_DELETED, payload: id });
    });

    // -------------------------------------------------------------------------

    channel.on('system_updated', data => {
      const { entities } = normalizeSystem(data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_SYSTEM_SYSTEM_UPDATED, payload: data });
    });

    // -------------------------------------------------------------------------

    channel.on('organisation_updated', data => {
      const { entities } = normalizeOrganisation(data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_SYSTEM_ORGANISATION_UPDATED, payload: data });
    });
  };
}

export default {
  join,
  subscribe,
};
