import React, { Component } from 'react';
import Container from './Container';
import Menu from './Menu';
import styles from './style.module.scss';

class ColumnLayout extends Component {
  render() {
    let classNames = [styles['content'], 'modal-content'];

    return <div className={classNames.join(' ')}>{this.props.children}</div>;
  }
}

ColumnLayout.Container = Container;
ColumnLayout.Menu = Menu;

export default ColumnLayout;
