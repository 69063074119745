import { request, groupRequest } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const fetchUser = userId => request.get(`users/${userId}`);
export const listUsers = (systemId, params, config) =>
  request.get(`systems/${systemId}/users?${HelperFunctions.convertObjToQueryParameters(params)}`, config);
export const listUsersForOrganisation = (orgId, params, config) =>
  request.get(`organisations/${orgId}/users?${HelperFunctions.convertObjToQueryParameters(params)}`, config);
export const listUsersForGroup = (params, config) =>
  groupRequest.get(`users?${HelperFunctions.convertObjToQueryParameters(params)}`, config);
export const listUsersAsAdmin = (params, config) =>
  request.get(`admin/users?${HelperFunctions.convertObjToQueryParameters(params)}`, config);
export const createUser = (systemId, data) => request.post(`systems/${systemId}/users`, data);
export const createUserForOrganisation = (orgId, data) => request.post(`organisations/${orgId}/users`, data);
export const updateUser = (userId, data) => request.patch(`users/${userId}`, data);
export const deleteUser = userId => request.delete(`users/${userId}`);
export const resendInvite = (userId, email) => request.put(`users/${userId}/resend_invite`, { email });
export const requestResetPasswordForUser = (userId, email) =>
  request.put(`users/${userId}/request_reset_password`);

export const addUsersToGroup = (groupId, userIds) =>
  request.put(`groups/${groupId}/add_users`, { user_ids: userIds });
export const removeUserFromGroup = (groupId, userId) => request.delete(`groups/${groupId}/users/${userId}`);

export const updateMe = data => request.patch('me', data);
export const resetNotificationBadge = systemId => request.put(`systems/${systemId}/reset_notification_badge`);
export const updateProfilePicture = ({ image, mime_type }) =>
  request.post('profile_picture', { image, mime_type });
export const sendVerificationEmail = () => request.put('send_verify_email');
