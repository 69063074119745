import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.SPARE_PART_CREATED: {
      const { params, data: sparePart } = action.payload;
      if (state[params.purchase_order_row_id]) {
        return {
          ...state,
          [params.purchase_order_row_id]: {
            ...state[params.purchase_order_row_id],
            spare_part_id: sparePart.id,
          },
        };
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.purchaseOrderRowById) {
        return HelperFunctions.mergeEntities(state, action.payload.purchaseOrderRowById);
      }
      return state;
    default:
      return state;
  }
};
