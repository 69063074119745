import React, { Component } from 'react';

class Row extends Component {
  render() {
    return (
      <div className="layout-card-grid-row" style={{ height: this.props.fullHeight ? '100%' : null }}>
        {this.props.children}
      </div>
    );
  }
}

export default Row;
