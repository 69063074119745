import React from 'react';
import PropTypes from 'prop-types';
import Menu from './Menu';
import MainContent from './MainContent';
import styles from './style.module.scss';

const Content = props => {
  return <div className={styles['content']}>{props.children}</div>;
};

Content.Menu = Menu;
Content.MainContent = MainContent;
export default Content;

Content.propTypes = {
  children: PropTypes.node,
};

Content.defaultProps = {
  children: null,
};
