import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeLaborTariff } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createLaborTariff(systemId, params) {
  return dispatch => {
    return API.createLaborTariff(systemId, params)
      .then(res => {
        const { entities } = normalizeLaborTariff(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.LABOR_TARIFF_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateLaborTariff(contactPersonId, params) {
  return dispatch => {
    return API.updateLaborTariff(contactPersonId, params)
      .then(res => {
        const { entities } = normalizeLaborTariff(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { contactPersonId, params, data: res.data };
        dispatch({ type: types.LABOR_TARIFF_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteLaborTariff(laborTariffId) {
  return dispatch => {
    return API.deleteLaborTariff(laborTariffId)
      .then(res => {
        const payload = { laborTariffId };
        dispatch({ type: types.LABOR_TARIFF_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
