import React from 'react';
import styles from './style.module.scss';
import Column from './Column';
import Separator from './Separator';
import Row from './Row';
import { Container } from 'react-grid-system';

const Grid = props => {
  return (
    <div className={styles['grid']}>
      <Container fluid {...props}>
        <div className={styles['grid-content']}>{props.children}</div>
      </Container>
    </div>
  );
};

Grid.Column = Column;
Grid.Separator = Separator;
Grid.Row = Row;

export default Grid;

Grid.propTypes = {};
Grid.defaultProps = {};
