import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeAssetVendor } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createAssetVendor(systemId, params) {
  return dispatch => {
    return API.createAssetVendor(systemId, params)
      .then(res => {
        const { entities } = normalizeAssetVendor(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.ASSET_VENDOR_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateAssetVendor(assetVendorId, params) {
  return dispatch => {
    return API.updateAssetVendor(assetVendorId, params)
      .then(res => {
        const { entities } = normalizeAssetVendor(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { assetVendorId, params, data: res.data };
        dispatch({ type: types.ASSET_VENDOR_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteAssetVendor(assetVendorId) {
  return (dispatch, getState) => {
    const assetVendor = getState().entities.assetVendorById[assetVendorId];
    return API.deleteAssetVendor(assetVendorId)
      .then(res => {
        const payload = { assetVendorId, data: assetVendor };
        dispatch({ type: types.ASSET_VENDOR_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
