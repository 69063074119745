import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeAttachmentVersion } from 'sdk/Schemas';
import HelperFunctions from '../utilities/HelperFunctions';
import * as types from './reduxTypes';

export function updateAttachmentVersion(attachmentVersionId, params) {
  return dispatch => {
    return API.updateAttachmentVersion(attachmentVersionId, params)
      .then(res => {
        const { entities } = normalizeAttachmentVersion(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { attachmentVersionId, params, data: res.data };
        dispatch({ type: types.ATTACHMENT_VERSION_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function attachmentVersionUploaded(attachmentVersionId, meta = {}) {
  return dispatch => {
    return API.attachmentVersionUploaded(attachmentVersionId)
      .then(res => {
        const { entities } = normalizeAttachmentVersion(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { attachmentVersionId, data: res.data, meta };
        dispatch({ type: types.ATTACHMENT_VERSION_UPLOADED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteAttachmentVersion(attachmentVersionId) {
  return dispatch => {
    return API.deleteAttachmentVersion(attachmentVersionId)
      .then(res => {
        const payload = { attachmentVersionId };
        dispatch({ type: types.ATTACHMENT_VERSION_DELETED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createAttachmentVersionForAttachment(attachmentId, params) {
  const { description, title, file, type, mime_type, link_url, extension } = params;
  return dispatch => {
    if (type == 'file') {
      return API.createAttachmentVersionForAttachment(attachmentId, {
        description,
        title,
        mime_type,
        type,
        link_url,
        extension,
      })
        .then(res => {
          const { data: attachmentVersion } = res;

          return HelperFunctions.uploadFileToS3({
            url: attachmentVersion.upload_url,
            file,
            mime_type,
          }).then(() => {
            return API.attachmentVersionUploaded(attachmentVersion.id).then(res => {
              const { data: attachmentVersion } = res;
              const { entities } = normalizeAttachmentVersion(attachmentVersion);
              dispatch(EntityOperations.updateEntities(entities));

              const payload = {
                attachmentId,
                params,
                data: attachmentVersion,
              };
              dispatch({
                type: types.ATTACHMENT_VERSION_CREATED_FOR_ATTACHMENT,
                payload,
              });

              return payload;
            });
          });
        })
        .catch(e => {
          throw e;
        });
    } else {
      return API.createAttachmentVersionForAttachment(attachmentId, {
        description,
        title,
        mime_type,
        type,
        link_url,
      })
        .then(res => {
          const { entities } = normalizeAttachmentVersion(res.data);
          dispatch(EntityOperations.updateEntities(entities));

          const payload = { attachmentId, params, data: res.data };
          dispatch({
            type: types.ATTACHMENT_VERSION_CREATED_FOR_ATTACHMENT,
            payload,
          });

          return payload;
        })
        .catch(e => {
          throw e;
        });
    }
  };
}
