import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Icon } from 'views/components/Shared/General';
import Group from './Group';
import styles from './style.module.scss';

export default class Checkbox extends Component {
  renderQuestionTooltipContent = () => {
    if (this.props.questionTooltipContent == null) return null;

    return (
      <div className={styles['question-icon-container']}>
        <Tooltip
          trigger={<Icon type="question-circle" regular size={12} />}
          label={this.props.questionTooltipContent}
        />
      </div>
    );
  };

  renderLabel = () => {
    if (this.props.label == null) return null;

    return (
      <div className={styles['label']}>
        {this.props.label}
        {this.renderQuestionTooltipContent()}
      </div>
    );
  };

  render() {
    let classNames = [styles['container']];
    if (this.props.disabled) {
      classNames = [...classNames, styles['disabled']];
    }
    if (this.props.checked) {
      classNames = [...classNames, styles['checked']];
    }
    if (this.props.radio) {
      classNames = [...classNames, styles['radio']];
    }

    return (
      <div
        className={classNames.join(' ')}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          if (this.props.disabled) {
            return;
          }
          this.props.onChange(!this.props.checked);
        }}
      >
        <div className={styles['custom-input']}>
          <div className={styles['check']} />
        </div>
        <input
          type={this.props.radio ? 'radio' : 'checkbox'}
          disabled={this.props.disabled}
          checked={this.props.checked}
        />
        {this.renderLabel()}
      </div>
    );
  }
}

Checkbox.Group = Group;

Checkbox.propTypes = {
  label: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  radio: PropTypes.bool,
  questionTooltipContent: PropTypes.node,
};
Checkbox.defaultProps = {
  checked: false,
  radio: false,
  disabled: false,
  onChange: () => {},
};
