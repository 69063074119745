import { omit } from 'lodash-es';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.PURCHASE_ORDER_PAYMENT_TERM_UPDATED:
    case SDKReduxTypes.WS_SYSTEM_PURCHASE_ORDER_PAYMENT_TERM_UPDATED: {
      let data = {};
      if (action.type === SDKReduxTypes.PURCHASE_ORDER_PAYMENT_TERM_UPDATED) {
        data = action.payload.data;
      } else {
        data = action.payload;
      }
      if (data['default'] === true) {
        let newPurchaseOrderPaymentTermById = {};
        for (let paymentTermId in state) {
          newPurchaseOrderPaymentTermById[paymentTermId] = {
            ...state[paymentTermId],
            default: state[paymentTermId].id === data.id,
          };
        }
        return newPurchaseOrderPaymentTermById;
      }
      return state;
    }
    case SDKReduxTypes.PURCHASE_ORDER_PAYMENT_TERM_CREATED:
    case SDKReduxTypes.WS_SYSTEM_PURCHASE_ORDER_PAYMENT_TERM_CREATED: {
      let data = {};
      if (action.type === SDKReduxTypes.PURCHASE_ORDER_PAYMENT_TERM_CREATED) {
        data = action.payload.data;
      } else {
        data = action.payload;
      }
      if (data['default'] === true) {
        let newPurchaseOrderPaymentTermById = {};
        newPurchaseOrderPaymentTermById[data.id] = data;
        for (let paymentTermId in state) {
          newPurchaseOrderPaymentTermById[paymentTermId] = {
            ...state[paymentTermId],
            default: state[paymentTermId].id === data.id,
          };
        }
        return newPurchaseOrderPaymentTermById;
      }
      return state;
    }
    case SDKReduxTypes.PURCHASE_ORDER_PAYMENT_TERM_DELETED:
    case SDKReduxTypes.WS_SYSTEM_PURCHASE_ORDER_PAYMENT_TERM_DELETED: {
      let id = null;
      if (action.type === SDKReduxTypes.PURCHASE_ORDER_PAYMENT_TERM_DELETED) {
        id = action.payload.purchaseOrderPaymentTermId;
      } else {
        id = action.payload.id;
      }
      return omit(state, id);
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.purchaseOrderPaymentTermById) {
        return HelperFunctions.mergeEntities(state, action.payload.purchaseOrderPaymentTermById);
      }
      return state;
    default:
      return state;
  }
};
