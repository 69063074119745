import actions from "./actions";

const setOptions = actions.setOptions;
const selectOption = actions.selectOption;
const clearOptions = actions.clearOptions;

export default {
  setOptions,
  selectOption,
  clearOptions,
};
