import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeCosts } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createCostForWorkOrder(workOrderId, params) {
  return dispatch => {
    return API.createCostForWorkOrder(workOrderId, params)
      .then(res => {
        const { entities } = normalizeCosts(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderId, params, data: res.data };
        dispatch({ type: types.COST_CREATED_FOR_WORK_ORDER, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateCost(costId, params) {
  return dispatch => {
    return API.updateCost(costId, params)
      .then(res => {
        const { entities } = normalizeCosts(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { costId, params, data: res.data };
        dispatch({ type: types.COST_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteCost(costId) {
  return (dispatch, getState) => {
    const cost = getState().entities.costById[costId];
    return API.deleteCost(costId)
      .then(res => {
        const payload = { costId, data: cost };
        dispatch({ type: types.COST_DELETED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
