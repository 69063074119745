import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeRecurringMaintenance } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createRecurringMaintenance(systemId, params) {
  return dispatch => {
    return API.createRecurringMaintenance(systemId, params)
      .then(res => {
        const { entities } = normalizeRecurringMaintenance(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({
          type: types.RECURRING_MAINTENANCE_CREATED,
          payload,
          meta: { includeCurrentUser: true },
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateRecurringMaintenance(recurringMaintenanceId, params) {
  return dispatch => {
    return API.updateRecurringMaintenance(recurringMaintenanceId, params)
      .then(res => {
        const { entities } = normalizeRecurringMaintenance(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { recurringMaintenanceId, params, data: res.data };
        dispatch({ type: types.RECURRING_MAINTENANCE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteRecurringMaintenance(recurringMaintenanceId, params) {
  return dispatch => {
    return API.deleteRecurringMaintenance(recurringMaintenanceId, params)
      .then(res => {
        const payload = { recurringMaintenanceId, params };
        dispatch({ type: types.RECURRING_MAINTENANCE_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function archiveRecurringMaintenance(recurringMaintenanceId, params) {
  return dispatch => {
    return API.archiveRecurringMaintenance(recurringMaintenanceId, params)
      .then(res => {
        const { entities } = normalizeRecurringMaintenance(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { recurringMaintenanceId, params, data: res.data };
        dispatch({ type: types.RECURRING_MAINTENANCE_ARCHIVED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function activateRecurringMaintenance(recurringMaintenanceId) {
  return dispatch => {
    return API.activateRecurringMaintenance(recurringMaintenanceId)
      .then(res => {
        const { entities } = normalizeRecurringMaintenance(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { recurringMaintenanceId, data: res.data };
        dispatch({ type: types.RECURRING_MAINTENANCE_ACTIVATED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
