import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { ContentContainer, CardGrid } from 'views/components/Shared/Layout';
import OverviewCard from './OverviewCard';
import RevenueCard from './RevenueCard';
import DiscountCard from './DiscountCard';
import ActionButtons from './ActionButtons';

class Overview extends Component {
  render() {
    return (
      <ContentContainer>
        <CardGrid>
          <CardGrid.Column width={268}>
            <CardGrid.Row>
              <OverviewCard />
            </CardGrid.Row>
            <CardGrid.Row>{this.props.organisation.demo ? null : <RevenueCard />}</CardGrid.Row>
            {this.props.organisation.billing_discounts &&
            this.props.organisation.billing_discounts.length > 0 ? (
              <CardGrid.Row>
                <DiscountCard />
              </CardGrid.Row>
            ) : null}
          </CardGrid.Column>
          <CardGrid.Column>
            <CardGrid.Row>
              <ActionButtons />
            </CardGrid.Row>
          </CardGrid.Column>
        </CardGrid>
      </ContentContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
  };
}

export default connect(mapStateToProps)(Overview);
