import React from 'react';
import Header from './Header';
import Items from './Items';
import EmptyDataSet from './EmptyDataSet';

const Section = ({ children }) => <div>{children}</div>;

Section.Header = Header;
Section.Items = Items;
Section.EmptyDataSet = EmptyDataSet;
export default Section;
