import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listRecurringMaintenances = (systemId, params) =>
  request.get(
    `systems/${systemId}/recurring_maintenances?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
export const fetchRecurringMaintenance = recurringMaintenanceId =>
  request.get(`recurring_maintenances/${recurringMaintenanceId}`);
export const createRecurringMaintenance = (systemId, data) =>
  request.post(`systems/${systemId}/recurring_maintenances`, data);
export const updateRecurringMaintenance = (recurringMaintenanceId, data) =>
  request.patch(`recurring_maintenances/${recurringMaintenanceId}`, data);
export const deleteRecurringMaintenance = (recurringMaintenanceId, params) =>
  request.delete(
    `recurring_maintenances/${recurringMaintenanceId}?${HelperFunctions.convertObjToQueryParameters(params)}`
  );

export const archiveRecurringMaintenance = (recurringMaintenanceId, params) => {
  const queryParams = HelperFunctions.convertObjToQueryParameters(params);
  return request.put(`recurring_maintenances/${recurringMaintenanceId}/archive?${queryParams}`);
};

export const activateRecurringMaintenance = recurringMaintenanceId =>
  request.put(`recurring_maintenances/${recurringMaintenanceId}/activate`);
