import React from 'react';
import styles from './style.module.scss';

const Loader = props => {
  var classNames = [styles['loader']];
  if (props.light) {
    classNames = [...classNames, styles['light']];
  }
  if (props.tiny) {
    classNames = [...classNames, styles['tiny']];
  }
  if (props.small) {
    classNames = [...classNames, styles['small']];
  }
  if (props.centerInParent) {
    classNames = [...classNames, styles['center-in-parent']];
  }
  return <div className={classNames.join(' ')} />;
};

export default Loader;

Loader.defaultProps = {
  tiny: false,
  small: false,
  light: false,
  centerInParent: false,
};
