import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EmptyDataSet } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default ({ children }) => {
  return (
    <div className={styles['empty-data-set']}>
      <EmptyDataSet title={<FormattedMessage id="general.search-field-empty-data-set.title" />} modal />
    </div>
  );
};
