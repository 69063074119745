import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const Content = props => {
  return (
    <div className={styles['content']}>
      <PerfectScrollbar>
        <div className={styles['list']}>{props.children}</div>
      </PerfectScrollbar>
    </div>
  );
};

export default Content;

Content.propTypes = {
  children: PropTypes.node,
};

Content.defaultProps = {
  children: null,
};
