import React from 'react';
import PropTypes from 'prop-types';
import Group from './Group';
import { Icon, Button } from 'views/components/Shared/General';
import styles from './style.module.scss';

const FilterButton = props => {
  const getClassNames = () => {
    let classNames = [styles['filter-button']];
    if (props.filtered) {
      classNames = [...classNames, styles['has-filters']];
    }
    return classNames.join(' ');
  };

  const renderCaret = () => {
    if (props.caret) {
      return (
        <div className={styles['caret-container']}>
          <Icon type="caret-down" />
        </div>
      );
    }
    return null;
  };

  const renderCount = () => {
    if (props.count) {
      return <div className={styles['count']}>{props.count}</div>;
    }
    return null;
  };

  const renderClearButton = () => {
    if (props.clearable) {
      return (
        <div className={styles['clear-container']}>
          <Button
            type="icon"
            icon={<Icon regular size={12} type="times" />}
            iconButtonSize={18}
            small
            onClick={props.onClear}
          />
        </div>
      );
    }
    return null;
  };

  if (props.filtered) {
    return (
      <div className={getClassNames()} onClick={props.onClick}>
        <div className={styles['button-content']}>
          <div>{props.label}</div>
          {renderClearButton()}
        </div>
        {renderCount()}
      </div>
    );
  }

  return (
    <div className={getClassNames()} onClick={props.onClick}>
      <div className={styles['button-content']}>
        <div>{props.label}</div>
        {renderCaret()}
      </div>
    </div>
  );
};

FilterButton.Group = Group;
export default FilterButton;

FilterButton.propTypes = {
  count: PropTypes.number,
  filtered: PropTypes.bool,
  onClick: PropTypes.func,
  onClear: PropTypes.func,
  label: PropTypes.node,
  caret: PropTypes.bool,
  clearable: PropTypes.bool,
};

FilterButton.defaultProps = {
  count: 0,
  filtered: false,
  label: null,
  caret: true,
  clearable: true,
  onClick: () => {},
  onClear: () => {},
};
