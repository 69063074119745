const DOMAIN_PREFIX = 'header/';

const SET_OPTIONS = `${DOMAIN_PREFIX}set_options`;
const SELECT_OPTION = `${DOMAIN_PREFIX}select_option`;
const CLEAR_OPTIONS = `${DOMAIN_PREFIX}clear_options`;

export default {
  SET_OPTIONS,
  SELECT_OPTION,
  CLEAR_OPTIONS,
}