import React from 'react';
import PropTypes from 'prop-types';
import { SideModal } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

const Values = props => {
  return (
    <div className={styles['items']}>
      {props.data.reduce(
        (accu, data) =>
          accu === null
            ? [
                <SideModal.Container.Filter.AppliedFilters.Value skipBackground={props.skipBackground}>
                  {props.renderItem(data)}
                </SideModal.Container.Filter.AppliedFilters.Value>,
              ]
            : [
                ...accu,
                <div>,&nbsp;</div>,
                <SideModal.Container.Filter.AppliedFilters.Value skipBackground={props.skipBackground}>
                  {props.renderItem(data)}
                </SideModal.Container.Filter.AppliedFilters.Value>,
              ],
        null
      )}
    </div>
  );
};

export default Values;

Values.propTypes = {
  skipBackground: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.any),
  renderItem: PropTypes.func,
};

Values.defaultProps = {
  skipBackground: false,
  data: [],
  renderItem: () => {},
};
