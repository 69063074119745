import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listAttachments = (systemId, params) =>
  request.get(`systems/${systemId}/attachments?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const listAttachmentsForVendor = (vendorId, params) =>
  request.get(`vendors/${vendorId}/attachments?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const listAttachmentsForSparePart = (sparePartId, params) =>
  request.get(
    `spare_parts/${sparePartId}/attachments?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
export const listAttachmentsForPurchaseOrder = (purchaseOrderId, params) =>
  request.get(
    `purchase_orders/${purchaseOrderId}/attachments?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
export const listAttachmentsForAsset = (assetId, params) =>
  request.get(`assets/${assetId}/attachments?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const createAttachmentForVendor = (vendorId, data) =>
  request.post(`vendors/${vendorId}/attachments`, data);
export const createAttachmentForSparePart = (sparePartId, data) =>
  request.post(`spare_parts/${sparePartId}/attachments`, data);
export const createAttachmentForPurchaseOrder = (purchaseOrderId, data) =>
  request.post(`purchase_orders/${purchaseOrderId}/attachments`, data);
export const createAttachmentForAsset = (assetId, data) =>
  request.post(`assets/${assetId}/attachments`, data);
export const createRegistrationAttachmentForWorkOrder = (workOrderId, data) =>
  request.post(`work_orders/${workOrderId}/registration_attachments`, data);
export const createCompletionAttachmentForWorkOrder = (workOrderId, data) =>
  request.post(`work_orders/${workOrderId}/completion_attachments`, data);
export const addRegistrationAttachmentToWorkOrder = (workOrderId, attachmentId) =>
  request.put(`work_orders/${workOrderId}/registration_attachments/${attachmentId}`);
export const removeRegistrationAttachmentFromWorkOrder = (workOrderId, attachmentId) =>
  request.delete(`work_orders/${workOrderId}/registration_attachments/${attachmentId}`);
export const createRegistrationAttachmentForRecurringMaintenance = (recurringMaintenanceId, data) =>
  request.post(`recurring_maintenances/${recurringMaintenanceId}/registration_attachments`, data);
export const createAttachmentForChecklistTemplateRow = (checklistTemplateRowId, data) =>
  request.post(`checklist_template_rows/${checklistTemplateRowId}/attachments`, data);
export const removeAttachmentFromChecklistTemplaterow = (checklistTemplateRowId, attachmentId) =>
  request.delete(`checklist_template_rows/${checklistTemplateRowId}/attachments/${attachmentId}`);
export const addRegistrationAttachmentToRecurringMaintenance = (recurringMaintenanceId, attachmentId) =>
  request.put(`recurring_maintenances/${recurringMaintenanceId}/registration_attachments/${attachmentId}`);
export const removeRegistrationAttachmentFromRecurringMaintenance = (recurringMaintenanceId, attachmentId) =>
  request.delete(`recurring_maintenances/${recurringMaintenanceId}/registration_attachments/${attachmentId}`);
export const addAttachmentsToVendor = (vendorId, data) =>
  request.put(`vendors/${vendorId}/add_attachments`, data);
export const removeAttachmentFromVendor = (attachmentId, vendorId) =>
  request.delete(`vendors/${vendorId}/attachments/${attachmentId}`);
export const deleteAttachment = attachmentId => request.delete(`attachments/${attachmentId}`);
export const updateAttachment = (attachmentId, data) => request.patch(`attachments/${attachmentId}`, data);
export const removeAttachmentFromAsset = (assetId, attachmentId) =>
  request.delete(`assets/${assetId}/attachments/${attachmentId}`);
export const removeAttachmentFromSparePart = (sparePartId, attachmentId) =>
  request.delete(`spare_parts/${sparePartId}/attachments/${attachmentId}`);
export const createAttachmentForMultipleAssets = (systemId, data) =>
  request.post(`systems/${systemId}/multiple_asset_attachments`, data);
export const createAttachmentForMultipleSpareParts = (systemId, data) =>
  request.post(`systems/${systemId}/multiple_spare_part_attachments`, data);
