import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.DOWNTIME_DELETED: {
      const { downtimeId } = action.payload;
      return Object.entries(state).reduce((acc, [id, cost]) => {
        if (cost.downtime_id === downtimeId) {
          return {
            ...acc,
            [id]: {
              ...cost,
              _deleted: true,
            },
          };
        }
        return {
          ...acc,
          [id]: {
            ...cost,
          },
        };
      }, {});
    }
    case SDKReduxTypes.WORK_ORDER_SPENT_TIME_DELETED: {
      const { workOrderSpentTimeId } = action.payload;
      return Object.entries(state).reduce((acc, [id, cost]) => {
        return {
          ...acc,
          [id]: {
            ...cost,
            _deleted: cost._deleted || workOrderSpentTimeId === cost.work_order_spent_time_id,
          },
        };
      }, {});
    }
    case SDKReduxTypes.SPARE_PART_WITHDRAWAL_DELETED: {
      const { sparePartWithdrawalId } = action.payload;
      return Object.entries(state).reduce((acc, [id, cost]) => {
        return {
          ...acc,
          [id]: {
            ...cost,
            _deleted: cost._deleted || sparePartWithdrawalId === cost.spare_part_withdrawal_id,
          },
        };
      }, {});
    }
    case SDKReduxTypes.SPARE_PART_WITHDRAWAL_BATCH_DELETED: {
      const { sparePartWithdrawalIds } = action.payload;
      return Object.entries(state).reduce((acc, [id, cost]) => {
        return {
          ...acc,
          [id]: {
            ...cost,
            _deleted: cost._deleted || sparePartWithdrawalIds.includes(cost.spare_part_withdrawal_id),
          },
        };
      }, {});
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.costById) {
        return HelperFunctions.mergeEntities(state, action.payload.costById);
      }
      return state;

    default:
      return state;
  }
};
