import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizePurchaseOrder } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createPurchaseOrder(systemId, params) {
  return dispatch => {
    return API.createPurchaseOrder(systemId, params)
      .then(res => {
        const { entities } = normalizePurchaseOrder(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.PURCHASE_ORDER_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updatePurchaseOrder(purchaseOrderId, params) {
  return dispatch => {
    return API.updatePurchaseOrder(purchaseOrderId, params)
      .then(res => {
        const { entities } = normalizePurchaseOrder(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { purchaseOrderId, params, data: res.data };
        dispatch({ type: types.PURCHASE_ORDER_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function sendPurchaseOrder(purchaseOrderId, params) {
  return dispatch => {
    return API.sendPurchaseOrder(purchaseOrderId, params)
      .then(res => {
        const payload = { purchaseOrderId, params, data: res.data };
        dispatch({ type: types.PURCHASE_ORDER_EMAIL_SENT, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deletePurchaseOrder(purchaseOrderId) {
  return dispatch => {
    return API.deletePurchaseOrder(purchaseOrderId)
      .then(res => {
        const payload = { purchaseOrderId };
        dispatch({ type: types.PURCHASE_ORDER_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
