import React, { Component } from 'react';
import uuid from 'uuid';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import ReactTooltip from 'react-tooltip';

export default class FieldErrorWrapper extends Component {
  state = {
    id: uuid.v4(),
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      ReactTooltip.show(this.errorRef);
    } else if (prevProps.show && !this.props.show) {
      ReactTooltip.hide(this.errorRef);
    }
  }

  getPositioningClass = () => {
    let classNames = [styles['position-container']];
    if (this.props.position === 'left') {
      classNames = [...classNames, styles['left']];
    }
    if (this.props.position === 'top') {
      classNames = [...classNames, styles['top']];
    }
    if (this.props.position === 'right') {
      classNames = [...classNames, styles['right']];
    }
    return classNames;
  };

  getPointingValueFromPosition = () => {
    switch (this.props.position) {
      case 'left':
        return 'left';
      case 'right':
        return 'right';
      case 'top':
        return 'top';
      default:
        return null;
    }
  };

  render() {
    return (
      <div
        className={styles['error-wrapper']}
        ref={ref => (this.errorRef = ref)}
        data-tip="tooltip"
        data-for={this.state.id}
      >
        {this.props.show ? (
          <div className={this.getPositioningClass().join(' ')}>
            <ReactTooltip
              id={this.state.id}
              place={this.getPointingValueFromPosition()}
              backgroundColor="#c55050"
              textColor="#fff"
              event="nothing"
              resizeHide={false}
            >
              {this.props.errorElement}
            </ReactTooltip>
          </div>
        ) : null}
        {this.props.children}
      </div>
    );
  }
}

FieldErrorWrapper.propTypes = {
  position: PropTypes.oneOf(['top', 'left', 'right']),
  children: PropTypes.element,
  show: PropTypes.bool,
  errorElement: PropTypes.node,
};

FieldErrorWrapper.defaultProps = {
  position: 'left',
  show: false,
};
