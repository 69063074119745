import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeRequestType } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createRequestType(systemId, params) {
  return dispatch => {
    return API.createRequestType(systemId, params)
      .then(res => {
        const { entities } = normalizeRequestType(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.REQUEST_TYPE_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateRequestType(requestTypeId, params) {
  return dispatch => {
    return API.updateRequestType(requestTypeId, params)
      .then(res => {
        const { entities } = normalizeRequestType(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { requestTypeId, params, data: res.data };
        dispatch({ type: types.REQUEST_TYPE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteRequestType(requestTypeId) {
  return dispatch => {
    return API.deleteRequestType(requestTypeId)
      .then(res => {
        const payload = { requestTypeId };
        dispatch({ type: types.REQUEST_TYPE_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
