import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { OrganisationOperations } from 'state/ducks/organisation';
import AdditionalCostsList from './AdditionalCostsList';
import AdditionalCostDetail from './AdditionalCostDetail';

class AdditionalCostsModal extends Component {
  state = {
    modalType: '',
    id: null,
    title: null,
    detailAdditionalCostId: null,
    showListModal: false,
    showDetailModal: false,
  };

  componentDidMount() {
    this.setState({ showListModal: true });
  }

  renderAdditionalCostsList = () => {
    return (
      <AdditionalCostsList
        open={this.state.showListModal && this.props.open}
        onDetailSelected={additionalCostId => {
          this.setState({
            showListModal: false,
            detailAdditionalCostId: additionalCostId,
          });
          setTimeout(() => {
            this.setState({ showDetailModal: true });
          }, 100);
        }}
        onClose={() => {
          this.props.onClose();
        }}
      />
    );
  };

  renderAdditionalCostDetail = () => {
    return (
      <AdditionalCostDetail
        open={this.state.showDetailModal && this.props.open}
        billingAdditionalCostId={this.state.detailAdditionalCostId}
        onClose={() => {
          this.setState({
            showDetailModal: false,
            detailAdditionalCostId: null,
          });
          setTimeout(() => {
            this.setState({ showListModal: true });
          }, 100);
        }}
      />
    );
  };
  render() {
    return (
      <>
        {this.renderAdditionalCostsList()}
        {this.renderAdditionalCostDetail()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBillingAdditionalCosts: OrganisationOperations.fetchBillingAdditionalCosts,
    },
    dispatch
  );
}

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(AdditionalCostsModal)));
