import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeSparePartUnit } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createSparePartUnit(systemId, params) {
  return dispatch => {
    return API.createSparePartUnit(systemId, params)
      .then(res => {
        const { entities } = normalizeSparePartUnit(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_UNIT_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateSparePartUnit(sparePartUnitId, params) {
  return dispatch => {
    return API.updateSparePartUnit(sparePartUnitId, params)
      .then(res => {
        const { entities } = normalizeSparePartUnit(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { sparePartUnitId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_UNIT_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteSparePartUnit(sparePartUnitId) {
  return dispatch => {
    return API.deleteSparePartUnit(sparePartUnitId)
      .then(res => {
        const payload = { sparePartUnitId };
        dispatch({ type: types.SPARE_PART_UNIT_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
