import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styles from './style.module.scss';
import { Button, Icon, NewSearchField } from 'views/components/Shared/General';

class Header extends Component {
  renderSearchField = () => {
    if (this.props.searchable) {
      return (
        <div className={styles['search-field']}>
          <NewSearchField
            value={this.props.searchValue}
            placeholder={this.props.intl.formatMessage({ id: 'general.search-field-placeholder' })}
            onSearch={value => {
              this.props.onSearch(value);
            }}
            onClear={() => {
              this.props.onClearSearch();
            }}
          />
        </div>
      );
    }
    return null;
  };

  render() {
    let classNames = [styles['header-container']];
    if (this.props.noPaddingBottom) {
      classNames = [...classNames, styles['no-padding-bottom']];
    }
    return (
      <div className={classNames.join(' ')}>
        <div className={styles['header']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>{this.props.title}</div>
          </div>
          <div>
            <Button type="icon" icon={<Icon light size={20} type="times" />} onClick={this.props.onClose} />
          </div>
        </div>
        {this.renderSearchField()}
      </div>
    );
  }
}

export default injectIntl(Header);

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.element,
  searchable: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  searchValue: PropTypes.string,
  onSearch: PropTypes.func,
  onClearSearch: PropTypes.func,
};

Header.defaultProps = {
  title: null,
  searchValue: '',
  searchable: false,
  noPaddingBottom: false,
  onClose: () => {},
  onSearch: () => {},
  onClearSearch: () => {},
};
