import EntityTypes from '../types';
import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';

export default (state = {}, action) => {
  switch (action.type) {
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.attachmentById) {
        return HelperFunctions.mergeEntities(state, action.payload.attachmentById);
      }
      return state;
    case SDKReduxTypes.ATTACHMENT_VERSION_CREATED_FOR_ATTACHMENT: {
      const { attachmentId, data } = action.payload;
      if (state[attachmentId]) {
        return {
          ...state,
          [attachmentId]: {
            ...state[attachmentId],
            attachment_version: data.id,
            attachment_version_id: data.id,
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_REMOVED_FROM_ASSET: {
      const { attachmentId } = action.payload;
      if (state[attachmentId]) {
        return {
          ...state,
          [attachmentId]: {
            ...state[attachmentId],
            asset_count: state[attachmentId].asset_count - 1,
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_REMOVED_FROM_SPARE_PART: {
      const { attachmentId } = action.payload;
      if (state[attachmentId]) {
        return {
          ...state,
          [attachmentId]: {
            ...state[attachmentId],
            spare_part_count: state[attachmentId].spare_part_count - 1,
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ASSET_MULTIPLE_ADDED_TO_ATTACHMENT: {
      const { attachmentId, data: addedAssets } = action.payload;
      if (state[attachmentId]) {
        return {
          ...state,
          [attachmentId]: {
            ...state[attachmentId],
            asset_count: state[attachmentId].asset_count + addedAssets.length,
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PARTS_MULTIPLE_ADDED_TO_ATTACHMENT: {
      const { attachmentId, data: addedSpareParts } = action.payload;
      if (state[attachmentId]) {
        return {
          ...state,
          [attachmentId]: {
            ...state[attachmentId],
            spare_part_count: state[attachmentId].spare_part_count + addedSpareParts.length,
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_DELETED: {
      const { attachmentId } = action.payload;
      return update(state, {
        $unset: [attachmentId],
      });
    }
    default:
      return state;
  }
};
