import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HelperFunctions, SDKReduxOperations } from 'sdk';
import { ErrorOperations, ErrorSelectors } from 'state/ducks/error';
import { injectIntl } from 'react-intl';
import { EntityOperations } from 'sdk/State/entities';
import toast from 'react-hot-toast';
import { ToastMessage, SideModal, Grid } from 'views/components/Shared/Layout';
import { Button, Field, FieldErrorWrapper, Tooltip, Icon } from 'views/components/Shared/General';
import CardOption from './CardOption';
import { API } from 'sdk';
import styles from './style.module.scss';
import CurrencyDropdown from './CurrencyDropdown';
import ImportTypeDropdown from './ImportTypeDropdown';
import TierDropdown from './TierDropdown';
import LanguageDropdown from './LanguageDropdown';

class CreateSystemModal extends Component {
  constructor(props) {
    super(props);
    var thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
    this.state = {
      showErrorForName: false,
      showErrorForInternalName: false,
      showErrorForUserName: false,
      showErrorForEmail: false,
      showErrorForPassword: false,
      showErrorForImportPath: false,
      showErrorForInvalidTimezone: false,
      emailError: '',
      systemParams: {
        name: '',
        internal_name: '',
        timezone: 'Europe/Stockholm',
        tier: 'pro',
        currency: 'sek',
        user_name: '',
        import_path: process.env.REACT_APP_LOCAL_IMPORT_PATH || '',
        language: 'sv',
        import: 'amu',
        demo_expiration_date: thirtyDaysFromNow,
        email: '',
        password: '',
        seed_demo: false
      },

      createSystemWithData: false,
      showDatePicker: false,
      isCreatingSystem: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  buildParamsForCreateOrganisation = () => {
    if (this.state.createSystemWithData) {
      return {
        name: this.state.systemParams.name,
        internal_name: this.state.systemParams.internal_name,
        demo: true,
        demo_expiration_date: this.state.systemParams.demo_expiration_date,
        tier: this.state.systemParams.tier,
        system: {
          name: this.state.systemParams.name,
          company_name: this.state.systemParams.name,
          timezone: this.state.systemParams.timezone,
          currency: this.state.systemParams.currency,
          import: this.state.systemParams.import,
          import_path: this.state.systemParams.import_path,
        },
        user: {
          name: this.state.systemParams.user_name,
          language: this.state.systemParams.language,
          login_credential: {
            username: this.state.systemParams.email,
            password: this.state.systemParams.password,
          }
        },
      };
    } else {
      return {
        name: this.state.systemParams.name,
        internal_name: this.state.systemParams.internal_name,
        demo: true,
        demo_expiration_date: this.state.systemParams.demo_expiration_date,
        tier: this.state.systemParams.tier,
        system: {
          name: this.state.systemParams.name,
          company_name: this.state.systemParams.name,
          timezone: this.state.systemParams.timezone,
          currency: this.state.systemParams.currency,
          seed_demo: this.state.systemParams.seed_demo,
        },
        user: {
          name: this.state.systemParams.user_name,
          language: this.state.systemParams.language,
          login_credential: {
            username: this.state.systemParams.email,
            password: this.state.systemParams.password,
          }
        },
      };
    }
  };

  buildParamsForCreateSystem = () => {
    if (this.state.createSystemWithData) {
      return {
        name: this.state.systemParams.name,
        company_name: this.state.systemParams.internal_name,
        timezone: this.state.systemParams.timezone,
        currency: this.state.systemParams.currency,
        import: this.state.systemParams.import,
        import_path: this.state.systemParams.import_path,
      };
    } else {
      return {
        name: this.state.systemParams.name,
        company_name: this.state.systemParams.internal_name,
        timezone: this.state.systemParams.timezone,
        currency: this.state.systemParams.currency,
      };
    }
  };

  changeInternalName = value => {
    this.setState({
      showErrorForInternalName: false,
      systemParams: { ...this.state.systemParams, internal_name: value },
    });
  };

  changeName = value => {
    this.setState({
      showErrorForName: false,
      systemParams: { ...this.state.systemParams, name: value },
    });
  };
  changeUserName = value => {
    this.setState({
      showErrorForUserName: false,
      systemParams: { ...this.state.systemParams, user_name: value },
    });
  };

  create = () => {
    if (this.props.createForOrgId) {
      this.createSystem();
    } else {
      this.createOrganisation();
    }
  };

  createOrganisation = () => {
    if (this.state.isCreatingSystem) return;
    if (this.createOrganisaionHasError()) return;

    this.setState({
      isCreatingSystem: true,
    });
    const params = this.buildParamsForCreateOrganisation();
    API.createOrganisationAsAdmin(params)
      .then(({ data: system }) => {
        toast(() => <ToastMessage success text="System was created" />);
        this.setState({ isCreatingSystem: false });
        this.props.onSystemCreated(system);
      })
      .catch(error => {
        if (HelperFunctions.hasError(error, '10002', 'email')) {
          this.setState({ emailError: 'in-use' });
        }
        if (HelperFunctions.hasError(error, '10003', 'timezone')) {
          this.setState({ showErrorForInvalidTimezone: true });
        }
        if (HelperFunctions.hasError(error, '10018', 'email')) {
          this.setState({ emailError: 'invalid' });
        }
        this.setState({ isCreatingSystem: false });
      });
  };

  createSystem = () => {
    if (this.state.isCreatingSystem) return;
    if (this.createSystemHasError()) return;

    this.setState({
      isCreatingSystem: true,
    });
    const params = this.buildParamsForCreateSystem();
    this.props
      .createSystemAsAdmin(this.props.createForOrgId, params)
      .then(({ data: system }) => {
        toast(() => <ToastMessage success text="System was created" />);
        this.setState({ isCreatingSystem: false });
        this.props.onSystemCreated(system);
      })
      .catch(error => {
        if (HelperFunctions.hasError(error, '10002', 'email')) {
          this.setState({ emailError: 'in-use' });
        }
        if (HelperFunctions.hasError(error, '10003', 'timezone')) {
          this.setState({ showErrorForInvalidTimezone: true });
        }
        if (HelperFunctions.hasError(error, '10018', 'email')) {
          this.setState({ emailError: 'invalid' });
        }
        this.setState({ isCreatingSystem: false });
      });
  };

  createSystemHasError = () => {
    let error = false;
    if (this.state.systemParams.name === null || this.state.systemParams.name.length === 0) {
      this.setState({ showErrorForName: true });
      error = true;
    }
    if (
      this.state.systemParams.internal_name === null ||
      this.state.systemParams.internal_name.length === 0
    ) {
      this.setState({ showErrorForInternalName: true });
      error = true;
    }

    return error;
  };

  createOrganisaionHasError = () => {
    let error = false;
    if (this.state.createSystemWithData) {
      if (this.state.systemParams.name === null || this.state.systemParams.name.length === 0) {
        this.setState({ showErrorForName: true });
        error = true;
      }
      if (
        this.state.systemParams.internal_name === null ||
        this.state.systemParams.internal_name.length === 0
      ) {
        this.setState({ showErrorForInternalName: true });
        error = true;
      }
      if (this.state.systemParams.import_path === null || this.state.systemParams.import_path.length === 0) {
        this.setState({ showErrorForImportPath: true });
        error = true;
      }
    } else {
      if (this.state.systemParams.name === null || this.state.systemParams.name.length === 0) {
        this.setState({ showErrorForName: true });
        error = true;
      }
      if (
        this.state.systemParams.internal_name === null ||
        this.state.systemParams.internal_name.length === 0
      ) {
        this.setState({ showErrorForInternalName: true });
        error = true;
      }
      if (this.state.systemParams.user_name === null || this.state.systemParams.user_name.length === 0) {
        this.setState({ showErrorForUserName: true });
        error = true;
      }
      if (this.state.systemParams.email === null || this.state.systemParams.email.length === 0) {
        this.setState({ showErrorForEmail: true });
        error = true;
      }
      if (this.state.systemParams.password === null || this.state.systemParams.password.length === 0) {
        this.setState({ showErrorForPassword: true });
        error = true;
      }
    }
    return error;
  };

  generatePassword = () => {
    const randomPassword = Math.random().toString(36).substr(2, 10);
    this.setState({
      showErrorForPassword: false,
      systemParams: { ...this.state.systemParams, password: randomPassword },
    });
  };

  setImportPath = value => {
    this.setState({
      showErrorForImportPath: false,
      systemParams: { ...this.state.systemParams, import_path: value },
    });
  };

  resetData = () => {
    var thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
    this.setState({
      systemParams: {
        name: '',
        internal_name: '',
        timezone: 'Europe/Stockholm',
        tier: 'pro',
        currency: 'sek',
        user_name: '',
        import_path: process.env.REACT_APP_LOCAL_IMPORT_PATH || '',
        language: 'sv',
        demo_expiration_date: thirtyDaysFromNow,
        email: '',
        password: '',
      },
    });
  };

  renderContent = () => {
    return (
      <>
        {this.renderOptionButtons()}
        {this.state.createSystemWithData ? this.renderSystemWithData() : this.renderSystemWithoutData()}
      </>
    );
  };

  renderOptionButtons = () => (
    <div className={styles['options']}>
      <CardOption.Container>
        <CardOption
          fullWidth
          icon="upload"
          selected={!this.state.createSystemWithData}
          title="Empty system"
          subtitle="Create an empty system without importing data"
          onClick={() => {
            this.setState({
              createSystemWithData: false,
            });
          }}
        />
        <CardOption
          fullWidth
          icon="file-import"
          selected={this.state.createSystemWithData}
          title="With data"
          subtitle="Import data from AM System"
          onClick={() =>
            this.setState({
              createSystemWithData: true,
            })
          }
        />
      </CardOption.Container>
    </div>
  );

  renderSystemWithData = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label="Name">
              <Field.Text
                autoFocus
                error={this.state.showErrorForName}
                value={this.state.systemParams.name}
                onChange={value => this.changeName(value)}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label="System name (For internal use)">
              <Field.Text
                error={this.state.showErrorForInternalName}
                value={this.state.systemParams.internal_name}
                onChange={value => this.changeInternalName(value)}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={this.props.createForOrgId ? null : 400}>
            <CurrencyDropdown
              onSelectedValue={currency =>
                this.setState({
                  systemParams: {
                    ...this.state.systemParams,
                    currency: currency,
                  },
                })
              }
            />
          </Grid.Column>
          {this.renderTierDropdown()}
        </Grid.Row>
        <Grid.Row>
          {this.renderExpirationDate()}
          <Grid.Column>
            <Field label="Timezone">
              <FieldErrorWrapper
                local
                position="top"
                show={this.state.showErrorForInvalidTimezone}
                errorElement="Invalid timezone"
              >
                <Field.Text
                  value={this.state.systemParams.timezone}
                  onChange={value =>
                    this.setState({
                      systemParams: {
                        ...this.state.systemParams,
                        timezone: value,
                      },
                    })
                  }
                />
              </FieldErrorWrapper>
              <p className={styles['link']}>
                Valid timezones can be found{' '}
                <a
                  href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>here</u>
                </a>
              </p>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>
            <ImportTypeDropdown
              value={this.state.systemParams.import}
              onSelectedValue={import_type => {
                this.setState({
                  systemParams: {
                    ...this.state.systemParams,
                    import: import_type,
                  },
                })
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label="Path to import">
              <Field.Text
                error={this.state.showErrorForImportPath}
                placeholder="System_20200703135338"
                onChange={value => this.setImportPath(value)}
                value={this.state.systemParams.import_path}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderUserFields = () => {
    if (this.props.createForOrgId) {
      return null;
    }
    return (
      <>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column width={400}>
            <Field label="Name of user">
              <Field.Text
                placeholder="John Doe"
                error={this.state.showErrorForUserName}
                value={this.state.systemParams.user_name}
                onChange={value => this.changeUserName(value)}
              />
            </Field>
          </Grid.Column>
          <Grid.Column>
            <LanguageDropdown
              onSelectedLanguage={language =>
                this.setState({
                  systemParams: {
                    ...this.state.systemParams,
                    language: language,
                  },
                })
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label="User email">
              <FieldErrorWrapper
                local
                position="top"
                show={this.state.emailError !== ''}
                errorElement={
                  this.state.emailError === 'in-use' ? 'Email already in use' : 'Invalid email format'
                }
              >
                <Field.Text
                  error={this.state.showErrorForEmail}
                  value={this.state.systemParams.email}
                  onChange={value =>
                    this.setState({
                      emailError: '',
                      systemParams: {
                        ...this.state.systemParams,
                        email: value,
                      },
                    })
                  }
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label="Password">
              <Field.Text
                error={this.state.showErrorForPassword}
                value={this.state.systemParams.password}
                onChange={value =>
                  this.setState({
                    showErrorForPassword: false,
                    systemParams: {
                      ...this.state.systemParams,
                      password: value,
                    },
                  })
                }
                rightLabel={
                  <Tooltip
                    trigger={
                      <Button
                        type="icon"
                        icon={<Icon type="shield-alt" />}
                        onClick={() => this.generatePassword()}
                      />
                    }
                    label="Generate password"
                  />
                }
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              checked={this.state.systemParams.seed_demo}
              onChange={value =>
                this.setState({
                  systemParams: {
                    ...this.state.systemParams,
                    seed_demo: value,
                  },
                })
              }
              label="Generera demo-data"
            />
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  renderExpirationDate = () => {
    if (this.props.createForOrgId) {
      return null;
    }
    return (
      <Grid.Column>
        <Field label="Demo expiration date">
          <Field.Date
            open={this.state.showDatePicker}
            error={this.state.showExactDateRequiredError}
            onClose={() => this.setState({ showDatePicker: false })}
            onFocus={() => this.setState({ showDatePicker: true })}
            disabledDays={{
              before: new Date(),
            }}
            value={this.state.systemParams.demo_expiration_date}
            onChangeDate={expirationDate => {
              this.setState({
                systemParams: {
                  ...this.state.systemParams,
                  demo_expiration_date: expirationDate,
                },
                showDatePicker: false,
              });
            }}
            onClear={() => {
              this.setState({
                systemParams: {
                  ...this.state.systemParams,
                  demo_expiration_date: null,
                },
                showDatePicker: false,
              });
            }}
          />
        </Field>
      </Grid.Column>
    );
  };

  renderTierDropdown = () => {
    if (this.props.createForOrgId) {
      return null;
    }
    return (
      <Grid.Column>
        <TierDropdown
          onSelectedTier={tier =>
            this.setState({
              systemParams: {
                ...this.state.systemParams,
                tier: tier,
              },
            })
          }
        />
      </Grid.Column>
    );
  };

  renderSystemWithoutData = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label="Name">
              <Field.Text
                autoFocus
                error={this.state.showErrorForName}
                value={this.state.systemParams.name}
                onChange={value => this.changeName(value)}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label="System name (For internal use)">
              <Field.Text
                error={this.state.showErrorForInternalName}
                value={this.state.systemParams.internal_name}
                onChange={value => this.changeInternalName(value)}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={this.props.createForOrgId ? null : 400}>
            <CurrencyDropdown
              onSelectedValue={currency =>
                this.setState({
                  systemParams: {
                    ...this.state.systemParams,
                    currency: currency,
                  },
                })
              }
            />
          </Grid.Column>
          {this.renderTierDropdown()}
        </Grid.Row>
        <Grid.Row>
          {this.renderExpirationDate()}
          <Grid.Column>
            <Field label={'Timezone'}>
              <FieldErrorWrapper
                local
                position="top"
                show={this.state.showErrorForInvalidTimezone}
                errorElement="Invalid timezone"
              >
                <Field.Text
                  value={this.state.systemParams.timezone}
                  onChange={value =>
                    this.setState({
                      systemParams: {
                        ...this.state.systemParams,
                        timezone: value,
                      },
                    })
                  }
                />
              </FieldErrorWrapper>
              <p className={styles['link']}>
                Valid timezones can be found{' '}
                <a
                  href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>here</u>
                </a>
              </p>
            </Field>
          </Grid.Column>
        </Grid.Row>
        {this.renderUserFields()}
      </Grid>
    );
  };

  render() {
    return (
      <>
        <SideModal
          open={this.props.open}
          onClose={() => {
            if (this.props.hasUnsavedChanges) {
              const confirmed = window.confirm('You have unsaved changes, are you sure you want to leave?');
              if (confirmed) {
                this.resetData();
                this.props.onClose();
              }
            } else {
              this.resetData();
              this.props.onClose();
            }
          }}
          footerComponent={
            <Button.Group>
              <Button
                primary
                label="Create new"
                loading={this.state.isCreatingSystem}
                onClick={() => this.create()}
              />
              <Button
                label="Cancel"
                onClick={() => {
                  this.resetData();
                  this.props.onClose();
                }}
              />
            </Button.Group>
          }
        >
          <SideModal.Container>
            <SideModal.Container.Content>
              <SideModal.Header title="Create new system" onClose={this.props.onClose} />
              {this.renderContent()}
            </SideModal.Container.Content>
          </SideModal.Container>
        </SideModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setFieldErrors: ErrorOperations.setFieldErrors,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
      updateEntities: EntityOperations.updateEntities,
      createSystemAsAdmin: SDKReduxOperations.createSystemAsAdmin,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    fieldErrors: ErrorSelectors.getFieldErrors(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateSystemModal));
