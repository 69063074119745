import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AnimateHeight from 'react-animate-height';
import Container from 'views/components/Shared/Layout/SideModal/Container/Filter/Detail/Types/Container';

class Exact extends Component {
  renderField = () => {
    return (
      <AnimateHeight duration={120} height={this.props.selected ? 'auto' : 0}>
        {this.props.children}
      </AnimateHeight>
    );
  };

  render() {
    return (
      <Container
        selected={this.props.selected}
        title={<FormattedMessage id="filters.types.number.exact.title" />}
        subtitle={<FormattedMessage id="filters.types.number.exact.subtitle" />}
        onClick={this.props.onClick}
      >
        {this.renderField()}
      </Container>
    );
  }
}

Exact.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Exact.defaultProps = {
  selected: false,
  children: null,
  onClick: () => {},
};

export default Exact;
