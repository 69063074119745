import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const TabBarItem = ({ children, active, onClick }) => (
  <div
    onClick={onClick}
    className={`
    ${styles['item']}
    ${active ? styles['active'] : ''}
  `}
  >
    {children}
    <div className={styles['line']} />
  </div>
);

TabBarItem.propTypes = {
  active: PropTypes.bool,
};
TabBarItem.defaultProps = {
  active: false,
};

export default TabBarItem;
