import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import * as types from './reduxTypes';
import { normalizeSystemAccess } from 'sdk/Schemas';

export function updateSystemAccess(systemAccessId, params) {
  return dispatch => {
    return API.updateSystemAccess(systemAccessId, params)
      .then(res => {
        const { entities } = normalizeSystemAccess(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemAccessId, params, data: res.data };
        dispatch({ type: types.SYSTEM_ACCESS_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createSystemAccess(orgId, params) {
  return dispatch => {
    return API.createSystemAccess(orgId, params)
      .then(res => {
        const { entities } = normalizeSystemAccess(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { orgId, params, data: res.data };
        dispatch({ type: types.SYSTEM_ACCESS_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteSystemAccess(systemAccessId) {
  return (dispatch, getState) => {
    const systemAccess = getState().entities.systemAccessById[systemAccessId];
    return API.deleteSystemAccess(systemAccessId)
      .then(res => {
        const payload = { systemAccessId, systemAccess };
        dispatch({ type: types.SYSTEM_ACCESS_DELETED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
