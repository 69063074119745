import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const fetchPermissionProfile = permissionProfileId =>
  request.get(`permission_profiles/${permissionProfileId}`);
export const listPermissionProfiles = (systemId, params) =>
  request.get(`systems/${systemId}/permission_profiles?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const createPermissionProfile = (systemId, data) =>
  request.post(`systems/${systemId}/permission_profiles`, data);
export const deletePermissionProfile = permissionProfileId =>
  request.delete(`permission_profiles/${permissionProfileId}`);
export const updatePermissionProfile = (permissionProfileId, data) =>
  request.patch(`permission_profiles/${permissionProfileId}`, data, {
    id: permissionProfileId,
  });
