import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './style.module.scss';

class Menu extends Component {
  render() {
    return (
      <div className={styles['menu']}>
        <PerfectScrollbar>{this.props.children}</PerfectScrollbar>
      </div>
    );
  }
}

export default Menu;
