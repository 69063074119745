import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { OrganisationOperations } from 'state/ducks/organisation';
import DiscountsList from './DiscountsList';
import DiscountDetail from './DiscountDetail';

class DiscountsModal extends Component {
  state = {
    id: null,
    title: null,
    detailDiscountId: null,
    showListModal: false,
    showDetailModal: false,
  };

  componentDidMount() {
    this.setState({ showListModal: true });
  }

  renderDiscountsList = () => {
    return (
      <DiscountsList
        open={this.state.showListModal && this.props.open}
        onDetailSelected={discountId => {
          this.setState({ showListModal: false, detailDiscountId: discountId });
          setTimeout(() => {
            this.setState({ showDetailModal: true });
          }, 100);
        }}
        onClose={() => {
          this.props.onClose();
        }}
      />
    );
  };

  renderDiscountDetail = () => {
    return (
      <DiscountDetail
        open={this.state.showDetailModal && this.props.open}
        billingDiscountId={this.state.detailDiscountId}
        onClose={() => {
          this.setState({ showDetailModal: false, detailDiscountId: null });
          setTimeout(() => {
            this.setState({ showListModal: true });
          }, 100);
        }}
      />
    );
  };
  render() {
    return (
      <>
        {this.renderDiscountsList()}
        {this.renderDiscountDetail()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBillingDiscounts: OrganisationOperations.fetchBillingDiscounts,
    },
    dispatch
  );
}

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(DiscountsModal)));
