import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { HeaderOperations } from 'state/ducks/header';
import { ListLayout } from 'views/components/Shared/Layout';
import {
  Menu,
  List,
  EmptyDataSet,
  Pagination,
  MoneyWithCurrency,
  WhiteCard,
  Button,
} from 'views/components/Shared/General';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import HelperFunctions from 'utilities/HelperFunctions';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { OrganisationsOperations, OrganisationsSelectors } from 'state/ducks/organisations';
import { CreateSystemModal } from 'views/components/System';
import styles from './style.module.scss';
import { SystemTier } from 'sdk/System';

class Organisations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateSystemModal: false,
      isFetching: true,
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle('Systems');
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Systems);
    this.props.setHeaderOptions({ title: 'Systems' });
    this.fetchOrganisations();
  }

  componentDidUpdate(prevProps) {
    const { list: oldList } = queryString.parse(prevProps.location.search);
    const { list } = queryString.parse(this.props.location.search);
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const changedList = oldList !== list;
    if (changedQueryParams || changedList) {
      if (changedList) {
        this.addQueryParameter({ page: 1 });
      }
      this.setState({ isFetching: true });
      this.fetchOrganisations();
    }
  }

  setNavigatedTo = () => {
    const { list } = queryString.parse(this.props.location.search);
    this.props.setNavigatedTo({
      search: {
        list,
      },
    });
  };

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  fetchOrganisations = params => {
    const { list } = queryString.parse(this.props.location.search);
    let attrs = {
      demo: list === 'demo',
      ...this.props.queryParameters,
      ...params,
    };
    if (this.state.searchTerm) {
      attrs = {
        ...attrs,
        search: this.state.searchTerm,
      };
    }

    return this.props
      .fetchOrganisations(attrs)
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(() => {});
  };

  renderCreateSystemModal = () => {
    return (
      <CreateSystemModal
        open={this.state.showCreateSystemModal}
        onSystemCreated={system => {
          this.setState({ showCreateSystemModal: false });
          this.props.history.push(`/organisations/${system.id}`, {
            fromPath: this.props.location.pathname,
          });
        }}
        onClose={() => this.setState({ showCreateSystemModal: false })}
      />
    );
  };

  renderEmptyDataSet = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={<span>You search returned no results</span>}
          subtitle={<span>Change your search criteria and try again</span>}
        />
      </WhiteCard>
    );
  };

  renderTierText = ({ tier }) => {
    if (tier === SystemTier.Enterprise) {
      return SystemTier.Enterprise;
    }
    if (tier === SystemTier.Pro) {
      return SystemTier.Pro;
    }
    return SystemTier.Basic;
  };

  renderRevenue = organisation => {
    let price = 0;
    if (organisation.billing_tier === SystemTier.Basic) {
      price = 299;
    } else {
      price = 699;
    }

    let userCount = organisation.billable_user_count;
    if (organisation.billable_users_cap && organisation.billable_users_cap < userCount)
      userCount = organisation.billable_users_cap;

    return (
      <MoneyWithCurrency currency="SEK" value={userCount * price} fractionSize={0} currencyFontSize={12} />
    );
  };

  renderDemoExpires = organisation => {
    if (organisation.demo_expiration_date == null) {
      return '-';
    }
    if (moment(organisation.demo_expiration_date).isBefore(moment().format('YYYY-MM-DD'))) {
      return (
        <span className={styles['demo-expired']}>
          {moment(organisation.demo_expiration_date).format('LL')}
        </span>
      );
    }
    return moment(organisation.demo_expiration_date).format('LL');
  };

  renderOrganisationListItem = organisation => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === 'customer') {
      return (
        <List.Item
          small
          clickable
          key={organisation.id}
          linkTo={`/organisations/${organisation.id}`}
          onClick={() => this.setNavigatedTo()}
        >
          <List.Item.TitleColumn title={organisation.internal_name} />
          <List.Item.Column width={150}>{moment(organisation.created_at).format('LL')}</List.Item.Column>
          <List.Item.Column width={150}>{this.renderTierText(organisation)}</List.Item.Column>
          <List.Item.Column width={150}>{this.renderRevenue(organisation)}</List.Item.Column>
        </List.Item>
      );
    }
    return (
      <List.Item
        small
        clickable
        key={organisation.id}
        linkTo={`/organisations/${organisation.id}`}
        onClick={() => this.setNavigatedTo()}
      >
        <List.Item.TitleColumn title={organisation.internal_name} />
        <List.Item.Column width={150}>{this.renderTierText(organisation)}</List.Item.Column>
        <List.Item.Column width={150}>{this.renderDemoExpires(organisation)}</List.Item.Column>
      </List.Item>
    );
  };

  renderOrganisations = () => {
    return this.props.organisations.map(organisation => {
      return this.renderOrganisationListItem(organisation);
    });
  };

  renderLeftMenu = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <ListLayout.Content.Menu>
        <ListLayout.Content.Menu.Content>
          <Button
            fullWidth
            primary
            translate={false}
            label={<span>Create system</span>}
            onClick={() => {
              this.setState({ showCreateSystemModal: true });
            }}
          />
          <Menu.Separator />
          <Menu.Item
            linkTo={{
              pathname: '/organisations',
              search: 'list=customer',
            }}
            title={<span>Customers</span>}
            selected={list === 'customer'}
          />
          <Menu.Item
            linkTo={{
              pathname: '/organisations',
              search: 'list=demo',
            }}
            title={<span>Demo</span>}
            selected={list === 'demo'}
          />
        </ListLayout.Content.Menu.Content>
      </ListLayout.Content.Menu>
    );
  };

  renderHeader = () => {
    return (
      <ListLayout.Header
        searchable
        searchValue={this.state.searchTerm}
        searchPlaceHolder="Search"
        totalEntries={`Totalt ${this.props.pagination.totalEntries} system`}
        onSearch={value => {
          this.setState({
            searchTerm: value,
            isFetching: true,
          });
        }}
        onDebouncedSearch={value => {
          this.fetchOrganisations({ search: value });
        }}
        onClearSearch={() => {
          this.setState({ isFetching: true, searchTerm: '' }, () => {
            this.fetchOrganisations({ search: '' });
          });
        }}
        title={<span>System</span>}
      />
    );
  };

  renderListHeader = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === 'customer') {
      return (
        <List.Header small background>
          <List.Header.Column flex>
            <span>Name</span>
          </List.Header.Column>
          <List.Header.Column width={150}>
            <span>Created at</span>
          </List.Header.Column>
          <List.Header.Column width={150}>
            <span>Package</span>
          </List.Header.Column>
          <List.Header.Column width={150}>
            <span>Monthly revenue</span>
          </List.Header.Column>
        </List.Header>
      );
    }
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <span>Name</span>
        </List.Header.Column>
        <List.Header.Column width={150}>
          <span>Package</span>
        </List.Header.Column>
        <List.Header.Column width={150}>
          <span>Demo expires</span>
        </List.Header.Column>
      </List.Header>
    );
  };

  renderPagination = () => {
    if (this.props.organisations.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.setState({ isFetching: true });
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.setState({ isFetching: true });
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderList = () => {
    if (this.state.isFetching) {
      return (
        <>
          {this.renderListHeader()}
          <List small>
            <List.Item>
              <List.Item.TitleColumn loading />
            </List.Item>
            <List.Item>
              <List.Item.TitleColumn loading />
            </List.Item>
          </List>
        </>
      );
    }
    if (this.props.organisations.length === 0) {
      return this.renderEmptyDataSet();
    }
    return (
      <>
        {this.renderListHeader()}
        <List small>{this.renderOrganisations()}</List>
      </>
    );
  };

  renderMainContent = () => {
    return (
      <ListLayout.Content.MainContent>
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderCreateSystemModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOrganisations: OrganisationsOperations.fetchOrganisations,
      addQueryParameter: OrganisationsOperations.addQueryParameter,
      setHeaderOptions: HeaderOperations.setOptions,
      selectMenuItem: MenuOperations.selectItem,
      setNavigatedTo: OrganisationsOperations.setNavigatedTo,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    organisations: OrganisationsSelectors.getOrganisations(state),
    pagination: OrganisationsSelectors.getPagination(state),
    queryParameters: OrganisationsSelectors.getQueryParameters(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Organisations));
