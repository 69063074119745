import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InlineModal, Button } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';

export default class ConfirmDeleteInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
            }));
          }}
        >
          {this.props.trigger}
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={e => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          <React.Fragment>
            <InlineModal.Header
              width={this.props.width}
              title={this.props.title}
              subtitle={this.props.subtitle}
              hideCloseButton
            />
            <InlineModal.Body width={this.props.width}>
              <Button.Group>
                <Button
                  destructive
                  primary
                  small
                  label={this.props.buttonLabel}
                  onClick={this.props.onDelete}
                  loading={this.props.loading}
                />
                <Button
                  small
                  label="general.cancel"
                  onClick={() => {
                    this.setState({ isOpen: false });
                  }}
                />
              </Button.Group>
            </InlineModal.Body>
          </React.Fragment>
        </InlineModal>
      </>
    );
  }
}

ConfirmDeleteInlineModal.propTypes = {
  title: PropTypes.element,
  subtitle: PropTypes.element,
  position: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  onDelete: PropTypes.func,
};

ConfirmDeleteInlineModal.defaultProps = {
  title: <FormattedMessage id="components.delete-inline-modal.title" />,
  subtitle: <FormattedMessage id="components.delete-inline-modal.subtitle" />,
  buttonLabel: 'general.delete',
  onDelete: () => {},
  position: 'left',
  width: '300px',
};
