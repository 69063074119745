import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const fetchPurchaseOrderDelivery = deliveryId => request.get(`purchase_order_deliveries/${deliveryId}`);
export const listPurchaseOrderDeliveriesForSystem = (systemId, params) =>
  request.get(
    `systems/${systemId}/purchase_order_deliveries?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
export const listPurchaseOrderDeliveriesForPurchaseOrder = (purchaseOrderId, params) =>
  request.get(
    `purchase_orders/${purchaseOrderId}/purchase_order_deliveries?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`
  );
export const createPurchaseOrderDelivery = (purchaseOrderId, data) =>
  request.post(`purchase_orders/${purchaseOrderId}/purchase_order_deliveries`, data);

export const deletePurchaseOrderDelivery = purchaseOrderDeliveryId =>
  request.delete(`purchase_order_deliveries/${purchaseOrderDeliveryId}`);
