import React, { Component } from 'react';
import { Decimal as DecimalJs } from 'decimal.js';
import Decimal from '../Decimal';

export default class Number extends Component {
  onBlur = value => {
    if (typeof this.props.onBlur === 'function') {
      try {
        let valueAsNumber = new DecimalJs(value).floor().toString();
        this.props.onBlur(valueAsNumber);
      } catch (e) {
        this.props.onBlur(value);
      }
    }
  };

  render() {
    return <Decimal {...this.props} onBlur={this.onBlur} />;
  }
}
