import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const Field = ({ label, children }) => {
  return (
    <div className={styles['field']}>
      {label != null ? (
        <p>
          <FormattedMessage id={label} />
        </p>
      ) : null}
      <div>{children}</div>
    </div>
  );
};

export default Field;

Field.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
};
