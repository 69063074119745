import React, { Component } from 'react';
import Column from '../Column';
import styles from './style.module.scss';

export default class IconColumn extends Component {
  renderIcon = () => {
    let classNames = [];
    if (this.props.loading) classNames = [...classNames, styles['loading']];
    return <div className={classNames.join(' ')}>{this.props.icon}</div>;
  };

  render() {
    return <Column width={20}>{this.renderIcon()}</Column>;
  }
}
