import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeDowntime } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createDowntimeForAsset(assetId, params) {
  return dispatch => {
    return API.createDowntimeForAsset(assetId, params)
      .then(res => {
        const { entities } = normalizeDowntime(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { assetId, params, data: res.data };
        dispatch({ type: types.DOWNTIME_CREATED_FOR_ASSET, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateDowntime(downtimeId, params) {
  return dispatch => {
    return API.updateDowntime(downtimeId, params)
      .then(res => {
        const { entities } = normalizeDowntime(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { downtimeId, params, data: res.data };
        dispatch({ type: types.DOWNTIME_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteDowntime(downtimeId) {
  return (dispatch, getState) => {
    const downtime = getState().entities.downtimeById[downtimeId];
    return API.deleteDowntime(downtimeId)
      .then(res => {
        const payload = { downtimeId, data: downtime };
        dispatch({ type: types.DOWNTIME_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
