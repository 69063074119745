import React, { Component } from 'react';
import { WhiteCard, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';
import AdditionalCostsModal from '../../../components/AdditionalCostsModal';

export default class AdditionalCostsButton extends Component {
  state = {
    additionalCostModalOpen: false,
  };
  renderAdditionalCostsModal = () => {
    return (
      <AdditionalCostsModal
        open={this.state.additionalCostModalOpen}
        onClose={() => this.setState({ additionalCostModalOpen: false })}
      />
    );
  };
  render() {
    return (
      <>
        <div onClick={() => this.setState({ additionalCostModalOpen: true })}>
          <WhiteCard noPadding clickable fullHeight>
            <div className={styles['add-container']}>
              <div className={styles['icon-container']}>
                <Icon withBackground blue type="file-invoice-dollar" backgroundSize={34} size={16} />
              </div>
              <div className={styles['text-container']}>
                <p className={styles['add-title']}>Additional costs</p>
                <p className={styles['add-subtitle']}>
                  Add additional rows to next invoice, for example introductory training or migration
                </p>
              </div>
            </div>
          </WhiteCard>
        </div>
        {this.renderAdditionalCostsModal()}
      </>
    );
  }
}
