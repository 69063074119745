import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeExport } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createExport(systemId, params) {
  return dispatch => {
    return API.createExport(systemId, params)
      .then(res => {
        const { entities } = normalizeExport(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({
          type: types.EXPORT_CREATED,
          payload,
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
