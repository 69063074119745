import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { API } from 'sdk';
import moment from 'moment';
import { ListLayout } from 'views/components/Shared/Layout';
import { Icon, List, MoneyWithCurrency, Pagination, Tooltip } from 'views/components/Shared/General';
import styles from './style.module.scss';

class BillingActivities extends Component {
  state = {
    billingActivities: null,
    isFetching: true,
    page: 1,
    totalPages: 1,
    totalSize: null,
  };

  componentDidMount() {
    API.listBillingActivitiesAsAdmin().then(({ data }) => {
      console.log(data);

      this.setState({
        billingActivities: data,
        isFetching: false,
      });
    });
  }

  renderContentLoader = () => (
    <>
      <List.Item small>
        <List.Item.TitleColumn loading />
      </List.Item>
      <List.Item small>
        <List.Item.TitleColumn loading />
      </List.Item>
    </>
  );

  renderTypeColumn = item => {
    console.log(item);
    switch (item.type) {
      case 'organisation_initiated':
        return (
          <div className={styles['type']}>
            Organisation initated
            <Tooltip trigger={<Icon regular type="question-circle" />} label={item.tier} />
          </div>
        );
      case 'user_added':
        return (
          <div className={styles['type']}>
            User added
            <Tooltip trigger={<Icon regular type="question-circle" />} label={item.user_billing_type} />
          </div>
        );
      case 'user_removed':
        return (
          <div className={styles['type']}>
            User removed
            <Tooltip trigger={<Icon regular type="question-circle" />} label={item.user_billing_type} />
          </div>
        );
      case 'user_changed':
        return (
          <div className={styles['type']}>
            User type changed
            <Tooltip
              trigger={<Icon regular type="question-circle" />}
              label={`${item.old_user_billing_type} -> ${item.user_billing_type}`}
            />
          </div>
        );
      case 'tier_changed':
        return (
          <div className={styles['type']}>
            Tier changed
            <Tooltip
              trigger={<Icon regular type="question-circle" />}
              label={`${item.old_tier} -> ${item.tier}`}
            />
          </div>
        );
    }
  };

  renderRows = () => {
    return this.state.billingActivities.map(item => {
      const positive = Number(item.cost) >= 0;

      return (
        <List.Item small>
          <List.Item.TitleColumn title={item.organisation.internal_name} />
          <List.Item.Column width={250}>{this.renderTypeColumn(item)}</List.Item.Column>
          <List.Item.Column width={250}>{moment(item.created_at).format('LL')}</List.Item.Column>
          <List.Item.Column width={150} alignRight>
            <span className={positive ? styles['green-revenue'] : styles['red-revenue']}>
              <MoneyWithCurrency currency="SEK" value={item.cost} fractionSize={0} currencyFontSize={12} />
            </span>
          </List.Item.Column>
        </List.Item>
      );
    });
  };

  renderListContent = () => {
    if (this.state.isFetching) return this.renderContentLoader();
    return this.renderRows();
  };

  renderList = () => (
    <>
      <List.Header small background>
        <List.Header.Column flex>Customer</List.Header.Column>
        <List.Header.Column width={250}>Type</List.Header.Column>
        <List.Header.Column width={250}>Date</List.Header.Column>
        <List.Header.Column width={150} alignRight>
          Revenue difference
        </List.Header.Column>
      </List.Header>
      <List small>{this.renderListContent()}</List>
    </>
  );

  renderPagination = () => {
    if (!this.props.billingActivities || this.props.billingActivities.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.state.page}
          totalPages={this.state.totalPages}
          pageSize={this.state.pageSize}
          onSelectPage={page => {
            this.setState({ isFetching: true, page: page });
          }}
          onChangePageSize={pageSize => {
            this.setState({ isFetching: true, pageSize: pageSize });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  render() {
    return (
      <ListLayout.Content.MainContent>
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  }
}

export default withRouter(injectIntl(connect(null)(BillingActivities)));
