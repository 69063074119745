import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HelperFunctions } from 'sdk';
import { Button, Field } from 'views/components/Shared/General';
import { Search, Exists, NotExists } from './comparators';

class Text extends Component {
  getInitialState = () => ({
    value: '',
    comparator: HelperFunctions.FILTER_COMPARABLES.Search,
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  componentDidMount() {
    const { value, comparator } = this.props;
    this.setState({
      value,
      comparator,
    });
  }

  renderSearchComparator = () => {
    return (
      <Search
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Search}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.Search) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.Search });
          }
        }}
      >
        <Field.Text
          autoFocus
          value={this.state.value}
          onChange={value => this.setState({ value })}
          onBlur={value => {
            if (value) {
              this.props.onChange({
                value: this.state.value,
                comparator: HelperFunctions.FILTER_COMPARABLES.Search,
              });
            }
          }}
        />
      </Search>
    );
  };

  renderExistsComparator = () => {
    if (this.props.required === false) {
      return (
        <Exists
          selected={
            this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.value === true
          }
          onClick={() => {
            this.setState({ value: true, comparator: HelperFunctions.FILTER_COMPARABLES.Exists }, () => {
              this.props.onChange({
                value: true,
                comparator: HelperFunctions.FILTER_COMPARABLES.Exists,
              });
            });
          }}
        />
      );
    }
    return null;
  };

  renderNotExistsComparator = () => {
    if (this.props.required === false) {
      return (
        <NotExists
          selected={
            this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.value === false
          }
          onClick={() => {
            this.setState({ value: false, comparator: HelperFunctions.FILTER_COMPARABLES.Exists }, () => {
              this.props.onChange({
                value: false,
                comparator: HelperFunctions.FILTER_COMPARABLES.Exists,
              });
            });
          }}
        />
      );
    }
    return null;
  };

  hasFiltersApplied = () => {
    const { comparator, value } = this.state;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    if (value) {
      return true;
    }
    return false;
  };

  renderApplyFiltersButton = () => {
    return (
      <Button
        primary
        disabled={this.hasFiltersApplied() === false}
        label="filters.add-button"
        onClick={() => {
          this.props.onApplyfilter({
            comparator: this.state.comparator,
            value: this.state.value,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderSearchComparator()}
        {this.renderExistsComparator()}
        {this.renderNotExistsComparator()}
        {this.renderApplyFiltersButton()}
      </>
    );
  }
}

Text.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  comparator: PropTypes.string,
  onApplyfilter: PropTypes.func,
};

Text.defaultProps = {
  value: null,
  comparator: HelperFunctions.FILTER_COMPARABLES.Search,
  required: false,
  onApplyfilter: () => {},
};

export default Text;
