import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeWorkOrderSpentTime } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createWorkOrderSpentTime(workOrderId, params) {
  return dispatch => {
    return API.createWorkOrderSpentTime(workOrderId, params)
      .then(({ data: workOrderSpentTime }) => {
        const { entities } = normalizeWorkOrderSpentTime(workOrderSpentTime);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderId, params, data: workOrderSpentTime };
        dispatch({ type: types.WORK_ORDER_SPENT_TIME_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateWorkOrderSpentTime(workOrderSpentTimeId, params) {
  return dispatch => {
    return API.updateWorkOrderSpentTime(workOrderSpentTimeId, params)
      .then(res => {
        const { entities } = normalizeWorkOrderSpentTime(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderSpentTimeId, params, data: res.data };
        dispatch({ type: types.WORK_ORDER_SPENT_TIME_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteWorkOrderSpentTime(workOrderSpentTimeId) {
  return (dispatch, getState) => {
    const workOrderSpentTime = getState().entities.workOrderSpentTimeById[workOrderSpentTimeId];
    return API.deleteWorkOrderSpentTime(workOrderSpentTimeId)
      .then(res => {
        const payload = { workOrderSpentTimeId, data: workOrderSpentTime };
        dispatch({ type: types.WORK_ORDER_SPENT_TIME_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
