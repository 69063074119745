const DOMAIN_PREFIX = 'error/';

const RESOURCE_NOT_FOUND = `${DOMAIN_PREFIX}resurce_not_found`;
const RESET_RESOURCE_NOT_FOUND = `${DOMAIN_PREFIX}reset_resource_not_found`;
const SET_FIELD_ERRORS = `${DOMAIN_PREFIX}set_field_errors`;
const REMOVE_FIELDS_FROM_ERRORS = `${DOMAIN_PREFIX}remove_fields_from_errors`;
const REMOVE_ALL_ERRORS = `${DOMAIN_PREFIX}remove_all_errors`;

export default {
  RESOURCE_NOT_FOUND,
  RESET_RESOURCE_NOT_FOUND,
  SET_FIELD_ERRORS,
  REMOVE_FIELDS_FROM_ERRORS,
  REMOVE_ALL_ERRORS,
}
