import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import toast from 'react-hot-toast';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import {
  Button,
  Field,
  ConfirmDeleteInlineModal,
  FieldErrorWrapper,
  Icon,
} from 'views/components/Shared/General';
import TierDropdown from './TierDropdown';
import styles from './style.module.scss';

class DiscountDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorForExpirationDate: false,
      isSavingBillingDiscount: false,
      isDeletingBillingDiscount: false,
      showDatePicker: false,
      expiration_date: null,
      tier: null,
      price_full: null,
      price_operator: null,
      price_production_supervisor: null,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.billingDiscount) {
        this.setState({
          expiration_date: this.props.billingDiscount.expiration_date,
          tier: this.props.billingDiscount.tier,
          price_full: this.props.billingDiscount.price_full,
          price_operator: this.props.billingDiscount.price_operator,
          price_production_supervisor: this.props.billingDiscount.price_production_supervisor,
        });
      } else {
        this.setState({
          expiration_date: null,
          tier: this.props.organisation.tier,
          price_full: null,
          price_operator: null,
          price_production_supervisor: null,
        });
      }
    }
  }

  hasError = () => {
    let error = false;
    if (this.state.expiration_date === null || this.state.expiration_date.length === 0) {
      this.setState({ showErrorForExpirationDate: true });
      error = true;
    }
    return error;
  };

  deleteBillingDiscount = () => {
    this.setState({ isDeletingBillingDiscount: true });
    this.props.deleteBillingDiscountAsAdmin(this.props.billingDiscount.id).then(res => {
      this.setState({ isDeletingBillingDiscount: false });
      toast(() => <ToastMessage success text="Billing discount deleted" />);
      this.props.onClose();
    });
  };

  saveBillingDiscount = () => {
    if (this.hasError()) return;
    this.setState({ isSavingBillingDiscount: true });
    const formattedFullPrice = isNaN(this.state.price_full)
      ? this.state.price_full.replace(/ /g, '')
      : this.state.price_full;
    const formattedOperatorPrice = isNaN(this.state.price_operator)
      ? this.state.price_operator.replace(/ /g, '')
      : this.state.price_operator;
    const formattedProductionSupervisorPrice = isNaN(this.state.price_production_supervisor)
      ? this.state.price_production_supervisor.replace(/ /g, '')
      : this.state.price_production_supervisor;
    const data = {
      expiration_date: this.state.expiration_date,
      tier: this.state.tier,
      price_full: formattedFullPrice,
      price_operator: formattedOperatorPrice,
      price_production_supervisor: formattedProductionSupervisorPrice,
    };
    if (this.props.billingDiscount === null) {
      this.props
        .createBillingDiscountAsAdmin(this.props.organisation.id, data)
        .then(res => {
          this.setState({ isSavingBillingDiscount: false });
          toast(() => <ToastMessage success text="Billing discount created" />);
          this.props.onClose();
        })
        .catch(error => {
          this.setState({ isSavingBillingDiscount: false });
        });
    } else {
      this.props
        .updateBillingDiscountAsAdmin(this.props.billingDiscount.id, data)
        .then(res => {
          this.setState({ isSavingBillingDiscount: false });
          toast(() => <ToastMessage success text="Billing discount updated" />);
          this.props.onClose();
        })
        .catch(error => {
          this.setState({ isSavingBillingDiscount: false });
        });
    }
  };

  renderDiscountTitle = () => {
    if (this.props.billingDiscount === null) return 'Create discount';
    else return 'Edit discount';
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={560}>
        <Modal.Header
          ignoreLine
          title={this.renderDiscountTitle()}
          onClose={() => {
            this.props.onClose();
          }}
          iconButtons={
            this.props.billingDiscount === null ? null : (
              <ConfirmDeleteInlineModal
                position="right"
                trigger={<Button type="icon" icon={<Icon type="trash-alt" regular red />} label="Delete" />}
                title="Are you sure?"
                onDelete={() => this.deleteBillingDiscount()}
                loading={this.state.isDeletingBillingDiscount}
                buttonLabel="Delete"
                cancelButtonLabel="Cancel"
                subtitle={`Deleting discount expiring ${this.props.billingDiscount.expiration_date}`}
              />
            )
          }
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field label="Expiration date">
                  <Field.Date
                    open={this.state.showDatePicker}
                    error={this.state.showErrorForExpirationDate}
                    onClose={() => this.setState({ showDatePicker: false })}
                    onFocus={() => this.setState({ showDatePicker: true })}
                    disabledDays={{
                      before: new Date(),
                    }}
                    value={this.state.expiration_date}
                    onChangeDate={expirationDate => {
                      this.setState({
                        expiration_date: expirationDate,
                        showErrorForExpirationDate: false,
                        showDatePicker: false,
                      });
                    }}
                    onClear={() => {
                      this.setState({
                        expiration_date: null,
                        showDatePicker: false,
                      });
                    }}
                  />
                </Field>
                <p className={styles['field-subtitle']}>
                  Date should set to the last day before the discount expires. Eg. 31 december 2021.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column md={4}>
                <Field label="Full user">
                  <Field.Money
                    currency="SEK"
                    value={this.state.price_full}
                    onBlur={price_full => {
                      this.setState({
                        price_full,
                      });
                    }}
                  />
                </Field>
              </Grid.Column>
              <Grid.Column md={4}>
                <Field label="Production supervisor">
                  <Field.Money
                    currency="SEK"
                    value={this.state.price_production_supervisor}
                    onBlur={price_production_supervisor => {
                      this.setState({
                        price_production_supervisor,
                      });
                    }}
                  />
                </Field>
              </Grid.Column>
              <Grid.Column md={4}>
                <Field label="Operator">
                  <Field.Money
                    currency="SEK"
                    value={this.state.price_operator}
                    onBlur={price_operator => {
                      this.setState({
                        price_operator,
                      });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <TierDropdown
                  currentTier={this.state.tier}
                  onSelectedTier={tier =>
                    this.setState({
                      tier,
                    })
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="Save"
              loading={this.state.isSavingBillingDiscount}
              onClick={() => this.saveBillingDiscount()}
            />
            <Button label="Cancel" onClick={() => this.props.onClose()} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createBillingDiscountAsAdmin: SDKReduxOperations.createBillingDiscountAsAdmin,
      updateBillingDiscountAsAdmin: SDKReduxOperations.updateBillingDiscountAsAdmin,
      deleteBillingDiscountAsAdmin: SDKReduxOperations.deleteBillingDiscountAsAdmin,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
    billingDiscount: EntitySelectors.getBillingDiscount(state, ownProps.billingDiscountId),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(DiscountDetail)));
