import React from 'react';
import Text from './Text';
import Resource from './Resource';
import Date from './Date';
import Number from './Number';
import Boolean from './Boolean';
import styles from './style.module.scss';

const Types = ({ children }) => {
  return <div className={styles['options']}>{children}</div>;
};

Types.Text = Text;
Types.Resource = Resource;
Types.Number = Number;
Types.Date = Date;
Types.Boolean = Boolean;
export default Types;
