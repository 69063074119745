import { omit } from 'lodash-es';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.WORK_ORDER_SPENT_TIME_CATEGORY_UPDATED:
    case SDKReduxTypes.WS_WORK_ORDER_SPENT_TIME_CATEGORY_UPDATED: {
      let data = {};
      if (action.type === SDKReduxTypes.WORK_ORDER_SPENT_TIME_CATEGORY_UPDATED) {
        data = action.payload.data;
      } else {
        data = action.payload;
      }
      if (data['default'] === true) {
        let newWorkOrderSpentTimeCategoryById = {};
        for (let workOrderSpentTimeCategoryId in state) {
          newWorkOrderSpentTimeCategoryById[workOrderSpentTimeCategoryId] = {
            ...state[workOrderSpentTimeCategoryId],
            default: state[workOrderSpentTimeCategoryId].id === data.id,
          };
        }
        return newWorkOrderSpentTimeCategoryById;
      }
      return state;
    }
    case SDKReduxTypes.WORK_ORDER_SPENT_TIME_CATEGORY_CREATED:
    case SDKReduxTypes.WS_WORK_ORDER_SPENT_TIME_CATEGORY_CREATED: {
      let data = {};
      if (action.type === SDKReduxTypes.SPARE_PART_UNIT_CREATED) {
        data = action.payload.data;
      } else {
        data = action.payload;
      }
      if (data['default'] === true) {
        let newWorkOrderSpentTimeCategoryById = {};
        newWorkOrderSpentTimeCategoryById[data.id] = data;
        for (let workOrderSpentTimeCategoryId in state) {
          newWorkOrderSpentTimeCategoryById[workOrderSpentTimeCategoryId] = {
            ...state[workOrderSpentTimeCategoryId],
            default: state[workOrderSpentTimeCategoryId].id === data.id,
          };
        }
        return newWorkOrderSpentTimeCategoryById;
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.workOrderSpentTimeCategoryById) {
        return HelperFunctions.mergeEntities(state, action.payload.workOrderSpentTimeCategoryById);
      }
      return state;
    default:
      return state;
  }
};
