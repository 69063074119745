import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeTemplateField } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createTemplateField(systemId, params) {
  return dispatch => {
    return API.createTemplateField(systemId, params)
      .then(res => {
        const { entities } = normalizeTemplateField(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.TEMPLATE_FIELD_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateTemplateField(templateFieldId, params) {
  return dispatch => {
    return API.updateTemplateField(templateFieldId, params)
      .then(res => {
        const { entities } = normalizeTemplateField(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { templateFieldId, params, data: res.data };
        dispatch({ type: types.TEMPLATE_FIELD_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteTemplateField(templateFieldId) {
  return (dispatch, getState) => {
    return API.deleteTemplateField(templateFieldId)
      .then(res => {
        const templateField = getState().entities.templateFieldById[templateFieldId];
        const payload = { templateFieldId, data: templateField };
        dispatch({ type: types.TEMPLATE_FIELD_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
