import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.CHECKLIST_TEMPLATE_LINK_CREATED_FOR_RECURRING_MAINTENANCE: {
      const { recurringMaintenanceId, data } = action.payload;
      const { id } = data;
      if (state && state[recurringMaintenanceId]) {
        return {
          ...state,
          [recurringMaintenanceId]: {
            ...state[recurringMaintenanceId],
            checklist_template_links: [id, ...state[recurringMaintenanceId].checklist_template_links],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.CHECKLIST_TEMPLATE_LINK_DELETED: {
      const { checklistTemplateLinkId, data } = action.payload;
      const { recurring_maintenance_id } = data;
      if (state && state[recurring_maintenance_id]) {
        return {
          ...state,
          [recurring_maintenance_id]: {
            ...state[recurring_maintenance_id],
            checklist_template_links: state[recurring_maintenance_id].checklist_template_links.filter(
              id => id !== checklistTemplateLinkId
            ),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.REGISTRATION_ATTACHMENT_ADDED_TO_RECURRING_MAINTENANCE: {
      const { attachmentId, recurringMaintenanceId } = action.payload;
      if (state[recurringMaintenanceId]) {
        return {
          ...state,
          [recurringMaintenanceId]: {
            ...state[recurringMaintenanceId],
            registration_attachments: [
              attachmentId,
              ...state[recurringMaintenanceId].registration_attachments,
            ],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.REGISTRATION_ATTACHMENT_CREATED_FOR_RECURRING_MAINTENANCE: {
      const { recurringMaintenanceId, data, params } = action.payload;
      if (params.type == 'link') {
        if (state[recurringMaintenanceId]) {
          return {
            ...state,
            [recurringMaintenanceId]: {
              ...state[recurringMaintenanceId],
              registration_attachments: [data.id, ...state[recurringMaintenanceId].registration_attachments],
            },
          };
        }
      }
      return state;
    }
    case SDKReduxTypes.REGISTRATION_ATTACHMENT_REMOVED_FROM_RECURRING_MAINTENANCE: {
      const { recurringMaintenanceId, attachmentId } = action.payload;
      if (state[recurringMaintenanceId]) {
        return {
          ...state,
          [recurringMaintenanceId]: {
            ...state[recurringMaintenanceId],
            registration_attachments: state[recurringMaintenanceId].registration_attachments.filter(
              id => id !== attachmentId
            ),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_VERSION_UPLOADED: {
      const { data, meta } = action.payload;
      const { recurringMaintenanceId } = meta;
      if (state[recurringMaintenanceId]) {
        return {
          ...state,
          [recurringMaintenanceId]: {
            ...state[recurringMaintenanceId],
            registration_attachments: [
              data.attachment_id,
              ...state[recurringMaintenanceId].registration_attachments,
            ],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_DELETED: {
      const { attachmentId, data } = action.payload;
      const { recurring_maintenance_id } = data;
      if (state[recurring_maintenance_id]) {
        return {
          ...state,
          [recurring_maintenance_id]: {
            ...state[recurring_maintenance_id],
            registration_attachments: state[recurring_maintenance_id].registration_attachments.filter(
              id => id !== attachmentId
            ),
          },
        };
      }

      return state;
    }
    case SDKReduxTypes.IMAGE_CREATED_FOR_RECURRING_MAINTENANCE: {
      const { recurringMaintenanceId, data } = action.payload;
      const { id } = data;
      if (state[recurringMaintenanceId]) {
        return {
          ...state,
          [recurringMaintenanceId]: {
            ...state[recurringMaintenanceId],
            images: [id, ...state[recurringMaintenanceId].images],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId, data } = action.payload;
      if (!data) return state;

      const { recurring_maintenance_id } = data;
      if (state[recurring_maintenance_id]) {
        return {
          ...state,
          [recurring_maintenance_id]: {
            ...state[recurring_maintenance_id],
            images: state[recurring_maintenance_id].images.filter(id => id !== imageId),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_RESERVATION_CREATED_FOR_RECURRING_MAINTENANCE: {
      const { recurringMaintenanceId, data } = action.payload;
      if (state[recurringMaintenanceId]) {
        return {
          ...state,
          [recurringMaintenanceId]: {
            ...state[recurringMaintenanceId],
            spare_part_reservations: [data.id, ...state[recurringMaintenanceId].spare_part_reservations],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_RESERVATION_DELETED: {
      const { data, sparePartReservationId } = action.payload;
      const recurringMaintenanceId = data.recurring_maintenance_id;
      if (state[recurringMaintenanceId]) {
        return {
          ...state,
          [recurringMaintenanceId]: {
            ...state[recurringMaintenanceId],
            spare_part_reservations: state[recurringMaintenanceId].spare_part_reservations.filter(
              id => id !== sparePartReservationId
            ),
          },
        };
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.recurringMaintenanceById) {
        return HelperFunctions.mergeEntities(state, action.payload.recurringMaintenanceById);
      }
      return state;
    default:
      return state;
  }
};
