import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const getWorkOrderListCounts = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/work_order_list_counts?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const listWorkOrders = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/work_orders?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const getOperationalMaintenancesListCounts = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/operational_maintenance_list_counts?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`,
    config
  );
export const listOperationalMaintenances = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/operational_maintenances?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );

export const listWorkOrdersMobileInbox = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/work_orders_mobile_inbox?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const fetchWorkOrder = workOrderId => request.get(`work_orders/${workOrderId}`);
export const createWorkOrder = (systemId, params) => request.post(`systems/${systemId}/work_orders`, params);
export const updateWorkOrder = (workOrderId, params) => request.patch(`work_orders/${workOrderId}`, params);
export const batchUpdateWorkOrders = (systemId, data) =>
  request.put(`systems/${systemId}/work_orders/batch`, data);
export const sendWorkOrderToVendor = (workOrderId, params) =>
  request.put(`work_orders/${workOrderId}/send_email`, params);
export const deleteWorkOrder = workOrderId => request.delete(`work_orders/${workOrderId}`);
export const getWorkOrderReport = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/work_order_report?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
