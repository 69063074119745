import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listSparePartUnits = (systemId, params) =>
  request.get(`systems/${systemId}/spare_part_units?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const updateSparePartUnit = (sparePartUnitId, data) =>
  request.patch(`spare_part_units/${sparePartUnitId}`, data);
export const createSparePartUnit = (systemId, data) =>
  request.post(`systems/${systemId}/spare_part_units`, data);
export const deleteSparePartUnit = sparePartUnitId => request.delete(`spare_part_units/${sparePartUnitId}`);
