import { API } from 'sdk';
import * as Sentry from '@sentry/browser';
import actions from './actions';
import HelperFunctions from 'utilities/HelperFunctions';

const initiateUser = () => {
  return API.getMe()
    .then(({ data: currentUser }) => {
      const { name, email } = currentUser;
      Sentry.configureScope(scope => {
        scope.setUser({ email, name });
      });
      return currentUser;
    })
    .catch(e => {});
};

const signIn =
  ({ email, password }) =>
  dispatch => {
    dispatch(actions.authenticateUser());
    return API.login({ email, password })
      .then(response => {
        HelperFunctions.updateUserAuthToken(response.data.token);
        return initiateUser();
      })
      .then(currentUser => {
        if (!currentUser.internal_admin) {
          return Promise.reject();
        } else {
          dispatch(actions.appIsReady({ currentUser }));
        }
      });
  };

const unauthenticateUser = () => dispatch => {
  if (localStorage.getItem('token')) {
    return API.signOut().then(() => {
      HelperFunctions.clearUserAuthToken();
      dispatch(actions.unAuthenticateUser());
    });
  } else {
    dispatch(actions.unAuthenticateUser());
  }
};

const prepareAuthenticatedUser = () => dispatch => {
  HelperFunctions.updateUserAuthToken(localStorage.getItem('token'));
  dispatch(actions.prepareAuthenticatedUser());
  return initiateUser()
    .then(currentUser => {
      if (!currentUser.internal_admin) {
        return Promise.reject();
      } else {
        dispatch(actions.appIsReady({ currentUser }));
      }
    })
    .catch(() => {
      dispatch(actions.authenticateUserError());
    });
};

export default {
  signIn,
  unauthenticateUser,
  prepareAuthenticatedUser,
};
