import { Component } from 'react';
import Separator from './Separator';
import Item from './Item';
import Expandable from './Expandable';

class Menu extends Component {
  render() {
    return this.props.children;
  }
}

Menu.Expandable = Expandable;
Menu.Item = Item;
Menu.Separator = Separator;
export default Menu;
