import { combineReducers } from 'redux';
import attachmentReducer from './attachment';
import attachmentVersionReducer from './attachmentVersion';
import attachmentFolderReducer from './attachmentFolder';
import billingInvoiceReducer from './billingInvoice';
import billingAdditionalCostReducer from './billingAdditionalCost';
import billingDiscountReducer from './billingDiscount';
import billingDiscountsReducer from './billingDiscounts';
import billingInvoiceRowReducer from './billingInvoiceRow';
import checklistTemplatesReducer from './checklistTemplate';
import checklistDeviationActionReducer from './checklistDeviationAction';
import checklistTemplateLinkReducer from './checklistTemplateLink';
import checklistTemplatesVersionReducer from './checklistTemplateVersion';
import checklistTemplatesRowReducer from './checklistTemplateRow';
import meterReducer from './meter';
import meterUnitReducer from './meterUnit';
import meterReadingReducer from './meterReading';
import checklistInstanceOptionReducer from './checklistInstanceOption';
import checklistInstanceReducer from './checklistInstance';
import checklistInstanceRowReducer from './checklistInstanceRow';
import contactPersonReducer from './contactPerson';
import costReducer from './cost';
import exportReducer from './export';
import loginCredentialReducer from './loginCredential';
import costOtherCategoryReducer from './costOtherCategory';
import downtimeReducer from './downtime';
import downtimeReasonReducer from './downtimeReason';
import groupReducer from './group';
import imageReducer from './image';
import notificationReducer from './notification';
import assetReducer from './asset';
import assetFieldReducer from './assetField';
import assetTypeReducer from './assetType';
import assetVendorReducer from './assetVendor';
import laborTariffReducer from './laborTariff';
import laborTariffScheduleRuleReducer from './laborTariffScheduleRule';
import permissionProfileReducer from './permissionProfile';
import purchaseOrderReducer from './purchaseOrder';
import purchaseOrderDeliveryReducer from './purchaseOrderDelivery';
import purchaseOrderDeliveryRowReducer from './purchaseOrderDeliveryRow';
import purchaseOrderRowReducer from './purchaseOrderRow';
import purchaseOrderDeliveryMethodReducer from './purchaseOrderDeliveryMethod';
import purchaseOrderPaymentTermReducer from './purchaseOrderPaymentTerm';
import recurringMaintenanceReducer from './recurringMaintenance';
import requestReducer from './request';
import requestAutomationRuleReducer from './requestAutomationRule';
import requestTypeReducer from './requestType';
import requestSelectableAssigneeReducer from './requestSelectableAssignee';
import templateFieldReducer from './templateField';
import templateFieldItemReducer from './templateFieldItem';
import userReducer from './user';
import vendorReducer from './vendor';
import workOrderReducer from './workOrder';
import workOrderPurchaseReducer from './workOrderPurchase';
import workOrderActivityReducer from './workOrderActivity';
import workOrderTypeReducer from './workOrderType';
import workOrderSpentTimeReducer from './workOrderSpentTime';
import workOrderSpentTimeCategoryReducer from './workOrderSpentTimeCategory';
import sparePartReservationReducer from './sparePartReservation';
import sparePartReducer from './sparePart';
import sparePartStockActivityReducer from './sparePartStockActivity';
import sparePartAssetReducer from './sparePartAsset';
import sparePartVendorReducer from './sparePartVendor';
import sparePartTypeReducer from './sparePartType';
import sparePartLocationReducer from './sparePartLocation';
import sparePartUnitReducer from './sparePartUnit';
import sparePartWithdrawalReducer from './sparePartWithdrawal';
import sparePartFieldReducer from './sparePartField';
import stocktakingReducer from './stocktaking';
import stocktakingRowReducer from './stocktakingRow';
import systemReducer from './system';
import systemAccessReducer from './systemAccess';
import organisationReducer from './organisation';

export default combineReducers({
  attachmentById: attachmentReducer,
  attachmentVersionById: attachmentVersionReducer,
  attachmentFolderById: attachmentFolderReducer,
  billingInvoiceById: billingInvoiceReducer,
  billingAdditionalCostById: billingAdditionalCostReducer,
  billingDiscountById: billingDiscountReducer,
  billingDiscountsById: billingDiscountsReducer,
  billingInvoiceRowById: billingInvoiceRowReducer,
  checklistDeviationActionById: checklistDeviationActionReducer,
  checklistTemplateById: checklistTemplatesReducer,
  checklistTemplateLinkById: checklistTemplateLinkReducer,
  checklistTemplateVersionById: checklistTemplatesVersionReducer,
  checklistTemplateRowById: checklistTemplatesRowReducer,
  checklistInstanceOptionById: checklistInstanceOptionReducer,
  checklistInstanceById: checklistInstanceReducer,
  checklistInstanceRowById: checklistInstanceRowReducer,
  contactPersonById: contactPersonReducer,
  costById: costReducer,
  exportById: exportReducer,
  meterById: meterReducer,
  meterUnitById: meterUnitReducer,
  meterReadingById: meterReadingReducer,
  costOtherCategoryById: costOtherCategoryReducer,
  groupById: groupReducer,
  imageById: imageReducer,
  notificationById: notificationReducer,
  assetById: assetReducer,
  laborTariffById: laborTariffReducer,
  laborTariffScheduleRuleById: laborTariffScheduleRuleReducer,
  assetFieldById: assetFieldReducer,
  assetTypeById: assetTypeReducer,
  assetVendorById: assetVendorReducer,
  loginCredentialById: loginCredentialReducer,
  permissionProfileById: permissionProfileReducer,
  purchaseOrderById: purchaseOrderReducer,
  purchaseOrderDeliveryById: purchaseOrderDeliveryReducer,
  purchaseOrderDeliveryRowById: purchaseOrderDeliveryRowReducer,
  purchaseOrderRowById: purchaseOrderRowReducer,
  purchaseOrderDeliveryMethodById: purchaseOrderDeliveryMethodReducer,
  purchaseOrderPaymentTermById: purchaseOrderPaymentTermReducer,
  recurringMaintenanceById: recurringMaintenanceReducer,
  downtimeById: downtimeReducer,
  downtimeReasonById: downtimeReasonReducer,
  requestById: requestReducer,
  requestAutomationRuleById: requestAutomationRuleReducer,
  requestTypeById: requestTypeReducer,
  requestSelectableAssigneeById: requestSelectableAssigneeReducer,
  templateFieldById: templateFieldReducer,
  templateFieldItemById: templateFieldItemReducer,
  userById: userReducer,
  vendorById: vendorReducer,
  workOrderPurchaseById: workOrderPurchaseReducer,
  workOrderById: workOrderReducer,
  workOrderActivityById: workOrderActivityReducer,
  workOrderTypeById: workOrderTypeReducer,
  workOrderSpentTimeById: workOrderSpentTimeReducer,
  workOrderSpentTimeCategoryById: workOrderSpentTimeCategoryReducer,
  sparePartReservationById: sparePartReservationReducer,
  sparePartById: sparePartReducer,
  sparePartStockActivityById: sparePartStockActivityReducer,
  sparePartAssetById: sparePartAssetReducer,
  sparePartVendorById: sparePartVendorReducer,
  sparePartTypeById: sparePartTypeReducer,
  sparePartUnitById: sparePartUnitReducer,
  sparePartFieldById: sparePartFieldReducer,
  sparePartLocationById: sparePartLocationReducer,
  sparePartWithdrawalById: sparePartWithdrawalReducer,
  stocktakingById: stocktakingReducer,
  stocktakingRowById: stocktakingRowReducer,
  systemById: systemReducer,
  systemAccessById: systemAccessReducer,
  organisationById: organisationReducer,
});
