import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeSparePartAsset } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createSparePartAsset(systemId, params) {
  return dispatch => {
    return API.createSparePartAsset(systemId, params)
      .then(res => {
        const { entities } = normalizeSparePartAsset(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_ASSET_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateSparePartAsset(sparePartAssetId, params) {
  return dispatch => {
    return API.updateSparePartAsset(sparePartAssetId, params)
      .then(res => {
        const { entities } = normalizeSparePartAsset(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { sparePartAssetId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_ASSET_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteSparePartAsset(sparePartAssetId) {
  return (dispatch, getState) => {
    const sparePartAsset = getState().entities.sparePartAssetById[sparePartAssetId];
    return API.deleteSparePartAsset(sparePartAssetId)
      .then(res => {
        const payload = { sparePartAssetId, data: sparePartAsset };
        dispatch({ type: types.SPARE_PART_ASSET_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
