import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { omit } from 'lodash-es';
import { InlineModal, TimePicker, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class Time extends Component {
  inputRef = null;

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: this.isTimeStringValid(props.value) ? this.formatTime(props.value).format('HH:mm') : '',
      time: this.isTimeStringValid(props.value) ? this.formatTime(props.value).format('HH:mm') : '',
      lastValidTime: this.isTimeStringValid(props.value) ? this.formatTime(props.value).format('HH:mm') : '',
    };
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.inputRef.select();
    }
  }

  isTimeStringValid = dateString =>
    moment(dateString, 'HH:mm').isValid() || moment(dateString, 'HHmm').isValid();

  formatTime = time => {
    if (moment(time, 'HH:mm').isValid()) return moment(time, 'HH:mm');
    return moment(time, 'HHmm');
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value && this.isTimeStringValid(this.props.value)) {
      this.setState({
        value: this.formatTime(this.props.value).format('HH:mm'),
        time: this.formatTime(this.props.value).format('HH:mm'),
        lastValidTime: this.formatTime(this.props.value).format('HH:mm'),
      });
    }
  }

  renderIcon = () => {
    if (!this.props.icon) return null;
    return (
      <div className={styles['icon-container']}>
        <Icon type={this.props.icon} />
      </div>
    );
  };

  renderRightLabel = () => {
    if (!this.props.rightLabel) return null;
    return (
      <div
        className={[styles['label-container'], styles['right']].join(' ')}
        onClick={() => this.inputRef.focus()}
      >
        {this.props.rightLabel}
      </div>
    );
  };

  onLeaveField = () => {
    this.setState({ focused: false, showTimePicker: false });
    if (!this.state.value) {
      this.setState({
        value: '',
        time: '',
        lastValidTime: '',
      });
      this.props.onChangeTime(null);
      return;
    }
    if (this.isTimeStringValid(this.state.value)) {
      this.setState(
        {
          value: this.formatTime(this.state.value).format('HH:mm'),
          time: this.formatTime(this.state.value).format('HH:mm'),
          lastValidTime: this.formatTime(this.state.value).format('HH:mm'),
        },
        () => {
          this.props.onChangeTime(this.state.time);
        }
      );
    } else {
      this.setState(
        {
          value: this.isTimeStringValid(this.state.lastValidTime)
            ? this.formatTime(this.state.lastValidTime).format('HH:mm')
            : '',
          time: this.isTimeStringValid(this.state.lastValidTime)
            ? this.formatTime(this.state.lastValidTime).format('HH:mm')
            : '',
        },
        () => {}
      );
      this.props.onChangeTime(
        this.isTimeStringValid(this.state.lastValidTime)
          ? this.formatTime(this.state.lastValidTime).format('HH:mm')
          : null
      );
    }
  };

  render() {
    let additionalStyles = {};
    let classNames = [styles['input-container']];
    if (this.props.error) {
      classNames = [...classNames, styles['error']];
    }
    if (this.state.focused) {
      classNames = [...classNames, styles['focused']];
    }
    if (this.props.rightLabel) {
      classNames = [...classNames, styles['has-right-label']];
    }

    return (
      <>
        <div className={classNames.join(' ')} ref={ref => (this.inlineModalPositioningRef = ref)}>
          {this.renderIcon()}
          <input
            {...omit(this.props, ['rightLabel', 'leftLabel', 'icon', 'autoFocus'])}
            ref={ref => {
              this.inputRef = ref;
            }}
            value={this.state.value}
            onChange={e => {
              this.setState({
                value: e.target.value,
                time: moment(e.target.value, 'HH:mm').format('HH:mm'),
              });
            }}
            onKeyDown={e => {
              if (e.key === 'Tab') {
                this.onLeaveField();
              }
            }}
            onFocus={e => {
              this.inputRef.select();
              this.setState({ focused: true, showTimePicker: true });
              this.props.onFocus();
            }}
            className={styles['input']}
            style={additionalStyles}
          />
          {this.renderRightLabel()}
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showTimePicker}
          onClose={this.onLeaveField}
        >
          <InlineModal.Body width={380} paddingTop>
            <TimePicker
              time={this.state.time}
              inline
              onSelectHour={value => {
                this.setState({
                  value: moment(value, 'HH:mm').format('HH:mm'),
                  time: moment(value, 'HH:mm').format('HH:mm'),
                  lastValidTime: moment(value, 'HH:mm').format('HH:mm'),
                });
                this.props.onChangeTime(value);
              }}
              onSelectMinute={value => {
                this.setState({
                  value: moment(value, 'HH:mm').format('HH:mm'),
                  time: moment(value, 'HH:mm').format('HH:mm'),
                  lastValidTime: moment(value, 'HH:mm').format('HH:mm'),
                  showTimePicker: false,
                  focused: false,
                });
                this.props.onChangeTime(value);
              }}
            />
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  }
}

Time.propTypes = {
  onChangeTime: PropTypes.func,
  onFocus: PropTypes.func,
  icon: PropTypes.string,
  rightLabel: PropTypes.node,
};
Time.defaultProps = {
  onChangeTime: () => {},
  onFocus: () => {},
};
