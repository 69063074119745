import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';

class Number extends Component {
  renderSubtitle = () => {
    switch (this.props.comparator) {
      case HelperFunctions.FILTER_COMPARABLES.LessThan: {
        return <FormattedMessage id="filters.types.number.less-than.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.LessThanOrEqual: {
        return <FormattedMessage id="filters.types.number.less-than-or-equal.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.MoreThan: {
        return <FormattedMessage id="filters.types.number.more-than.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.MoreThanOrEqual: {
        return <FormattedMessage id="filters.types.number.more-than-or-equal.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.Between: {
        return <FormattedMessage id="filters.types.number.between.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.Exact: {
        return <FormattedMessage id="filters.types.number.exact.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.NotExact: {
        return <FormattedMessage id="filters.types.number.not-exact.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.Exists: {
        if (this.props.hasValue) {
          return <FormattedMessage id="filters.types.text.exists.title" />;
        }
        return <FormattedMessage id="filters.types.number.not-exists.title" />;
      }

      default:
        return null;
    }
  };

  render() {
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Container
        title={this.props.title}
        subtitle={this.renderSubtitle()}
        onClear={() => this.props.onClear()}
      >
        {this.props.children}
      </SideModal.Container.Filter.AppliedFilters.Filter.Container>
    );
  }
}

Number.propTypes = {
  required: PropTypes.bool,
  title: PropTypes.node,
  comparator: PropTypes.string,
  onClear: PropTypes.func,
};

Number.defaultProps = {
  title: null,
  comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
  required: false,
  onClear: () => {},
};

export default Number;
