import { EntitySelectors } from 'sdk/State/entities';

const getUserIds = state => {
  return state.organisation.users.ids;
};

const getSystems = state => {
  const { entities, organisation } = state;
  return organisation.systems.ids.map(id => entities.systemById[id]);
};
const getUsersPagination = state => state.organisation.users.pagination;

const getActiveDiscount = state => {
  const organisation = EntitySelectors.getOrganisation(state, state.organisation.id);

  const billingDiscounts = EntitySelectors.getBillingDiscounts(state, state.organisation.billingDiscounts.ids)
    .filter(discount => discount.tier === organisation.billing_tier)
    .sort((a, b) => {
      return new Date(a.expiration_date).getTime() - new Date(b.expiration_date).getTime();
    });
  const activeDiscount = billingDiscounts.find(discount => {
    const today = new Date();
    const formattedDate = new Date(discount.expiration_date);

    if (formattedDate.getTime() > today.getTime()) {
      return formattedDate;
    }
    return activeDiscount;
  });
  return activeDiscount;
};

const getBillingDiscountIds = state => {
  return state.organisation.billingDiscounts.ids;
};
const getBillingAdditionalCostIds = state => {
  return state.organisation.billingAdditionalCosts.ids;
};

export default {
  getActiveDiscount,
  getUserIds,
  getSystems,
  getUsersPagination,
  getBillingDiscountIds,
  getBillingAdditionalCostIds,
};
