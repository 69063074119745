import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

class Column extends Component {
  render() {
    let classNames = [styles['column']];
    if (this.props.flex) classNames = [...classNames, styles['flexable']];
    if (this.props.alignRight) classNames = [...classNames, styles['align-right']];
    return (
      <div className={classNames.join(' ')} style={{ width: this.props.width }}>
        {this.props.children}
      </div>
    );
  }
}

export default Column;

Column.propTypes = {
  width: PropTypes.any,
  flex: PropTypes.bool,
  alignRight: PropTypes.bool,
};
Column.defaultProps = {
  width: 'auto',
  flex: false,
  alignRight: false,
};
