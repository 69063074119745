import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const deleteAttachmentVersion = attachmentVersionId =>
  request.delete(`attachment_versions/${attachmentVersionId}`);
export const updateAttachmentVersion = (attachmentVersionId, data) =>
  request.patch(`attachment_versions/${attachmentVersionId}`, data);
export const attachmentVersionUploaded = attachmentVersionId =>
  request.put(`attachment_versions/${attachmentVersionId}/uploaded`);
export const createAttachmentVersionForAttachment = (attachmentId, data) =>
  request.post(`attachments/${attachmentId}/attachment_versions`, data);
export const listAttachmentVersionsForAttachment = (attachmentId, params) =>
  request.get(
    `attachments/${attachmentId}/attachment_versions?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
