import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Container from 'views/components/Shared/Layout/SideModal/Container/Filter/Detail/Types/Container';

class NotExists extends Component {
  render() {
    return (
      <Container
        selected={this.props.selected}
        title={<FormattedMessage id="filters.types.text.not-exists.title" />}
        subtitle={<FormattedMessage id="filters.types.text.not-exists.subtitle" />}
        onClick={this.props.onClick}
      />
    );
  }
}

NotExists.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

NotExists.defaultProps = {
  selected: false,
  onClick: () => {},
};

export default NotExists;
