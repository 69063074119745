import React, { Component } from 'react';
import { Col as GridSystemColumn } from 'react-grid-system';

class Column extends Component {
  render() {
    return <GridSystemColumn {...this.props}>{this.props.children}</GridSystemColumn>;
  }
}

export default Column;
