import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styles from './style.module.scss';

class FullScreenOverlayModal extends Component {
  render() {
    let classNames = [styles['modal']];
    let overlayClassNames = [styles['overlay']];
    return (
      <ReactModal
        contentRef={ref => (this.contentRef = ref)}
        appElement={document.getElementById('content')}
        isOpen={this.props.open}
        bodyOpenClassName="OverlayModal__Body--open"
        overlayClassName={overlayClassNames.join(' ')}
        portalClassName="FullScreenModal"
        className={classNames.join(' ')}
        closeTimeoutMS={150}
      >
        {this.props.children}
      </ReactModal>
    );
  }
}

export default FullScreenOverlayModal;

FullScreenOverlayModal.propTypes = {
  open: PropTypes.bool,
};

FullScreenOverlayModal.defaultProps = {
  open: false,
};
