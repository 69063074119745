import EntityOperations from 'sdk/State/entities/operations';
import { normalizeMeter } from 'sdk/Schemas';
import * as API from './api';
import * as types from './reduxTypes';

export function createMeter(systemId, params) {
  return dispatch => {
    return API.createMeter(systemId, params)
      .then(res => {
        const { entities } = normalizeMeter(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.METER_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteMeter(meterId) {
  return dispatch => {
    return API.deleteMeter(meterId)
      .then(() => {
        const payload = { meterId };
        dispatch({ type: types.METER_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateMeter(meterId, params) {
  return dispatch => {
    return API.updateMeter(meterId, params)
      .then(res => {
        const { entities } = normalizeMeter(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { meterId, params, data: res.data };
        dispatch({ type: types.METER_UPDATED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
