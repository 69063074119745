import React from 'react';
import styles from './style.module.scss';
import Row from './Row';

const DataList = ({ children }) => {
  return <div className={styles['table-data']}>{children}</div>;
};

DataList.Row = Row;
export default DataList;
