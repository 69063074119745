import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeSparePartLocation } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createSparePartLocation(systemId, params) {
  return dispatch => {
    return API.createSparePartLocation(systemId, params)
      .then(res => {
        const { entities } = normalizeSparePartLocation(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_LOCATION_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateSparePartLocation(sparePartLocationId, params) {
  return dispatch => {
    return API.updateSparePartLocation(sparePartLocationId, params)
      .then(res => {
        const { entities } = normalizeSparePartLocation(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { sparePartLocationId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_LOCATION_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteSparePartLocation(sparePartLocationId) {
  return dispatch => {
    return API.deleteSparePartLocation(sparePartLocationId)
      .then(res => {
        const payload = { sparePartLocationId };
        dispatch({ type: types.SPARE_PART_LOCATION_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
