import { omit } from 'lodash-es';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.SPARE_PART_RESERVATION_DELETED: {
      const { sparePartReservationId } = action.payload;

      return omit(state, sparePartReservationId);
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.sparePartReservationById) {
        return HelperFunctions.mergeEntities(state, action.payload.sparePartReservationById);
      }
      return state;
    default:
      return state;
  }
};
