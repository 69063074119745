import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeMeterUnit } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createMeterUnit(systemId, params) {
  return dispatch => {
    return API.createMeterUnit(systemId, params)
      .then(res => {
        const { entities } = normalizeMeterUnit(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.METER_UNIT_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateMeterUnit(meterUnitId, params) {
  return dispatch => {
    return API.updateMeterUnit(meterUnitId, params)
      .then(res => {
        const { entities } = normalizeMeterUnit(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { meterUnitId, params, data: res.data };
        dispatch({ type: types.METER_UNIT_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteMeterUnit(meterUnitId) {
  return dispatch => {
    return API.deleteMeterUnit(meterUnitId)
      .then(res => {
        const payload = { meterUnitId };
        dispatch({ type: types.METER_UNIT_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
