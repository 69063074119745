import { pickBy } from 'lodash-es';

const getAttachmentFolder = (state, attachmentFolderId) => {
  if (Object.keys(state.entities.attachmentFolderById).length === 0) return null;
  return state.entities.attachmentFolderById[attachmentFolderId];
};
const getAttachmentFoldersForFolder = (state, attachmentFolderId) => {
  if (Object.keys(state.entities.attachmentFolderById).length === 0) return [];
  if (attachmentFolderId == null) {
    return Object.values(
      pickBy(
        state.entities.attachmentFolderById,
        attachmentFolder => attachmentFolder.attachment_folder_parent_id == null
      )
    );
  }
  return Object.values(
    pickBy(
      state.entities.attachmentFolderById,
      attachmentFolder => attachmentFolder.attachment_folder_parent_id === attachmentFolderId
    )
  );
};

const getAttachmentFolders = state => {
  if (Object.keys(state.entities.attachmentFolderById).length === 0) return [];
  return Object.values(state.entities.attachmentFolderById);
};
const getChecklistDeviationAction = (state, id) =>
  id ? state.entities.checklistDeviationActionById[id] : null;
const getChecklistDeviationActions = (state, ids) =>
  ids ? ids.map(id => state.entities.checklistDeviationActionById[id]) : null;
const getChecklistInstanceOption = (state, id) =>
  id ? state.entities.checklistInstanceOptionById[id] : null;
const getChecklistInstanceOptions = (state, ids) =>
  ids ? ids.map(id => state.entities.checklistInstanceOptionById[id]) : null;
const getChecklistTemplate = (state, id) => (id ? state.entities.checklistTemplateById[id] : null);
const getChecklistTemplates = (state, ids) =>
  ids ? ids.map(id => state.entities.checklistTemplateById[id]) : null;
const getChecklistTemplateLink = (state, id) => (id ? state.entities.checklistTemplateLinkById[id] : null);
const getChecklistTemplateLinks = (state, ids) =>
  ids ? ids.map(id => state.entities.checklistTemplateLinkById[id]) : null;
const getChecklistTemplateVersion = (state, id) =>
  id ? state.entities.checklistTemplateVersionById[id] : null;
const getChecklistTemplateRow = (state, id) => (id ? state.entities.checklistTemplateRowById[id] : null);
const getChecklistTemplateRows = (state, ids) =>
  ids ? ids.map(id => state.entities.checklistTemplateRowById[id]) : null;
const getChecklistInstance = (state, id) => (id ? state.entities.checklistInstanceById[id] : null);
const getChecklistInstances = (state, ids) =>
  ids ? ids.map(id => state.entities.checklistInstanceById[id]) : null;
const getChecklistInstanceRow = (state, id) => (id ? state.entities.checklistInstanceRowById[id] : null);
const getChecklistInstanceRows = (state, ids) =>
  ids ? ids.map(id => state.entities.checklistInstanceRowById[id]) : null;
const getContactPersons = (state, ids) => (ids ? ids.map(id => state.entities.contactPersonById[id]) : null);
const getContactPerson = (state, id) => (id ? state.entities.contactPersonById[id] : null);

const getCosts = (state, ids) =>
  ids ? ids.map(id => state.entities.costById[id]).filter(({ _deleted }) => _deleted !== true) : null;
const getCost = (state, id) => (id ? state.entities.costById[id] : null);

const getCostOtherCategories = (state, ids) =>
  ids ? ids.map(id => state.entities.costOtherCategoryById[id]) : null;
const getCostOtherCategory = (state, id) => (id ? state.entities.costOtherCategoryById[id] : null);

const getLaborTariffs = (state, ids) => (ids ? ids.map(id => state.entities.laborTariffById[id]) : null);
const getLaborTariff = (state, id) => (id ? state.entities.laborTariffById[id] : null);

const getLaborTariffScheduleRules = (state, ids) =>
  ids ? ids.map(id => state.entities.laborTariffScheduleRuleById[id]) : null;
const getLaborTariffScheduleRule = (state, id) =>
  id ? state.entities.laborTariffScheduleRuleById[id] : null;

const getDowntimes = (state, ids) => (ids ? ids.map(id => state.entities.downtimeById[id]) : null);
const getDowntime = (state, id) => (id ? state.entities.downtimeById[id] : null);

const getDowntimeReasons = (state, ids) =>
  ids ? ids.map(id => state.entities.downtimeReasonById[id]) : null;
const getDowntimeReason = (state, id) => (id ? state.entities.downtimeReasonById[id] : null);

const getWorkOrder = (state, id) => (id ? state.entities.workOrderById[id] : null);
const getWorkOrders = (state, ids) => (ids ? ids.map(id => state.entities.workOrderById[id]) : null);
const getSparePart = (state, id) => (id ? state.entities.sparePartById[id] : null);
const getSpareParts = (state, ids) => (ids ? ids.map(id => state.entities.sparePartById[id]) : null);
const getSparePartStockActivity = (state, id) => (id ? state.entities.sparePartStockActivityById[id] : null);
const getSparePartStockActivities = (state, ids) =>
  ids ? ids.map(id => state.entities.sparePartStockActivityById[id]) : null;
const getSparePartAsset = (state, id) => (id ? state.entities.sparePartAssetById[id] : null);
const getSparePartVendor = (state, id) => (id ? state.entities.sparePartVendorById[id] : null);
const getSparePartVendors = (state, ids) =>
  ids ? ids.map(id => state.entities.sparePartVendorById[id]) : null;

const getSparePartType = (state, id) => (id ? state.entities.sparePartTypeById[id] : null);
const getSparePartTypes = (state, ids) => (ids ? ids.map(id => state.entities.sparePartTypeById[id]) : null);
const getSparePartLocation = (state, id) => (id ? state.entities.sparePartLocationById[id] : null);
const getSparePartFields = (state, ids) =>
  ids ? ids.map(id => state.entities.sparePartFieldById[id]) : null;
const getSparePartUnit = (state, id) => (id ? state.entities.sparePartUnitById[id] : null);
const getSparePartUnits = (state, ids) => (ids ? ids.map(id => state.entities.sparePartUnitById[id]) : null);

const getSparePartWithdrawals = (state, ids) =>
  ids ? ids.map(id => state.entities.sparePartWithdrawalById[id]) : null;
const getSparePartWithdrawal = (state, id) => (id ? state.entities.sparePartWithdrawalById[id] : null);

const getSparePartReservations = (state, ids) =>
  ids ? ids.map(id => state.entities.sparePartReservationById[id]) : null;
const getSparePartReservation = (state, id) => (id ? state.entities.sparePartReservationById[id] : null);

const getSystemAccesses = (state, ids) => (ids ? ids.map(id => state.entities.systemAccessById[id]) : null);
const getSystemAccess = (state, id) => (id ? state.entities.systemAccessById[id] : null);

/*
  Spare Part locations
*/
const getSparePartLocations = state => {
  if (Object.keys(state.entities.sparePartLocationById).length === 0) return [];
  return Object.values(state.entities.sparePartLocationById);
};
const getSparePartLocationsForLocation = (state, id) => {
  if (Object.keys(state.entities.sparePartLocationById).length === 0) return [];
  if (id == null) {
    return Object.values(
      pickBy(
        state.entities.sparePartLocationById,
        sparePartLocation => sparePartLocation.spare_part_location_parent_id == null
      )
    );
  }
  return Object.values(
    pickBy(
      state.entities.sparePartLocationById,
      sparePartLocation => sparePartLocation.spare_part_location_parent_id === id
    )
  );
};
const getSparePartLocationPathForSparePartLocationId = (state, sparePartLocationId) => {
  const allSparePartLocations = getSparePartLocations(state);
  const sparePartLocation = allSparePartLocations.find(
    sparePartLocation => sparePartLocationId === sparePartLocation.id
  );
  if (sparePartLocation == null) return [];
  let sparePartLocations = [sparePartLocation];
  allSparePartLocations.forEach(loopedSparePartLocation => {
    if (sparePartLocation.spare_part_location_parent_id === loopedSparePartLocation.id) {
      sparePartLocations = [
        ...getSparePartLocationPathForSparePartLocationId(state, loopedSparePartLocation.id),
        ...sparePartLocations,
      ];
    }
  });
  return sparePartLocations;
};

const getStocktaking = (state, id) => (id ? state.entities.stocktakingById[id] : null);
const getStocktakings = (state, ids) =>
  ids ? ids.map(id => state.entities.stocktakingById[id]).filter(obj => obj != null) : null;
const getStocktakingRow = (state, id) => (id ? state.entities.stocktakingRowById[id] : null);

const getImagesForWorkOrder = (state, workOrderId) => {
  const { entities } = state;
  const { images } = entities.workOrderById[workOrderId];
  if (!images) return [];
  return images.map(id => entities.imageById[id]);
};
const getAttachmentsForWorkOrder = (state, workOrderId) => {
  const { entities } = state;
  const attachments = entities.workOrderById[workOrderId].registration_attachments;
  if (!attachments) return [];
  return attachments.map(id => entities.attachmentById[id]).filter(val => val);
};
const getRequestForWorkOrder = (state, workOrderId) => {
  const { entities } = state;
  const { requests } = entities.workOrderById[workOrderId];
  if (!requests || requests.length === 0) return null;
  return entities.requestById[requests[0]];
};

const getRequestSelectableAssignee = (state, id) =>
  id ? state.entities.requestSelectableAssigneeById[id] : null;

const getRequestAutomationRule = (state, id) => (id ? state.entities.requestAutomationRuleById[id] : null);

const getWorkOrderActivity = (state, id) => (id ? state.entities.workOrderActivityById[id] : null);
const getWorkOrderActivities = (state, ids) => {
  const { entities } = state;
  return ids ? ids.map(id => entities.workOrderActivityById[id]) : null;
};
const getWorkOrderType = (state, id) => (id ? state.entities.workOrderTypeById[id] : null);
const getWorkOrderSpentTimes = (state, ids) =>
  ids ? ids.map(id => state.entities.workOrderSpentTimeById[id]) : null;
const getWorkOrderSpentTime = (state, id) => (id ? state.entities.workOrderSpentTimeById[id] : null);

const getWorkOrderSpentTimeCategories = (state, ids) =>
  ids ? ids.map(id => state.entities.workOrderSpentTimeCategoryById[id]) : null;
const getWorkOrderSpentTimeCategory = (state, id) =>
  id ? state.entities.workOrderSpentTimeCategoryById[id] : null;

const getWorkOrderPurchase = (state, id) => (id ? state.entities.workOrderPurchaseById[id] : null);
const getWorkOrderPurchases = (state, ids) => {
  return ids ? ids.map(id => state.entities.workOrderPurchaseById[id]) : null;
};

const getRequest = (state, id) => (id ? state.entities.requestById[id] : null);
const getRequests = (state, ids) => {
  return ids ? ids.map(id => state.entities.requestById[id]) : null;
};
const getRequestType = (state, id) => (id ? state.entities.requestTypeById[id] : null);
const getRequestTypes = (state, ids) => {
  return ids ? ids.map(id => state.entities.requestTypeById[id]) : null;
};
const getMeterUnit = (state, id) => (id ? state.entities.meterUnitById[id] : null);
const getMeterReading = (state, id) => (id ? state.entities.meterReadingById[id] : null);
const getMeter = (state, id) => (id ? state.entities.meterById[id] : null);
const getMeters = (state, ids) => {
  return ids ? ids.map(id => state.entities.meterById[id]) : null;
};
const getSystem = (state, id) => (id ? state.entities.systemById[id] : null);
const getSystems = (state, ids) => {
  return ids ? ids.map(id => state.entities.systemById[id]) : null;
};

const getOrganisation = (state, id) => (id ? state.entities.organisationById[id] : null);
const getOrganisations = (state, ids) => {
  return ids ? ids.map(id => state.entities.organisationById[id]) : null;
};

const getPermissionProfile = (state, permissionProfileId) =>
  state.entities.permissionProfileById[permissionProfileId];

const getPurchaseOrder = (state, purchaseOrderId) => state.entities.purchaseOrderById[purchaseOrderId];
const getPurchaseOrders = (state, ids) => {
  return ids ? ids.map(id => state.entities.purchaseOrderById[id]) : null;
};

const getPurchaseOrderRows = (state, ids) => {
  return ids ? ids.map(id => state.entities.purchaseOrderRowById[id]) : null;
};
const getPurchaseOrderRow = (state, purchaseOrderRowId) =>
  state.entities.purchaseOrderRowById[purchaseOrderRowId];

const getPurchaseOrderDeliveryMethod = (state, purchaseOrderDeliveryMethodId) =>
  state.entities.purchaseOrderDeliveryMethodById[purchaseOrderDeliveryMethodId];
const getPurchaseOrderDeliveryMethods = (state, ids) =>
  ids ? ids.map(id => state.entities.purchaseOrderDeliveryMethodById[id]) : null;

const getPurchaseOrderDelivery = (state, id) => state.entities.purchaseOrderDeliveryById[id];
const getPurchaseOrderDeliveries = (state, ids) => {
  const { entities } = state;
  return ids ? ids.map(id => entities.purchaseOrderDeliveryById[id]) : null;
};
const getPurchaseOrderDeliveryRow = (state, id) => state.entities.purchaseOrderDeliveryRowById[id];
const getPurchaseOrderDeliveryRows = (state, ids) => {
  const { entities } = state;
  return ids ? ids.map(id => entities.purchaseOrderDeliveryRowById[id]) : null;
};

const getPurchaseOrderPaymentTerm = (state, purchaseOrderPaymentTermId) =>
  state.entities.purchaseOrderPaymentTermById[purchaseOrderPaymentTermId];
const getPurchaseOrderPaymentTerms = (state, ids) =>
  ids ? ids.map(id => state.entities.purchaseOrderPaymentTermById[id]) : null;

const getAsset = (state, assetId) => state.entities.assetById[assetId];
const getAssetType = (state, assetTypeId) => state.entities.assetTypeById[assetTypeId];
const getAssetTypes = (state, ids) => {
  const { entities } = state;
  return ids ? ids.map(id => entities.assetTypeById[id]) : null;
};
const getAssetVendor = (state, id) => state.entities.assetVendorById[id];
const getAssetFields = (state, ids) => (ids ? ids.map(id => state.entities.assetFieldById[id]) : null);
const getTemplateFields = (state, ids) => (ids ? ids.map(id => state.entities.templateFieldById[id]) : null);
const getTemplateField = (state, id) => state.entities.templateFieldById[id];

const getTemplateFieldItem = (state, id) => (id ? state.entities.templateFieldItemById[id] : null);
const getTemplateFieldItems = (state, ids) =>
  ids
    ? ids
        .map(id => state.entities.templateFieldItemById[id])
        .sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          }
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          return 0;
        })
    : null;

const getUser = (state, id) => (id ? state.entities.userById[id] : null);
const getUsers = (state, ids) => (ids ? ids.map(id => state.entities.userById[id]) : null);
const getGroup = (state, id) => (id ? state.entities.groupById[id] : null);
const getGroups = (state, ids) => (ids ? ids.map(id => state.entities.groupById[id]) : null);
const getVendor = (state, id) => (id ? state.entities.vendorById[id] : null);
const getVendors = (state, ids) => (ids ? ids.map(id => state.entities.vendorById[id]) : null);

const getRecurringMaintenance = (state, id) => (id ? state.entities.recurringMaintenanceById[id] : null);
const getAttachmentVersion = (state, id) => (id ? state.entities.attachmentVersionById[id] : null);

const getImage = (state, id) => (id ? state.entities.imageById[id] : null);
const getImages = (state, ids) => (ids ? ids.map(id => state.entities.imageById[id]) : null);

const getAttachment = (state, id) => (id ? state.entities.attachmentById[id] : null);
const getAttachments = (state, ids) =>
  ids ? ids.map(id => state.entities.attachmentById[id]).filter(val => val) : [];

const getAssets = (state, ids, opts = {}) => {
  if (!ids) return null;
  return ids
    .map(id => state.entities.assetById[id])
    .filter(obj => obj._deleted !== true || opts.includeDeleted);
};

const getTreePathForAsset = (state, assetId) => {
  const { entities } = state;
  const asset = entities.assetById[assetId];
  if (!asset) return [];
  const treePath = entities.assetById[assetId].tree_path;
  if (!treePath) return [];
  return treePath.map(id => entities.assetById[id]);
};

const getBillingInvoice = (state, id) => (id ? state.entities.billingInvoiceById[id] : null);
const getBillingInvoiceRows = (state, ids) =>
  ids ? ids.map(id => state.entities.billingInvoiceRowById[id]) : [];
const getBillingAdditionalCost = (state, id) => (id ? state.entities.billingAdditionalCostById[id] : null);
const getBillingAdditionalCosts = (state, ids) =>
  ids ? ids.map(id => state.entities.billingAdditionalCostById[id]) : [];
const getBillingDiscount = (state, id) => (id ? state.entities.billingDiscountById[id] : null);
const getBillingDiscounts = (state, ids) =>
  ids ? ids.map(id => state.entities.billingDiscountById[id]) : [];

const getLoginCredential = (state, id) => (id ? state.entities.loginCredentialById[id] : null);

export default {
  getAttachmentFolder,
  getAttachmentFoldersForFolder,
  getAttachmentFolders,
  getAttachmentVersion,
  getAttachment,
  getAttachments,
  getChecklistDeviationAction,
  getChecklistDeviationActions,
  getChecklistTemplateLink,
  getChecklistTemplateLinks,
  getChecklistTemplate,
  getChecklistTemplates,
  getChecklistTemplateVersion,
  getChecklistTemplateRow,
  getChecklistTemplateRows,
  getChecklistInstance,
  getChecklistInstances,
  getChecklistInstanceOption,
  getChecklistInstanceOptions,
  getChecklistInstanceRow,
  getChecklistInstanceRows,
  getCosts,
  getCost,
  getCostOtherCategories,
  getCostOtherCategory,
  getContactPersons,
  getContactPerson,
  getDowntimes,
  getDowntime,
  getDowntimeReason,
  getDowntimeReasons,
  getAssetVendor,
  getWorkOrder,
  getWorkOrders,
  getSparePart,
  getSparePartStockActivity,
  getLaborTariffs,
  getLaborTariff,
  getLaborTariffScheduleRules,
  getLaborTariffScheduleRule,
  getSparePartStockActivities,
  getSpareParts,
  getSparePartAsset,
  getSparePartVendor,
  getSparePartVendors,
  getSparePartType,
  getSparePartTypes,
  getSparePartFields,
  getSparePartLocation,
  getSparePartLocations,
  getSparePartLocationsForLocation,
  getSparePartLocationPathForSparePartLocationId,
  getSparePartUnit,
  getSparePartUnits,
  getSparePartWithdrawals,
  getSparePartWithdrawal,
  getStocktaking,
  getStocktakings,
  getStocktakingRow,
  getSystemAccesses,
  getSystemAccess,
  getImagesForWorkOrder,
  getAttachmentsForWorkOrder,
  getRequestForWorkOrder,
  getRequestSelectableAssignee,
  getWorkOrderActivity,
  getWorkOrderActivities,
  getWorkOrderType,
  getWorkOrderSpentTime,
  getWorkOrderSpentTimes,
  getWorkOrderSpentTimeCategories,
  getWorkOrderSpentTimeCategory,
  getSparePartReservations,
  getSparePartReservation,
  getRecurringMaintenance,
  getAsset,
  getAssetType,
  getAssetTypes,
  getTemplateField,
  getTemplateFields,
  getTemplateFieldItem,
  getTemplateFieldItems,
  getAssetFields,
  getGroup,
  getGroups,
  getVendor,
  getVendors,
  getAssets,
  getOrganisation,
  getOrganisations,
  getTreePathForAsset,
  getPurchaseOrder,
  getPurchaseOrders,
  getWorkOrderPurchase,
  getWorkOrderPurchases,
  getPurchaseOrderRows,
  getPurchaseOrderRow,
  getPurchaseOrderDeliveryMethods,
  getPurchaseOrderDeliveryMethod,
  getPurchaseOrderDelivery,
  getPurchaseOrderDeliveries,
  getPurchaseOrderDeliveryRow,
  getPurchaseOrderDeliveryRows,
  getPurchaseOrderPaymentTerms,
  getPurchaseOrderPaymentTerm,
  getPermissionProfile,
  getSystem,
  getSystems,
  getLoginCredential,
  getUser,
  getUsers,
  getRequest,
  getRequests,
  getRequestType,
  getRequestTypes,
  getRequestAutomationRule,
  getMeter,
  getMeters,
  getMeterUnit,
  getMeterReading,
  getImage,
  getImages,
  getBillingInvoice,
  getBillingInvoiceRows,
  getBillingDiscount,
  getBillingDiscounts,
  getBillingAdditionalCost,
  getBillingAdditionalCosts,
};
