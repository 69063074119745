import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listSparePartVendors = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/spare_part_vendors?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const createSparePartVendor = (systemId, data) =>
  request.post(`systems/${systemId}/spare_part_vendors`, data);
export const fetchSparePartVendor = sparePartIdVendorId =>
  request.get(`spare_part_vendors/${sparePartIdVendorId}`);
export const updateSparePartVendor = (sparePartIdVendorId, data) =>
  request.patch(`spare_part_vendors/${sparePartIdVendorId}`, data);
export const deleteSparePartVendor = sparePartIdVendorId =>
  request.delete(`spare_part_vendors/${sparePartIdVendorId}`);
