import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { AuthOperations, AuthSelectors } from 'state/ducks/auth';
import { Button } from 'views/components/Shared/General';
import Logo from 'assets/images/Logo.png';
import HelperFunctions from 'utilities/HelperFunctions';
import styles from './style.module.scss';
import Card from './Card';
import BackgroundLogo from './BackgroundLogo';

class Login extends Component {
  constructor(props) {
    super(props);

    console.log('process.env.USERNAME', process.env.USERNAME);
    this.state = {
      email: process.env.REACT_APP_USERNAME || '',
      password: process.env.REACT_APP_PASSWORD || '',
      showLoginError: false,
      isLoggingIn: false,
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle('Login');
  }

  login = () => {
    this.setState({ isLoggingIn: true });
    const { email, password } = this.state;
    this.props
      .signIn({
        email,
        password,
      })
      .catch(() => {
        this.setState({ showLoginError: true, isLoggingIn: false });
      });
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    if (this.props.authenticated) {
      return <Redirect to={'/'} />;
    }
    return (
      <BackgroundLogo hideLogo>
        <div className={`${styles['form-container']}`}>
          <Card>
            <div className={styles['title']}>
              <img src={Logo} alt="" />
              <span>Sign in to AM Service</span>
            </div>
            <div className={styles['info-wrapper']}>
              {this.state.showLoginError ? (
                <div className={styles['wrong-user-info']}>The username or password is not correct</div>
              ) : null}
              <div className={styles['input-container']}>
                <div className={styles['field']}>
                  <input
                    type="email"
                    className={styles['input']}
                    autoFocus
                    placeholder="Email"
                    autoComplete="new-password"
                    value={this.state.email}
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className={styles['input-container']}>
                <div className={styles['field']}>
                  <input
                    type="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    className={styles['input']}
                    value={this.state.password}
                    onChange={e => {
                      this.setState({ password: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className={styles['separator']} />
              <Button
                label="Sign in"
                primary
                translate={false}
                fullWidth
                disabled={this.state.email.length === 0}
                loading={this.state.isLoggingIn}
                onClick={this.login}
              />
            </div>
          </Card>
        </div>
      </BackgroundLogo>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: AuthSelectors.isAuthenticated(state),
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signIn: AuthOperations.signIn }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

Login.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

Login.defaultProps = {
  authenticated: false,
  error: false,
};
