import React, { Component } from 'react';
import { Field } from 'views/components/Shared/General';
import { SystemTier } from 'sdk/System';

class TierDropdown extends Component {
  state = {
    tier: this.props.currentTier || SystemTier.Pro,
  };
  renderTierDropdownItems = () => {
    return (
      <>
        <Field.Dropdown.Item
          selected={this.state.tier === SystemTier.Enterprise}
          onClick={() => {
            this.props.onSelectedTier(SystemTier.Enterprise);
            this.setState({
              tier: SystemTier.Enterprise,
            });
          }}
        >
          {SystemTier.Enterprise}
        </Field.Dropdown.Item>
        <Field.Dropdown.Item
          selected={this.state.tier === SystemTier.Pro}
          onClick={() => {
            this.props.onSelectedTier(SystemTier.Pro);
            this.setState({
              tier: SystemTier.Pro,
            });
          }}
        >
          {SystemTier.Pro}
        </Field.Dropdown.Item>
        <Field.Dropdown.Item
          selected={this.state.tier === SystemTier.Basic}
          onClick={() => {
            this.props.onSelectedTier(SystemTier.Basic);
            this.setState({
              tier: SystemTier.Basic,
            });
          }}
        >
          {SystemTier.Basic}
        </Field.Dropdown.Item>
      </>
    );
  };

  renderSelectedTier = () => {
    if (this.state.tier === SystemTier.Enterprise) {
      return SystemTier.Enterprise;
    }
    if (this.state.tier === SystemTier.Pro) {
      return SystemTier.Pro;
    }
    return SystemTier.Basic;
  };

  render() {
    return (
      <Field label="Tier">
        <Field.Dropdown clearable={false} value={this.renderSelectedTier()}>
          {this.renderTierDropdownItems()}
        </Field.Dropdown>
      </Field>
    );
  }
}

export default TierDropdown;
