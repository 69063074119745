import React, { Component } from 'react';

import { toast } from 'react-hot-toast';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';

class BillingFrequencyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billing_frequency: this.props.organisation.billing_frequency,
      isSaving: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  save = () => {
    this.setState({ isSaving: true });
    const billing_frequency = this.state.billing_frequency;

    this.props
      .updateOrganisationAsAdmin(this.props.organisation.id, {
        billing_frequency: billing_frequency,
      })
      .then(() => {
        this.setState({ isSaving: false });
        toast(() => <ToastMessage success text="Billing frequency has been saved" />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
    this.props.onClose();
  };

  renderSelectedFrequency = () => {
    switch (this.state.billing_frequency) {
      case 'monthly':
        return 'Monthly';
      case 'quarterly':
        return 'Quarterly';
      case 'yearly':
        return 'Yearly';
      default:
        return null;
    }
  };

  renderDropdownItems = () => {
    return (
      <>
        <Field.Dropdown.Item
          selected={this.state.billing_frequency === 'monthly'}
          onClick={() => {
            this.setState({
              billing_frequency: 'monthly',
            });
          }}
        >
          Monthly
        </Field.Dropdown.Item>
        <Field.Dropdown.Item
          selected={this.state.billing_frequency === 'quarterly'}
          onClick={() => {
            this.setState({
              billing_frequency: 'quarterly',
            });
          }}
        >
          Quarterly
        </Field.Dropdown.Item>
        <Field.Dropdown.Item
          selected={this.state.billing_frequency === 'yearly'}
          onClick={() => {
            this.setState({
              billing_frequency: 'yearly',
            });
          }}
        >
          Yearly
        </Field.Dropdown.Item>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={450}>
        <Modal.Header
          ignoreLine
          title="Change billing frequency"
          subtitle="Will be applied to the next invoice"
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field.Dropdown clearable={false} value={this.renderSelectedFrequency()}>
                  {this.renderDropdownItems()}
                </Field.Dropdown>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="Save" loading={this.state.isSaving} onClick={this.save} />
            <Button label="Cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrganisationAsAdmin: SDKReduxOperations.updateOrganisationAsAdmin,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(BillingFrequencyModal)));
