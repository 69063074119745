import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './style.module.scss';

export default class Textarea extends Component {
  render() {
    let classNames = [styles['input']];
    if (this.props.error) {
      classNames = [...classNames, styles['error']];
    }

    return (
      <TextareaAutosize
        minRows={3}
        {...this.props}
        className={classNames.join(' ')}
        onChange={e => this.props.onChange(e.target.value)}
      />
    );
  }
}
