import React, { Component } from 'react';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import { Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export class NewSearchField extends Component {
  state = {
    isFocused: false,
  };

  debouncedSearch = debounce(value => {
    this.props.onDebouncedSearch(value);
  }, 400);

  onChange = value => {
    this.props.onSearch(value);
    if (this.props.debounce) {
      this.debouncedSearch(value);
    }
  };

  rendershowMoreFiltersButton = () => {
    if (!this.props.showMoreFiltersButton) return null;
    return (
      <React.Fragment>
        {this.props.isShowingMoreFilters ? (
          <div className={styles['more-filters']}>
            <div className={styles['separator']} />
            <Button
              type="icon"
              icon={<Icon light size={22} type="angle-up" />}
              small
              onClick={this.props.onClickMoreFilters}
            />
          </div>
        ) : (
          <div className={styles['more-filters']}>
            <div className={styles['separator']} />
            <Button
              type="icon"
              icon={<Icon light size={22} type="angle-down" />}
              small
              onClick={this.props.onClickMoreFilters}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  render() {
    return (
      <div
        className={`
        ${styles['search-container']}
        ${this.state.isFocused ? styles['focused'] : ''}
        ${this.props.againstGrayBackground ? styles['gray-background'] : ''}
        ${this.props.value.length > 0 ? styles['has-value'] : ''}
      `}
      >
        <div className={styles['input-container']}>
          <div className={styles['input-logo']}>
            <Icon regular type="search" />
          </div>
          <input
            className={styles['input']}
            autoFocus={this.props.autoFocus}
            placeholder={this.props.placeholder}
            autoComplete="off"
            ref={this.props.ref}
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => {
              this.setState({ isFocused: false });
              this.props.onBlur();
            }}
            value={this.props.value}
            name="search"
            onChange={e => this.onChange(e.target.value)}
          />
          {this.props.value || this.props.alwaysShowClear ? (
            <div className={styles['clear-container']}>
              <Button
                type="icon"
                icon={<Icon light size={16} type="times" />}
                small
                onClick={this.props.onClear}
              />
            </div>
          ) : null}
        </div>
        {this.rendershowMoreFiltersButton()}
      </div>
    );
  }
}

export default NewSearchField;

NewSearchField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onDebouncedSearch: PropTypes.func,
  debounce: PropTypes.bool,
  againstGrayBackground: PropTypes.bool,
  alwaysShowClear: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  showMoreFiltersButton: PropTypes.bool,
  isShowingMoreFilters: PropTypes.bool,
  onClickMoreFilters: PropTypes.func,
};

NewSearchField.defaultProps = {
  value: '',
  onChange: () => {},
  onBlur: () => {},
  debounce: false,
  alwaysShowClear: false,
  showMoreFiltersButton: false,
  isShowingMoreFilters: false,
  againstGrayBackground: false,
};
