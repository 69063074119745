import React from 'react';
import Text from './Text';
import Resource from './Resource';
import Date from './Date';
import Number from './Number';
import Container from './Container';
import Boolean from './Boolean';

const Filter = ({ children }) => {
  return <div>{children}</div>;
};

Filter.Container = Container;
Filter.Text = Text;
Filter.Resource = Resource;
Filter.Number = Number;
Filter.Date = Date;
Filter.Boolean = Boolean;
export default Filter;
