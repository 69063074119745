import React, { Component } from 'react';
import Search from './Search';
import Tabs from './Tabs';
import styles from './style.module.scss';

export default class Header extends Component {
  render() {
    return <div className={styles['header']}>{this.props.children}</div>;
  }
}

Header.Search = Search;
Header.Tabs = Tabs;
