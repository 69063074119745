import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeAssetType } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createAssetType(systemId, params) {
  return dispatch => {
    return API.createAssetType(systemId, params)
      .then(res => {
        const { entities } = normalizeAssetType(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.ASSET_TYPE_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateAssetType(assetTypeId, params) {
  return dispatch => {
    return API.updateAssetType(assetTypeId, params)
      .then(res => {
        const { entities } = normalizeAssetType(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { assetTypeId, params, data: res.data };
        dispatch({ type: types.ASSET_TYPE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteAssetType(assetTypeId) {
  return dispatch => {
    return API.deleteAssetType(assetTypeId)
      .then(res => {
        const payload = { assetTypeId };
        dispatch({ type: types.ASSET_TYPE_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
