import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizePurchaseOrderDeliveryMethod } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createPurchaseOrderDeliveryMethod(systemId, params) {
  return dispatch => {
    return API.createPurchaseOrderDeliveryMethod(systemId, params)
      .then(res => {
        const { entities } = normalizePurchaseOrderDeliveryMethod(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.PURCHASE_ORDER_DELIVERY_METHOD_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updatePurchaseOrderDeliveryMethod(purchaseOrderDeliveryMethodId, params) {
  return dispatch => {
    return API.updatePurchaseOrderDeliveryMethod(purchaseOrderDeliveryMethodId, params)
      .then(res => {
        const { entities } = normalizePurchaseOrderDeliveryMethod(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { purchaseOrderDeliveryMethodId, params, data: res.data };
        dispatch({ type: types.PURCHASE_ORDER_DELIVERY_METHOD_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deletePurchaseOrderDeliveryMethod(purchaseOrderDeliveryMethodId) {
  return dispatch => {
    return API.deletePurchaseOrderDeliveryMethod(purchaseOrderDeliveryMethodId)
      .then(res => {
        const payload = { purchaseOrderDeliveryMethodId };
        dispatch({ type: types.PURCHASE_ORDER_DELIVERY_METHOD_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
