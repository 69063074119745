import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import Header from './Header';
import Content from './Content';
import Loader from './Loader';
import Footer from './Footer';
import ColumnLayout from './ColumnLayout';
import styles from './style.module.scss';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingModalId: null,
      openedModalIds: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.nestedModalContainer) {
      if (!prevProps.isOpen && this.props.isOpen) {
        const defaultModalId = this.props.children[0].props.nestedModalId;
        this.setState({ showingModalId: defaultModalId });
      } else if (prevState.showingModalId !== this.state.showingModalId) {
        this.contentRef.style.overflow = 'hidden';
        const openedFirstNestedModal = prevState.showingModalId == null;
        if (openedFirstNestedModal) {
          this.contentRef.style.height = null;
          this.setState({
            openedModalIds: [...this.state.openedModalIds, this.state.showingModalId],
          });
        } else {
          setTimeout(() => {
            const justOpenedNestedModal = !this.state.openedModalIds.includes(this.state.showingModalId);
            if (justOpenedNestedModal) {
              this.setState({
                openedModalIds: [...this.state.openedModalIds, this.state.showingModalId],
              });
            }
          }, 0);
          setTimeout(() => {
            if (this.contentRef) {
              this.contentRef.style.overflow = 'visible';
            }
          }, 250);
        }
      } else if (prevProps.isOpen && !this.props.isOpen) {
        this.setState({ showingModalId: null, openedModalIds: [] });
      }
    }
  }

  goToId = modalId => {
    this.setState({ showingModalId: modalId });
  };

  goBackToId = modalId => {
    let openedModalIds = [...this.state.openedModalIds];
    openedModalIds.pop();
    this.setState({ showingModalId: modalId, openedModalIds });
  };

  renderModalContent = () => {
    if (this.props.nestedModalContainer) {
      return (
        <div ref={n => (this.childContainer = n)}>
          {React.Children.map(this.props.children, (child, i) => {
            return React.cloneElement(child, {
              nested: true,
              isOpen: child.props.nestedModalId === this.state.showingModalId,
              open: this.state.openedModalIds.includes(child.props.nestedModalId),
              isNestedAndHidden: this.state.openedModalIds.includes(child.props.nestedModalId) === false,
            });
          })}
        </div>
      );
    }
    return this.props.children;
  };

  render() {
    if (this.props.nested) {
      return (
        <CSSTransition
          in={this.props.isOpen}
          timeout={250}
          classNames={this.props.isNestedAndHidden ? `nested-modal-right` : 'nested-modal-left'}
          unmountOnExit={this.props.isNestedAndHidden}
        >
          <div className={styles['nested-modal']}>{this.props.children}</div>
        </CSSTransition>
      );
    }

    const contentStyle = { width: `${this.props.width}px`, minHeight: this.props.minHeight };

    let classNames = [styles['modal']];
    if (this.props.fullHeight) {
      classNames = [...classNames, styles['content-scroll']];
    }
    if (this.props.nestedModalContainer) {
      classNames = [...classNames, styles['has-nested-modals']];
    }

    let overlayClassNames = [styles['overlay']];
    if (this.props.fullHeight) {
      overlayClassNames = [...overlayClassNames, styles['no-scroll']];
    }

    let overlayStyle = {};
    if (this.props.verticalMargin) {
      overlayStyle = {
        ...overlayStyle,
        paddingTop: this.props.verticalMargin,
        paddingBottom: this.props.verticalMargin,
      };
    }

    return (
      <ReactModal
        contentRef={ref => (this.contentRef = ref)}
        appElement={document.getElementById('content')}
        centered={false}
        isOpen={this.props.isOpen}
        style={{ content: contentStyle, overlay: overlayStyle }}
        overlayClassName={overlayClassNames.join(' ')}
        className={classNames.join(' ')}
        closeTimeoutMS={120}
      >
        {this.renderModalContent()}
      </ReactModal>
    );
  }
}

Modal.Header = Header;
Modal.Content = Content;
Modal.Loader = Loader;
Modal.Footer = Footer;
Modal.ColumnLayout = ColumnLayout;

export default Modal;

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.string.isRequired,
  shouldCloseOnEsc: PropTypes.bool,
  closeOnDimmerClick: PropTypes.bool.isRequired,
  fullHeight: PropTypes.bool,
  className: PropTypes.string,
  verticalMargin: PropTypes.string,
  nestedModalContainer: PropTypes.bool,
};

Modal.defaultProps = {
  width: '600',
  fullHeight: false,
  minHeight: 'none',
  shouldCloseOnEsc: false,
  closeOnDimmerClick: false,
  nestedModalContainer: false,
};
