import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Switch, Route } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import HelperFunctions from 'utilities/HelperFunctions';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { OrganisationOperations } from 'state/ducks/organisation';
import { OrganisationsSelectors } from 'state/ducks/organisations';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { Header } from 'views/components/Shared/Layout';
import { Button, InlineModal, Icon, Loader } from 'views/components/Shared/General';
import { BillingInformation, Overview, Users, Systems } from './Tabs';
import DeleteSystemModal from './components/DeleteSystemModal';
import EditSystemNameModal from './components/EditSystemNameModal';

class OrganisationDetail extends Component {
  state = {
    isLoading: true,
    deleteModalOpen: false,
  };

  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Systems);
    this.fetchOrganisation();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchOrganisation();
    }
  }
  fetchOrganisation = () => {
    this.setState({ isLoading: true });
    this.props
      .fetchOrganisation(this.props.match.params.id)
      .then(organisation => {
        HelperFunctions.setDocumentTitle(organisation.internal_name);
      })
      .catch(e => {
        this.setState({ notFound: e.response.status === 404 });
      });
  };

  getLinkForBreadCrumb = () => {
    const { search: propsSearch } = this.props.navigatedTo;
    let search = null;
    if (Object.keys(propsSearch).length === 0) {
      search = SDKHelperFunctions.convertObjToQueryParameters({ list: 'customer' });
    } else {
      search = SDKHelperFunctions.convertObjToQueryParameters(propsSearch);
    }
    return {
      pathname: '/organisations',
      search,
    };
  };

  renderBreadcrumb = () => {
    return (
      <Header.Breadcrumb>
        <Header.BreadcrumbItem to={this.getLinkForBreadCrumb()}>Systems</Header.BreadcrumbItem>
        <Header.BreadcrumbItem>{this.props.organisation.internal_name}</Header.BreadcrumbItem>
      </Header.Breadcrumb>
    );
  };

  renderDeleteSystemModal = () => {
    return (
      <DeleteSystemModal
        open={this.state.deleteModalOpen}
        orgId={this.props.match.params.id}
        onDelete={() => {
          this.setState({ deleteModalOpen: false });
          this.props.history.push(this.getLinkForBreadCrumb());
        }}
        onClose={() => {
          this.setState({ deleteModalOpen: false });
        }}
      />
    );
  };

  renderDropdown = () => {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button small icon={<Icon type="ellipsis-h" regular />} iconSize={16} />
        </div>
        <InlineModal
          open={this.state.dropdownOpen}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ dropdownOpen: false })}
          position="right"
        >
          <InlineModal.Body width={250} dropdown>
            <InlineModal.ListItem
              icon="trash-alt"
              iconThickness="regular"
              title="Delete system"
              destructive
              onClick={() => {
                this.setState({ deleteModalOpen: true, dropdownOpen: false });
              }}
            />
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  };

  renderEditSystemNameButton = () => {
    if (this.props.loading) {
      return null;
    }
    return (
      <Button
        type="icon"
        icon={<Icon type="pen" regular />}
        onClick={() => this.setState({ showEditSystemNameModal: true })}
      />
    );
  };

  renderEditSystemNameModal = () => {
    return (
      <EditSystemNameModal
        open={this.state.showEditSystemNameModal}
        organisation={this.props.organisation}
        onClose={() => {
          this.setState({ showEditSystemNameModal: false });
        }}
      />
    );
  };

  renderHeader = () => {
    return (
      <Header
        breadcrumbComponent={this.renderBreadcrumb()}
        tabBarComponent={this.renderTabs()}
        editTitleButton={this.renderEditSystemNameButton()}
        title={this.props.organisation.internal_name}
        buttonsComponent={this.renderDropdown()}
      />
    );
  };

  renderTabs = () => (
    <Header.TabBar>
      <Header.TabBarItem
        active={
          matchPath(this.props.location.pathname, {
            path: '/organisations/:id',
            exact: true,
          }) != null
        }
        to={`/organisations/${this.props.match.params.id}`}
      >
        Overview
      </Header.TabBarItem>
      <Header.TabBarItem
        active={
          matchPath(this.props.location.pathname, {
            path: '/organisations/:id/billing-information',
            exact: true,
          }) != null
        }
        to={`/organisations/${this.props.match.params.id}/billing-information`}
      >
        Billing Information
      </Header.TabBarItem>
      <Header.TabBarItem
        active={
          matchPath(this.props.location.pathname, {
            path: '/organisations/:id/users',
            exact: true,
          }) != null
        }
        to={`/organisations/${this.props.match.params.id}/users`}
      >
        Users
      </Header.TabBarItem>
      <Header.TabBarItem
        active={
          matchPath(this.props.location.pathname, {
            path: '/organisations/:id/systems',
            exact: true,
          }) != null
        }
        to={`/organisations/${this.props.match.params.id}/systems`}
      >
        System
      </Header.TabBarItem>
    </Header.TabBar>
  );

  render() {
    if (!this.props.organisation) return <Loader size="big" active />;
    return (
      <PerfectScrollbar>
        {this.renderHeader()}
        <Switch key={this.props.match.params.id}>
          <Route path={`/organisations/:id`} exact component={Overview} />
          <Route path={`/organisations/:id/billing-information`} exact component={BillingInformation} />
          <Route path={`/organisations/:id/users`} exact component={Users} />
          <Route path={`/organisations/:id/systems`} exact component={Systems} />
        </Switch>
        {this.renderDeleteSystemModal()}
        {this.renderEditSystemNameModal()}
      </PerfectScrollbar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOrganisation: OrganisationOperations.fetchOrganisation,
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const orgId = ownProps.match.params.id;
  return {
    organisation: EntitySelectors.getOrganisation(state, orgId),
    navigatedTo: OrganisationsSelectors.getNavigatedTo(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganisationDetail));
