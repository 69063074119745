import { Socket } from '../../';
import EntityOperations from 'sdk/State/entities/operations';
import * as types from './reduxTypes';
import { normalizeNotification } from 'sdk/Schemas';

let channel = null;

async function join(systemId, userId) {
  channel = Socket.socket.channel(`notifications:${systemId}:${userId}`);
  return new Promise(function (resolve, reject) {
    channel.join().receive('ok', data => {
      resolve(data);
    });
  });
}

function subscribe(data) {
  return dispatch => {
    const { entities } = normalizeNotification(data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch({
      type: types.WS_NOTIFICATIONS_JOINED,
      payload: data,
    });

    channel.on('notification_created', payload => {
      const { entities } = normalizeNotification(payload.notification);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_NOTIFICATIONS_NOTIFICATION_CREATED, payload });
    });

    channel.on('badge_count_updated', payload => {
      dispatch({ type: types.WS_NOTIFICATIONS_BADGE_COUNT_UPDATED, payload });
    });
  };
}

export default {
  join,
  subscribe,
};
