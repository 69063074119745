import actions from './actions';
import {
  normalizeContactPerson,
  normalizeAsset,
  normalizeAssetVendor,
  normalizeWorkOrder,
  normalizeRequest,
} from 'sdk/Schemas';
import { API } from 'sdk';

const updateEntities = actions.updateEntities;

const fetchContactPerson = contactPersonId => dispatch => {
  return API.fetchContactPerson(contactPersonId).then(res => {
    const { entities } = normalizeContactPerson(res.data);
    dispatch(actions.updateEntities(entities));
    return res.data;
  });
};

const fetchAsset = assetId => dispatch => {
  return fetchAsset(assetId).then(res => {
    const { entities } = normalizeAsset(res.data);
    dispatch(actions.updateEntities(entities));
    return res.data;
  });
};

const fetchAssetVendor = assetVendorId => dispatch => {
  return API.fetchAssetVendor(assetVendorId).then(res => {
    const { entities } = normalizeAssetVendor(res.data);
    dispatch(actions.updateEntities(entities));
    return res.data;
  });
};

const fetchWorkOrder = workOrderId => dispatch => {
  return API.fetchWorkOrder(workOrderId).then(res => {
    const { entities } = normalizeWorkOrder(res.data);
    dispatch(actions.updateEntities(entities));
    return res.data;
  });
};

const fetchRequest = requestId => dispatch => {
  return API.fetchRequest(requestId).then(res => {
    const { entities } = normalizeRequest(res.data);
    dispatch(actions.updateEntities(entities));
    return res.data;
  });
};

export default {
  updateEntities,
  fetchContactPerson,
  fetchAsset,
  fetchAssetVendor,
  fetchWorkOrder,
  fetchRequest,
};
