import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export default class Column extends Component {
  render() {
    let classNames = [styles['column']];
    if (this.props.hide) classNames = [...classNames, styles['hide']];
    if (this.props.flex) classNames = [...classNames, styles['flexable']];
    if (this.props.lightTextColor) classNames = [...classNames, styles['light-text-color']];
    if (this.props.alignRight) classNames = [...classNames, styles['align-right']];
    if (this.props.borderLeft) classNames = [...classNames, styles['border-left']];
    if (this.props.borderRight) classNames = [...classNames, styles['border-right']];
    return (
      <div className={classNames.join(' ')} style={{ width: this.props.width }}>
        {this.props.children}
      </div>
    );
  }
}

Column.propTypes = {
  width: PropTypes.any,
  flex: PropTypes.bool,
  lightTextColor: PropTypes.bool,
  alignRight: PropTypes.bool,
  borderLeft: PropTypes.bool,
  borderRight: PropTypes.bool,
  hide: PropTypes.bool,
};
Column.defaultProps = {
  width: 'auto',
  flex: false,
  lightTextColor: false,
  alignRight: false,
  borderLeft: false,
  borderRight: false,
  hide: false,
};
