import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';

class EditSystemNameModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      internal_name: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        internal_name: this.props.organisation.internal_name || '',
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  save = () => {
    const { internal_name } = this.state;
    this.setState({ isSaving: true });
    this.props.updateOrganisationAsAdmin(this.props.organisation.id, { internal_name }).then(() => {
      this.props.onClose();
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.internal_name) {
      this.save();
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title="Edit system name"
          subtitle="Internal name only visible for us at AM Service"
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <Field view={false} label="Name">
            <Field.Text
              autoFocus
              value={this.state.internal_name}
              onKeyPress={this.handleKeyPress}
              onChange={internal_name => {
                this.setState({ internal_name });
              }}
            />
          </Field>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              disabled={this.state.internal_name.length === 0}
              label="Save"
              loading={this.state.isSaving}
              onClick={this.save}
            />
            <Button label="Cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrganisationAsAdmin: SDKReduxOperations.updateOrganisationAsAdmin,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(EditSystemNameModal));
