import { API as SDKApi } from 'sdk';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeImage } from 'sdk/Schemas';
import * as API from './api';
import * as types from './reduxTypes';

export function createDraftImage(systemId, params, meta = {}) {
  return dispatch => {
    return SDKApi.createImage(systemId, params)
      .then(res => {
        const { entities } = normalizeImage(res.data);
        const payload = { systemId, params, data: res.data, meta };
        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.IMAGE_DRAFT_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createImageForRequest(requestId, params) {
  return dispatch => {
    return API.createImageForRequest(requestId, params)
      .then(res => {
        const { entities, result } = normalizeImage(res.data);
        const data = entities.imageById[result];
        const payload = { requestId, params, data };

        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.IMAGE_CREATED_FOR_REQUEST, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createImageForChecklistInstanceRow(checklistInstanceRowId, params) {
  return dispatch => {
    return API.createImageForChecklistInstanceRow(checklistInstanceRowId, params)
      .then(res => {
        const { entities, result } = normalizeImage(res.data);
        const data = entities.imageById[result];
        const payload = { checklistInstanceRowId, params, data };

        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.IMAGE_CREATED_FOR_CHECKLIST_INSTANCE_ROW, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createImageForChecklistTemplateRow(checklistTemplateRowId, params) {
  return dispatch => {
    return API.createImageForChecklistTemplateRow(checklistTemplateRowId, params)
      .then(res => {
        const { entities, result } = normalizeImage(res.data);
        const data = entities.imageById[result];
        const payload = { checklistTemplateRowId, params, data };

        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.IMAGE_CREATED_FOR_CHECKLIST_TEMPLATE_ROW, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createImageForWorkOrderSpentTime(workOrderSpentTimeId, params) {
  return dispatch => {
    return API.createImageForWorkOrderSpentTime(workOrderSpentTimeId, params)
      .then(res => {
        const { entities, result } = normalizeImage(res.data);
        const data = entities.imageById[result];
        const payload = { workOrderSpentTimeId, params, data };

        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.IMAGE_CREATED_FOR_WORK_ORDER_SPENT_TIME, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createRegistrationImageForWorkOrder(workOrderId, params) {
  return dispatch => {
    return API.createRegistrationImageForWorkOrder(workOrderId, params)
      .then(res => {
        const { entities } = normalizeImage(res.data);
        const payload = { workOrderId, params, data: res.data };

        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.REGISTRATION_IMAGE_CREATED_FOR_WORK_ORDER, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createCompletionImageForWorkOrder(workOrderId, params) {
  return dispatch => {
    return API.createCompletionImageForWorkOrder(workOrderId, params)
      .then(res => {
        const { entities } = normalizeImage(res.data);
        const payload = { workOrderId, params, data: res.data };

        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.COMPLETION_IMAGE_CREATED_FOR_WORK_ORDER, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createImageForRecurringMaintenance(recurringMaintenanceId, params) {
  return dispatch => {
    return API.createImageForRecurringMaintenance(recurringMaintenanceId, params)
      .then(res => {
        const { entities } = normalizeImage(res.data);
        const payload = { recurringMaintenanceId, params, data: res.data };

        dispatch(EntityOperations.updateEntities(entities));
        dispatch({
          type: types.IMAGE_CREATED_FOR_RECURRING_MAINTENANCE,
          payload,
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createImageForAsset(assetId, params) {
  return dispatch => {
    return API.createImageForAsset(assetId, params)
      .then(res => {
        const { entities, result } = normalizeImage(res.data);
        const data = entities.imageById[result];
        const payload = { assetId, params, data };

        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.IMAGE_CREATED_FOR_ASSET, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createImageForSparePart(sparePartId, params) {
  return dispatch => {
    return API.createImageForSparePart(sparePartId, params)
      .then(res => {
        const { entities, result } = normalizeImage(res.data);
        const data = entities.imageById[result];
        const payload = { sparePartId, params, data };

        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.IMAGE_CREATED_FOR_SPARE_PART, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createImageForSystemLogo(systemId, params) {
  return dispatch => {
    return API.createImageForSystemLogo(systemId, params)
      .then(res => {
        const { entities, result } = normalizeImage(res.data);
        const data = entities.imageById[result];
        const payload = { systemId, params, data };

        dispatch(EntityOperations.updateEntities(entities));
        dispatch({ type: types.IMAGE_CREATED_FOR_SYSTEM_LOGO, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateImage(imageId, params) {
  return dispatch => {
    return API.updateImage(imageId, params)
      .then(res => {
        const { entities } = normalizeImage(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { imageId, params, data: res.data };
        dispatch({ type: types.IMAGE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteImage(imageId) {
  return (dispatch, getState) => {
    const image = getState().entities.imageById[imageId];
    return API.deleteImage(imageId)
      .then(() => {
        const payload = { imageId, data: image };
        dispatch({ type: types.IMAGE_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
