import { HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.sparePartFieldById) {
        return HelperFunctions.mergeEntities(state, action.payload.sparePartFieldById);
      }
      return state;
    default:
      return state;
  }
};
