import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

const ListItem = props => {
  const renderIcon = () => {
    if (props.icon == null && !props.selected) return null;
    let icon = '';
    let color;

    if (props.selected) {
      icon = 'check';
      color = '2baf2d';
    } else if (props.destructive) {
      color = '#C55050';
      icon = props.icon;
    } else {
      icon = props.icon;
      color = props.iconColor;
    }
    return (
      <div className={styles['icon']}>
        <Icon
          color={color}
          regular={props.iconThickness === 'regular'}
          light={props.iconThickness === 'light'}
          green={props.iconColor === 'green'}
          red={props.iconColor === 'red'}
          orange={props.iconColor === 'orange'}
          blue={props.iconColor === 'blue'}
          size={props.iconSize}
          type={icon}
          fixedWidth
        />
      </div>
    );
  };

  const renderTitle = () => (
    <div className={styles['data']}>
      <div className={styles['title']}>{props.title}</div>
      {props.subtitle ? <div className={styles['subtitle']}>{props.subtitle}</div> : null}
    </div>
  );

  const renderBadge = () => {
    if (!props.badge) return null;

    return <div className={styles['badge']}>{props.badge}</div>;
  };

  const renderRightContainer = () => {
    if (!props.rightContainer) return null;

    return <div className={styles['rightContainer']}>{props.rightContainer}</div>;
  };

  const renderButtons = () => {
    if (!props.buttons) return null;

    return (
      <div className={styles['buttons']}>
        <div className={styles['line']} />
        {props.buttons}
      </div>
    );
  };

  return (
    <div className={`list-item-container ${styles['list-item-container']}`}>
      <div
        className={`
          list-item
          ${styles['list-item']}
          ${props.clickable ? styles['clickable'] : ''}
          ${props.disabled ? styles['disabled'] : ''}
          ${props.destructive ? styles['destructive'] : ''}
        `}
        onClick={props.onClick && !props.disabled ? props.onClick : null}
      >
        {renderIcon()}
        {renderTitle()}
        {renderBadge()}
        {renderRightContainer()}
      </div>
      {renderButtons()}
    </div>
  );
};

export default ListItem;

ListItem.propTypes = {
  iconSize: PropTypes.number,
  title: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  iconThickness: PropTypes.string,
  clickable: PropTypes.bool,
  destructive: PropTypes.bool,
  subtitle: PropTypes.node,
  selected: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
  buttons: PropTypes.node,
  rightContainer: PropTypes.node,
};

ListItem.defaultProps = {
  iconSize: 14,
  disabled: false,
  selected: false,
  clickable: true,
  destructive: false,
  iconThickness: 'solid',
  iconColor: '',
};
