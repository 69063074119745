import * as axios from 'axios';
import Cookies from 'universal-cookie';
import { setUserAuthToken } from 'sdk/utilities/Axios';
const cookie = new Cookies();

const convertObjToQueryParameters = object => {
  let str = [];
  for (var key in object) {
    if (object[key] == null || object[key] === '') {
      continue;
    }
    str.push(encodeURIComponent(key) + '=' + encodeURIComponent(object[key]));
  }
  return str.join('&');
};

const base64EncodeFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({ file: reader.result.split(',')[1], mime_type: file.type, name: file.name });
    };
    reader.onerror = error => {
      reject(error);
    };
  });
};

const getPaginationFromHeader = (headers = null) => {
  return headers == null
    ? null
    : {
        currentPage: Number(headers['page-number']),
        totalPages: Number(headers['total-pages']),
        totalEntries: Number(headers['total-entries']),
      };
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/*
  Get language specific ordinal suffix

  Currently only swedish is supported
*/
const getOrdinalSuffix = n => n + ([1, 2].includes(n % 10) && ![11, 12].includes(n) ? ':a' : ':e');

const openAttachment = attachment => {
  window.open(`${process.env.REACT_APP_BROWSER_URL}attachments/${attachment.id}`, '_blank');
};

const getInitialRootLocationForModal = ({ pathname }) => {
  if (pathname.startsWith('/s/')) {
    return { pathname: '/' };
  }
};

const setDocumentTitle = title => {
  document.title = `[ADMIN] ${title} | AM Service`;
};

const validateEmail = email => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

function createCancellableGetRequest() {
  var call;
  return function (url) {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return axios.get(url, { cancelToken: call.token });
  };
}

function getErrorCode(e, key) {
  if (e.response.data.errors[key]) {
    return e.response.data.errors[key][0].code;
  }
}

const updateUserAuthToken = token => {
  cookie.set('token', token, {
    path: '/',
    domain: process.env.NODE_ENV === 'development' ? 'localhost' : 'mainter.com',
  });
  localStorage.setItem('token', token);
  setUserAuthToken(token);
};

const clearUserAuthToken = token => {
  localStorage.removeItem('token');
  cookie.remove('token', {
    path: '/',
    domain: process.env.NODE_ENV === 'development' ? 'localhost' : 'mainter.com',
  });
};

export default {
  convertObjToQueryParameters,
  base64EncodeFile,
  getPaginationFromHeader,
  capitalizeFirstLetter,
  getOrdinalSuffix,
  openAttachment,
  getInitialRootLocationForModal,
  validateEmail,
  setDocumentTitle,
  createCancellableGetRequest,
  getErrorCode,
  updateUserAuthToken,
  clearUserAuthToken,
};
