import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listMeters = (systemId, params, config = null) => {
  return request.get(
    `systems/${systemId}/meters?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
};
export const fetchMeter = meterId => request.get(`meters/${meterId}`);
export const updateMeter = (meterId, data) => request.patch(`meters/${meterId}`, data);
export const createMeter = (systemId, data) => request.post(`systems/${systemId}/meters`, data);
export const deleteMeter = meterId => request.delete(`meters/${meterId}`);
export const fetchMeterListCounts = systemId => {
  return request.get(`systems/${systemId}/meter_list_counts`);
};
