import React from 'react';
import BackButton from './BackButton';
import Title from './Title';
import Types from './Types';
import styles from './style.module.scss';

const Detail = ({ children }) => <div className={styles['detail']}>{children}</div>;

Detail.BackButton = BackButton;
Detail.Title = Title;
Detail.Types = Types;
export default Detail;
