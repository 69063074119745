import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import ReactDOM from 'react-dom';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import Dropdown from './Dropdown';
import styles from './style.module.scss';

class NewInlineModal extends Component {
  getInitialState = () => ({
    contentHeight: 0,
    pxFromTopOfWindow: 0,
    pxFromLeftSideOfWindow: 0,
    width: 0,
    maxHeight: 'none',
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.initiatedClickOutside = false;
    this.debouncedWindowResizedFunction = debounce(this.setInlineModalHorizontalAndVerticalPositions, 300);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!nextProps.open && !this.props.open) return false;
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setInlineModalHorizontalAndVerticalPositions();
      window.addEventListener('resize', this.debouncedWindowResizedFunction);
      document.addEventListener('mousedown', this.handleMouseDown, true);
      document.addEventListener('click', this.handleClickOutside, true);
      document.addEventListener('keydown', this.handleKeyDown, true);
    } else if (prevProps.open && !this.props.open) {
      document.removeEventListener('click', this.handleClickOutside, true);
      document.removeEventListener('keydown', this.handleKeyDown, true);
      document.removeEventListener('mousedown', this.handleMouseDown, true);
      window.removeEventListener('resize', this.debouncedWindowResizedFunction);
    } else {
      const contentHeightIncreased =
        this.inlineModalPositioningRef &&
        this.inlineModalPositioningRef.scrollHeight !== prevState.contentHeight &&
        prevState.contentHeight < this.inlineModalPositioningRef.scrollHeight;
      if (contentHeightIncreased) {
        this.setInlineModalHorizontalAndVerticalPositions();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('keydown', this.handleKeyDown, true);
    document.removeEventListener('mousedown', this.handleMouseDown, true);
    window.removeEventListener('resize', this.debouncedWindowResizedFunction);
  }

  handleKeyDown = e => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.stopPropagation();
      this.props.onClose(e);
    }
  };

  handleMouseDown = e => {
    const clickedOutside =
      this.inlineModalPositioningRef && !this.inlineModalPositioningRef.contains(e.target);
    if (clickedOutside) {
      this.initiatedClickOutside = true;
    }
  };

  handleClickOutside = e => {
    const clickedOutside =
      this.inlineModalPositioningRef &&
      !this.inlineModalPositioningRef.contains(e.target) &&
      !this.props.positionToRef.contains(e.target);
    if (clickedOutside && this.initiatedClickOutside) {
      this.props.onClose(e);
      this.initiatedClickOutside = false;
    }
  };

  getPixelsFromLeftSideOfScreen = () => {
    const amountOfPixelsToPushFromScreen = 10;
    const elementTriggerWidth = this.props.positionToRef.getBoundingClientRect().width;
    let width = this.props.positionToRef.getBoundingClientRect().width;
    const minWidthIsBiggerThanTriggerWidth = this.props.minWidth && this.props.minWidth > elementTriggerWidth;
    if (minWidthIsBiggerThanTriggerWidth) {
      width = this.props.minWidth;
    }
    let pxFromLeftSideOfWindow = this.props.positionToRef.getBoundingClientRect().left;
    if (this.props.position === 'right') {
      pxFromLeftSideOfWindow = this.props.positionToRef.getBoundingClientRect().right - width;
      if (pxFromLeftSideOfWindow + width > window.innerWidth) {
        let amountOverflowing = pxFromLeftSideOfWindow + width - window.innerWidth;
        pxFromLeftSideOfWindow = pxFromLeftSideOfWindow - amountOverflowing - amountOfPixelsToPushFromScreen;
      }
      if (minWidthIsBiggerThanTriggerWidth) {
        ++pxFromLeftSideOfWindow;
      }
    } else {
      if (pxFromLeftSideOfWindow + width > window.innerWidth) {
        let amountOverflowing = pxFromLeftSideOfWindow + width - window.innerWidth;
        pxFromLeftSideOfWindow = pxFromLeftSideOfWindow - amountOverflowing - amountOfPixelsToPushFromScreen;
      }
      if (minWidthIsBiggerThanTriggerWidth) {
        --pxFromLeftSideOfWindow;
      }
    }
    return pxFromLeftSideOfWindow;
  };

  setInlineModalHorizontalAndVerticalPositions = () => {
    if (!this.props.open) {
      return;
    }
    const windowHeight = window.innerHeight;
    const triggerElementBottomPositionFromTop = this.props.positionToRef.getBoundingClientRect().bottom;
    const pxFromLeftSideOfWindow = this.getPixelsFromLeftSideOfScreen();

    this.setState(
      {
        maxHeight: 'none',
      },
      () => {
        if (!this.props.open) {
          return;
        }
        /*
        Set vertical position
      */
        let pxFromTopOfWindow = triggerElementBottomPositionFromTop;
        let inlineModalContentHeight = this.inlineModalPositioningRef.scrollHeight;
        let width = this.props.positionToRef.getBoundingClientRect().width;
        if (this.props.minWidth && this.props.minWidth > width) {
          width = this.props.minWidth;
        }
        const mininumHeightWhenBelowTrigger = 250;
        const inlineModalIsTallerThanScreen =
          triggerElementBottomPositionFromTop + inlineModalContentHeight > window.innerHeight;
        if (inlineModalIsTallerThanScreen) {
          const diffBetweenInlineModalAndWindowHeight =
            triggerElementBottomPositionFromTop + inlineModalContentHeight - window.innerHeight;
          const newHeight = inlineModalContentHeight - diffBetweenInlineModalAndWindowHeight;
          if (newHeight < mininumHeightWhenBelowTrigger) {
            if (windowHeight - inlineModalContentHeight <= 0) {
              pxFromTopOfWindow = 0;
            } else {
              pxFromTopOfWindow = windowHeight - inlineModalContentHeight;
            }
            let maxHeight = 'none';
            if (inlineModalContentHeight >= window.innerHeight) {
              let difference = inlineModalContentHeight - window.innerHeight;
              maxHeight = inlineModalContentHeight - difference;
            }
            this.setState({
              pxFromTopOfWindow: pxFromTopOfWindow - 1,
              pxFromLeftSideOfWindow,
              width,
              contentHeight: this.inlineModalPositioningRef.scrollHeight,
              maxHeight,
            });
          } else {
            this.setState({
              pxFromTopOfWindow: pxFromTopOfWindow - 1,
              pxFromLeftSideOfWindow,
              width,
              contentHeight: this.inlineModalPositioningRef.scrollHeight,
              maxHeight: newHeight - 20,
            });
          }
        } else {
          this.setState({
            pxFromTopOfWindow: pxFromTopOfWindow - 1,
            pxFromLeftSideOfWindow,
            width,
            contentHeight: this.inlineModalPositioningRef.scrollHeight,
          });
        }
      }
    );
  };

  getInlineModalPositioningStyles = () => {
    if (window.document.documentMode) {
      /*
      If IE11, use height instead of max height
      */
      return {
        top: `${this.state.pxFromTopOfWindow}px`,
        left: `${this.state.pxFromLeftSideOfWindow}px`,
        width: `${this.state.width}px`,
        height: this.state.maxHeight === 'none' ? 'auto' : `${this.state.maxHeight}px`,
      };
    }
    return {
      top: `${this.state.pxFromTopOfWindow}px`,
      left: `${this.state.pxFromLeftSideOfWindow}px`,
      width: `${this.state.width}px`,
      maxHeight: this.state.maxHeight === 'none' ? 'none' : `${this.state.maxHeight}px`,
    };
  };

  renderInlineModalContent = () => {
    return (
      <div
        ref={ref => (this.inlineModalPositioningRef = ref)}
        className={`inline-modal ${styles['inline-modal']}`}
        style={this.getInlineModalPositioningStyles()}
      >
        {this.props.children}
      </div>
    );
  };

  render() {
    if (this.props.open) {
      return ReactDOM.createPortal(this.renderInlineModalContent(), document.body);
    }
    return null;
  }
}

NewInlineModal.Header = Header;
NewInlineModal.Body = Body;
NewInlineModal.Footer = Footer;
NewInlineModal.Dropdown = Dropdown;

NewInlineModal.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  position: PropTypes.string.isRequired,
  minWidth: PropTypes.number,
};

NewInlineModal.defaultProps = {
  open: false,
  position: 'left',
  minWidth: null,
};

export default NewInlineModal;
