import { omit } from 'lodash-es';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.SPARE_PART_LOCATION_DELETED: {
      const { sparePartLocationId } = action.payload;

      return omit(state, sparePartLocationId);
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.sparePartLocationById) {
        return HelperFunctions.mergeEntities(state, action.payload.sparePartLocationById);
      }
      return state;
    default:
      return state;
  }
};
