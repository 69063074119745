/*
  --------------------
    DATA SELECTORS
  --------------------
*/

/*
  --------------------
    UI SELECTORS
  --------------------
*/

const getTabs = state => state.header.tabs;
const getTitle = state => state.header.title;
const getSelectedTab = state => state.header.selectedTab;

export default {
  getSelectedTab,
  getTabs,
  getTitle,
};
