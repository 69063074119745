import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as sdkReducers from 'sdk/State';
import * as reducers from './ducks';
import { includeCurrentUserInfo } from './middlewares';

const appReducer = combineReducers({ ...reducers, ...sdkReducers });

export default createStore(
  appReducer,
  compose(
    applyMiddleware(thunk, includeCurrentUserInfo),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
);
