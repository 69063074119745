import React from 'react';
import { range } from 'lodash-es';
import * as moment from 'moment';
import { Button, Icon } from 'views/components/Shared/General';
import styles from '../style.module.scss';

export default ({ date, onChange }) => {
  const months = moment.months();
  const startYear = moment().subtract(10, 'year').year();
  const endYear = moment().add(10, 'year').year();
  let years = range(startYear, endYear + 1);
  if (moment(date).year() < startYear) {
    years = [moment(date).year(), ...years];
  } else if (moment(date).year() > endYear) {
    years = [...years, moment(date).year()];
  }

  const handleChange = e => {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className={styles['header']}>
      <div className={styles['texts']}>
        <form className="DayPicker-Caption">
          <div className={styles['select-container']}>
            {moment(date).format('MMMM')}
            <select name="month" onChange={handleChange} value={moment(date).month()}>
              {months.map((month, i) => (
                <option key={month} value={i}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          <div className={styles['select-container']}>
            {moment(date).format('YYYY')}
            <select name="year" onChange={handleChange} value={moment(date).year()}>
              {years.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </form>
      </div>
      <div className={styles['navs']}>
        <div className={styles['today']}>
          <Button
            type="text"
            primary
            noUnderline
            label="components.date-picker.today"
            fontSize={12}
            onClick={() => onChange(moment().toDate())}
          />
        </div>
        <Button
          type="icon"
          icon={<Icon regular size={18} type="angle-left" />}
          onClick={() => onChange(moment(date).subtract({ month: 1 }).format('YYYY-MM-DD'))}
        />
        <Button
          type="icon"
          icon={<Icon regular size={18} type="angle-right" />}
          onClick={() => onChange(moment(date).add({ month: 1 }).format('YYYY-MM-DD'))}
        />
      </div>
    </div>
  );
};
