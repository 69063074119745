import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const getAssignees = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/calendar_assignees?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const showCalendar = (systemId, params, config = null) =>
  request.get(`systems/${systemId}/calendar?${HelperFunctions.convertObjToQueryParameters(params)}`, config);
export const showCalendarSummary = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/calendar_summary?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const showCalendarCount = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/calendar_count?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const updateCalendarInstance = (recurringMaintenanceId, date, data, config = null) =>
  request.put(`recurring_maintenances/${recurringMaintenanceId}/changes/${date}`, data, config);
