const getOrganisations = state => {
  const { organisations, entities } = state;
  return organisations.ids.map(id => entities.organisationById[id]);
};

const getPagination = state => state.organisations.pagination;

const getQueryParameters = state => {
  const { organisations } = state;
  return organisations.queryParams;
};

const getNavigatedTo = state => {
  const { organisations } = state;
  return organisations.navigatedTo;
};

export default {
  getNavigatedTo,
  getQueryParameters,
  getOrganisations,
  getPagination,
};
