import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeGroup } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createGroup(systemId, params) {
  return dispatch => {
    return API.createGroup(systemId, params)
      .then(res => {
        const { entities } = normalizeGroup(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.GROUP_CREATED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateGroup(groupId, params) {
  return dispatch => {
    return API.updateGroup(groupId, params)
      .then(res => {
        const { entities } = normalizeGroup(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { groupId, params, data: res.data };
        dispatch({ type: types.GROUP_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteGroup(groupId) {
  return dispatch => {
    return API.deleteGroup(groupId)
      .then(res => {
        const payload = { groupId };
        dispatch({ type: types.GROUP_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
