/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getFieldErrors = (state) => state.error.fieldErrors;
const getErrorMessageForField = (state, field) => state.error.fieldErrors[field];
/*
  --------------------
    UI SELECTORS
  --------------------
*/

export default {
  getErrorMessageForField,
  getFieldErrors,
};
