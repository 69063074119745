import React, { Component } from 'react';
import Pagination from './Pagination';
import styles from './style.module.scss';

export default class Footer extends Component {
  render() {
    return <div className={styles['footer']}>{this.props.children}</div>;
  }
}

Footer.Pagination = Pagination;
