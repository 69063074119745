import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeBillingAdditionalCost } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createBillingAdditionalCostAsAdmin(systemId, params) {
  return dispatch => {
    return API.createBillingAdditionalCostAsAdmin(systemId, params)
      .then(res => {
        const { entities } = normalizeBillingAdditionalCost(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.BILLING_ADDITIONAL_COST_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateBillingAdditionalCostAsAdmin(billingAdditionalCostId, params) {
  return dispatch => {
    return API.updateBillingAdditionalCostAsAdmin(billingAdditionalCostId, params)
      .then(res => {
        const { entities } = normalizeBillingAdditionalCost(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { billingAdditionalCostId, params, data: res.data };
        dispatch({ type: types.BILLING_ADDITIONAL_COST_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteBillingAdditionalCostAsAdmin(billingAdditionalCostId) {
  return dispatch => {
    return API.deleteBillingAdditionalCostAsAdmin(billingAdditionalCostId)
      .then(res => {
        const payload = { billingAdditionalCostId };
        dispatch({ type: types.BILLING_ADDITIONAL_COST_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
