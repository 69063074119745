import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { API } from 'sdk';
import { injectIntl } from 'react-intl';
import { Icon, MoneyWithCurrency } from 'views/components/Shared/General';
import { ContentContainer, CardGrid, ListLayout } from 'views/components/Shared/Layout';
import OverviewCard from './OverviewCard';

class Overview extends Component {
  state = {
    isLoadingView: true,
    data: {}
  };

  componentDidMount() {
    API.listBillingOverviewData().then(({ data }) => {
      this.setState({ isLoadingView: false, data });
    });
  }

  render() {
    return (
      <ListLayout.Content.MainContent>
        <ListLayout.Content.MainContent.Content>
          <ContentContainer maxWidth={800}>
            <CardGrid>
              <CardGrid.Column width={265}>
                <CardGrid.Row>
                  <OverviewCard
                    loading={this.state.isLoadingView}
                    title="Monthly Revenue"
                    icon={
                      <Icon type="dollar-sign" regular withBackground green size={120} backgroundSize={190} />
                    }
                    value={
                      <span>
                        <MoneyWithCurrency
                          currency="SEK"
                          value={this.state.data.total_revenue}
                          fractionSize={0}
                          currencyFontSize={24}
                        />
                      </span>
                    }
                  />
                </CardGrid.Row>
                <CardGrid.Row>
                  <OverviewCard
                    title="Unbilled Revenue"
                    icon={
                      <Icon type="credit-card-front" regular withBackground size={100} backgroundSize={190} />
                    }
                    loading={this.state.isLoadingView}
                    value={
                      <span>
                        <MoneyWithCurrency
                          currency="SEK"
                          value={this.state.data.total_unbilled_revenue}
                          fractionSize={0}
                          currencyFontSize={24}
                        />
                      </span>
                    }
                  />
                </CardGrid.Row>
              </CardGrid.Column>
              <CardGrid.Column width={265}>
                <CardGrid.Row>
                  <OverviewCard
                    title="Active Systems"
                    icon={<Icon type="database" withBackground orange size={120} backgroundSize={190} />}
                    loading={this.state.isLoadingView}
                    value={<span>{this.state.data.active_systems} st</span>}
                  />
                </CardGrid.Row>
                <CardGrid.Row>
                  <OverviewCard
                    title="Credits"
                    icon={<Icon type="money-bill-alt" regular withBackground size={100} backgroundSize={190} />}
                    loading={this.state.isLoadingView}
                    value={
                      <span>
                        <MoneyWithCurrency
                          currency="SEK"
                          value={this.state.data.total_billing_credits}
                          fractionSize={0}
                          currencyFontSize={24}
                        />
                      </span>
                    }
                  />
                </CardGrid.Row>
              </CardGrid.Column>
              <CardGrid.Column width={265}>
                <CardGrid.Row>
                  <OverviewCard
                    title="Operators"
                    icon={<Icon type="users" withBackground blue size={120} backgroundSize={190} />}
                    loading={this.state.isLoadingView}
                    value={<span>{this.state.data.user_operator_count + this.state.data.user_production_supervisor_count} st</span>}
                  />
                </CardGrid.Row>
                <CardGrid.Row>
                  <OverviewCard
                    title="Paying Users"
                    icon={
                      <Icon type="file-invoice-dollar" withBackground green size={120} backgroundSize={190} />
                    }
                    loading={this.state.isLoadingView}
                    value={<span>{this.state.data.user_full_count} st</span>}
                  />
                </CardGrid.Row>
              </CardGrid.Column>
            </CardGrid>
          </ContentContainer>
        </ListLayout.Content.MainContent.Content >
      </ListLayout.Content.MainContent>
    );
  }
}

export default withRouter(injectIntl(connect(null)(Overview)));
