import { API } from 'sdk';
import { normalizeOrganisation } from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import HelperFunctions from 'utilities/HelperFunctions';
import actions from './actions';

const setNavigatedTo = actions.setNavigatedTo;
const addQueryParameter = actions.addQueryParameter;

const fetchOrganisations = params => dispatch => {
  return API.listOrganisationsAsAdmin(params).then(res => {
    const { headers } = res;
    const pagination = HelperFunctions.getPaginationFromHeader(headers);
    const { entities, result } = normalizeOrganisation(res.data);

    dispatch(EntityOperations.updateEntities(entities));
    dispatch(actions.fetchOrganisationsSuccess({ ids: result, pagination }));
    return res.data;
  });
};

const fetchOrganisation = params => dispatch => {
  return API.fetchSystem(params).then(res => {
    const { entities, result } = normalizeOrganisation(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(actions.fetchSystemsSuccess({ ids: result }));
    return res.data;
  });
};

export default {
  setNavigatedTo,
  addQueryParameter,
  fetchOrganisations,
  fetchOrganisation,
};
