import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const ContentContainer = props => {
  const { maxWidth, minWidth } = props;
  return (
    <div
      className={`${styles['background']} ${props.noPadding ? styles['no-padding'] : ''}`}
      style={{ minWidth }}
    >
      <div className={styles['centered-content']} style={{ maxWidth }}>
        {props.children}
      </div>
    </div>
  );
};

export default ContentContainer;

ContentContainer.propTypes = {
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  noPadding: PropTypes.bool,
};

ContentContainer.defaultProps = {
  minWidth: 800,
  maxWidth: 960,
  noPadding: false,
};
