import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styles from './style.module.scss';
import Header from './Header';
import Container from './Container';

class SideModal extends Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      document.addEventListener('keydown', this.handleKeyDown);
    } else if (prevProps.open && !this.props.open) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleKeyDown = e => {
    const { closeOnEscape } = this.props;
    if ((e.key === 'Escape' || e.key === 'Esc') && closeOnEscape) {
      this.props.onClose();
    }
  };

  onClickOverlay = () => {
    const { closeOnClickOverlay } = this.props;
    if (closeOnClickOverlay) {
      this.props.onClose();
    }
  };

  renderContent = () => {
    return <div className={styles['content']}>{this.props.children}</div>;
  };

  renderFooter = () => {
    if (!this.props.footerComponent) return null;
    return <div className={styles['footer']}>{this.props.footerComponent}</div>;
  };

  render() {
    let classNames = [styles['modal']];
    let overlayClassNames = [styles['overlay']];

    return (
      <ReactModal
        appElement={document.getElementById('content')}
        centered={false}
        isOpen={this.props.open}
        onRequestClose={this.onClickOverlay}
        overlayClassName={overlayClassNames.join(' ')}
        className={classNames.join(' ')}
        closeTimeoutMS={200}
        style={{ content: { width: this.props.width } }}
      >
        {this.renderContent()}
        {this.renderFooter()}
      </ReactModal>
    );
  }
}

SideModal.Header = Header;
SideModal.Container = Container;

SideModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  footerComponent: PropTypes.element,
  width: PropTypes.number,
  closeOnEscape: PropTypes.bool,
  closeOnClickOverlay: PropTypes.bool,
};

SideModal.defaultProps = {
  onClose: () => {},
  width: 600,
  open: false,
  closeOnEscape: true,
  closeOnClickOverlay: true,
};

export default SideModal;
