import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import * as types from './reduxTypes';
import { normalizeMeterReading } from 'sdk/Schemas';

export function createMeterReading(meterId, params) {
  return dispatch => {
    return API.createMeterReading(meterId, params)
      .then(res => {
        const { entities } = normalizeMeterReading(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { meterId, params, data: res.data };
        dispatch({ type: types.METER_READING_CREATED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createMeterReadingForChecklistInstanceRow(checklistInstanceRowId, params) {
  return dispatch => {
    return API.createMeterReadingForChecklistInstanceRow(checklistInstanceRowId, params)
      .then(res => {
        const { entities } = normalizeMeterReading(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { checklistInstanceRowId, params, data: res.data };
        dispatch({ type: types.METER_READING_CREATED_FOR_CHECKLIST_INSTANCE_ROW, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteMeterReading(meterReadingId) {
  return (dispatch, getState) => {
    const meterReading = getState().entities.meterReadingById[meterReadingId];
    return API.deleteMeterReading(meterReadingId)
      .then(res => {
        const payload = { meterReadingId, data: meterReading };
        dispatch({ type: types.METER_READING_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
