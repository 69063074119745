import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeVendor } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createVendor(systemId, params) {
  return dispatch => {
    return API.createVendor(systemId, params)
      .then(({ data: vendor }) => {
        const { entities } = normalizeVendor(vendor);

        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: vendor };
        dispatch({ type: types.VENDOR_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateVendor(vendorId, params) {
  return dispatch => {
    return API.updateVendor(vendorId, params)
      .then(res => {
        const { entities } = normalizeVendor(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { vendorId, params, data: res.data };
        dispatch({ type: types.VENDOR_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteVendor(vendorId) {
  return dispatch => {
    return API.deleteVendor(vendorId)
      .then(res => {
        const payload = { vendorId };
        dispatch({ type: types.VENDOR_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
