import React, { Component } from 'react';
import { Field } from 'views/components/Shared/General';

class CurrencyDropdown extends Component {
  state = {
    language: 'sv',
  };
  renderLanguageDropdownItems = () => {
    return (
      <>
        <Field.Dropdown.Item
          selected={this.state.language === 'sv'}
          onClick={() => {
            this.props.onSelectedLanguage('sv');
            this.setState({
              language: 'sv',
            });
          }}
        >
          Swedish
        </Field.Dropdown.Item>
        <Field.Dropdown.Item
          selected={this.state.language === 'en'}
          onClick={() => {
            this.props.onSelectedLanguage('en');
            this.setState({
              language: 'en',
            });
          }}
        >
          English
        </Field.Dropdown.Item>
      </>
    );
  };

  renderSelectedLanguage = () => {
    if (this.state.language === 'sv') {
      return 'Swedish';
    }
    return 'English';
  };
  render() {
    return (
      <Field label="Language">
        <Field.Dropdown clearable={false} value={this.renderSelectedLanguage()}>
          {this.renderLanguageDropdownItems()}
        </Field.Dropdown>
      </Field>
    );
  }
}

export default CurrencyDropdown;
