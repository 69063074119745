export default store => next => action => {
  if (action.meta != null && action.meta.includeCurrentUser === true) {
    return next({
      ...action,
      meta: {
        ...action.meta,
        currentUser: store.getState().auth.currentUser,
        currentUserGroups: store.getState().auth.groups,
      }
    });
  } else {
    return next(action);
  }
}