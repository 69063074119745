import * as moment from 'moment';

/*
  ------------------
    Swedish
  ------------------
*/
const initializeTranslation = () => {
  moment.locale('en');
}


const init = () => {
  initializeTranslation();
};

export default {
  init,
}
