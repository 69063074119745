import update from 'immutability-helper';
import { HelperFunctions } from 'sdk';
import types from './types';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE:
      return update(
        { ...state },
        {
          [action.payload.id]: {
            $merge: action.payload.data,
          },
        }
      );
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.workOrderTypeById) {
        return HelperFunctions.mergeEntities(state, action.payload.workOrderTypeById);
      }
      return state;
    default:
      return state;
  }
};
