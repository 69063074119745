import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const fetchChecklistTemplateVersion = checklistTemplateVersionId =>
  request.get(`checklist_template_versions/${checklistTemplateVersionId}`);
export const fetchTemplateChecklistVersionsForTemplate = (checklistId, params) =>
  request.get(
    `checklist_templates/${checklistId}/checklist_template_versions?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`
  );
export const updateChecklistTemplateVersion = (checklistTemplateVersionId, data) =>
  request.patch(`checklist_template_versions/${checklistTemplateVersionId}`, data);
export const deleteChecklistTemplateVersion = checklistTemplateVersionId =>
  request.delete(`checklist_template_versions/${checklistTemplateVersionId}`);
export const createChecklistTemplateVersionForChecklistTemplate = (checklistTemplateId, data) =>
  request.post(`checklist_templates/${checklistTemplateId}/checklist_template_versions`, data);
