import React from 'react';
import { Icon } from 'views/components/Shared/General';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const ToastMessage = props => {
  const renderSuccessButton = () => {
    if (props.text === null)
      return (
        <div>
          <Icon green withBackground backgroundSize={28} size={14} type="check" />
        </div>
      );
    return (
      <div className={styles['toast-container']}>
        <Icon green withBackground backgroundSize={28} size={14} type="check" />
        {props.text}
      </div>
    );
  };
  const renderErrorButton = () => {
    if (props.text === null)
      return (
        <div>
          <Icon red withBackground backgroundSize={28} size={14} type="times" />
        </div>
      );
    return (
      <div className={styles['toast-container']}>
        <Icon red withBackground backgroundSize={28} size={14} type="times" />
        {props.text}
      </div>
    );
  };

  if (props.success) return renderSuccessButton();
  if (props.error) return renderErrorButton();
};

export default ToastMessage;

ToastMessage.propTypes = {
  success: PropTypes.bool,
  error: PropTypes.bool,
  text: PropTypes.string,
};
