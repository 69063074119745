import React from 'react';
import styles from './style.module.scss';
import { Loader } from 'views/components/Shared/General';

export default props => {
  return (
    <div
      className={`
      ${styles['loader']}
      ${props.noHeader ? styles['no-header'] : ''}
    `}
    >
      <Loader small />
    </div>
  );
};
