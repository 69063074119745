import types from './types';

const fetchOrganisationSuccess = ({ id }) => ({
  type: types.FETCH_ORGANISATION_SUCCESS,
  payload: {
    id,
  },
});

const fetchUsersSuccess = ({ ids, pagination }) => ({
  type: types.FETCH_USERS_SUCCESS,
  payload: {
    ids,
    pagination,
  },
});

const fetchSystemsSuccess = ({ ids, pagination }) => ({
  type: types.FETCH_SYSTEMS_SUCCESS,
  payload: {
    ids,
    pagination,
  },
});

const fetchBillingAdditionalCostsSuccess = ({ ids }) => ({
  type: types.FETCH_BILLING_ADDITIONAL_COSTS_SUCCESS,
  payload: {
    ids,
  },
});

const fetchBillingDiscountsSuccess = ({ ids }) => ({
  type: types.FETCH_BILLING_DISCOUNTS_SUCCESS,
  payload: {
    ids,
  },
});
export default {
  fetchSystemsSuccess,
  fetchOrganisationSuccess,
  fetchUsersSuccess,
  fetchBillingAdditionalCostsSuccess,
  fetchBillingDiscountsSuccess,
};
