import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WhiteCard, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class ActionButton extends Component {
  render() {
    return (
      <div onClick={this.props.onClick}>
        <WhiteCard noPadding clickable fullHeight>
          <div className={styles['add-container']}>
            <div className={styles['icon-container']}>
              <Icon
                withBackground
                blue
                regular={this.props.iconThickness === 'regular'}
                type={this.props.icon}
                backgroundSize={40}
                size={18}
              />
            </div>
            <div className={styles['text-container']}>
              <p className={styles['add-title']}>{this.props.title}</p>
              <p className={styles['add-subtitle']}>{this.props.subtitle}</p>
            </div>
          </div>
        </WhiteCard>
      </div>
    );
  }
}

ActionButton.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  icon: PropTypes.node,
};

ActionButton.defaultProps = {
  title: '',
  subtitle: '',
  icon: '',
  onClick: () => {},
  inactive: false,
};
