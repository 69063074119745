import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { Button, Field } from 'views/components/Shared/General';
import {
  Between,
  LessThan,
  LessThanOrEqual,
  MoreThan,
  MoreThanOrEqual,
  NotExact,
  Exact,
  Exists,
  NotExists,
} from './comparators';
import styles from './style.module.scss';

class Number extends Component {
  getInitialState = () => ({
    value: '',
    comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
    fromValue: '',
    toValue: '',
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  componentDidMount() {
    const { value, comparator } = this.props;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Between) {
      const fromValue = value.split('.', 1)[0];
      const toValue = value.substring(value.indexOf('.') + 3);
      this.setState({ value, comparator, fromValue, toValue });
    } else {
      this.setState({
        value,
        comparator,
      });
    }
  }

  renderDecimalField = () => (
    <Field.Decimal
      value={this.state.value}
      onChange={value => this.setState({ value })}
      onBlur={value =>
        this.setState({ value }, () => {
          if (value) {
            this.props.onChange({
              value: this.state.value,
              comparator: this.state.comparator,
            });
          }
        })
      }
    />
  );

  renderExactComparator = () => {
    return (
      <Exact
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exact}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.Exact) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.Exact });
          }
        }}
      >
        {this.renderDecimalField()}
      </Exact>
    );
  };

  renderNotExactComparator = () => {
    return (
      <NotExact
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.NotExact}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.NotExact) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.NotExact });
          }
        }}
      >
        {this.renderDecimalField()}
      </NotExact>
    );
  };

  renderLessThanComparator = () => {
    return (
      <LessThan
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.LessThan}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.LessThan) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.LessThan });
          }
        }}
      >
        {this.renderDecimalField()}
      </LessThan>
    );
  };

  renderLessThanOrEqualComparator = () => {
    return (
      <LessThanOrEqual
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.LessThanOrEqual}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.LessThanOrEqual) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.LessThanOrEqual });
          }
        }}
      >
        {this.renderDecimalField()}
      </LessThanOrEqual>
    );
  };

  renderGreaterThanComparator = () => {
    return (
      <MoreThan
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.MoreThan}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.MoreThan) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.MoreThan });
          }
        }}
      >
        {this.renderDecimalField()}
      </MoreThan>
    );
  };

  renderGreaterThanOrEqualComparator = () => {
    return (
      <MoreThanOrEqual
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.MoreThanOrEqual}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.MoreThanOrEqual) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.MoreThanOrEqual });
          }
        }}
      >
        {this.renderDecimalField()}
      </MoreThanOrEqual>
    );
  };

  renderBetweenComparator = () => {
    return (
      <Between
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Between}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.Between) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.Between });
          }
        }}
      >
        <div>
          <Field.Decimal
            value={this.state.fromValue}
            onChange={fromValue => {
              this.setState({ fromValue }, () => {
                if (this.state.fromValue && this.state.toValue) {
                  this.setState({ value: `${this.state.fromValue}...${this.state.toValue}` }, () => {
                    this.props.onChange({
                      comparator: HelperFunctions.FILTER_COMPARABLES.Between,
                      value: this.state.value,
                    });
                  });
                } else {
                  this.setState({ value: '' }, () => {
                    this.props.onChange({
                      comparator: HelperFunctions.FILTER_COMPARABLES.Between,
                      value: '',
                    });
                  });
                }
              });
            }}
            onBlur={fromValue => {
              this.setState({ fromValue }, () => {
                if (this.state.fromValue && this.state.toValue) {
                  this.setState({ value: `${this.state.fromValue}...${this.state.toValue}` }, () => {
                    this.props.onChange({
                      comparator: HelperFunctions.FILTER_COMPARABLES.Between,
                      value: this.state.value,
                    });
                  });
                } else {
                  this.setState({ value: '' }, () => {
                    this.props.onChange({
                      comparator: HelperFunctions.FILTER_COMPARABLES.Between,
                      value: '',
                    });
                  });
                }
              });
            }}
          />
        </div>
        <div className={styles['and']}>
          <FormattedMessage id="general.and" />
        </div>
        <div>
          <Field.Decimal
            value={this.state.toValue}
            onChange={toValue => {
              this.setState({ toValue }, () => {
                if (this.state.fromValue && this.state.toValue) {
                  this.setState({ value: `${this.state.fromValue}...${this.state.toValue}` });
                } else {
                  this.setState({ value: '' });
                }
              });
            }}
            onBlur={toValue => {
              this.setState({ toValue }, () => {
                if (this.state.fromValue && this.state.toValue) {
                  this.setState({ value: `${this.state.fromValue}...${this.state.toValue}` });
                } else {
                  this.setState({ value: '' });
                }
              });
            }}
          />
        </div>
      </Between>
    );
  };

  renderExistsComparator = () => {
    if (this.props.required === false) {
      return (
        <Exists
          selected={
            this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.value === true
          }
          onClick={() => {
            this.setState({ value: true, comparator: HelperFunctions.FILTER_COMPARABLES.Exists }, () => {
              this.props.onChange({
                value: true,
                comparator: HelperFunctions.FILTER_COMPARABLES.Exists,
              });
            });
          }}
        />
      );
    }
    return null;
  };

  renderNotExistsComparator = () => {
    if (this.props.required === false) {
      return (
        <NotExists
          selected={
            this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.value === false
          }
          onClick={() => {
            this.setState({ value: false, comparator: HelperFunctions.FILTER_COMPARABLES.Exists }, () => {
              this.props.onChange({
                value: false,
                comparator: HelperFunctions.FILTER_COMPARABLES.Exists,
              });
            });
          }}
        />
      );
    }
    return null;
  };

  hasFiltersApplied = () => {
    const { comparator, value } = this.state;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    if (value) {
      return true;
    }
    return false;
  };

  renderApplyFiltersButton = () => {
    return (
      <Button
        primary
        disabled={this.hasFiltersApplied() === false}
        label="filters.add-button"
        onClick={() => {
          this.props.onApplyfilter({
            comparator: this.state.comparator,
            value: this.state.value,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderExactComparator()}
        {this.renderNotExactComparator()}
        {this.renderLessThanComparator()}
        {this.renderLessThanOrEqualComparator()}
        {this.renderGreaterThanComparator()}
        {this.renderGreaterThanOrEqualComparator()}
        {this.renderBetweenComparator()}
        {this.renderExistsComparator()}
        {this.renderNotExistsComparator()}
        {this.renderApplyFiltersButton()}
      </>
    );
  }
}

Number.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  comparator: PropTypes.string,
  onApplyfilter: PropTypes.func,
};

Number.defaultProps = {
  value: null,
  comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
  required: false,
  onApplyfilter: () => {},
};

export default Number;
