export const ATTACHMENT_DRAFT_CREATED = '/sdk/attachment/draft_created';
export const ATTACHMENT_DELETED = '/sdk/attachment/deleted';
export const ATTACHMENT_UPDATED = '/sdk/attachment/updated';

export const ATTACHMENT_CREATED_FOR_CHECKLIST_TEMPLATE_ROW =
  '/sdk/attachment/attachment_created_for_checklist_template_row';

export const ATTACHMENT_REMOVED_FROM_CHECKLIST_TEMPlATE_ROW =
  '/sdk/attachment/attachment_removed_from_checklist_template_row';

export const ATTACHMENT_CREATED_FOR_ASSET = '/sdk/attachment/created_for_asset';
export const ATTACHMENT_CREATED_FOR_MULTIPLE_ASSETS = '/sdk/attachment/created_for_multiple_asset';
export const ATTACHMENT_REMOVED_FROM_ASSET = '/sdk/attachment/removed_from_asset';

export const ATTACHMENT_CREATED_FOR_VENDOR = '/sdk/attachment/created_for_vendor';

export const ATTACHMENT_CREATED_FOR_SPARE_PART = '/sdk/attachment/created_for_spare_part';
export const ATTACHMENT_CREATED_FOR_PURCHASE_ORDER = '/sdk/attachment/created_for_purchase_order';
export const ATTACHMENT_REMOVED_FROM_SPARE_PART = '/sdk/attachment/removed_from_spare_part';
export const ATTACHMENT_CREATED_FOR_MULTIPLE_SPARE_PARTS = '/sdk/attachment/created_for_multiple_spare_parts';

export const REGISTRATION_ATTACHMENT_CREATED_FOR_WORK_ORDER =
  '/sdk/attachment/registration_attachment_created_for_work_order';
export const COMPLETION_ATTACHMENT_CREATED_FOR_WORK_ORDER =
  '/sdk/attachment/completion_attachment_created_for_work_order';
export const REGISTRATION_ATTACHMENT_ADDED_TO_WORK_ORDER =
  '/sdk/attachment/registration_attachment_added_to_work_order';
export const REGISTRATION_ATTACHMENT_REMOVED_FROM_WORK_ORDER =
  '/sdk/attachment/registration_attachment_removed_from_work_order';

export const REGISTRATION_ATTACHMENT_CREATED_FOR_RECURRING_MAINTENANCE =
  '/sdk/attachment/created_for_recurring_maintenance';
export const REGISTRATION_ATTACHMENT_ADDED_TO_RECURRING_MAINTENANCE =
  '/sdk/attachment/registration_attachment_added_to_recurring_maintenance';
export const REGISTRATION_ATTACHMENT_REMOVED_FROM_RECURRING_MAINTENANCE =
  '/sdk/attachment/registration_attachment_removed_from_recurring_maintenance';
