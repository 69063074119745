import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AnimateHeight from 'react-animate-height';
import { Menu } from 'views/components/Shared/General';
import styles from './style.module.scss';

class Expandable extends Component {
  state = {
    expanded: this.props.defaultExpanded,
  };

  render() {
    let classNames = [styles['item']];
    if (this.state.expanded) {
      classNames = [...classNames, styles['expanded']];
    }
    return (
      <>
        <div
          onClick={() => {
            this.setState(prevState => ({
              expanded: !prevState.expanded,
            }));
          }}
          className={classNames.join(' ')}
        >
          <div className={styles['text-container']}>
            <FormattedMessage id="general.more-options" />
          </div>
          <Menu.Item.NavigateButton
            onClick={() => {
              this.setState(prevState => ({
                expanded: !prevState.expanded,
              }));
            }}
          />
        </div>
        <AnimateHeight duration={250} height={this.state.expanded ? 'auto' : 0}>
          <div className={styles['expanded-content']}>{this.props.children}</div>
        </AnimateHeight>
      </>
    );
  }
}

export default Expandable;

Expandable.propTypes = {
  defaultExpanded: PropTypes.bool,
  children: PropTypes.node,
};

Expandable.defaultProps = {
  defaultExpanded: false,
  children: null,
};
