import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConnectedIntlProvider } from 'sdk/Components';
import { AuthOperations, AuthSelectors } from 'state/ducks/auth';
import Routes from './routes';
import styles from './style.scss';
import { AppLoader } from 'views/layout/AppLayout/components';

class App extends Component {
  componentWillMount() {
    if (localStorage.getItem('token')) {
      this.props.prepareAuthenticatedUser();
    } else {
      this.props.unauthenticateUser();
    }
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.isPreparingApp ? (
          <AppLoader />
        ) : (
          <ConnectedIntlProvider language="sv">
            <BrowserRouter>
              <Routes location={this.props.location} authenticated={this.props.authenticated} />
            </BrowserRouter>
          </ConnectedIntlProvider>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      prepareAuthenticatedUser: AuthOperations.prepareAuthenticatedUser,
      unauthenticateUser: AuthOperations.unauthenticateUser,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    authenticated: AuthSelectors.isAuthenticated(state),
    isPreparingApp: AuthSelectors.isPreparingApp(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
