import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { omit } from 'lodash-es';
import { InlineModal, DatePicker, TimePicker, Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class DateTime extends Component {
  dateRef = null;
  timeRef = null;

  constructor(props) {
    super(props);
    this.state = {
      showDatePicker: false,
      showTimePicker: false,
      focused: false,
      dateValue: this.isDateStringValid(props.date) ? this.formatDate(props.date).format('LL') : '',
      date: this.isDateStringValid(props.date) ? this.formatDate(props.date).format('YYYY-MM-DD') : '',
      lastValidDate: this.isDateStringValid(props.date)
        ? this.formatDate(props.date).format('YYYY-MM-DD')
        : '',
      timeValue: this.isTimeStringValid(props.time) ? this.formatTime(props.time).format('HH:mm') : '',
      time: this.isTimeStringValid(props.time) ? this.formatTime(props.time).format('HH:mm') : '',
      lastValidTime: this.isTimeStringValid(props.time) ? this.formatTime(props.time).format('HH:mm') : '',
    };
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.dateRef.select();
    }
  }

  formatDate = date => {
    if (moment(date, 'YYYY-MM-DD').isValid()) return moment(date, 'YYYY-MM-DD');
    return moment(date, 'LL');
  };

  formatTime = time => {
    if (moment(time, 'HH:mm').isValid()) return moment(time, 'HH:mm');
    return moment(time, 'HHmm');
  };

  isTimeStringValid = dateString =>
    moment(dateString, 'HH:mm').isValid() || moment(dateString, 'HHmm').isValid();

  isDateStringValid = dateString =>
    moment(dateString, 'YYYY-MM-DD').isValid() || moment(dateString, 'LL').isValid();

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.date !== this.props.date && this.isDateStringValid(this.props.date)) {
      this.setState({
        dateValue: this.formatDate(this.props.date).format('LL'),
        date: this.formatDate(this.props.date).format('YYYY-MM-DD'),
        lastValidDate: this.formatDate(this.props.date).format('YYYY-MM-DD'),
      });
    }
    if (prevProps.time !== this.props.time && this.isTimeStringValid(this.props.time)) {
      this.setState({
        timeValue: this.formatTime(this.props.time).format('HH:mm'),
        time: this.formatTime(this.props.time).format('HH:mm'),
        lastValidTime: this.formatTime(this.props.time).format('HH:mm'),
      });
    }
  }

  renderIcon = () => {
    if (!this.props.icon) return null;
    return (
      <div className={styles['icon-container']}>
        <Icon type={this.props.icon} />
      </div>
    );
  };

  renderRightLabel = () => {
    if (!this.props.rightLabel) return null;
    return (
      <div
        className={[styles['label-container'], styles['right']].join(' ')}
        onClick={() => this.dateRef.focus()}
      >
        {this.props.rightLabel}
      </div>
    );
  };

  onLeaveDateField = () => {
    this.setState({ focused: false, showDatePicker: false });
    if (!this.state.dateValue) {
      this.setState({
        dateValue: '',
        date: '',
        lastValidDate: '',
      });
      this.props.onChangeDate(null);
      return;
    }
    if (this.isDateStringValid(this.state.dateValue)) {
      this.setState(
        {
          dateValue: this.formatDate(this.state.dateValue).format('LL'),
          date: this.formatDate(this.state.dateValue).format('YYYY-MM-DD'),
          lastValidDate: this.state.dateValue,
        },
        () => {
          this.props.onChangeDate(this.state.date);
        }
      );
    } else {
      this.setState({
        dateValue: this.isDateStringValid(this.state.lastValidDate)
          ? this.formatDate(this.state.lastValidDate).format('LL')
          : '',
        date: this.isDateStringValid(this.state.lastValidDate)
          ? this.formatDate(this.state.lastValidDate).format('YYYY-MM-DD')
          : '',
      });
      this.props.onChangeDate(
        this.isDateStringValid(this.state.lastValidDate)
          ? this.formatDate(this.state.lastValidDate).format('YYYY-MM-DD')
          : null
      );
    }
  };

  onLeaveTimeField = () => {
    this.setState({ focused: false, showTimePicker: false });
    if (!this.state.timeValue) {
      this.setState({
        timeValue: '',
        time: null,
        lastValidTime: null,
      });
      this.props.onChangeTime(null);
      return;
    }
    if (this.isTimeStringValid(this.state.timeValue)) {
      this.setState(
        {
          timeValue: this.formatTime(this.state.timeValue).format('HH:mm'),
          time: this.formatTime(this.state.timeValue).format('HH:mm'),
          lastValidTime: this.formatTime(this.state.timeValue).format('HH:mm'),
        },
        () => {
          this.props.onChangeTime(this.formatTime(this.state.timeValue).format('HH:mm'));
        }
      );
    } else {
      this.setState(
        {
          timeValue: this.isTimeStringValid(this.state.lastValidTime)
            ? this.formatTime(this.state.lastValidTime).format('HH:mm')
            : '',
          time: this.isTimeStringValid(this.state.lastValidTime)
            ? this.formatTime(this.state.lastValidTime).format('HH:mm')
            : null,
        },
        () => {}
      );
      this.props.onChangeTime(
        this.isTimeStringValid(this.state.lastValidTime)
          ? this.formatTime(this.state.lastValidTime).format('HH:mm')
          : null
      );
    }
  };

  hideDateTimePicker = () => {
    this.setState({ focused: false, showDatePicker: false, showTimePicker: false });
  };

  render() {
    let additionalStyles = {};
    let classNames = [styles['input-container']];
    if (this.props.error) {
      classNames = [...classNames, styles['error']];
    }
    if (this.props.disabled) {
      classNames = [...classNames, styles['disabled']];
    }
    if (this.props.rightLabel) {
      classNames = [...classNames, styles['has-right-label']];
    }
    if (this.state.focused) {
      classNames = [...classNames, styles['focused']];
    }

    return (
      <>
        <div
          className={classNames.join(' ')}
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            if (!this.state.focused) {
              this.dateRef.select();
            }
          }}
        >
          {this.renderIcon()}
          <input
            {...omit(this.props, ['rightLabel', 'leftLabel', 'icon', 'autoFocus'])}
            ref={ref => {
              this.dateRef = ref;
            }}
            value={this.state.dateValue}
            onChange={e => {
              this.setState({
                dateValue: e.target.value,
                date: this.formatDate(e.target.value).format('YYYY-MM-DD'),
              });
            }}
            onKeyDown={e => {
              if (e.key === 'Tab') {
                this.onLeaveDateField();
              }
            }}
            onFocus={e => {
              this.dateRef.select();
              this.setState({ focused: true, showDatePicker: true, showTimePicker: false });
              this.props.onFocus();
            }}
            className={`${styles['input']} ${styles['date']}`}
            style={{
              ...additionalStyles,
              minWidth: this.state.dateValue.length === 0 ? '83px' : `${this.state.dateValue.length}ch`,
            }}
          />
          <div className={styles['separator']} />
          <input
            {...omit(this.props, ['rightLabel', 'leftLabel', 'icon', 'autoFocus'])}
            ref={ref => {
              this.timeRef = ref;
            }}
            value={this.state.timeValue}
            onChange={e => {
              this.setState({
                timeValue: e.target.value,
                time: moment(e.target.value, 'HH:mm').format('HH:mm'),
              });
            }}
            onKeyDown={e => {
              if (e.key === 'Tab') {
                this.onLeaveTimeField();
              }
            }}
            onFocus={e => {
              this.timeRef.select();
              this.setState({ focused: true, showTimePicker: true });
              this.props.onFocus();
            }}
            className={`${styles['input']} ${styles['time']}`}
            style={additionalStyles}
          />
          {this.renderRightLabel()}
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showDatePicker}
          onClose={this.onLeaveDateField}
        >
          <InlineModal.Body width={380} paddingTop>
            <DatePicker.Day
              date={this.state.date}
              inline
              onSelectDate={value => {
                this.setState({
                  dateValue: moment(value).format('LL'),
                  date: moment(value).format('YYYY-MM-DD'),
                  lastValidDate: moment(value).format('YYYY-MM-DD'),
                  showDatePicker: false,
                  showTimePicker: true,
                });
                this.props.onChangeDate(value);
              }}
            />
          </InlineModal.Body>
        </InlineModal>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showTimePicker}
          onClose={this.onLeaveTimeField}
        >
          <InlineModal.Header
            onClose={() => this.setState({ showTimePicker: false })}
            title={
              <div className={styles['time-picker-header']}>
                <p className={styles['text']}>
                  {!this.state.date ? (
                    <FormattedMessage id="components.date-time-field.no-date-set" />
                  ) : (
                    moment(this.state.date).format('LL')
                  )}
                  <span className={styles['divider']}> - </span>
                  <Button
                    type="text"
                    label="components.date-time-field.change-date"
                    primary
                    noUnderline
                    fontSize={12}
                    onClick={() => this.setState({ showDatePicker: true, showTimePicker: false })}
                  />
                </p>
              </div>
            }
          />

          <InlineModal.Body width={380}>
            <TimePicker
              time={this.state.time}
              inline
              onSelectMinute={value => {
                this.setState({
                  timeValue: moment(value, 'HH:mm').format('HH:mm'),
                  time: moment(value, 'HH:mm').format('HH:mm'),
                  lastValidTime: moment(value, 'HH:mm').format('HH:mm'),
                  showTimePicker: false,
                  focused: false,
                });
                this.props.onChangeTime(value);
              }}
            />
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  }
}

DateTime.propTypes = {
  onChangeDate: PropTypes.func,
  onChangeTime: PropTypes.func,
  onFocus: PropTypes.func,
  icon: PropTypes.string,
  alignRight: PropTypes.bool,
  rightLabel: PropTypes.node,
};
DateTime.defaultProps = {
  onChangeDate: () => {},
  onChangeTime: () => {},
  onFocus: () => {},
  alignRight: false,
};
