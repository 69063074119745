import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Login } from 'views/scenes';
import PrivateApp from './PrivateApp'

const Routes = (props) => (
  <Switch>
    <Route path="/login" component={Login} />
    <PrivateApp {...props} />
  </Switch>
);

export default Routes;
