import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const fetchChecklistTemplateLink = id => request.get(`checklist_template_links/${id}`);
export const createChecklistTemplateLinkForWorkOrder = (workOrderId, data) =>
  request.post(`work_orders/${workOrderId}/checklist_template_links`, data);
export const createChecklistTemplateLinkForRecurringMaintenance = (recurringMaintenanceId, data) =>
  request.post(`recurring_maintenances/${recurringMaintenanceId}/checklist_template_links`, data);
export const deleteChecklistTemplateLink = checklistTemplateLinkId =>
  request.delete(`checklist_template_links/${checklistTemplateLinkId}`);
