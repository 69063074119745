import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listContactPersonsForVendor = (vendorId, params) =>
  request.get(`vendors/${vendorId}/contact_persons?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const fetchContactPerson = contactPersonId => request.get(`contact_persons/${contactPersonId}`);
export const createContactPerson = (vendorId, data) =>
  request.post(`vendors/${vendorId}/contact_persons`, data);
export const updateContactPerson = (contactPersonId, data) =>
  request.patch(`contact_persons/${contactPersonId}`, data, {
    id: contactPersonId,
  });
export const deleteContactPerson = contactPersonId => request.delete(`contact_persons/${contactPersonId}`);
