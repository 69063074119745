import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { ErrorRoute } from 'views/layout';
import { Organisations, Billing, Developer, OrganisationDetail } from 'views/scenes';
import AppLayout from 'views/layout/AppLayout';

class PrivateApp extends Component {
  render() {
    if (this.props.location.pathname === '/') {
      return <Redirect to={{ pathname: '/organisations', search: 'list=customer' }} />;
    }
    if (this.props.authenticated) {
      return (
        <AppLayout currentRoute={this.props.location.pathname}>
          <Switch location={this.rootLocation}>
            <Route path="/organisations" exact component={Organisations} />
            <Route path="/organisations/:id" component={OrganisationDetail} />
            <Route path="/billing" component={Billing} />
            <Route path="/developer" component={Developer} />
            <Route component={ErrorRoute} {...this.props} />
          </Switch>
        </AppLayout>
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: this.props.location },
          }}
        />
      );
    }
  }
}

export default injectIntl(withRouter(connect(null)(PrivateApp)));

PrivateApp.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};
PrivateApp.defaultProps = {
  authenticated: false,
};
