import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import * as moment from 'moment';
import { API } from 'sdk';
import { Button, List, Icon, WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import { Toolbar } from 'views/components/Shared/Layout';
import CreateImportModal from './CreateImportModal';
import EditImportModal from './EditImportModal';

class Imports extends Component {
  state = {
    data: null,
    isLoadingView: true,
    showCreateImportModal: false,
    showEditImportModal: false,
    editImport: null,
  };

  componentDidMount() {
    this.loadImports();
  }

  loadImports = () => {
    this.setState({ isLoadingView: true });
    API.listImportsAsAdmin().then(({ data }) => {
      this.setState({
        data,
        isLoadingView: false,
      });
    });
  };

  renderCreateImportModal = () => {
    return (
      <CreateImportModal
        open={this.state.showCreateImportModal}
        onClose={() => {
          this.setState({ showCreateImportModal: false });
        }}
        onSave={() => {
          this.loadImports();
          this.setState({ showCreateImportModal: false });
        }}
      />
    );
  };

  renderEditImportModal = () => {
    return (
      <EditImportModal
        open={this.state.showEditImportModal}
        import={this.state.editImport}
        onClose={() => {
          this.setState({ showEditImportModal: false, editImport: null });
        }}
        onDelete={() => {
          this.loadImports();
          this.setState({ showEditImportModal: false, editImport: null });
        }}
      />
    );
  };

  renderHeader = () => (
    <List.Header small background>
      <List.Header.Column flex>Path</List.Header.Column>
      <List.Header.Column alignRight>Is filled in</List.Header.Column>
    </List.Header>
  );

  renderToolbar = () => (
    <Toolbar
      buttons={
        <Button
          primary
          label="Create import form"
          onClick={() => this.setState({ showCreateImportModal: true })}
        />
      }
    />
  );

  renderContent = () => {
    if (this.state.isLoadingView) {
      return (
        <>
          {this.renderToolbar()}
          {this.renderHeader()}
          <List small>
            <List.Item small>
              <List.Item.TitleColumn loading />
            </List.Item>
            <List.Item small>
              <List.Item.TitleColumn loading />
            </List.Item>
          </List>
        </>
      );
    }
    if (this.state.data.length === 0) {
      return (
        <WhiteCard centerContent>
          <EmptyDataSet
            title={<span>There are no imports</span>}
            subtitle={<span>No imports are active or has been filled in</span>}
            button={
              <Button
                primary
                label="Create import form"
                onClick={() => this.setState({ showCreateImportModal: true })}
              />
            }
          />
        </WhiteCard>
      );
    }
    return (
      <>
        {this.renderToolbar()}
        {this.renderHeader()}
        <List small>
          {this.state.data.map(item => (
            <List.Item
              onClick={() => {
                this.setState({ showEditImportModal: true, editImport: item });
              }}
              clickable
              small
            >
              <List.Item.TitleColumn title={item.s3_path} subtitle={moment(item.created_at).format('LL')} />
              <List.Item.Column right>
                <Icon
                  green={item.config_data != null}
                  lightGray={item.config_data == null}
                  regular
                  withBackground
                  backgroundSize={22}
                  size={14}
                  type="check"
                />
              </List.Item.Column>
            </List.Item>
          ))}
        </List>
      </>
    );
  };

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderCreateImportModal()}
        {this.renderEditImportModal()}
      </>
    );
  }
}

export default withRouter(injectIntl(Imports));
