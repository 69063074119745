import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { Button, Icon } from 'views/components/Shared/General';

export default class Header extends Component {
  renderSubtitle = () => {
    if (!this.props.subtitle) return null;
    return <p className={styles['subtitle']}>{this.props.subtitle}</p>;
  };

  render() {
    return (
      <div className={styles['header']}>
        <div className={styles['title-container']}>
          <p className={styles['title']}>{this.props.title}</p>
          {this.renderSubtitle()}
        </div>
        <div className={styles['icon']}>
          <Button type="icon" icon={<Icon light size={18} type="times" />} onClick={this.props.onClose} />
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.element,
  subtitle: PropTypes.element,
};

Header.defaultProps = {
  onClose: () => {},
  title: null,
  subtitle: null,
};
