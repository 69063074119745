export const IMAGE_DRAFT_CREATED = '/sdk/image/draft_created';
export const IMAGE_CREATED_FOR_REQUEST = '/sdk/image/created_for_request';
export const REGISTRATION_IMAGE_CREATED_FOR_WORK_ORDER =
  '/sdk/image/registration_image_created_for_work_order';
export const COMPLETION_IMAGE_CREATED_FOR_WORK_ORDER = '/sdk/image/completion_image_created_for_work_order';
export const IMAGE_CREATED_FOR_CHECKLIST_INSTANCE_ROW =
  '/sdk/image/completion_image_created_for_checklist_instance_row';
export const IMAGE_CREATED_FOR_CHECKLIST_TEMPLATE_ROW =
  '/sdk/image/completion_image_created_for_checklist_template_row';
export const IMAGE_CREATED_FOR_SPARE_PART = '/sdk/image/created_for_spare_part';
export const IMAGE_CREATED_FOR_RECURRING_MAINTENANCE = '/sdk/image/created_for_recurring_maintenance';
export const IMAGE_CREATED_FOR_ASSET = '/sdk/image/created_for_asset';
export const IMAGE_CREATED_FOR_WORK_ORDER_SPENT_TIME = '/sdk/image/created_for_work_order_spent_time';
export const IMAGE_CREATED_FOR_SYSTEM_LOGO = '/sdk/image/created_for_system_logo';
export const IMAGE_UPDATED = '/sdk/image/updated';
export const IMAGE_DELETED = '/sdk/image/deleted';
