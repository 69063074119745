import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.systemById) {
        return HelperFunctions.mergeEntities(state, action.payload.systemById);
      }
      return state;

    case SDKReduxTypes.IMAGE_CREATED_FOR_SYSTEM_LOGO: {
      const {
        systemId,
        data: { id },
      } = action.payload;

      if (!state[systemId]) return state;

      return update(state, {
        [systemId]: {
          logo_image_id: {
            $set: id,
          },
        },
      });
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId } = action.payload;

      return Object.entries(state).reduce((acc, [id, system]) => {
        return {
          ...acc,
          [id]: {
            ...system,
            logo_image_id: system.logo_image_id === imageId ? null : system.logo_image_id,
          },
        };
      }, {});
    }
    default:
      return state;
  }
};
