import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeBillingDiscount } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createBillingDiscountAsAdmin(systemId, params) {
  return dispatch => {
    return API.createBillingDiscountAsAdmin(systemId, params)
      .then(res => {
        const { entities } = normalizeBillingDiscount(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.BILLING_DISCOUNT_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateBillingDiscountAsAdmin(billingDiscountId, params) {
  return dispatch => {
    return API.updateBillingDiscountAsAdmin(billingDiscountId, params)
      .then(res => {
        const { entities } = normalizeBillingDiscount(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { billingDiscountId, params, data: res.data };
        dispatch({ type: types.BILLING_DISCOUNT_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteBillingDiscountAsAdmin(billingDiscountId) {
  return dispatch => {
    return API.deleteBillingDiscountAsAdmin(billingDiscountId)
      .then(res => {
        const payload = { billingDiscountId };
        dispatch({ type: types.BILLING_DISCOUNT_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
