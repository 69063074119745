import { mergeWith } from 'lodash-es';
import EntityTypes from '../types';

/*
  NOTE:

  Race condition while opening notification and navigating to the list.

  When Opening notification:
  1. updateNotification(opened: true)
  2. Navigate to list and run fetchNotifications() (here lies the problem)
  3. The list call arrives later than the updateNotification which updates opened to the wrong value (false).
  
  Therefore we customise the update with the function 'fixOpenedRaceConditionMerge'.
  So when we try to update true with false it keeps the true value.
  This works because a notification cant go from opened false from true.
*/

function fixOpenedRaceConditionMerge(objValue, srcValue, key) {
  if ((key === 'opened' && srcValue === true) || objValue === true) {
    return true;
  }
}
export default (state = {}, action) => {
  switch (action.type) {
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.notificationById) {
        return mergeWith({}, state, action.payload.notificationById, fixOpenedRaceConditionMerge);
      }
      return state;
    default:
      return state;
  }
};
