import React from 'react';
import { Decimal } from 'decimal.js';

export default ({ value, unit, decimalScale = 2, valueSize = null, abbreviationSize = null }) => {
  if (value == null) return '-';
  if (value.length === 0) return '-';

  const renderValue = () => {
    let decimalValue = Decimal.isDecimal(value) ? value : new Decimal(value);
    decimalValue = parseFloat(decimalValue.toFixed(decimalScale));
    return new Decimal(decimalValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  return (
    <>
      <span style={{ fontSize: valueSize }}>{renderValue()}</span>
      <span> </span>
      <span style={{ fontSize: abbreviationSize }}>{unit}</span>
    </>
  );
};
