import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import { Status } from 'sdk/ChecklistTemplateVersion';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.checklistTemplateLinkById) {
        return HelperFunctions.mergeEntities(state, action.payload.checklistTemplateLinkById);
      }
      return state;

    default:
      return state;
  }
};
