import types from './types';
import update from 'immutability-helper';
import { mergeWith } from 'lodash-es';
import { MenuTypes } from 'state/ducks/menu';

const INITIAL_STATE = {
  ids: [],
  navigatedTo: {
    search: {},
  },
  pagination: {},
  queryParams: {
    page: 1,
    page_size: 25,
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MenuTypes.RESET_LIST_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.SET_NAVIGATED_TO: {
      const { search } = action.payload;
      return update(state, {
        navigatedTo: {
          search: {
            $set: search,
          },
        },
      });
    }
    case types.ADD_QUERY_PARAMETER: {
      return update(state, {
        queryParams: {
          $apply: filters =>
            mergeWith({}, filters, action.payload, (a, b) => (Array.isArray(b) ? b : undefined)),
        },
      });
    }
    case types.FETCH_ORGANISATIONS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        ids,
        isFetching: false,
        pagination,
      };
    }
    default:
      return state;
  }
};
