import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listSparePartAssets = (systemId, params) =>
  request.get(`systems/${systemId}/spare_part_assets?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const createSparePartAsset = (systemId, data) =>
  request.post(`systems/${systemId}/spare_part_assets`, data);
export const fetchSparePartAsset = sparePartId => request.get(`spare_part_assets/${sparePartId}`);
export const updateSparePartAsset = (sparePartId, data) =>
  request.patch(`spare_part_assets/${sparePartId}`, data);
export const deleteSparePartAsset = sparePartId => request.delete(`spare_part_assets/${sparePartId}`);
