import React, { Component } from 'react';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class Breadcrumb extends Component {
  render() {
    let children = React.Children.toArray(this.props.children);

    children = React.Children.map(children, (child, index) => {
      if (index < children.length - 1) {
        return (
          <React.Fragment>
            {child}
            <div className={styles['icon-container']}>
              <Icon regular type="angle-right" size={12} />
            </div>
          </React.Fragment>
        );
      } else {
        return React.cloneElement(child, { active: true });
      }
    });

    return <div className={styles['breadcrumb']}>{children}</div>;
  }
}
