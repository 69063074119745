import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeRequestAutomationRule } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createRequestAutomationRule(systemId, params) {
  return dispatch => {
    return API.createRequestAutomationRule(systemId, params)
      .then(res => {
        const { entities } = normalizeRequestAutomationRule(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.REQUEST_AUTOMATION_RULE_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateRequestAutomationRule(requestAutomationRuleId, params) {
  return dispatch => {
    return API.updateRequestAutomationRule(requestAutomationRuleId, params)
      .then(res => {
        const { entities } = normalizeRequestAutomationRule(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { requestAutomationRuleId, params, data: res.data };
        dispatch({ type: types.REQUEST_AUTOMATION_RULE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteRequestAutomationRule(requestAutomationRuleId) {
  return dispatch => {
    return API.deleteRequestAutomationRule(requestAutomationRuleId)
      .then(res => {
        const payload = { requestAutomationRuleId };
        dispatch({ type: types.REQUEST_AUTOMATION_RULE_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
