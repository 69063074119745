import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizePurchaseOrderRow } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createPurchaseOrderRowForPurchaseOrder(purchaseOrderId, params) {
  return dispatch => {
    return API.createPurchaseOrderRowForPurchaseOrder(purchaseOrderId, params)
      .then(res => {
        const { entities } = normalizePurchaseOrderRow(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { purchaseOrderId, params, data: res.data };
        dispatch({ type: types.PURCHASE_ORDER_ROW_CREATED_FOR_PURCHASE_ORDER, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updatePurchaseOrderRow(purchaseOrderRowId, params) {
  return dispatch => {
    return API.updatePurchaseOrderRow(purchaseOrderRowId, params)
      .then(res => {
        const { entities } = normalizePurchaseOrderRow(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { purchaseOrderRowId, params, data: res.data };
        dispatch({ type: types.PURCHASE_ORDER_ROW_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deletePurchaseOrderRow(purchaseOrderRowId) {
  return (dispatch, getState) => {
    const purchaseOrderRow = getState().entities.purchaseOrderRowById[purchaseOrderRowId];
    return API.deletePurchaseOrderRow(purchaseOrderRowId)
      .then(res => {
        const payload = { purchaseOrderRowId, data: purchaseOrderRow };
        dispatch({ type: types.PURCHASE_ORDER_ROW_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
