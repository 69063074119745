import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'views/components/Shared/General';
import styles from './style.module.scss';

class Comparator extends Component {
  renderChildren = () => {
    if (this.props.children) {
      let classNames = [styles['field']];
      if (this.props.selected) {
        classNames = [...classNames, styles['selected']];
      }
      return <div className={classNames.join(' ')}>{this.props.children}</div>;
    }
    return null;
  };

  render() {
    return (
      <div className={styles['option']} onClick={this.props.onClick}>
        <div className={styles['radio']}>
          <Field.Checkbox radio checked={this.props.selected} onChange={this.props.onClick} />
        </div>
        <div className={styles['content']}>
          <div className={styles['title']}>{this.props.title}</div>
          <div className={styles['subtitle']}>{this.props.subtitle}</div>
          {this.renderChildren()}
        </div>
      </div>
    );
  }
}

Comparator.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Comparator.detaultProps = {
  title: null,
  subtitle: null,
  children: null,
  onClick: () => {},
};

export default Comparator;
