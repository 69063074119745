import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SystemTier } from 'sdk/System';
import { OrganisationSelectors } from 'state/ducks/organisation';
import { WhiteCard, Field, Icon, MoneyWithCurrency, List } from 'views/components/Shared/General';
import DiscountsModal from '../../../components/DiscountsModal';
import BillingFrequencyModal from './BillingFrequencyModal';
import styles from './style.module.scss';

const MetaInfo = ({ organisation, loading, billingDiscountIds }) => {
  const [discountModalOpen, setDiscountModalOpen] = useState(false);
  const [billingFrequencyModalOpen, setBillingFrequencyModalOpen] = useState(false);

  const calculateRevenue = () => {
    var price = 0;
    if (organisation.billing_tier === SystemTier.Basic) {
      price = 299;
    } else {
      price = 699;
    }

    var userCount = organisation.billable_user_count;
    if (organisation.billable_users_cap && organisation.billable_users_cap < userCount)
      userCount = organisation.billable_users_cap;

    return userCount * price;
  };

  const formatBillingFrequency = () => {
    return organisation.billing_frequency === null ? (
      '-'
    ) : (
      <span style={{ textTransform: 'capitalize' }}>{organisation.billing_frequency}</span>
    );
  };

  const renderDiscountsModal = () => {
    return (
      <DiscountsModal
        open={discountModalOpen}
        onClose={() => {
          setDiscountModalOpen(false);
        }}
      />
    );
  };

  const renderBillingFrequencyModal = () => {
    return (
      <BillingFrequencyModal
        open={billingFrequencyModalOpen}
        onClose={() => {
          setBillingFrequencyModalOpen(false);
        }}
      />
    );
  };

  const renderBilledUntil = () => {
    const today = new Date();
    const billedExpirationDate = new Date(organisation.billed_until);
    if (organisation.billed_until != null && billedExpirationDate >= today) {
      return (
        <Field
          icon={<Icon type="dollar-sign" regular withBackground backgroundSize={34} size={16} />}
          value="Billed until"
          fontSize={13}
          subtitle="Billed until"
        >
          <div className={styles['field-child']}>{moment(organisation.billed_until).format('LL')}</div>
        </Field>
      );
    }
    return;
  };

  const renderBillingFrequency = () => (
    <div onClick={() => setBillingFrequencyModalOpen(true)}>
      <Field
        icon={<Icon type="file-invoice-dollar" blue withBackground backgroundSize={34} size={16} />}
        value="Billing frequency"
        fontSize={13}
        subtitle="Billing Frequency"
      >
        <div className={styles['field-child']}>{formatBillingFrequency()}</div>
      </Field>
    </div>
  );

  const renderCredits = () => {
    if (organisation.status === 'demo') return;
    return (
      <Field
        icon={<Icon type="credit-card-front" regular withBackground backgroundSize={34} size={16} />}
        value="Credits"
        fontSize={13}
        subtitle="Credits"
      >
        <div className={styles['field-child']}>
          <MoneyWithCurrency
            currency="SEK"
            value={organisation.billing_credit}
            fractionSize={2}
            currencyFontSize={13}
          />
        </div>
      </Field>
    );
  };
  const renderDiscount = () => (
    <div onClick={() => setDiscountModalOpen(true)}>
      <Field
        icon={<Icon type="exclamation-circle" red regular withBackground backgroundSize={34} size={16} />}
        value="Discount"
        fontSize={13}
        subtitle="Discount"
      >
        <div className={styles['field-child']}>{billingDiscountIds.length} Discounts</div>
      </Field>
    </div>
  );

  const renderMonthlyRevenue = () => (
    <Field
      icon={<Icon type="poll-h" regular withBackground backgroundSize={34} size={16} />}
      value="Revenue"
      fontSize={13}
      subtitle="Monthly Revenue"
    >
      <div className={styles['field-child']}>
        {organisation.demo ? (
          <MoneyWithCurrency
            currency="SEK"
            value={calculateRevenue()}
            fractionSize={0}
            currencyFontSize={13}
          />
        ) : (
          <MoneyWithCurrency
            currency="SEK"
            value={calculateRevenue()}
            fractionSize={0}
            currencyFontSize={13}
          />
        )}
      </div>
    </Field>
  );
  const renderUnbilledRevenue = () => {
    if (organisation.status === 'demo') return;
    return (
      <Field
        icon={<Icon type="dollar-sign" regular withBackground backgroundSize={34} size={16} />}
        value="Unbilled revenue"
        fontSize={13}
        subtitle="Unbilled revenue"
      >
        <div className={styles['field-child']}>
          <MoneyWithCurrency
            currency="SEK"
            value={organisation.unbilled_revenue}
            fractionSize={2}
            currencyFontSize={13}
          />
        </div>
      </Field>
    );
  };
  return (
    <>
      <WhiteCard noPadding>
        {loading ? (
          <List.Item>
            <List.Item.ImageColumn loading />
            <List.Item.TitleColumn loading />
          </List.Item>
        ) : (
          <>
            <div className={styles['static-overview']}>
              {renderMonthlyRevenue()}
              {renderUnbilledRevenue()}
              {renderCredits()}
              {renderBilledUntil()}
            </div>
            <div className={styles['overview']}>
              {renderDiscount()}
              {renderBillingFrequency()}
            </div>
          </>
        )}
      </WhiteCard>
      {renderDiscountsModal()}
      {renderBillingFrequencyModal()}
    </>
  );
};
function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
    billingDiscountIds: OrganisationSelectors.getBillingDiscountIds(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps)(MetaInfo)));
