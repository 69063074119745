import React from 'react';
import PropTypes from 'prop-types';
import Content from './Content';
import styles from './style.module.scss';

const Menu = props => {
  return <div className={styles['left-panel']}>{props.children}</div>;
};

Menu.Content = Content;
export default Menu;

Menu.propTypes = {
  LeftPanel: PropTypes.node,
};

Menu.defaultProps = {
  children: null,
};
