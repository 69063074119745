import React, { Component } from 'react';
import ChangeTierModal from './ChangeTierModal';
import TierConfirmationModal from './TierConfirmationModal';

export default class TierModal extends Component {
  state = {
    showChangeTierModal: false,
    showConfirmationModal: false,
    tierAction: null,
  };

  componentDidMount() {
    this.setState({ showChangeTierModal: true });
  }

  renderChangeTierModal = () => {
    return (
      <ChangeTierModal
        open={this.state.showChangeTierModal && this.props.open}
        onTierActionSelected={type => {
          this.setState({
            showChangeTierModal: false,
            tierAction: type,
          });
          setTimeout(() => {
            this.setState({ showConfirmationModal: true });
          }, 100);
        }}
        onClose={() => {
          this.props.onClose();
        }}
      />
    );
  };

  renderConfirmationModal = () => {
    return (
      <TierConfirmationModal
        open={this.state.showConfirmationModal && this.props.open}
        tierType={this.state.tierAction}
        onClose={updated => {
          this.setState({
            showConfirmationModal: false,
          });
          if (updated) {
            this.props.onClose();
          }
          setTimeout(() => {
            this.setState({ showChangeTierModal: true, tierAction: null });
          }, 100);
        }}
      />
    );
  };
  render() {
    return (
      <>
        {this.renderChangeTierModal()}
        {this.renderConfirmationModal()}
      </>
    );
  }
}
