import update from 'immutability-helper';
import { HelperFunctions, SDKReduxTypes } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId, data } = action.payload;
      if (!data) return state;

      const { checklist_template_row_id } = data;
      if (state[checklist_template_row_id]) {
        return {
          ...state,
          [checklist_template_row_id]: {
            ...state[checklist_template_row_id],
            images: state[checklist_template_row_id].images.filter(id => id !== imageId),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_CREATED_FOR_CHECKLIST_TEMPLATE_ROW: {
      const { data } = action.payload;
      const { checklist_template_row_id, id } = data;
      if (state[checklist_template_row_id]) {
        return update(state, {
          [checklist_template_row_id]: {
            images: {
              $push: [id],
            },
          },
        });
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_CREATED_FOR_CHECKLIST_TEMPLATE_ROW: {
      const { checklistTemplateRowId, data, params } = action.payload;
      const { type } = params;
      if (type === 'link' && state[checklistTemplateRowId]) {
        return update(state, {
          [checklistTemplateRowId]: {
            attachments: {
              $push: [data.id],
            },
          },
        });
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_REMOVED_FROM_CHECKLIST_TEMPlATE_ROW: {
      const { checklistTemplateRowId, attachmentId } = action.payload;
      if (state[checklistTemplateRowId]) {
        return {
          ...state,
          [checklistTemplateRowId]: {
            ...state[checklistTemplateRowId],
            attachments: state[checklistTemplateRowId].attachments.filter(id => id !== attachmentId),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_VERSION_UPLOADED: {
      const { data, meta } = action.payload;
      const { attachment_id } = data;
      const { checklistTemplateRowId } = meta;
      if (state[checklistTemplateRowId]) {
        return update(state, {
          [checklistTemplateRowId]: {
            attachments: {
              $push: [attachment_id],
            },
          },
        });
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.checklistTemplateRowById) {
        return HelperFunctions.mergeEntities(state, action.payload.checklistTemplateRowById);
      }
      return state;
    default:
      return state;
  }
};
