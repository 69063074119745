import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listSpareParts = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/spare_parts?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const listSparePartsForStocktaking = (stocktakingId, params, config = null) =>
  request.get(
    `stocktakings/${stocktakingId}/spare_parts?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const fetchSparePart = sparePartId => request.get(`spare_parts/${sparePartId}`);
export const fetchSparePartForStocktaking = (sparePartId, stocktakingId) =>
  request.get(`stocktakings/${stocktakingId}/spare_parts/${sparePartId}`);
export const updateSparePart = (sparePartId, data) => request.patch(`spare_parts/${sparePartId}`, data);
export const duplicateSparePart = (sparePartId, data) =>
  request.put(`spare_parts/${sparePartId}/duplicate`, data);
export const createSparePart = (systemId, data) => request.post(`systems/${systemId}/spare_parts`, data);
export const deleteSparePart = sparePartId => request.delete(`spare_parts/${sparePartId}`);
export const addMultipleSparePartsToAttachment = (attachmentId, data) =>
  request.put(`attachments/${attachmentId}/add_spare_parts`, data);
export const fetchSparePartLowStockQuantityCount = systemId =>
  request.get(`systems/${systemId}/spare_part_low_stock_quantity_count`);
export const batchUpdateSpareParts = (systemId, data) =>
  request.put(`systems/${systemId}/spare_parts/batch`, data);
