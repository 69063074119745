import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const updatePurchaseOrderRow = (purchaseOrderRowId, data) =>
  request.patch(`purchase_order_rows/${purchaseOrderRowId}`, data);
export const createPurchaseOrderRowForPurchaseOrder = (purchaseOrderId, data) =>
  request.post(`purchase_orders/${purchaseOrderId}/purchase_order_rows`, data);
export const deletePurchaseOrderRow = purchaseOrderRowId =>
  request.delete(`purchase_order_rows/${purchaseOrderRowId}`);
export const listPurchaseOrderDeliveryRowsForPurchaseRow = purchaseOrderRowId =>
  request.get(`purchase_order_rows/${purchaseOrderRowId}/purchase_order_delivery_rows`);
