import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { OrganisationOperations, OrganisationSelectors } from 'state/ducks/organisation';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ContentContainer } from 'views/components/Shared/Layout';
import { List, Pagination, NewSearchField, WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import UserListItem from './UserListItem';
import ContactPersonSmall from 'assets/images/EmptyDataSet/ContactPersonSmall.png';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import styles from './style.module.scss';

class Users extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);
    this.state = {
      isViewLoading: true,
      isTableLoading: false,
      searchValue: queryParams.search || '',
      queryParams: queryParams,
    };
  }

  componentDidMount() {
    this.setState({ isTableLoading: true });
    this.fetchUsers();
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ isTableLoading: true });
        this.fetchUsers();
      });
    }
  }

  changeQueryParams = obj => {
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  fetchUsers = params => {
    const queryParams = this.state.queryParams;
    this.setState({ isTableLoading: true });
    this.props
      .fetchUsers(this.props.match.params.id, queryParams)
      .then(users => {
        this.setState({
          isTableLoading: false,
          isViewLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isTableLoading: false });
      });
  };

  isFiltering = () => this.state.searchValue !== '';

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title="There are no users in this system yet"
        subtitle="Add users to the system in order to view them here"
        image={ContactPersonSmall}
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>{this.props.pagination.totalEntries} användare</p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderSearchField = () => (
    <div className={styles['search-container']}>
      <NewSearchField
        small
        againstGrayBackground
        debounce
        value={this.state.searchValue}
        placeholder="Search"
        onSearch={value => {
          this.setState({
            searchValue: value,
            isTableLoading: true,
          });
        }}
        onDebouncedSearch={value => {
          this.changeQueryParams({ search: value, page: null });
        }}
        onClear={() => {
          this.setState({ isTableLoading: true, searchValue: '' });
          this.changeQueryParams({ search: '', page: null });
        }}
      />
    </div>
  );

  renderEmptySearch = () => {
    if (this.props.userIds.length > 0 || !this.isFiltering() || this.state.isTableLoading) return null;

    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title="No users with this name"
          subtitle="Edit the search term and try again."
          image={SearchImage}
          tiny
          horizontal
          listContainer
        />
      </WhiteCard>
    );
  };

  renderList = () => {
    if (this.state.isTableLoading) {
      return (
        <>
          <List>
            {Array(this.props.userIds.length === 0 ? 1 : this.props.userIds.length)
              .fill()
              .map(() => (
                <UserListItem loading />
              ))}
          </List>
        </>
      );
    }
    if (this.props.userIds.length === 0) return null;
    return (
      <>
        <List>
          {this.props.userIds.map(userId => {
            return <UserListItem key={userId} id={userId} />;
          })}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  renderUsers = () => {
    return (
      <>
        <div className={styles['toolbar']}>{this.renderSearchField()}</div>
        <List.Header small background>
          <List.Header.Column flex>Name</List.Header.Column>
        </List.Header>
        {this.state.isViewLoading ? (
          <List>
            <List.Item small>
              <List.Item.TitleColumn loading />
            </List.Item>
            <List.Item small>
              <List.Item.TitleColumn loading />
            </List.Item>
          </List>
        ) : (
          this.renderList()
        )}
        {this.renderEmptySearch()}
      </>
    );
  };

  renderView = () => {
    if (this.props.userIds.length === 0 && !this.isFiltering() && !this.state.isTableLoading)
      return this.renderEmptyDataset();
    else return this.renderUsers();
  };

  render() {
    return (
      <PerfectScrollbar>
        <ContentContainer>{this.renderView()}</ContentContainer>
      </PerfectScrollbar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUsers: OrganisationOperations.fetchUsers,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    userIds: OrganisationSelectors.getUserIds(state),
    pagination: OrganisationSelectors.getUsersPagination(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Users)));
