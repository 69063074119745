import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { bindActionCreators } from 'redux';
import { OrganisationSelectors, OrganisationOperations } from 'state/ducks/organisation';
import { Modal } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { Button, List, EmptyDataSet } from 'views/components/Shared/General';
import AdditionalCostModalListItem from './AdditionalCostModalListItem';
import styles from './style.module.scss';
import CostStatistics from 'assets/images/CostStatistics.png';

class AdditionalCostsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDoneFetching: false,
    };
  }

  isLoaded = () =>
    this.props.billingAdditionalCostIds != null && this.props.billingAdditionalCostIds.length !== 0;

  componentDidMount() {
    this.fetchBillingAdditionalCosts();
    this.setState({ isLoading: true, isDoneFetching: false });
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  fetchBillingAdditionalCosts = params => {
    this.props
      .fetchBillingAdditionalCosts(this.props.match.params.id)
      .then(additionalCosts => {
        this.setState({ isTableLoading: false, isDoneFetching: true });
      })
      .catch(() => {
        this.setState({ isTableLoading: false, isDoneFetching: true });
      });
  };

  renderContentLoader = () => (
    <List.Item>
      <List.Item.TitleColumn loading />
    </List.Item>
  );

  renderAdditionalCosts = () => {
    if (!this.isLoaded() && !this.state.isDoneFetching) return this.renderContentLoader();
    if (this.state.isDoneFetching && this.props.billingAdditionalCostIds.length === 0) {
      return this.renderEmptyDataSet();
    }
    return this.props.billingAdditionalCostIds.map(additionalCostId => {
      return (
        <AdditionalCostModalListItem
          onClose={() => this.props.onClose()}
          onClick={() => {
            this.props.onDetailSelected(additionalCostId);
          }}
          id={additionalCostId}
          key={additionalCostId}
        />
      );
    });
  };

  renderEmptyDataSet = () => {
    return (
      <EmptyDataSet
        title="Create new costs to include in the next billing"
        subtitle="Anything not included in monthly billing should be added here."
        image={CostStatistics}
        tiny
        horizontal
        listContainer
      />
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={560}>
          <Modal.Header
            ignoreLine
            title="Additional costs"
            subtitle={
              this.props.billingAdditionalCostIds.length === 0 ? (
                <p>
                  There are no additional costs for the system{' '}
                  <span className={styles['subtitle-bold']}>{this.props.organisation.internal_name}</span>
                </p>
              ) : (
                <>
                  <p>
                    Current additional costs for{' '}
                    <span className={styles['subtitle-bold']}>{this.props.organisation.internal_name}</span>
                  </p>
                </>
              )
            }
            onClose={() => {
              this.props.onClose();
            }}
          />
          <Modal.Content>
            <Button primary label="Create new" onClick={() => this.props.onDetailSelected(null)} />

            <div className={styles['separator']} />
            <List light>{this.renderAdditionalCosts()}</List>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createBillingAdditionalCostAsAdmin: SDKReduxOperations.createBillingAdditionalCostAsAdmin,
      fetchBillingAdditionalCosts: OrganisationOperations.fetchBillingAdditionalCosts,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const billingAdditionalCostIds = OrganisationSelectors.getBillingAdditionalCostIds(state);
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
    billingAdditionalCostIds,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdditionalCostsList)));
