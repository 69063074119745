import React, { Component } from 'react';
import { FormattedPlural, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { List } from 'views/components/Shared/General';
import Button from './Button';
import styles from './style.module.scss';

class MultipleOptions extends Component {
  render() {
    return (
      <>
        <List.Header.Column>{this.props.checkbox}</List.Header.Column>
        <List.Header.Column flex>
          <div className={styles['options']}>
            <div className={styles['count']}>
              <FormattedPlural
                value={this.props.count}
                zero={<FormattedMessage id="general.amount-selected.zero" />}
                one={<FormattedMessage id="general.amount-selected.one" />}
                two={
                  <FormattedMessage id="general.amount-selected.two" values={{ amount: this.props.count }} />
                }
                few={
                  <FormattedMessage id="general.amount-selected.few" values={{ amount: this.props.count }} />
                }
                many={
                  <FormattedMessage id="general.amount-selected.many" values={{ amount: this.props.count }} />
                }
                other={
                  <FormattedMessage
                    id="general.amount-selected.other"
                    values={{ amount: this.props.count }}
                  />
                }
              />
            </div>
            <div className={styles['buttons']}>{this.props.buttons}</div>
          </div>
        </List.Header.Column>
      </>
    );
  }
}

MultipleOptions.Button = Button;
export default MultipleOptions;

MultipleOptions.propTypes = {
  count: PropTypes.number,
  buttons: PropTypes.node,
  checkbox: PropTypes.node,
};
MultipleOptions.defaultProps = {
  count: 0,
  buttons: null,
  checkbox: null,
};
