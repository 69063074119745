import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizePurchaseOrderDelivery } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createPurchaseOrderDelivery(purchaseOrderId, params) {
  return dispatch => {
    return API.createPurchaseOrderDelivery(purchaseOrderId, params)
      .then(res => {
        const { entities } = normalizePurchaseOrderDelivery(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { purchaseOrderId, params, data: res.data };
        dispatch({ type: types.PURCHASE_ORDER_DELIVERY_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deletePurchaseOrderDelivery(purchaseOrderDeliveryId) {
  return (dispatch, getState) => {
    const purchaseOrderDelivery = getState().entities.purchaseOrderDeliveryById[purchaseOrderDeliveryId];
    return API.deletePurchaseOrderDelivery(purchaseOrderDeliveryId)
      .then(res => {
        const payload = { purchaseOrderDeliveryId, purchaseOrderDelivery };
        dispatch({ type: types.PURCHASE_ORDER_DELIVERY_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
