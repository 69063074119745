import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listTemplateFields = (systemId, params) =>
  request.get(`systems/${systemId}/template_fields?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const fetchTemplateField = templateFieldId => request.get(`template_fields/${templateFieldId}`);
export const createTemplateField = (systemId, data) =>
  request.post(`systems/${systemId}/template_fields`, data);
export const updateTemplateField = (templateFieldId, data) =>
  request.patch(`template_fields/${templateFieldId}`, data);
export const deleteTemplateField = templateFieldId => request.delete(`template_fields/${templateFieldId}`);
