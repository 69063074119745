import React, { Component } from 'react';
import { List } from 'views/components/Shared/General';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SystemTier } from 'sdk/System';
import { EntitySelectors } from 'sdk/State/entities';

class DiscountModalListItem extends Component {
  renderDiscountTier = () => {
    if (this.props.billingDiscount.tier === SystemTier.Enterprise) {
      return SystemTier.Enterprise;
    }
    if (this.props.billingDiscount.tier === SystemTier.Pro) {
      return SystemTier.Pro;
    }
    return SystemTier.Basic;
  };
  render() {
    return (
      <>
        <List.Item clickable onClick={() => this.props.onClick()}>
          <List.Item.TitleColumn
            title={moment(this.props.billingDiscount.expiration_date).format('LL')}
            subtitle={this.renderDiscountTier()}
          />
          <List.Item.Column alignRight>
            <p style={{ fontSize: 13 }}>
              {this.props.billingDiscount.price} {this.props.organisation.currency}
            </p>
          </List.Item.Column>
        </List.Item>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
    billingDiscount: EntitySelectors.getBillingDiscount(state, ownProps.id),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps)(DiscountModalListItem)));
