import types from './types';

const setOptions = options => ({ type: types.SET_OPTIONS, payload: options });
const selectOption = id => ({ type: types.SELECT_OPTION, payload: id });
const clearOptions = () => ({ type: types.CLEAR_OPTIONS });

export default {
  setOptions,
  selectOption,
  clearOptions,
};
