import types from './types';

const INITIAL_STATE = {
  ids: [],
  paginateFrom: null,
  isFullyLoaded: false,
  totalEntries: null
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_BILLING_INVOICES_SUCCESS: {
      const { ids, paginateFrom, isFullyLoaded, totalEntries, initialLoad } = action.payload;

      return {
        ...state,
        ids: initialLoad ? ids : [...state.ids, ...ids],
        paginateFrom,
        isFullyLoaded,
        totalEntries: initialLoad ? totalEntries : state.totalEntries,
      }
    }

    default:
      return state;
  }
};
