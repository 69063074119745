import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import Column from '../Column';
import styles from './style.module.scss';

export default class TitleColumn extends Component {
  renderSubtitle = () => {
    if (!this.props.subtitle) return null;

    return <div className={styles['subtitle']}>{this.props.subtitle}</div>;
  };

  render() {
    if (this.props.loading) {
      return (
        <Column flex>
          <div style={{ width: 100, height: 22 }}>
            <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="100" height="6" />
              <rect x="0" y="16" rx="3" ry="3" width="70" height="6" />
            </ContentLoader>
          </div>
        </Column>
      );
    }
    return (
      <Column flex>
        <div className={styles['title']}>{this.props.title}</div>
        {this.renderSubtitle()}
      </Column>
    );
  }
}
