import React, { Component } from 'react';
import styles from './style.module.scss';

class Pagination extends Component {
  render() {
    return <div className={`${styles['pagination']}`}>{this.props.children}</div>;
  }
}

export default Pagination;
