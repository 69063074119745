import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listVendors = (systemId, params, config = null) =>
  request.get(`systems/${systemId}/vendors?${HelperFunctions.convertObjToQueryParameters(params)}`, config);
export const listVendorsForShouldOrder = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/vendors_for_should_order?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const fetchVendor = vendorId => request.get(`vendors/${vendorId}`);
export const createVendor = (systemId, data) => request.post(`systems/${systemId}/vendors`, data);
export const updateVendor = (vendorId, data) => request.patch(`vendors/${vendorId}`, data, { id: vendorId });
export const deleteVendor = vendorId => request.delete(`vendors/${vendorId}`);
