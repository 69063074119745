export const WS_SYSTEM_JOINED = '/sdk/ws/system/joined';
export const WS_SYSTEM_PURCHASE_ORDER_DELIVERY_METHOD_CREATED =
  '/sdk/ws/system/purchase_order_delivery_method_created';
export const WS_SYSTEM_PURCHASE_ORDER_DELIVERY_METHOD_UPDATED =
  '/sdk/ws/system/purchase_order_delivery_method_updated';
export const WS_SYSTEM_PURCHASE_ORDER_DELIVERY_METHOD_DELETED =
  '/sdk/ws/system/purchase_order_delivery_method_deleted';
export const WS_SYSTEM_PURCHASE_ORDER_PAYMENT_TERM_CREATED =
  '/sdk/ws/system/purchase_order_payment_term_created';
export const WS_SYSTEM_PURCHASE_ORDER_PAYMENT_TERM_UPDATED =
  '/sdk/ws/system/purchase_order_payment_term_updated';
export const WS_SYSTEM_PURCHASE_ORDER_PAYMENT_TERM_DELETED =
  '/sdk/ws/system/purchase_order_payment_term_deleted';
export const WS_SYSTEM_SPARE_PART_UNIT_CREATED = '/sdk/ws/system/spare_part_unit_created';
export const WS_SYSTEM_SPARE_PART_UNIT_UPDATED = '/sdk/ws/system/spare_part_unit_updated';
export const WS_SYSTEM_SPARE_PART_UNIT_DELETED = '/sdk/ws/system/spare_part_unit_deleted';
export const WS_SYSTEM_METER_UNIT_CREATED = '/sdk/ws/system/meter_unit_created';
export const WS_SYSTEM_METER_UNIT_UPDATED = '/sdk/ws/system/meter_unit_updated';
export const WS_SYSTEM_METER_UNIT_DELETED = '/sdk/ws/system/meter_unit_deleted';
export const WS_SYSTEM_TEMPLATE_FIELD_CREATED = '/sdk/ws/system/template_field_created';
export const WS_SYSTEM_TEMPLATE_FIELD_UPDATED = '/sdk/ws/system/template_field_updated';
export const WS_SYSTEM_TEMPLATE_FIELD_DELETED = '/sdk/ws/system/template_field_deleted';
export const WS_SYSTEM_TEMPLATE_FIELD_ITEM_CREATED = '/sdk/ws/system/template_field_item_created';
export const WS_SYSTEM_TEMPLATE_FIELD_ITEM_UPDATED = '/sdk/ws/system/template_field_item_updated';
export const WS_SYSTEM_TEMPLATE_FIELD_ITEM_DELETED = '/sdk/ws/system/template_field_item_deleted';
export const WS_SYSTEM_ATTACHMENT_FOLDER_CREATED = '/sdk/ws/system/attachment_folder_created';
export const WS_SYSTEM_ATTACHMENT_FOLDER_UPDATED = '/sdk/ws/system/attachment_folder_updated';
export const WS_SYSTEM_ATTACHMENT_FOLDER_DELETED = '/sdk/ws/system/attachment_folder_deleted';
export const WS_SYSTEM_SYSTEM_UPDATED = '/sdk/ws/system/system_updated';
export const WS_SYSTEM_ORGANISATION_UPDATED = '/sdk/ws/system/organisation_updated';
export const WS_COST_OTHER_CATEGORY_CREATED = '/sdk/ws/system/cost_other_category_created';
export const WS_COST_OTHER_CATEGORY_UPDATED = '/sdk/ws/system/cost_other_category_updated';
export const WS_COST_OTHER_CATEGORY_DELETED = '/sdk/ws/system/cost_other_category_deleted';
export const WS_WORK_ORDER_SPENT_TIME_CATEGORY_CREATED =
  '/sdk/ws/system/work_order_spent_time_category_created';
export const WS_WORK_ORDER_SPENT_TIME_CATEGORY_UPDATED =
  '/sdk/ws/system/work_order_spent_time_category_updated';
export const WS_WORK_ORDER_SPENT_TIME_CATEGORY_DELETED =
  '/sdk/ws/system/work_order_spent_time_category_deleted';
export const WS_LABOR_TARIFF_CREATED = '/sdk/ws/system/labor_tariff_created';
export const WS_LABOR_TARIFF_UPDATED = '/sdk/ws/system/labor_tariff_updated';
export const WS_LABOR_TARIFF_DELETED = '/sdk/ws/system/labor_tariff_deleted';
