/*
  --------------------
    DATA SELECTORS
  --------------------
*/
const getCurrentUser = state => state.auth.currentUser;

/*
  --------------------
    UI SELECTORS
  --------------------
*/

const isAuthenticated = state => state.auth.isAuthenticated;
const isAuthenticating = state => state.auth.isAuthenticating;
const isPreparingApp = state => state.auth.isPreparingApp;

export default {
  getCurrentUser,
  isAuthenticated,
  isAuthenticating,
  isPreparingApp,
};
