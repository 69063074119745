import { request } from '../utilities/Axios';

export const fetchGroupLoginCredential = systemId =>
  request.get(`systems/${systemId}/group_login_credential`);
export const createLoginCredential = (userId, params) =>
  request.post(`users/${userId}/login_credentials`, params);
export const createLoginCredentialForGroup = (systemId, params) =>
  request.post(`systems/${systemId}/group_login_credentials`, params);
export const updateLoginCredential = (id, data) => request.patch(`login_credentials/${id}`, data);
export const updateLoginCredentialForMe = data => request.patch(`me/login_credential`, data);
export const deleteLoginCredential = id => request.delete(`login_credentials/${id}`);
