import types from './types';

const updateEntities = entities => ({
  type: types.UPDATE_ENTITIES,
  payload: entities,
});

export default {
  updateEntities,
};
