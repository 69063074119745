import React, { Component } from 'react';
import { API } from 'sdk';
import { Modal } from 'views/components/Shared/Layout';
import { Button, ConfirmModal } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class EditImportModal extends Component {
  state = {
    isSaving: false,
    showAmuData: false,
    showConfig: false,
    showDeleteConfirmModal: false,
  };

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.setState({
        isSaving: false,
        showAmuData: false,
        showConfig: false,
        showDeleteConfirmModal: false,
      });
    }
  }

  deleteImport = () => {
    this.setState({ isSaving: true });

    API.deleteImportAsAdmin(this.props.import.id).then(() => {
      this.setState({ showDeleteConfirmModal: false });
      this.props.onDelete();
    });
  };

  renderContent = () => {
    if (!this.props.import) return null;

    return (
      <div>
        <Button.Group>
          <Button
            primary
            label="Show AMU Data"
            onClick={() => {
              this.setState(prevState => ({
                showAmuData: !prevState.showAmuData,
                showConfig: false,
              }));
            }}
          />
          <Button
            primary
            label="Show Config"
            onClick={() => {
              this.setState(prevState => ({
                showConfig: !prevState.showConfig,
                showAmuData: false,
              }));
            }}
          />
          <a href={`https://migration.mainter.com/${this.props.import.token}`} target="_blank">
            <Button primary label="Go to form" />
          </a>
          <Button
            destructive
            primary
            label="Delete import"
            onClick={() => {
              this.setState({ showDeleteConfirmModal: true });
            }}
          />
        </Button.Group>
        <div>
          {this.state.showAmuData ? (
            <pre className={styles['pre']}>{JSON.stringify(this.props.import.amu_data, null, '\t')}</pre>
          ) : null}
          {this.state.showConfig ? (
            <pre className={styles['pre']}>{JSON.stringify(this.props.import.config_data, null, '\t')}</pre>
          ) : null}
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={800}>
          <Modal.Header
            ignoreLine
            title={this.props.import ? this.props.import.s3_path : null}
            onClose={() => {
              this.props.onClose();
            }}
          />
          <Modal.Content>{this.renderContent()}</Modal.Content>
        </Modal>
        <ConfirmModal
          open={this.state.showDeleteConfirmModal}
          destructive
          loading={this.state.isSaving}
          onConfirm={this.deleteImport}
          onCancel={() => {
            this.setState({ showDeleteConfirmModal: false });
          }}
          buttonLabel="Radera"
          title="Are you sure?"
          message={this.props.import ? this.props.import.s3_path : null}
        />
      </>
    );
  }
}
