import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeContactPerson } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createContactPerson(systemId, params) {
  return dispatch => {
    return API.createContactPerson(systemId, params)
      .then(res => {
        const { entities } = normalizeContactPerson(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.CONTACT_PERSON_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateContactPerson(contactPersonId, params) {
  return dispatch => {
    return API.updateContactPerson(contactPersonId, params)
      .then(res => {
        const { entities } = normalizeContactPerson(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { contactPersonId, params, data: res.data };
        dispatch({ type: types.CONTACT_PERSON_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteContactPerson(contactPersonId) {
  return dispatch => {
    return API.deleteContactPerson(contactPersonId)
      .then(res => {
        const payload = { contactPersonId };
        dispatch({ type: types.CONTACT_PERSON_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
