import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listOrganisationsAsAdmin = params =>
  request.get(`admin/organisations?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const listSystemsForOrganisation = (orgId, params) =>
  request.get(`admin/organisations/${orgId}/systems?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const createOrganisationAsAdmin = data => request.post(`admin/organisations`, data);
export const updateOrganisationAsAdmin = (orgId, data) => request.patch(`admin/organisations/${orgId}`, data);
export const fetchOrganisationAsAdmin = id => request.get(`admin/organisations/${id}`);
export const deleteOrganisationAsAdmin = orgId => request.delete(`admin/organisations/${orgId}`);
