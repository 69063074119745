import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { Button, Field } from 'views/components/Shared/General';
import { Exact, Exists, NotExists } from './comparators';

class Boolean extends Component {
  getInitialState = () => ({
    value: null,
    comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  componentDidMount() {
    const { value, comparator } = this.props;
    this.setState({
      value,
      comparator,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value || prevState.comparator !== this.state.comparator) {
      this.props.onChange({
        value: this.state.value,
        comparator: this.state.comparator,
      });
    }
  }

  renderSelectedValue = () => {
    if (this.state.value === true) {
      return <FormattedMessage id="general.true" />;
    }
    if (this.state.value === false) {
      return <FormattedMessage id="general.false" />;
    }
    return null;
  };

  renderExactComparator = () => {
    return (
      <Exact
        value={this.state.value}
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exact}
        onClick={() => {
          if (this.state.comparator !== HelperFunctions.FILTER_COMPARABLES.Exact) {
            this.setState({ value: '', comparator: HelperFunctions.FILTER_COMPARABLES.Exact });
          }
        }}
      >
        <Field.Dropdown clearable={false} value={this.renderSelectedValue()}>
          <Field.Dropdown.Item
            selected={this.state.value === true}
            onClick={() => this.setState({ value: true })}
          >
            <FormattedMessage id="general.true" />
          </Field.Dropdown.Item>
          <Field.Dropdown.Item
            selected={this.state.value === false}
            onClick={() => this.setState({ value: false })}
          >
            <FormattedMessage id="general.false" />
          </Field.Dropdown.Item>
        </Field.Dropdown>
      </Exact>
    );
  };

  renderExistsComparator = () => {
    if (this.props.required === false) {
      return (
        <Exists
          selected={
            this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.value === true
          }
          onClick={() => {
            this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.Exists, value: true });
          }}
        />
      );
    }
    return null;
  };

  renderNotExistsComparator = () => {
    if (this.props.required === false) {
      return (
        <NotExists
          selected={
            this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.value === false
          }
          onClick={() => {
            this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.Exists, value: false });
          }}
        />
      );
    }
    return null;
  };

  hasFiltersApplied = () => {
    const { comparator, value } = this.state;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    if (value === true) {
      return true;
    }
    if (value === false) {
      return true;
    }
    return false;
  };

  renderApplyFiltersButton = () => {
    return (
      <Button
        primary
        disabled={this.hasFiltersApplied() === false}
        label="filters.add-button"
        onClick={() => {
          this.props.onApplyfilter({
            comparator: this.state.comparator,
            value: this.state.value,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderExactComparator()}
        {this.renderExistsComparator()}
        {this.renderNotExistsComparator()}
        {this.renderApplyFiltersButton()}
      </>
    );
  }
}

Boolean.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  comparator: PropTypes.string,
  onApplyfilter: PropTypes.func,
};

Boolean.defaultProps = {
  value: null,
  comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
  required: false,
  onApplyfilter: () => {},
};

export default Boolean;
