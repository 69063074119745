import types from './types';

const authenticateUser = () => ({ type: types.AUTHENTICATE_USER });
const authenticateUserError = () => ({ type: types.AUTHENTICATE_USER_ERROR });

const prepareAuthenticatedUser = () => ({ type: types.PREPARE_AUTHENTICATED_USER });
const prepareAuthenticatedUserError = () => ({ type: types.PREPARE_AUTHENTICATED_USER_ERROR });

const unAuthenticateUser = () => ({ type: types.UNAUTHENTICATE_USER });

const appIsReady = ({ systems, selectedSystemId, currentUser, groups }) => (
  {
    type: types.APP_IS_READY,
    payload: {
      systems,
      currentUser,
      groups,
      selectedSystemId,
    },
  }
)

export default {
  authenticateUser,
  appIsReady,
  authenticateUserError,
  unAuthenticateUser,
  prepareAuthenticatedUser,
  prepareAuthenticatedUserError,
}
