import { omit } from 'lodash-es';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.PURCHASE_ORDER_DELIVERY_METHOD_UPDATED:
    case SDKReduxTypes.WS_SYSTEM_PURCHASE_ORDER_DELIVERY_METHOD_UPDATED: {
      let data = {};
      if (action.type === SDKReduxTypes.PURCHASE_ORDER_DELIVERY_METHOD_UPDATED) {
        data = action.payload.data;
      } else {
        data = action.payload;
      }
      if (data['default'] === true) {
        let newPurchaseOrderDeliveryMethodById = {};
        for (let purchaseOrderDeliveryMethodId in state) {
          newPurchaseOrderDeliveryMethodById[purchaseOrderDeliveryMethodId] = {
            ...state[purchaseOrderDeliveryMethodId],
            default: state[purchaseOrderDeliveryMethodId].id === data.id,
          };
        }
        return newPurchaseOrderDeliveryMethodById;
      }
      return state;
    }
    case SDKReduxTypes.PURCHASE_ORDER_DELIVERY_METHOD_CREATED:
    case SDKReduxTypes.WS_SYSTEM_PURCHASE_ORDER_DELIVERY_METHOD_CREATED: {
      let data = {};
      if (action.type === SDKReduxTypes.PURCHASE_ORDER_DELIVERY_METHOD_CREATED) {
        data = action.payload.data;
      } else {
        data = action.payload;
      }
      if (data['default'] === true) {
        let newPurchaseOrderDeliveryMethodById = {};
        newPurchaseOrderDeliveryMethodById[data.id] = data;
        for (let purchaseOrderDeliveryMethodId in state) {
          newPurchaseOrderDeliveryMethodById[purchaseOrderDeliveryMethodId] = {
            ...state[purchaseOrderDeliveryMethodId],
            default: state[purchaseOrderDeliveryMethodId].id === data.id,
          };
        }
        return newPurchaseOrderDeliveryMethodById;
      }
      return state;
    }
    case SDKReduxTypes.PURCHASE_ORDER_DELIVERY_METHOD_DELETED:
    case SDKReduxTypes.WS_SYSTEM_PURCHASE_ORDER_DELIVERY_METHOD_DELETED: {
      let id = null;
      if (action.type === SDKReduxTypes.PURCHASE_ORDER_DELIVERY_METHOD_DELETED) {
        id = action.payload.purchaseOrderDeliveryMethodId;
      } else {
        id = action.payload.id;
      }
      return omit(state, id);
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.purchaseOrderDeliveryMethodById) {
        return HelperFunctions.mergeEntities(state, action.payload.purchaseOrderDeliveryMethodById);
      }
      return state;
    default:
      return state;
  }
};
