import types from './types';

const INITIAL_STATE = {
  fieldErrors: {},
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_FIELD_ERRORS:
      return {
        ...state,
        fieldErrors: {
          ...state.fieldErrors,
          ...action.payload,
        }
      }
    case types.REMOVE_FIELDS_FROM_ERRORS: {
      const fieldErrors = { ...state.fieldErrors };
      action.payload.forEach(key => delete fieldErrors[key])
      return {
        ...state,
        fieldErrors,
      }
    }
    case types.REMOVE_ALL_ERRORS: {
      return {
        ...state,
        fieldErrors: {},
      }
    }
    default:
      return state;
  }
};
