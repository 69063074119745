import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { OrganisationSelectors } from 'state/ducks/organisation';
import { EntitySelectors } from 'sdk/State/entities';
import { WhiteCard, MoneyWithCurrency, Field, Icon, List } from 'views/components/Shared/General';
import moment from 'moment';
import DiscountsModal from '../../../components/DiscountsModal';
import styles from './style.module.scss';

class DiscountCard extends Component {
  state = {
    discountModalOpen: false,
  };

  formatActiveDiscount = () => {
    if (this.props.activeDiscount != null) {
      return (
        <>
          <span className={styles['discount']}>
            <MoneyWithCurrency
              currency="SEK"
              value={this.props.activeDiscount.price}
              fractionSize={0}
              currencyFontSize={13}
            />
            <p className={styles['subtitle']}>
              {' '}
              Until {moment(this.props.activeDiscount.expiration_date).format('LL')}
            </p>
          </span>
        </>
      );
    }
    return ' - ';
  };

  isLoaded = () =>
    this.props.organisation != null &&
    this.props.organisation.currency != null &&
    this.props.organisation.billing_discounts != null;

  renderDiscountModal = () => {
    return (
      <DiscountsModal
        open={this.state.discountModalOpen}
        onClose={() => {
          this.setState({ discountModalOpen: false });
        }}
      />
    );
  };
  renderContentLoader = () => (
    <List.Item>
      <List.Item.TitleColumn loading />
    </List.Item>
  );

  renderContent = () => {
    if (this.props.loading) return this.renderContentLoader();
    return (
      <Field
        icon={<Icon type="exclamation-circle" red regular withBackground backgroundSize={34} size={16} />}
        fontSize={13}
        subtitle="Active Discount"
      >
        {this.formatActiveDiscount()}
      </Field>
    );
  };
  render() {
    return (
      <>
        <span onClick={() => this.setState({ discountModalOpen: true })}>
          <WhiteCard padding={20} clickable>
            {this.renderContent()}
          </WhiteCard>
        </span>
        {this.renderDiscountModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading)
    return {
      organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
    };
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
    activeDiscount: OrganisationSelectors.getActiveDiscount(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps)(DiscountCard)));
