import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { Menu } from "./components";
import styles from "./style.module.scss";
import { AuthOperations } from "state/ducks/auth";
import { Toaster } from "react-hot-toast";

const AppLayout = ({ children, currentRoute, unauthenticateUser, intl }) => {
  return (
    <React.Fragment>
      <div
        id="content"
        className={[styles["content"], "app-container"].join(" ")}
      >
        <Menu />
        <div
          id="main"
          className={`
            ${styles["main"]}
          `}
        >
          {children}
        </div>
        <Toaster position="bottom-left" containerStyle={{ left: 250 }} />
      </div>
    </React.Fragment>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { unauthenticateUser: AuthOperations.unauthenticateUser },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(AppLayout));
