import React, { Component } from 'react';
import styles from './style.module.scss';

export default class Toolbar extends Component {
  render() {
    return (
      <div className={styles['toolbar']}>
        <div className={styles['buttons']}>{this.props.buttons}</div>
        <div className={styles['filter']}>
          <div
            className={`${styles['search-container']} ${
              this.props.filters != null ? styles['has-filters'] : ''
            }`}
          >
            {this.props.search}
          </div>
          <div>{this.props.filters}</div>
        </div>
      </div>
    );
  }
}
