import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listRequestAutomationRules = (systemId, params) =>
  request.get(
    `systems/${systemId}/request_automation_rules?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
export const fetchRequestAutomationRule = requestAutomationRuleId =>
  request.get(`request_automation_rules/${requestAutomationRuleId}`);
export const createRequestAutomationRule = (systemId, params) =>
  request.post(`systems/${systemId}/request_automation_rules`, params);
export const updateRequestAutomationRule = (requestAutomationRuleId, params) =>
  request.patch(`request_automation_rules/${requestAutomationRuleId}`, params);
export const deleteRequestAutomationRule = requestAutomationRuleId =>
  request.delete(`request_automation_rules/${requestAutomationRuleId}`);
