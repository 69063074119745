import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import moment from 'moment';
import { SystemTier } from 'sdk/System';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import toast from 'react-hot-toast';
import { ToastMessage, Modal } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';

class TierConfirmationModal extends Component {
  constructor(props) {
    super(props);
    var presetDate = new Date();
    presetDate.setDate(presetDate.getDate() + 14);
    this.state = {
      isSaving: false,
      tierTrialExpirationDate: presetDate,
      showDatePicker: false,
    };
  }
  updateOrganisation = () => {
    this.setState({ isSaving: true });
    var params = {};
    var toastMessage = null;
    if (this.props.tierType === 'upgrade') {
      params = {
        tier: SystemTier.Pro,
        billing_tier: SystemTier.Pro,
        tier_trial_expiration_date: null,
      };
      toastMessage = 'Tier upgraded to PRO';
    } else if (this.props.tierType === 'downgrade') {
      params = {
        tier: SystemTier.Basic,
        billing_tier: SystemTier.Basic,
        tier_trial_expiration_date: null,
      };
      toastMessage = 'Tier downgraded to BASIC';
    } else if (this.props.tierType === 'upgrade-trial') {
      params = {
        tier: SystemTier.Pro,
        billing_tier: SystemTier.Basic,
        tier_trial_expiration_date: this.state.tierTrialExpirationDate,
      };
      toastMessage = `Tier upgraded to PRO with trial expiration date ${moment(
        this.state.tierTrialExpirationDate
      ).format('LL')}`;
    }
    this.props
      .updateOrganisationAsAdmin(this.props.organisation.id, params)
      .then(() => {
        this.props.onClose(true);
        this.setState({ isSaving: false });
        toast(() => <ToastMessage success text={toastMessage} />);
      })
      .catch(error => {
        if (HelperFunctions.hasError(error, '10003', 'cost')) {
          toast(() => <ToastMessage error text="Du kan inte nedgradera en kund som har Enterprise" />);
          this.props.onClose(true);
        }
      });
  };
  renderContent = () => {
    if (this.props.tierType === 'upgrade') {
      return <Field label="Upgrading system to tier PRO with billing" />;
    } else if (this.props.tierType === 'downgrade') {
      return <Field label="Downgrading system tier BASIC" />;
    } else {
      return (
        <Field label="Upgrading system to PRO with trial expiration date">
          <Field.Date
            open={this.state.showDatePicker}
            onClose={() => this.setState({ showDatePicker: false })}
            onFocus={() => this.setState({ showDatePicker: true })}
            disabledDays={{
              before: new Date(),
            }}
            value={this.state.tierTrialExpirationDate}
            onChangeDate={expirationDate => {
              this.setState({
                tierTrialExpirationDate: expirationDate,
                showDatePicker: false,
              });
            }}
            onClear={() => {
              this.setState({
                tierTrialExpirationDate: null,
                showDatePicker: false,
              });
            }}
          />
        </Field>
      );
    }
  };
  render() {
    return (
      <Modal isOpen={this.props.open} width={560}>
        <Modal.Header
          ignoreLine
          title="Are you sure?"
          onClose={() => {
            this.props.onClose();
          }}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="Save"
              loading={this.state.isSaving}
              onClick={() => this.updateOrganisation()}
            />
            <Button label="Cancel" onClick={() => this.props.onClose()} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrganisationAsAdmin: SDKReduxOperations.updateOrganisationAsAdmin,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    organisation: EntitySelectors.getOrganisation(state, ownProps.match.params.id),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(TierConfirmationModal)));
