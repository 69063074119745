import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export default class Section extends Component {
  render() {
    let classNames = [styles['section']];
    if (this.props.noBorderBottom) classNames = [...classNames, styles['no-border-bottom']];
    if (this.props.noBorderTop) classNames = [...classNames, styles['no-border-top']];
    if (this.props.horizontalBorders) {
      classNames = [...classNames, styles['horizontal-borders']];
    }
    let additionalStyles = {};
    if (this.props.paddingHorizontal) {
      additionalStyles = {
        ...additionalStyles,
        paddingLeft: this.props.paddingHorizontal,
        paddingRight: this.props.paddingHorizontal,
      };
    }
    if (this.props.loading) {
      return (
        <div className={classNames.join(' ')}>
          <div style={{ width: 100, height: 16 }}>
            <ContentLoader
              primaryColor="#CCDEE8"
              secondaryColor="#C0D1DA"
              viewBox="0 0 100 8"
              preserveAspectRatio="xMinYMin"
            >
              <rect x="0" y="0" rx="2" ry="2" width="100" height="8" />
            </ContentLoader>
          </div>
        </div>
      );
    }
    return (
      <div className={classNames.join(' ')} style={additionalStyles}>
        <div className={styles['content']}>{this.props.children}</div>
      </div>
    );
  }
}

Section.propTypes = {
  loading: PropTypes.bool,
  noBorderBottom: PropTypes.bool,
  noBorderTop: PropTypes.bool,
  horizontalBorders: PropTypes.bool,
  paddingHorizontal: PropTypes.number,
  children: PropTypes.node,
};

Section.defaultProps = {
  loading: false,
  noBorderBottom: false,
  noBorderTop: false,
  horizontalBorders: false,
  children: null,
  paddingHorizontal: null,
};
