import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeBillingInvoice } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function updateBillingInvoiceAsAdmin(billingInvoiceId, params) {
  return dispatch => {
    return API.updateBillingInvoiceAsAdmin(billingInvoiceId, params)
      .then(res => {
        const { entities } = normalizeBillingInvoice(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { billingInvoiceId, params, data: res.data };
        dispatch({ type: types.BILLING_INVOICE_UPDATED_AS_ADMIN, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
