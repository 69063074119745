import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeStocktaking } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createStocktaking(systemId, params) {
  return dispatch => {
    return API.createStocktaking(systemId, params)
      .then(res => {
        const { entities } = normalizeStocktaking(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.STOCKTAKING_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateStocktaking(stocktakingId, params) {
  return dispatch => {
    return API.updateStocktaking(stocktakingId, params)
      .then(res => {
        const { entities } = normalizeStocktaking(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { stocktakingId, params, data: res.data };
        dispatch({ type: types.STOCKTAKING_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteStocktaking(stocktakingId) {
  return dispatch => {
    return API.deleteStocktaking(stocktakingId)
      .then(res => {
        const payload = { stocktakingId };
        dispatch({ type: types.STOCKTAKING_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
