import { request } from '../utilities/Axios';

export const createImageForRequest = (requestId, data) => request.post(`requests/${requestId}/images`, data);
export const createRegistrationImageForWorkOrder = (workOrderId, data) =>
  request.post(`work_orders/${workOrderId}/registration_images`, data);
export const createCompletionImageForWorkOrder = (workOrderId, data) =>
  request.post(`work_orders/${workOrderId}/completion_images`, data);
export const createImageForSparePart = (sparePartId, data) =>
  request.post(`spare_parts/${sparePartId}/images`, data);
export const createImageForRecurringMaintenance = (recurringMaintenanceId, data) =>
  request.post(`recurring_maintenances/${recurringMaintenanceId}/images`, data);
export const createImageForAsset = (assetId, data) => request.post(`assets/${assetId}/images`, data);
export const createImageForChecklistInstanceRow = (checklistInstanceRowId, data) =>
  request.post(`checklist_instance_rows/${checklistInstanceRowId}/images`, data);
export const createImageForChecklistTemplateRow = (checklistTemplateRowId, data) =>
  request.post(`checklist_template_rows/${checklistTemplateRowId}/images`, data);
export const createImageForWorkOrderSpentTime = (workOrderSpentTimeId, data) =>
  request.post(`work_order_spent_times/${workOrderSpentTimeId}/images`, data);
export const createImageForSystemLogo = (systemId, data) => request.post(`systems/${systemId}/logo`, data);
export const updateImage = (imageId, data) => request.patch(`images/${imageId}`, data);
export const deleteImage = imageId => request.delete(`images/${imageId}`);
