import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeSparePartVendor } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createSparePartVendor(systemId, params) {
  return dispatch => {
    return API.createSparePartVendor(systemId, params)
      .then(res => {
        const { entities } = normalizeSparePartVendor(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_VENDOR_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateSparePartVendor(sparePartVendorId, params) {
  return dispatch => {
    return API.updateSparePartVendor(sparePartVendorId, params)
      .then(res => {
        const { entities } = normalizeSparePartVendor(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { sparePartVendorId, params, data: res.data };
        dispatch({ type: types.SPARE_PART_VENDOR_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteSparePartVendor(sparePartVendorId) {
  return (dispatch, getState) => {
    const sparePartVendor = getState().entities.sparePartVendorById[sparePartVendorId];
    return API.deleteSparePartVendor(sparePartVendorId)
      .then(res => {
        const payload = { sparePartVendorId, data: sparePartVendor };
        dispatch({ type: types.SPARE_PART_VENDOR_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
