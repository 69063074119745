import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeDowntimeReason } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createDowntimeReason(systemId, params) {
  return dispatch => {
    return API.createDowntimeReason(systemId, params)
      .then(res => {
        const { entities } = normalizeDowntimeReason(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.DOWNTIME_REASON_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateDowntimeReason(assetTypeId, params) {
  return dispatch => {
    return API.updateDowntimeReason(assetTypeId, params)
      .then(res => {
        const { entities } = normalizeDowntimeReason(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { assetTypeId, params, data: res.data };
        dispatch({ type: types.DOWNTIME_REASON_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteDowntimeReason(downtimeReasonId) {
  return dispatch => {
    return API.deleteDowntimeReason(downtimeReasonId)
      .then(res => {
        const payload = { downtimeReasonId };
        dispatch({ type: types.DOWNTIME_REASON_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
