import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const getChecklistInstanceListCounts = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/checklist_instance_list_counts?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`,
    config
  );
export const listChecklistInstancesForSystem = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/checklist_instances?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const listChecklistInstances = (checklistId, params, config = null) =>
  request.get(
    `checklist_templates/${checklistId}/checklist_instances?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`,
    config
  );
export const updateChecklistInstance = (checklistInstanceId, data) =>
  request.patch(`checklist_instances/${checklistInstanceId}`, data);
export const fetchChecklistInstance = (checklistId, params, config = null) =>
  request.get(`checklist_instances/${checklistId}`, config);
export const createChecklistInstanceForTemplate = (templateId, data) =>
  request.post(`checklist_templates/${templateId}/checklist_instances`, data);
export const deleteChecklistInstance = instanceId => request.delete(`checklist_instances/${instanceId}`);
