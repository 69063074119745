import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Field, Tooltip } from 'views/components/Shared/General';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { API } from 'sdk';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';
import styles from './style.module.scss';

class DeleteSystemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view',
      isDeleting: false,
      confirmInput: '',
    };
  }

  componentDidUpdate(prevProps, { mode }) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        mode: 'view',
        isSaving: false,
        confirmInput: '',
      });
    }
  }

  delete = () => {
    if (this.props.system) {
      this.deleteSystem();
    } else {
      this.deleteOrganisation();
    }
  };

  deleteOrganisation = () => {
    this.setState({ isDeleting: true });
    API.deleteOrganisationAsAdmin(this.props.organisation.id).then(res => {
      toast(() => <ToastMessage success text="System was successfully deleted" />);
      this.setState({ isDeleting: false });
      this.props.onDelete();
    });
  };

  deleteSystem = () => {
    this.setState({ isDeleting: true });
    API.deleteSystemAsAdmin(this.props.system.id).then(res => {
      toast(() => <ToastMessage success text="System was successfully deleted" />);
      this.setState({ isDeleting: false });
      this.props.onDelete();
    });
  };

  getNameToDelete = () => {
    if (this.props.system) {
      return this.props.system.name;
    }
    return this.props.organisation.internal_name;
  };

  renderConfirmMode = () => (
    <React.Fragment>
      <Modal.Content>
        <Field
          label={
            <p className={styles['label']}>
              You are deleting <span className={styles['label-bold']}>{this.getNameToDelete()}</span> , write
              'permanently delete' to confirm
            </p>
          }
        >
          <Field.Text
            autoFocus
            value={this.state.confirmInput}
            onChange={value => {
              this.setState({ confirmInput: value });
            }}
          />
        </Field>
      </Modal.Content>
      <Modal.Footer>
        <Button.Group>
          <Button
            destructive
            primary
            disabled={this.state.confirmInput !== 'permanently delete'}
            label="Confirm delete"
            loading={this.state.isDeleting}
            onClick={() => this.delete()}
          />
          <Button label="Cancel" translate onClick={this.props.onClose} />
        </Button.Group>
      </Modal.Footer>
    </React.Fragment>
  );

  renderViewMode = () => (
    <React.Fragment>
      <Modal.Content noPadding>
        <React.Fragment>
          <div className={styles['delete-info-container']}>
            <Field
              label={
                <p className={styles['label']}>
                  {' '}
                  You are about to delete{' '}
                  <span className={styles['label-bold']}>{this.getNameToDelete()}</span> (This can not be
                  undone)
                </p>
              }
            ></Field>
          </div>
        </React.Fragment>
      </Modal.Content>
      <Modal.Footer>
        <Button.Group>
          {!this.props.organisation.demo ? (
            <Tooltip
              trigger={
                <Button
                  destructive
                  primary
                  label="Delete system"
                  disabled
                  loading={this.state.isSaving}
                  onClick={() => this.setState({ mode: 'confirm' })}
                />
              }
              label="This system can not be deleted as it is not a demo account"
            />
          ) : (
            <Button
              destructive
              primary
              label="Delete system"
              loading={this.state.isSaving}
              onClick={() => this.setState({ mode: 'confirm' })}
            />
          )}

          <Button label="Cancel" onClick={this.props.onClose} />
        </Button.Group>
      </Modal.Footer>
    </React.Fragment>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          title="Are you sure you want to delete this system?"
          onClose={this.props.onClose}
          ignoreLine
        />
        {this.state.mode === 'view' ? this.renderViewMode() : this.renderConfirmMode()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { systemId, orgId } = ownProps;
  return {
    system: EntitySelectors.getSystem(state, systemId),
    organisation: EntitySelectors.getOrganisation(state, orgId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSystemModal);
