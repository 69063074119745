import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './style.module.scss';

class Content extends Component {
  render() {
    return (
      <div className={styles['content']}>
        <PerfectScrollbar>
          <div className={styles['children']}>{this.props.children}</div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default Content;
