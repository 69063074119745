import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listAssets = (systemId, params, config = null) =>
  request.get(`systems/${systemId}/assets?${HelperFunctions.convertObjToQueryParameters(params)}`, config);
export const listAssetsForVendor = (vendorId, params) =>
  request.get(`vendors/${vendorId}/assets?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const fetchAsset = assetId => request.get(`assets/${assetId}`);
export const createAsset = (systemId, data) => request.post(`systems/${systemId}/assets`, data);
export const updateAsset = (assetId, data) => request.patch(`assets/${assetId}`, data, { id: assetId });
export const duplicateAsset = assetId => request.put(`assets/${assetId}/duplicate`);
export const deleteAsset = (assetId, data) => request.delete(`assets/${assetId}`, { data });
export const fetchTree = (systemId, params) =>
  request.get(`systems/${systemId}/tree?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const addMultipleAssetsToAttachment = (attachmentId, data) =>
  request.put(`attachments/${attachmentId}/add_assets`, data);
export const batchUpdateAssets = (systemId, data) => request.put(`systems/${systemId}/assets/batch`, data);
export const batchCreateSparePartAsset = (systemId, data) =>
  request.put(`systems/${systemId}/assets/batch_create_spare_part_asset`, data);
