import update from 'immutability-helper';
import { omit } from 'lodash-es';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.IMAGE_CREATED_FOR_WORK_ORDER_SPENT_TIME: {
      const { workOrderSpentTimeId, data } = action.payload;
      const { id } = data;
      if (state[workOrderSpentTimeId]) {
        return update(
          { ...state },
          {
            [workOrderSpentTimeId]: {
              images: {
                $push: [id],
              },
            },
          }
        );
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId, data } = action.payload;
      if (!data) return state;

      const { work_order_spent_time_id } = data;
      if (state[work_order_spent_time_id] == null) return state;
      return {
        ...state,
        [work_order_spent_time_id]: {
          ...state[work_order_spent_time_id],
          images: state[work_order_spent_time_id].images.filter(id => id !== imageId),
        },
      };
    }
    case SDKReduxTypes.WORK_ORDER_SPENT_TIME_DELETED: {
      const { workOrderSpentTimeId } = action.payload;

      return omit(state, workOrderSpentTimeId);
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.workOrderSpentTimeById) {
        return HelperFunctions.mergeEntities(state, action.payload.workOrderSpentTimeById);
      }
      return state;
    default:
      return state;
  }
};
