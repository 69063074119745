import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const showUserSettings = (systemId, userId, params, config = null) =>
  request.get(
    `systems/${systemId}/users/${userId}/settings?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const updateUserSettings = (systemId, userId, data, config = null) =>
  request.patch(`systems/${systemId}/users/${userId}/settings`, data, config);
