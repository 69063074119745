import EntityTypes from '../types';
import { HelperFunctions } from 'sdk';

export default (state = {}, action) => {
  switch (action.type) {
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.laborTariffScheduleRuleById) {
        return HelperFunctions.mergeEntities(state, action.payload.laborTariffScheduleRuleById);
      }
      return state;
    default:
      return state;
  }
};
