import React from 'react';
import styles from './style.module.scss';
import Column from './Column';
import Row from './Row';
import Separator from './Separator';

const CardGrid = props => {
  return <div className={styles['grid']}>{props.children}</div>;
};

CardGrid.Column = Column;
CardGrid.Row = Row;
CardGrid.Separator = Separator;

export default CardGrid;

CardGrid.propTypes = {};
CardGrid.defaultProps = {};
