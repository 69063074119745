export const USER_CREATED = '/sdk/user/created';
export const USER_CREATED_FOR_ORGANISATION = '/sdk/user/created_for_organisation';
export const USER_DELETED = '/sdk/user/deleted';
export const USER_UPDATED = '/sdk/user/updated';
export const USERS_ADDED_TO_GROUP = '/sdk/user/users_added_to_group';
export const USER_REMOVED_FROM_GROUP = '/sdk/user/user_removed_from_group';
export const CHANGED_PROFILE_PICTURE = '/sdk/user/changed_profile_picture';
export const DELETED_PROFILE_PICTURE = '/sdk/user/deleted_profile_picture';
export const ME_UPDATED = '/sdk/user/me_updated';
export const USER_INVITE_RESENT = '/sdk/user/user_invite_resent';
export const NOTIFICATION_BADGE_RESETTED = '/sdk/user/badge_resetted';
