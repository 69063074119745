import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeWorkOrderType } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createWorkOrderType(systemId, params) {
  return dispatch => {
    return API.createWorkOrderType(systemId, params)
      .then(res => {
        const { entities } = normalizeWorkOrderType(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.WORK_ORDER_TYPE_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateWorkOrderType(workOrderTypeId, params) {
  return dispatch => {
    return API.updateWorkOrderType(workOrderTypeId, params)
      .then(res => {
        const { entities } = normalizeWorkOrderType(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderTypeId, params, data: res.data };
        dispatch({ type: types.WORK_ORDER_TYPE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteWorkOrderType(workOrderTypeId) {
  return dispatch => {
    return API.deleteWorkOrderType(workOrderTypeId)
      .then(res => {
        const payload = { workOrderTypeId };
        dispatch({ type: types.WORK_ORDER_TYPE_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
