import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeWorkOrder } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createWorkOrder(systemId, params) {
  return dispatch => {
    return API.createWorkOrder(systemId, params)
      .then(res => {
        const { entities } = normalizeWorkOrder(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({
          type: types.WORK_ORDER_CREATED,
          payload,
          meta: { includeCurrentUser: true },
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateWorkOrder(workOrderId, params) {
  return (dispatch, getState) => {
    const beforeEdit = getState().entities.workOrderById[workOrderId];
    const payload = { workOrderId, params, beforeEdit };
    dispatch({ type: types.WORK_ORDER_UPDATING, payload });

    return API.updateWorkOrder(workOrderId, params)
      .then(res => {
        const { entities } = normalizeWorkOrder(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { workOrderId, params, data: res.data, beforeEdit };
        dispatch({ type: types.WORK_ORDER_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function sendWorkOrderToVendor(workOrderId, params) {
  return dispatch => {
    return API.sendWorkOrderToVendor(workOrderId, params)
      .then(res => {
        const payload = { workOrderId, params, data: res.data };
        dispatch({ type: types.WORK_ORDER_EMAIL_SENT, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteWorkOrder(workOrderId) {
  return dispatch => {
    return API.deleteWorkOrder(workOrderId)
      .then(res => {
        const payload = { workOrderId };
        dispatch({ type: types.WORK_ORDER_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
