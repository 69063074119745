import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Content from './Content';
import styles from './style.module.scss';

const ListLayout = props => {
  return <div className={styles['container']}>{props.children}</div>;
};

ListLayout.Header = Header;
ListLayout.Content = Content;
export default ListLayout;

ListLayout.propTypes = {
  children: PropTypes.node,
};

ListLayout.defaultProps = {
  children: null,
};
