import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listDowntimeReasons = (systemId, params) =>
  request.get(`systems/${systemId}/downtime_reasons?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const updateDowntimeReason = (assetTypeId, data) =>
  request.patch(`downtime_reasons/${assetTypeId}`, data);
export const createDowntimeReason = (systemId, data) =>
  request.post(`systems/${systemId}/downtime_reasons`, data);
export const deleteDowntimeReason = assetTypeId => request.delete(`downtime_reasons/${assetTypeId}`);
