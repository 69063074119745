import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'views/components/Shared/General';
import styles from './style.module.scss';

class SelectTotalEntries extends Component {
  renderContent = () => {
    if (this.props.selectedCount === this.props.totalEntriesCount) {
      return (
        <FormattedMessage
          id="general.all-rows-on-page-are-selected"
          values={{ count: this.props.totalEntriesCount }}
        />
      );
    }
    if (this.props.selected) {
      return (
        <>
          <FormattedMessage
            id="general.deselect-all-list-rows-label"
            values={{
              count: this.props.totalEntriesCount,
            }}
          />
          <span>&nbsp;-&nbsp;</span>
          <Button
            fontSize={12}
            primary
            noUnderline
            disabled={this.props.loading}
            type="text"
            translate={false}
            onClick={this.props.onDeselectAll}
            label={<FormattedMessage id="general.deselect-all-list-rows-button" />}
          />
        </>
      );
    }
    return (
      <>
        <FormattedMessage
          id="general.select-all-list-rows-label"
          values={{
            count: this.props.selectedCount,
          }}
        />
        <span>&nbsp;-&nbsp;</span>
        <Button
          fontSize={12}
          primary
          noUnderline
          type="text"
          translate={false}
          onClick={this.props.onSelectAll}
          label={
            <FormattedMessage
              id="general.select-all-list-rows-button"
              values={{ count: this.props.totalEntriesCount }}
            />
          }
        />
      </>
    );
  };

  render() {
    let classNames = [styles['select-all-item']];
    return <div className={classNames.join(' ')}>{this.renderContent()}</div>;
  }
}

export default SelectTotalEntries;

SelectTotalEntries.propTypes = {
  selected: PropTypes.bool,
  selectedCount: PropTypes.number,
  totalEntriesCount: PropTypes.number,
  onSelectAll: PropTypes.func,
  onDeselectAll: PropTypes.func,
};

SelectTotalEntries.defaultProps = {
  selected: false,
  selectedCount: 0,
  totalEntriesCount: 0,
  onSelectAll: [],
  onDeselectAll: [],
};
