import React from 'react'
import AMServiceLogo from 'assets/images/LogoWithText.png';
import styles from './style.module.scss';

export default ({ children, hideLogo = false }) => {
  return (
    <div className={styles['container']}>
      {hideLogo ? null : <div><img className={styles['logo']} src={AMServiceLogo} alt="logo" /></div>}
      {children}
    </div>
  )
}
