import { omit } from 'lodash-es';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.sparePartWithdrawalById) {
        return HelperFunctions.mergeEntities(state, action.payload.sparePartWithdrawalById);
      }
      return state;
    default:
      return state;
  }
};
