import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'views/components/Shared/General';
import 'react-day-picker/lib/style.css';
import { FormattedMessage } from 'react-intl';
import styles from '../style.module.scss';

export default class Footer extends Component {
  showOnOccasionButton = () => {
    return false;
  };

  showClearButton = () => {
    let showClearButton = true;
    if (this.props.showClear === false) {
      showClearButton = false;
    }
    return showClearButton;
  };

  showNoDateButton = () => {
    let showNoDateButton = true;
    if (this.props.showNoDate === false) {
      showNoDateButton = false;
    }
    return showNoDateButton;
  };

  render() {
    return (
      <div className={styles['footer']}>
        <div className={styles['left-side']}>
          {this.showOnOccasionButton() ? (
            <div className={styles['on-occasion']} onClick={this.props.onSelectOnOccasion}>
              <FormattedMessage id="components.date-picker.on-occasion" />
              <Tooltip
                trigger={
                  <div className={styles['icon-container']}>
                    <Icon regular type="question-circle" />
                  </div>
                }
                label={<FormattedMessage id="components.date-picker.on-occasion-popup-text" />}
              />
            </div>
          ) : null}
          {this.showNoDateButton() ? (
            <div className={styles['no-date']} onClick={this.props.onSelectNoDate}>
              <FormattedMessage id="components.date-picker.no-date" />
            </div>
          ) : null}
        </div>
        {this.showClearButton() ? (
          <div className={styles['clear-button']} onClick={this.props.onClear}>
            <FormattedMessage id="general.clean" />
          </div>
        ) : null}
      </div>
    );
  }
}

Footer.propTypes = {
  showOnOccasion: PropTypes.bool,
  showNoDate: PropTypes.bool,
  showClear: PropTypes.bool,
  onSelectOnOccasion: PropTypes.func,
  onSelectNoDate: PropTypes.func,
  onClear: PropTypes.func,
};

Footer.defaultProps = {
  onSelectOnOccasion: () => {},
  onSelectNoDate: () => {},
  onClear: () => {},
  showOnOccasion: false,
  showNoDate: false,
  showClear: false,
};
