import React, { Component } from 'react';
import { debounce } from 'lodash-es';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styles from './style.module.scss';

const MAIN_MENU_WIDTH = 237;

export default class PreviewOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top: 0,
      left: 0,
      maxHeight: 'none',
      animate: false,
    };
    this.debouncedWindowResizedFunction = debounce(this.positionOvelayComponent, 300);
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      document.addEventListener('mousedown', this.handleClickOutside);
      window.addEventListener('resize', this.debouncedWindowResizedFunction);
      this.positionOvelayComponent();
    } else if (prevProps.open && !this.props.open) {
      document.removeEventListener('mousedown', this.handleClickOutside);
      window.removeEventListener('resize', this.debouncedWindowResizedFunction);
      this.setState({ animate: false });
    } else if (this.props.open) {
      if (prevProps.top !== this.props.top || prevProps.left !== this.props.left) {
        this.positionOvelayComponent();
      }
    }
  }

  componentWillUnmount() {
    this.props.onClose();
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.debouncedWindowResizedFunction);
  }

  positionOvelayComponent = () => {
    const containerWidth = this.props.width;
    const { scrollHeight } = this.containerRef;
    const { top, left, extraWidth } = this.props;
    let positionFromTop = top;
    if (top + scrollHeight > window.innerHeight) {
      positionFromTop = window.innerHeight - scrollHeight - 20;
    }
    let pxToTheLeftOfScreen = left;
    let pxToTheRightOfScreen = window.innerWidth - left - extraWidth;
    let positionFromLeft = left + extraWidth;
    if (pxToTheLeftOfScreen > pxToTheRightOfScreen) {
      positionFromLeft = left - containerWidth;
    }
    if (positionFromLeft <= MAIN_MENU_WIDTH) {
      positionFromLeft = MAIN_MENU_WIDTH + 6;
    }

    let maxHeight = 'none';
    if (scrollHeight + 40 > window.innerHeight) {
      maxHeight = scrollHeight - (scrollHeight - window.innerHeight) - 40;
    }
    this.setState({
      top: Math.round(positionFromTop < 0 ? 20 : positionFromTop),
      left: Math.round(positionFromLeft),
      maxHeight: maxHeight === 'none' ? 'none' : Math.round(maxHeight),
    });
  };

  handleClickOutside = e => {
    const clickedInsideHolder = this.containerRef.contains(e.target);
    const clickedInsideInlineModal = e.target.closest('.inline-modal') != null;
    const clickedInsideModal = e.target.closest('.ReactModal__Overlay') != null;
    const clickedInsideSideModal = e.target.closest('.new-resource-modal') != null;
    const clickedOverlayTrigger = e.target.closest('.overlay-trigger') != null;
    const clickedFullscreenImagePicker = e.target.closest('.react-images__positioner') != null;
    const clickedPreviewOverlay = e.target.closest('.preview-overlay') != null;
    if (
      !clickedInsideHolder &&
      !clickedInsideInlineModal &&
      !clickedInsideModal &&
      !clickedOverlayTrigger &&
      !clickedInsideSideModal &&
      !clickedFullscreenImagePicker &&
      !clickedPreviewOverlay
    ) {
      this.props.onClose(e);
    }
  };

  getCssTransitionClassNames = () => {
    const { left, extraWidth } = this.props;
    let pxToTheLeftOfScreen = left;
    let pxToTheRightOfScreen = window.innerWidth - left - extraWidth;
    if (pxToTheLeftOfScreen > pxToTheRightOfScreen) {
      return {
        enter: styles['overlay-container-enter-from-right'],
        enterActive: styles['overlay-container-enter-active-from-right'],
        exit: styles['overlay-container-exit'],
        exitActive: styles['overlay-container-exit-active'],
      };
    }
    return {
      enter: styles['overlay-container-enter-from-left'],
      enterActive: styles['overlay-container-enter-active-from-left'],
      exit: styles['overlay-container-exit'],
      exitActive: styles['overlay-container-exit-active'],
    };
  };

  render() {
    const { top, left, maxHeight } = this.state;
    let classNames = ['preview-overlay'];
    classNames = [...classNames, styles['wrapper']];
    if (this.state.animate) {
      classNames = [...classNames, styles['animate']];
    }
    return ReactDOM.createPortal(
      <CSSTransition
        unmountOnExit
        in={this.props.open}
        timeout={150}
        classNames={this.getCssTransitionClassNames()}
        onEntered={() => {
          this.setState({ animate: true });
        }}
      >
        <div
          className={classNames.join(' ')}
          style={{
            top,
            left,
          }}
        >
          <div style={{ maxHeight }} className={styles['container']} ref={ref => (this.containerRef = ref)}>
            {this.props.children}
          </div>
        </div>
      </CSSTransition>,
      document.getElementById('root')
    );
  }
}
