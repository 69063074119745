import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const updateWorkOrderSpentTime = (workOrderSpentTimeId, data) =>
  request.patch(`work_order_spent_times/${workOrderSpentTimeId}`, data);
export const createWorkOrderSpentTime = (workOrderId, data) =>
  request.post(`work_orders/${workOrderId}/work_order_spent_times`, data);
export const deleteWorkOrderSpentTime = workOrderSpentTimeId =>
  request.delete(`work_order_spent_times/${workOrderSpentTimeId}`);
export const getWorkOrderSpentTimeReport = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/work_order_spent_time_report?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
