import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listSparePartReservationsForWorkOrder = (workOrderId, params) =>
  request.get(
    `work_orders/${workOrderId}/spare_part_reservations?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`
  );
export const listSparePartReservationsForRecurringMaintenance = (recurringMaintenanceId, params) =>
  request.get(
    `recurring_maintenances/${recurringMaintenanceId}/spare_part_reservations?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`
  );
export const listSparePartReservationsForSparePart = (sparePartId, params) =>
  request.get(
    `spare_parts/${sparePartId}/spare_part_reservations?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`
  );
export const createSparePartReservationForWorkOrder = (workOrderId, data) =>
  request.post(`work_orders/${workOrderId}/spare_part_reservations`, data);
export const createSparePartReservationForRecurringMaintenance = (recurringMaintenanceId, data) =>
  request.post(`recurring_maintenances/${recurringMaintenanceId}/spare_part_reservations`, data);
export const updateSparePartReservation = (sparePartReservationId, data) =>
  request.patch(`spare_part_reservations/${sparePartReservationId}`, data);
export const deleteSparePartReservation = sparePartReservationId =>
  request.delete(`spare_part_reservations/${sparePartReservationId}`);
