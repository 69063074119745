import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { HeaderOperations } from "state/ducks/header";
import styles from "./style.module.scss";

class ErrorRoute extends Component {
  componentDidMount() {
    this.props.setHeaderOptions({
      title: "Not found"
    });
  }

  componentWillUnmount() {
    this.props.clearHeaderOptions();
  }

  render() {
    return (
      <div className={styles["error-container"]}>
        <h1>This page does not exist</h1>
        <p>
          <Link to="/">Go back</Link>
        </p>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setHeaderOptions: HeaderOperations.setOptions,
      clearHeaderOptions: HeaderOperations.clearOptions
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(ErrorRoute);
