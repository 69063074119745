import React, { Component } from 'react';
import styles from './style.module.scss';
import PropTypes from 'prop-types';
import { HelperFunctions } from 'sdk';

export default class FileUploadWrapper extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  addFile = e => {
    if (e.target.files.length > 0) {
      if (this.props.image) {
        HelperFunctions.base64EncodeFile(e.target.files[0])
          .then(({ file, mime_type, name }) => {
            this.props.onSelectFile({
              file,
              mime_type,
              name,
              extension: HelperFunctions.getExtension(name),
            });
          })
          .catch(e => {
            throw e;
          });
        e.target.value = null;
      } else {
        const file = e.target.files[0];
        this.props.onSelectFile({
          file,
          size: file.size,
          mime_type: file.type,
          name: file.name,
          extension: HelperFunctions.getExtension(file.name),
        });
      }
    }
  };

  render() {
    return (
      <div
        htmlFor="upload-file"
        className={`${styles['upload-file']} ${this.props.inline ? styles['inline'] : ''}`}
        onClick={() => this.fileInputRef.current.click()}
      >
        <React.Fragment>
          {this.props.children}
          <input
            ref={this.fileInputRef}
            id="upload-file"
            type="file"
            disabled={this.props.disabled}
            accept={this.props.accept}
            onChange={e => {
              this.addFile(e);
            }}
          />
        </React.Fragment>
      </div>
    );
  }
}

FileUploadWrapper.propTypes = {
  onSelectFile: PropTypes.func.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
};

FileUploadWrapper.defaultProps = {
  accept: '*',
  disabled: false,
};
