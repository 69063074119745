import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { OrganisationOperations, OrganisationSelectors } from 'state/ducks/organisation';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ContentContainer, Toolbar } from 'views/components/Shared/Layout';
import { CreateSystemModal } from 'views/components/System';
import { List, Button } from 'views/components/Shared/General';
import DeleteSystemModal from 'views/scenes/OrganisationDetail/components/DeleteSystemModal';
import ActivityModal from './ActivityModal';

class Systems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isViewLoading: true,
      isTableLoading: false,
      showCreateSystemModal: false,
      showActivityModal: false,
      showDeleteModal: false,
    };
  }

  componentDidMount() {
    this.setState({ isTableLoading: true });
    this.fetchSystems();
  }

  fetchSystems = () => {
    this.setState({ isTableLoading: true });
    this.props
      .fetchSystems(this.props.match.params.id)
      .then(() => {
        this.setState({
          isTableLoading: false,
          isViewLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isTableLoading: false });
      });
  };

  renderList = () => {
    if (this.state.isTableLoading) {
      return (
        <>
          <List>
            <List.Item small>
              <List.Item.TitleColumn loading />
            </List.Item>

            <List.Item small>
              <List.Item.TitleColumn loading />
            </List.Item>
          </List>
        </>
      );
    }
    return (
      <List>
        {this.props.systems.map(system => {
          return (
            <List.Item
              small
              clickable
              onClick={() =>
                this.setState({ showActivityModal: true, showActivityModalForSystemId: system.id })
              }
            >
              <List.Item.TitleColumn title={system.name} />
              {/* <List.Item.Column alignRight>
                <Button
                  type="icon"
                  icon={<Icon regular red type="trash-alt" />}
                  onClick={e => {
                    e.stopPropagation();
                    this.setState({ showDeleteModal: true, showDeleteModalForSystemId: system.id });
                  }}
                />
              </List.Item.Column>*/}
            </List.Item>
          );
        })}
      </List>
    );
  };

  renderCreateSystemModal = () => {
    return (
      <CreateSystemModal
        open={this.state.showCreateSystemModal}
        createForOrgId={this.props.match.params.id}
        onSystemCreated={() => {
          this.setState({ showCreateSystemModal: false });
        }}
        onClose={() => this.setState({ showCreateSystemModal: false })}
      />
    );
  };

  renderView = () => {
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              onClick={() => this.setState({ showCreateSystemModal: true })}
              translate={false}
              label="Create new"
            />
          }
        />
        <List.Header small background>
          <List.Header.Column flex>Name</List.Header.Column>
        </List.Header>
        {this.renderList()}
      </>
    );
  };

  render() {
    return (
      <>
        <PerfectScrollbar>
          <ContentContainer>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderCreateSystemModal()}
        <ActivityModal
          open={this.state.showActivityModal}
          systemId={this.state.showActivityModalForSystemId}
          onClose={() => this.setState({ showActivityModal: false })}
        />
        <DeleteSystemModal
          open={this.state.showDeleteModal}
          orgId={this.props.match.params.id}
          systemId={this.state.showDeleteModalForSystemId}
          onDelete={() => {
            this.setState({ showDeleteModal: false });
          }}
          onClose={() => {
            this.setState({ showDeleteModal: false });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSystems: OrganisationOperations.fetchSystems,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    systems: OrganisationSelectors.getSystems(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Systems));
