import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, NewInlineModal, SectionHeader, Field } from 'views/components/Shared/General';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export default class Pagination extends Component {
  constructor(props) {
    super(props);
    const { currentPage } = props;
    this.state = {
      currentPage: Number(currentPage),
      searchPage: currentPage,
      showGoToPageInlineModal: false,
      showInvalidGoToPageError: false,
      showPageSizeInlineModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.setState({ currentPage: Number(this.props.currentPage) });
    }
  }

  getStartingPageNumber = () => {
    const { totalPages } = this.props;
    const { currentPage } = this.state;
    if (currentPage - 2 < 1) return 1;
    if (currentPage + 2 >= totalPages && totalPages >= 5) return totalPages - 4;
    return totalPages < 5 ? 1 : currentPage - 2;
  };

  renderPageNumbers = () => {
    const { totalPages } = this.props;
    const { currentPage } = this.state;
    const lastPageNumberIsShowing = currentPage + 4 > totalPages;
    const getLastPageNumber = currentPage > 2 ? currentPage - 2 + 4 : currentPage + 4;
    const pageNumbers = [];
    for (
      let pageNumber = this.getStartingPageNumber();
      pageNumber <= (lastPageNumberIsShowing ? totalPages : getLastPageNumber);
      pageNumber++
    ) {
      if (pageNumbers.length === 5) {
        return pageNumbers;
      } else {
        pageNumbers.push(
          <div
            className={`${styles['page']} ${currentPage === pageNumber ? styles['selected'] : ''}`}
            onClick={() => (pageNumber === currentPage ? null : this.selectPage(pageNumber))}
          >
            {pageNumber}
          </div>
        );
      }
    }
    return pageNumbers;
  };

  renderLoader = () => (
    <ContentLoader
      speed={2}
      width={839}
      height={60}
      viewBox="0 0 839 60"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="330" y="22" rx="3" ry="3" width="180" height="18" />
    </ContentLoader>
  );

  selectPage = page => {
    this.setState({
      currentPage: page,
      showGoToPageInlineModal: false,
    });
    this.props.onSelectPage(page);
  };

  renderGoToPageInlineModal = () => {
    if (this.props.totalPages <= 5) {
      return null;
    }
    return (
      <>
        <div
          ref={ref => (this.goToPageInlineModalPositioningRef = ref)}
          className={`${styles['button']} ${styles['go-to-page']}`}
          onClick={() => {
            this.setState(prevState => ({
              showGoToPageInlineModal: !prevState.showGoToPageInlineModal,
              searchPage: '',
              showInvalidGoToPageError: false,
            }));
          }}
        >
          <FormattedMessage id="components.pagination.go-to-page" />
        </div>
        <NewInlineModal
          minWidth={237}
          position="right"
          positionToRef={this.goToPageInlineModalPositioningRef}
          open={this.state.showGoToPageInlineModal}
          onClose={() => {
            const newPage = Math.abs(this.state.searchPage);
            let isValidPage = true;
            if (newPage <= 0) {
              isValidPage = false;
            }
            if (newPage > this.props.totalPages) {
              isValidPage = false;
            }
            if (newPage === this.state.currentPage) {
              isValidPage = false;
            }
            if (isNaN(this.state.searchPage)) {
              isValidPage = false;
            }
            if (isValidPage) {
              this.selectPage(newPage);
            } else {
              this.setState({ showGoToPageInlineModal: false });
            }
          }}
        >
          <NewInlineModal.Header>
            <SectionHeader noBorderTop>
              <FormattedMessage id="components.pagination.go-to-page" />
            </SectionHeader>
          </NewInlineModal.Header>
          <div className={styles['go-to-page-container']}>
            <Field.Number
              autoFocus
              error={this.state.showInvalidGoToPageError}
              value={this.state.searchPage}
              onChange={searchPage => {
                if (this.state.showInvalidGoToPageError) {
                  this.setState({ showInvalidGoToPageError: false });
                }
                this.setState({ searchPage });
              }}
              onKeyDown={({ key }) => {
                if (key === 'Enter' || key === 'Tab') {
                  const newPage = Math.abs(this.state.searchPage);
                  let isValidPage = true;
                  if (newPage <= 0) {
                    isValidPage = false;
                  }
                  if (newPage > this.props.totalPages) {
                    isValidPage = false;
                  }
                  if (newPage === this.state.currentPage) {
                    isValidPage = false;
                  }
                  if (isNaN(this.state.searchPage)) {
                    isValidPage = false;
                  }
                  if (isValidPage) {
                    this.selectPage(newPage);
                  } else {
                    this.setState({ showInvalidGoToPageError: true });
                  }
                }
              }}
            />
          </div>
        </NewInlineModal>
      </>
    );
  };

  renderPageSizeInlineModal = () => {
    if (this.props.hideOptions) {
      return null;
    }
    return (
      <>
        <div
          ref={ref => (this.pageSizeInlineModalPositioningRef = ref)}
          className={`${styles['button']} ${styles['page-size']}`}
          onClick={() => {
            this.setState(prevState => ({
              showPageSizeInlineModal: !prevState.showPageSizeInlineModal,
            }));
          }}
        >
          <FormattedMessage id="components.pagination.page-size" values={{ count: this.props.pageSize }} />
          <Icon type="caret-down" size={12} />
        </div>
        <NewInlineModal
          minWidth={237}
          position="right"
          positionToRef={this.pageSizeInlineModalPositioningRef}
          open={this.state.showPageSizeInlineModal}
          onClose={() => this.setState({ showPageSizeInlineModal: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                selected={this.props.pageSize === 25}
                onClick={() => {
                  this.setState({ showPageSizeInlineModal: false });
                  this.props.onChangePageSize(25);
                }}
              >
                <FormattedMessage id="components.pagination.page-size" values={{ count: 25 }} />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected={this.props.pageSize === 50}
                onClick={() => {
                  this.setState({ showPageSizeInlineModal: false });
                  this.props.onChangePageSize(50);
                }}
              >
                <FormattedMessage id="components.pagination.page-size" values={{ count: 50 }} />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected={this.props.pageSize === 100}
                onClick={() => {
                  this.setState({ showPageSizeInlineModal: false });
                  this.props.onChangePageSize(100);
                }}
              >
                <FormattedMessage id="components.pagination.page-size" values={{ count: 100 }} />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderOptions = () => {
    if (this.props.hideOptions) {
      return null;
    }
    return (
      <div className={styles['options']}>
        <div className={styles['separator']} />
        {this.renderGoToPageInlineModal()}
        {this.renderPageSizeInlineModal()}
      </div>
    );
  };

  getPageClassNames = () => {
    const { totalPages } = this.props;
    let stylesToApply = [styles['page']];
    if (totalPages === 1) {
      stylesToApply = [...stylesToApply, styles['disabled']];
    }
    return stylesToApply.join(' ');
  };

  render() {
    const { currentPage } = this.state;
    const { totalPages } = this.props;

    let classNames = [styles['pagination-container']];
    if (this.props.blue) {
      classNames = [...classNames, styles['blue']];
    }
    if (this.props.loading) return this.renderLoader();
    return (
      <div className={classNames.join(' ')} style={this.props.style}>
        {this.props.totalEntriesComponent == null ? null : (
          <div className={styles['total-entries']}>{this.props.totalEntriesComponent}</div>
        )}
        <div className={styles['pagination']}>
          {totalPages <= 5 ? null : (
            <div
              className={this.getPageClassNames()}
              onClick={() => {
                if (currentPage !== 1) {
                  this.selectPage(1);
                }
              }}
            >
              <Icon regular type="angle-double-left" />
            </div>
          )}
          <div
            className={this.getPageClassNames()}
            onClick={() => {
              if (currentPage !== 1) {
                this.selectPage(currentPage - 1);
              }
            }}
          >
            <Icon regular type="angle-left" size={16} />
          </div>
          {this.renderPageNumbers()}
          <div
            className={this.getPageClassNames()}
            onClick={() => {
              if (currentPage !== totalPages) {
                this.selectPage(currentPage + 1);
              }
            }}
          >
            <Icon regular type="angle-right" size={16} />
          </div>
          {totalPages <= 5 ? null : (
            <div
              className={this.getPageClassNames()}
              onClick={() => {
                if (currentPage !== totalPages) {
                  this.selectPage(totalPages);
                }
              }}
            >
              <Icon regular type="angle-double-right"></Icon>
            </div>
          )}
        </div>
        {this.renderOptions()}
      </div>
    );
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  hideOptions: PropTypes.bool,
  totalEntriesComponent: PropTypes.node.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  blue: PropTypes.bool,
  style: PropTypes.object,
};

Pagination.defaultProps = {
  currentPage: 1,
  pageSize: 25,
  hideOptions: false,
  style: null,
  onChangePageSize: () => {},
  blue: false,
  totalEntriesComponent: null,
};
