import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listAdditionalCostsAsAdmin = (orgId, params) =>
  request.get(
    `admin/organisations/${orgId}/billing_additional_costs?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`
  );
export const createBillingAdditionalCostAsAdmin = (orgId, data) =>
  request.post(`admin/organisations/${orgId}/billing_additional_costs`, data);
export const fetchBillingAdditionalCostAsAdmin = id => request.get(`admin/billing_additional_costs/${id}`);
export const updateBillingAdditionalCostAsAdmin = (id, data) =>
  request.patch(`admin/billing_additional_costs/${id}`, data);
export const deleteBillingAdditionalCostAsAdmin = id =>
  request.delete(`admin/billing_additional_costs/${id}`);
