import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listPurchaseOrders = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/purchase_orders?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const fetchPurchaseOrder = purchaseOrderId => request.get(`purchase_orders/${purchaseOrderId}`);
export const updatePurchaseOrder = (purchaseOrderId, data) =>
  request.patch(`purchase_orders/${purchaseOrderId}`, data);
export const sendPurchaseOrder = (purchaseOrderId, data) =>
  request.put(`purchase_orders/${purchaseOrderId}/send_email`, data);
export const createPurchaseOrder = (systemId, data) =>
  request.post(`systems/${systemId}/purchase_orders`, data);
export const deletePurchaseOrder = purchaseOrderId => request.delete(`purchase_orders/${purchaseOrderId}`);
