import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listAttachmentFolders = (systemId, params) =>
  request.get(`systems/${systemId}/attachment_folders?${HelperFunctions.convertObjToQueryParameters(params)}`);
export const createAttachmentFolder = (systemId, data) =>
  request.post(`systems/${systemId}/attachment_folders`, data);
export const updateAttachmentFolder = (attachmentFolderId, data) =>
  request.patch(`attachment_folders/${attachmentFolderId}`, data);
export const deleteAttachmentFolder = attachmentFolderId =>
  request.delete(`attachment_folders/${attachmentFolderId}`);
export const listAttachmentFoldersAttachmentCountForAsset = (assetId, params) =>
  request.get(
    `assets/${assetId}/attachment_folder_counts?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
