import { omit } from 'lodash-es';
import EntityTypes from '../types';
import { SDKReduxTypes, HelperFunctions } from 'sdk';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.METER_UNIT_DELETED:
    case SDKReduxTypes.WS_SYSTEM_METER_UNIT_DELETED: {
      let id = null;
      if (action.type === SDKReduxTypes.METER_UNIT_DELETED) {
        id = action.payload.meterUnitId;
      } else {
        id = action.payload.id;
      }
      return omit(state, id);
    }
    case SDKReduxTypes.METER_UNIT_UPDATED:
    case SDKReduxTypes.WS_SYSTEM_METER_UNIT_UPDATED: {
      let data = {};
      if (action.type === SDKReduxTypes.METER_UNIT_UPDATED) {
        data = action.payload.data;
      } else {
        data = action.payload;
      }
      if (data['default'] === true) {
        let newMeterUnitById = {};
        for (let meterUnitId in state) {
          newMeterUnitById[meterUnitId] = {
            ...state[meterUnitId],
            default: state[meterUnitId].id === data.id,
          };
        }
        return newMeterUnitById;
      }
      return state;
    }
    case SDKReduxTypes.METER_UNIT_CREATED:
    case SDKReduxTypes.WS_SYSTEM_METER_UNIT_CREATED: {
      let data = {};
      if (action.type === SDKReduxTypes.METER_UNIT_CREATED) {
        data = action.payload.data;
      } else {
        data = action.payload;
      }
      if (data['default'] === true) {
        let newMeterUnitById = {};
        newMeterUnitById[data.id] = data;
        for (let meterUnitId in state) {
          newMeterUnitById[meterUnitId] = {
            ...state[meterUnitId],
            default: state[meterUnitId].id === data.id,
          };
        }
        return newMeterUnitById;
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.meterUnitById) {
        return HelperFunctions.mergeEntities(state, action.payload.meterUnitById);
      }
      return state;
    default:
      return state;
  }
};
